import React, { useState, useEffect, useRef, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, SearchIcon } from "@heroicons/react/outline";
import moment from "moment";
import amplitude from "amplitude-js";

const AddMeetingsToCollectionModal = ({
  isOpen,
  onClose,
  collectionTitle,
  collectionUUID,
  onAddMeetingsSuccess,
  currentMeetings,
}) => {
  const [availableMeetings, setAvailableMeetings] = useState([]);
  const [selectedMeetings, setSelectedMeetings] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  // For infinite scrolling
  const observer = useRef();
  const listContainerRef = useRef(null);

  // Debounce search input
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  // Reset search results when search term changes
  useEffect(() => {
    if (isOpen) {
      setAvailableMeetings([]);
      setPage(1);
      setHasMore(true);
      fetchMeetings(1, true);
    }
  }, [debouncedSearchTerm, isOpen, collectionUUID]);

  // Setup intersection observer for infinite scrolling
  const lastMeetingRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreMeetings();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  // Load more meetings for infinite scroll
  const loadMoreMeetings = () => {
    if (!isLoading && hasMore) {
      fetchMeetings(page + 1);
    }
  };

  // Fetch meetings from server
  const fetchMeetings = async (pageNum = 1, reset = false) => {
    setIsLoading(true);

    try {
      const params = new URLSearchParams({
        page: pageNum,
        pageSize: 20,
      });

      if (debouncedSearchTerm) {
        params.append("q", debouncedSearchTerm);
      }

      if (collectionUUID) {
        params.append("excludeCollectionUUID", collectionUUID);
      }

      const response = await fetch(
        `https://backend.scribbl.co/enhanced-recordings-all?${params.toString()}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();

        // Update pagination state
        setTotalItems(data.pagination.totalItems);
        setHasMore(pageNum < data.pagination.totalPages);
        setPage(pageNum);

        // Update meetings list
        if (reset) {
          setAvailableMeetings(data.data);
        } else {
          setAvailableMeetings((prev) => [...prev, ...data.data]);
        }
      } else {
        console.error("Error fetching recordings");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Reset when modal opens/closes
  useEffect(() => {
    if (!isOpen) {
      setSelectedMeetings(new Set());
      setSearchTerm("");
      setDebouncedSearchTerm("");
      setAvailableMeetings([]);
      setPage(1);
    }
  }, [isOpen]);

  const toggleMeetingSelection = (meetingId) => {
    setSelectedMeetings((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(meetingId)) {
        newSelected.delete(meetingId);
      } else {
        newSelected.add(meetingId);
      }
      return newSelected;
    });
  };

  const handleAddMeetings = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/meetings`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            meetingIDs: Array.from(selectedMeetings),
          }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add meetings to collection");
      }

      onClose();
      onAddMeetingsSuccess();
      amplitude.getInstance().logEvent("Collection dashboard: meetings added");
    } catch (error) {
      console.error("Error adding meetings to collection:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle clearing search
  const handleClearSearch = () => {
    setSearchTerm("");
  };

  if (!isOpen) return null;

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
        initialFocus={listContainerRef}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="flex justify-between items-center">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Add Meetings to "{collectionTitle}"
                </Dialog.Title>
                <button
                  onClick={onClose}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <XIcon className="h-6 w-6" />
                </button>
              </div>

              {/* Search bar */}
              <div className="mt-4 relative">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search meetings..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-brand-turq focus:border-brand-turq"
                  />
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5 text-gray-400" />
                  </div>
                  {searchTerm && (
                    <button
                      onClick={handleClearSearch}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
                    >
                      <XIcon className="h-4 w-4" />
                    </button>
                  )}
                </div>

                {/* Results count */}
                <div className="text-xs text-gray-500 mt-2 flex justify-between items-center">
                  <span>
                    {isLoading && page === 1
                      ? "Searching..."
                      : totalItems > 0
                      ? `${totalItems} meeting${
                          totalItems !== 1 ? "s" : ""
                        } found`
                      : "No meetings found"}
                  </span>
                  <span>
                    {selectedMeetings.size > 0 &&
                      `${selectedMeetings.size} selected`}
                  </span>
                </div>
              </div>

              {/* Meeting list with infinite scroll */}
              <div
                ref={listContainerRef}
                className="mt-3 max-h-60 overflow-y-auto pr-1 -mr-1 rounded-md"
              >
                {availableMeetings.length === 0 && !isLoading ? (
                  <div className="text-center py-8 text-gray-500">
                    <p>No meetings found</p>
                    {debouncedSearchTerm && (
                      <p className="text-sm mt-1">Try adjusting your search</p>
                    )}
                  </div>
                ) : (
                  <div className="space-y-1">
                    {availableMeetings.map(
                      (meeting, index) =>
                        meeting.meetingTitle && (
                          <div
                            key={meeting.ID}
                            ref={
                              index === availableMeetings.length - 1
                                ? lastMeetingRef
                                : null
                            }
                            onClick={() => toggleMeetingSelection(meeting.ID)}
                            className={`p-3 rounded-md cursor-pointer transition-colors flex items-center 
                          ${
                            selectedMeetings.has(meeting.ID)
                              ? "bg-brand-turq bg-opacity-10 hover:bg-opacity-20 border border-brand-turq"
                              : "hover:bg-gray-100 border border-transparent"
                          }`}
                          >
                            <div className="flex-1 min-w-0 pr-2">
                              <p className="font-medium text-gray-800 truncate">
                                {meeting.meetingTitle}
                              </p>
                              <p className="text-xs text-gray-500 mt-1">
                                {moment(meeting.CreatedAt).format(
                                  "MMM D, YYYY"
                                )}
                              </p>
                            </div>
                            <input
                              type="checkbox"
                              checked={selectedMeetings.has(meeting.ID)}
                              onChange={() => {}}
                              className="h-5 w-5 text-brand-turq rounded border-gray-300 focus:ring-brand-turq"
                              onClick={(e) => e.stopPropagation()}
                            />
                          </div>
                        )
                    )}

                    {/* Loading indicator */}
                    {isLoading && (
                      <div className="py-3 text-center text-sm text-gray-500">
                        <div className="flex justify-center items-center">
                          <svg
                            className="animate-spin h-5 w-5 text-brand-turq mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Loading more meetings...
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Action buttons */}
              <div className="mt-6 flex space-x-3">
                <button
                  onClick={onClose}
                  className="flex-1 py-2 px-4 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddMeetings}
                  disabled={selectedMeetings.size === 0 || isSubmitting}
                  className={`flex-1 py-2 px-4 rounded-lg transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq ${
                    selectedMeetings.size > 0 && !isSubmitting
                      ? "bg-brand-turq hover:bg-opacity-90 text-white"
                      : "bg-gray-200 text-gray-500 cursor-not-allowed"
                  }`}
                >
                  {isSubmitting ? (
                    <div className="flex items-center justify-center">
                      <svg
                        className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Adding...
                    </div>
                  ) : (
                    <>
                      Add{" "}
                      {selectedMeetings.size > 0
                        ? `${selectedMeetings.size} Meeting${
                            selectedMeetings.size !== 1 ? "s" : ""
                          }`
                        : "Meetings"}
                    </>
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddMeetingsToCollectionModal;
