import React, { useState } from "react";
import {
  ArrowCircleRight,
  CheckCircle,
  CircleHalf,
  RocketLaunch,
  Brain,
  SlackLogo,
  PaperPlaneTilt,
  PencilSimple,
  EnvelopeSimple,
  ChatCenteredText,
  X,
} from "phosphor-react";
import { motion, AnimatePresence } from "framer-motion";

export function ActionsTab({ meetingId, actionOutputs }) {
  // Which action is selected in the normal (two-column) view?
  const [selectedActionId, setSelectedActionId] = useState(
    actionOutputs.length > 0 ? actionOutputs[0].ID : null
  );

  // --- AI Editing State ---
  // If set, we're in full-width AI editing mode for this action ID
  const [aiEditingActionId, setAiEditingActionId] = useState(null);
  const [editingActionType, setEditingActionType] = useState(null);

  // Email subject and body while AI editing
  const [draftSubject, setDraftSubject] = useState("");
  const [draftBody, setDraftBody] = useState("");

  // Slack message content
  const [slackMessageContent, setSlackMessageContent] = useState("");

  // User instructions/feedback for AI
  const [feedback, setFeedback] = useState("");

  // Loading state while AI processes
  const [loadingAi, setLoadingAi] = useState(false);

  // Find the currently selected action (for normal view)
  const currentAction = actionOutputs.find((a) => a.ID === selectedActionId);

  // Helpers
  function getActionDisplayTitle(action) {
    if (action.name && action.name.trim() !== "") return action.name;
    switch (action.actionType) {
      case "follow_up_email":
      case "send_followup_email":
        return "Send Follow-Up Email";
      case "google_doc":
      case "create_google_doc":
        return "Create Google Doc";
      case "slack_post":
      case "slack_message":
        return "Slack Message";
      default:
        return action.actionType || "Unknown Action";
    }
  }

  function getActionTypeIcon(action) {
    switch (action.actionType) {
      case "follow_up_email":
      case "send_followup_email":
        return <EnvelopeSimple size={16} className="text-brand-turq" />;
      case "google_doc":
      case "create_google_doc":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-brand-turq"
          >
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" y1="13" x2="8" y2="13"></line>
            <line x1="16" y1="17" x2="8" y2="17"></line>
            <polyline points="10 9 9 9 8 9"></polyline>
          </svg>
        );
      case "slack_post":
      case "slack_message":
        return <SlackLogo size={16} className="text-brand-turq" />;
      default:
        return <ChatCenteredText size={16} className="text-brand-turq" />;
    }
  }

  function statusIcon(action) {
    const st = (action.status || "Draft").toLowerCase();
    if (["done", "completed", "sent"].includes(st)) {
      return <CheckCircle size={16} className="text-green-500" />;
    }
    return <CircleHalf size={16} className="text-gray-400" />;
  }

  function parseContent(action) {
    if (typeof action.content === "string") {
      try {
        return JSON.parse(action.content);
      } catch (e) {
        return { raw: action.content };
      }
    }
    return action.content || {};
  }

  // Start AI editing (enters full-width mode)
  const startAiEditing = (action) => {
    setAiEditingActionId(action.ID);
    setEditingActionType(action.actionType);

    const content = parseContent(action);

    if (
      action.actionType === "follow_up_email" ||
      action.actionType === "send_followup_email"
    ) {
      setDraftSubject(content.subject || "");
      setDraftBody(content.body || "");
    } else if (
      action.actionType === "slack_post" ||
      action.actionType === "slack_message"
    ) {
      setSlackMessageContent(content.message || content.text || "");
    }

    setFeedback("");
  };

  // Cancel AI editing mode
  const cancelAiEditing = () => {
    setAiEditingActionId(null);
    setEditingActionType(null);
    setLoadingAi(false);
    setFeedback("");
  };

  // Mock a backend call to have AI tweak the content
  const handleAiTweak = async () => {
    setLoadingAi(true);
    try {
      // Simulate API call delay
      await new Promise((res) => setTimeout(res, 1200));

      if (
        editingActionType === "follow_up_email" ||
        editingActionType === "send_followup_email"
      ) {
        // Demo effect for emails
        setDraftSubject((prev) => prev + " (AI Tweaked)");
        setDraftBody((prev) => prev + "\n\n[AI Tweaked Version]");
      } else if (
        editingActionType === "slack_post" ||
        editingActionType === "slack_message"
      ) {
        // Demo effect for Slack messages
        setSlackMessageContent((prev) => prev + "\n\n[AI Enhanced Message]");
      }
    } catch (error) {
      console.error("Error tweaking content with AI:", error);
    } finally {
      setLoadingAi(false);
    }
  };

  // Save final AI-edited content
  const handleSaveAiEdit = async () => {
    try {
      // Prepare the content based on action type
      let content;

      if (
        editingActionType === "follow_up_email" ||
        editingActionType === "send_followup_email"
      ) {
        content = { subject: draftSubject, body: draftBody };
      } else if (
        editingActionType === "slack_post" ||
        editingActionType === "slack_message"
      ) {
        content = { message: slackMessageContent };
      }

      console.log("Saved content:", {
        meetingId,
        actionId: aiEditingActionId,
        type: editingActionType,
        content,
      });

      // End AI editing mode
      setAiEditingActionId(null);
      setEditingActionType(null);
    } catch (error) {
      console.error("Error saving AI-edited content:", error);
    }
  };

  // Open in Gmail with account chooser
  const handleOpenInGmail = (subject, body) => {
    // Create the compose parameters
    const composeParams = `view=cm&fs=1&su=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Use the account chooser URL that will let the user select which account to use
    const accountChooserUrl = `https://accounts.google.com/AccountChooser?continue=${encodeURIComponent(
      `https://mail.google.com/mail/?${composeParams}`
    )}`;

    window.open(accountChooserUrl, "_blank");
  };

  // Open Google Doc
  const handleOpenGoogleDoc = (docUrl) => {
    if (docUrl) {
      window.open(docUrl, "_blank");
    } else {
      alert("No Google Doc URL available.");
    }
  };

  // Open in ChatGPT with context (removed Google Doc option)
  const handleOpenInChatGPT = (action) => {
    let prompt = "";
    const content = parseContent(action);

    if (
      action.actionType === "follow_up_email" ||
      action.actionType === "send_followup_email"
    ) {
      const subject = content.subject || "(No Subject)";
      const body = content.body || "(Empty body)";
      prompt = `Meeting follow-up email:\n\nSubject: ${subject}\n\nBody:\n${body}\n\nPlease help improve this follow-up email. The email should be professional and concise.`;
    } else if (
      action.actionType === "slack_post" ||
      action.actionType === "slack_message"
    ) {
      const message = content.message || content.text || "(Empty message)";
      prompt = `Meeting Slack message:\n\n${message}\n\nPlease help improve this Slack message. It should be clear and concise.`;
    }

    // Add meeting transcript context if available
    if (action.meetingTranscript) {
      prompt += `\n\nHere's the meeting transcript for context:\n${action.meetingTranscript}`;
    }

    const encoded = encodeURIComponent(prompt);
    window.open(`https://chat.openai.com/?prompt=${encoded}`, "_blank");
  };

  // Render the normal (two-column) action details
  const renderActionDetails = (action) => {
    if (!action) {
      return (
        <div className="text-sm text-gray-500">
          Select an action to view details
        </div>
      );
    }

    const { actionType } = action;
    const content = parseContent(action);
    const labelStatus = (action.status || "Draft").toLowerCase();

    // Email action type
    if (
      actionType === "follow_up_email" ||
      actionType === "send_followup_email"
    ) {
      const subject = content.subject || "(No Subject)";
      const body = content.body || "(Empty body)";

      return (
        <div className="space-y-4">
          {/* Email Content */}
          <div className="bg-white border border-gray-200 rounded-md p-3 shadow-sm">
            <p className="font-semibold text-gray-800">
              Subject: <span className="font-normal">{subject}</span>
            </p>
            <hr className="my-2" />
            <div className="max-h-48 overflow-auto whitespace-pre-wrap text-gray-700 text-sm leading-relaxed">
              {body}
            </div>
          </div>

          <p className="text-xs text-gray-400">Status: {labelStatus}</p>

          {/* Action Buttons */}
          <div className="flex items-center space-x-2 flex-wrap">
            <button
              onClick={() => startAiEditing(action)}
              className="px-3 py-1.5 bg-brand-turq text-white text-xs rounded-md hover:bg-brand-green transition inline-flex items-center space-x-1 mb-2 mr-2"
            >
              <RocketLaunch size={14} />
              <span>Edit with AI</span>
            </button>
            <button
              onClick={() => handleOpenInChatGPT(action)}
              className="px-3 py-1.5 bg-brand-turq text-white text-xs rounded-md hover:bg-brand-green transition inline-flex items-center space-x-1 mb-2 mr-2"
            >
              <Brain size={14} />
              <span>Open in ChatGPT</span>
            </button>
            <button
              onClick={() => handleOpenInGmail(subject, body)}
              className="px-3 py-1.5 bg-gray-100 text-gray-700 text-xs rounded-md hover:bg-gray-200 transition inline-flex items-center space-x-1 mb-2"
              title="Opens the account chooser so you can select which Gmail account to use"
            >
              <PaperPlaneTilt size={14} />
              <span>Open in Gmail</span>
            </button>
          </div>
        </div>
      );
    }

    // Google Doc action type
    else if (
      actionType === "google_doc" ||
      actionType === "create_google_doc"
    ) {
      const title = content.title || "(Untitled Document)";
      const docUrl = content.url || "";
      const createdAt = content.createdAt
        ? new Date(content.createdAt).toLocaleDateString()
        : null;

      return (
        <div className="space-y-4">
          {/* Google Doc - Document Card */}
          <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
            {/* Google Docs-like header bar */}
            <div className="bg-blue-600 py-2 px-4 flex items-center">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path
                  d="M8 19H16V21H8V19ZM4 3H20C21.1046 3 22 3.89543 22 5V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17V5C2 3.89543 2.89543 3 4 3ZM4 5V17H20V5H4ZM12 17H8V15H12V17ZM16 13H8V11H16V13ZM16 9H8V7H16V9Z"
                  fill="white"
                />
              </svg>
              <span className="text-white font-medium text-sm">
                Google Document
              </span>
            </div>

            {/* Document Content */}
            <div className="p-5">
              <div className="mb-4">
                <label className="block text-xs uppercase tracking-wide text-gray-500 mb-1">
                  Document Title
                </label>
                <h3 className="text-lg font-medium text-gray-800">{title}</h3>
              </div>

              {createdAt && (
                <div className="mb-4">
                  <label className="block text-xs uppercase tracking-wide text-gray-500 mb-1">
                    Created On
                  </label>
                  <p className="text-sm text-gray-700">{createdAt}</p>
                </div>
              )}

              <button
                onClick={() => handleOpenGoogleDoc(docUrl)}
                className="w-full mt-2 px-4 py-3 bg-blue-50 border border-blue-100 text-blue-700 rounded-md hover:bg-blue-100 transition duration-150 flex items-center justify-center space-x-2 font-medium"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                  <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" y1="14" x2="21" y2="3"></line>
                </svg>
                <span>Open Document</span>
              </button>
            </div>
          </div>

          <p className="text-xs text-gray-400">Status: {labelStatus}</p>
        </div>
      );
    }

    // Slack message action type
    else if (actionType === "slack_post" || actionType === "slack_message") {
      const message = content.message || content.text || "(Empty message)";
      const channel = content.channel || "#general";
      const isSent = ["sent", "done", "completed"].includes(labelStatus);

      return (
        <div className="space-y-4">
          {/* Slack Message Content */}
          <div className="bg-white border border-gray-200 rounded-md p-3 shadow-sm">
            <div className="flex items-center space-x-2 mb-2">
              <SlackLogo size={20} weight="fill" className="text-purple-500" />
              <p className="font-semibold text-gray-800">
                Posted to <span className="text-purple-600">{channel}</span>
              </p>
            </div>
            <hr className="my-2" />
            <div className="max-h-48 overflow-auto whitespace-pre-wrap text-gray-700 text-sm leading-relaxed">
              {message}
            </div>
          </div>

          <p className="text-xs text-gray-400">
            Status: {isSent ? "Message sent to Slack" : labelStatus}
          </p>

          {/* Action Buttons */}
          <div className="flex items-center space-x-2">
            {!isSent && (
              <button
                onClick={() => startAiEditing(action)}
                className="px-3 py-1.5 bg-brand-turq text-white text-xs rounded-md hover:bg-brand-green transition inline-flex items-center space-x-1"
              >
                <PencilSimple size={14} />
                <span>Edit Message</span>
              </button>
            )}
            <button
              onClick={() => handleOpenInChatGPT(action)}
              className="px-3 py-1.5 bg-brand-turq text-white text-xs rounded-md hover:bg-brand-green transition inline-flex items-center space-x-1"
            >
              <Brain size={14} />
              <span>Open in ChatGPT</span>
            </button>
          </div>
        </div>
      );
    }

    // If it's not a recognized action type
    return (
      <div className="space-y-2">
        <p className="text-sm text-gray-700">
          (No specialized preview available for this action type.)
        </p>
        <p className="text-xs text-gray-400">Status: {labelStatus}</p>
      </div>
    );
  };

  // Renders the two-column layout (normal view)
  const renderTwoColumnLayout = () => {
    return (
      <div className="flex h-full">
        {/* Left Column: Actions List */}
        <div className="w-1/3 border-r border-gray-200 p-3 space-y-2 overflow-y-auto">
          <h2 className="text-base font-semibold text-gray-800 flex items-center space-x-2 mb-2">
            <ArrowCircleRight
              size={20}
              weight="fill"
              className="text-brand-turq"
            />
            <span>Actions</span>
          </h2>
          {actionOutputs.length === 0 ? (
            <div className="p-4 bg-gray-50 border border-gray-200 rounded-md">
              <p className="text-sm text-gray-500">
                No post-meeting actions yet.
              </p>
            </div>
          ) : (
            <ul className="space-y-1">
              {actionOutputs.map((action) => {
                const label = getActionDisplayTitle(action);
                const selected = action.ID === selectedActionId;
                return (
                  <li key={action.ID}>
                    <button
                      className={`w-full text-left px-2 py-2 rounded-md flex items-center space-x-2 ${
                        selected
                          ? "bg-brand-turq text-white"
                          : "bg-gray-50 text-gray-700 hover:bg-gray-100"
                      }`}
                      onClick={() => setSelectedActionId(action.ID)}
                    >
                      <div className="flex items-center space-x-2 flex-1 min-w-0">
                        {selected ? (
                          <div className="text-white">
                            {getActionTypeIcon(action)}
                          </div>
                        ) : (
                          getActionTypeIcon(action)
                        )}
                        <span className="text-sm font-medium truncate">
                          {label}
                        </span>
                      </div>
                      <div>{statusIcon(action)}</div>
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        {/* Right Column: Action Details */}
        <div className="w-2/3 p-4 overflow-y-auto">
          {actionOutputs.length === 0 ? (
            <div className="text-sm text-gray-500">No actions to show.</div>
          ) : currentAction ? (
            <div className="space-y-4">
              <h3 className="text-sm font-semibold text-gray-800">
                Action Details
              </h3>
              {renderActionDetails(currentAction)}
            </div>
          ) : (
            <p className="text-sm text-gray-500">Select an action.</p>
          )}
        </div>
      </div>
    );
  };

  // Renders the full-width AI editing mode (side by side: content vs. AI instructions)
  const renderAiEditingLayout = () => {
    const isEmailEditing =
      editingActionType === "follow_up_email" ||
      editingActionType === "send_followup_email";
    const isSlackEditing =
      editingActionType === "slack_post" ||
      editingActionType === "slack_message";

    return (
      <div className="w-full flex flex-col h-full">
        {/* Top bar for Title + Cancel/Save */}
        <div className="flex justify-between items-center p-4 border-b border-gray-200 bg-white">
          <div className="flex items-center space-x-2 text-sm font-semibold text-gray-800">
            {isEmailEditing && (
              <EnvelopeSimple size={16} className="text-brand-turq" />
            )}
            {isSlackEditing && (
              <SlackLogo size={16} className="text-brand-turq" />
            )}
            <span>
              {isEmailEditing
                ? "Edit Email with AI"
                : isSlackEditing
                ? "Edit Slack Message with AI"
                : "Edit Content with AI"}
            </span>
          </div>
          <div className="space-x-2">
            <button
              onClick={cancelAiEditing}
              disabled={loadingAi}
              className="px-3 py-1.5 bg-gray-200 text-gray-700 text-xs rounded-md hover:bg-gray-300 transition inline-flex items-center space-x-1"
            >
              <X size={14} />
              <span>Cancel</span>
            </button>
            <button
              onClick={handleSaveAiEdit}
              disabled={loadingAi}
              className="px-3 py-1.5 bg-brand-turq text-white text-xs rounded-md hover:bg-brand-green transition inline-flex items-center space-x-1"
            >
              <CheckCircle size={14} />
              <span>Save</span>
            </button>
          </div>
        </div>

        {/* Main content: side-by-side panels */}
        <div className="flex flex-1 flex-row w-full overflow-hidden">
          {/* Left Panel: Email/Content Subject & Body */}
          <div className="w-1/2 p-4 bg-gray-50 border-r border-gray-200 overflow-auto">
            <div className="bg-white border border-gray-200 rounded-md p-3 shadow-sm space-y-3">
              {isEmailEditing ? (
                // Email editing fields
                <>
                  <div>
                    <label
                      htmlFor="ai-subject-input"
                      className="block text-sm font-semibold text-gray-700 mb-1"
                    >
                      Subject
                    </label>
                    <input
                      id="ai-subject-input"
                      className="w-full border border-gray-300 rounded p-2 text-sm focus:outline-none focus:ring-1 focus:ring-brand-turq"
                      type="text"
                      value={draftSubject}
                      onChange={(e) => setDraftSubject(e.target.value)}
                      disabled={loadingAi}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="ai-body-textarea"
                      className="block text-sm font-semibold text-gray-700 mb-1"
                    >
                      Body
                    </label>
                    <textarea
                      id="ai-body-textarea"
                      className="w-full border border-gray-300 rounded p-2 text-sm focus:outline-none focus:ring-1 focus:ring-brand-turq h-64"
                      value={draftBody}
                      onChange={(e) => setDraftBody(e.target.value)}
                      disabled={loadingAi}
                    />
                  </div>
                </>
              ) : isSlackEditing ? (
                // Slack message editing fields
                <div>
                  <label
                    htmlFor="ai-slack-message-textarea"
                    className="block text-sm font-semibold text-gray-700 mb-1"
                  >
                    Slack Message
                  </label>
                  <textarea
                    id="ai-slack-message-textarea"
                    className="w-full border border-gray-300 rounded p-2 text-sm focus:outline-none focus:ring-1 focus:ring-brand-turq h-64"
                    value={slackMessageContent}
                    onChange={(e) => setSlackMessageContent(e.target.value)}
                    disabled={loadingAi}
                  />
                </div>
              ) : (
                // Generic content editing
                <div>
                  <label
                    htmlFor="ai-content-textarea"
                    className="block text-sm font-semibold text-gray-700 mb-1"
                  >
                    Content
                  </label>
                  <textarea
                    id="ai-content-textarea"
                    className="w-full border border-gray-300 rounded p-2 text-sm focus:outline-none focus:ring-1 focus:ring-brand-turq h-64"
                    value={
                      "Content editing not available for this action type."
                    }
                    disabled={true}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Right Panel: AI Instructions / "Revise" button */}
          <div className="w-1/2 p-4 bg-gray-50 overflow-auto">
            <div className="bg-white border border-gray-200 rounded-md p-3 shadow-sm flex flex-col h-full">
              <div className="flex-1">
                <label
                  htmlFor="ai-feedback-textarea"
                  className="block text-sm font-semibold text-gray-700 mb-1"
                >
                  Provide instructions for AI
                </label>
                <textarea
                  id="ai-feedback-textarea"
                  className="w-full border border-gray-300 rounded p-2 text-sm focus:outline-none focus:ring-1 focus:ring-brand-turq h-40"
                  placeholder={
                    isEmailEditing
                      ? "e.g. 'Make it more concise', 'Change the tone to be friendlier', etc."
                      : isSlackEditing
                      ? "e.g. 'Make it more casual', 'Add bullet points for clarity', etc."
                      : "Provide instructions for AI to improve the content"
                  }
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  disabled={loadingAi}
                />
              </div>

              <div className="mt-3 flex">
                <button
                  onClick={handleAiTweak}
                  disabled={loadingAi}
                  className={`px-3 py-1.5 text-white text-xs rounded-md transition inline-flex items-center space-x-1 ${
                    loadingAi
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-brand-turq hover:bg-brand-green"
                  }`}
                >
                  {loadingAi ? (
                    <span>Revising...</span>
                  ) : (
                    <>
                      <RocketLaunch size={14} />
                      <span>Revise with AI</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // We animate the transition between normal view and AI editing view
  return (
    <div className="bg-white border border-gray-200 rounded-md shadow-sm w-full h-full">
      <AnimatePresence mode="wait">
        {aiEditingActionId ? (
          <motion.div
            key="editing"
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="w-full h-full"
          >
            {renderAiEditingLayout()}
          </motion.div>
        ) : (
          <motion.div
            key="normal"
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 50, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="w-full h-full"
          >
            {renderTwoColumnLayout()}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
