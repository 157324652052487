import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  ChatIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  XIcon,
  SearchIcon,
  DocumentTextIcon,
  CheckIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  SparklesIcon,
  PencilIcon,
  ExclamationIcon,
  CalendarIcon,
  ClockIcon,
  UserCircleIcon,
  ExternalLinkIcon,
  RefreshIcon,
  LightningBoltIcon,
  SelectorIcon,
} from "@heroicons/react/outline";
import { format, parseISO } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { Dialog, Transition } from "@headlessui/react";

/**
 * MeetingWorkflowSlack Component
 *
 * A conversational UI for configuring Slack channel post automations.
 * This component handles the Slack-specific configuration flow after
 * a user has selected the "Post in Slack Channel" automation type.
 */
export default function MeetingWorkflowSlack({
  initialData = {},
  onDataChange,
  onComplete,
  onCancel,
  userEmail = "",
  meetingTypeName = "Meeting",
  meetingTypeId = null, // Add meetingTypeId prop to match email component
}) {
  // --------------------------
  // State management
  // --------------------------
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [workflowComplete, setWorkflowComplete] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [automationCreated, setAutomationCreated] = useState(false);

  // Slack configuration state
  const [slackName, setSlackName] = useState(
    initialData.name || `${meetingTypeName} Slack Update`
  );
  const [slackConnected, setSlackConnected] = useState(false);
  const [checkingSlackStatus, setCheckingSlackStatus] = useState(false);
  const [slackChannels, setSlackChannels] = useState([]);
  const [loadingSlackChannels, setLoadingSlackChannels] = useState(false);
  const [slackChannel, setSlackChannel] = useState(
    initialData.slackChannel || ""
  );
  const [slackPrompt, setSlackPrompt] = useState(initialData.prompt || "");
  const [channelSearch, setChannelSearch] = useState("");
  const [channelError, setChannelError] = useState("");

  // Dropdown state for channel selector
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Test meeting state
  const [meetingModalOpen, setMeetingModalOpen] = useState(false);
  const [testSearchTerm, setTestSearchTerm] = useState("");
  const [testSearchType, setTestSearchType] = useState("quick");
  const [testMeetings, setTestMeetings] = useState([]);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [selectedTestMeeting, setSelectedTestMeeting] = useState(null);

  // Preview state
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewError, setPreviewError] = useState("");
  const [previewResult, setPreviewResult] = useState(null);

  // API base URL
  const API_BASE = "https://backend.scribbl.co";

  // Refs for focusing inputs
  const inputRefs = useRef([]);
  const contentRef = useRef(null);
  const searchInputRef = useRef(null);

  // --------------------------
  // Questions / Steps
  // --------------------------
  const questions = [
    {
      id: "slack-name",
      title: "What do you want to name this Slack post automation?",
      subtitle: "Give your Slack automation a descriptive name",
      validate: (value) => value && value.trim().length > 0,
      errorMessage: "Please provide a name for your Slack post automation",
      inputType: "text",
      value: slackName,
      onChange: setSlackName,
      placeholder: `${meetingTypeName} Slack Update`,
    },
    {
      id: "slack-connection",
      title: "Connect to your Slack workspace",
      subtitle: "Select the channel where your meeting updates will be posted",
      inputType: "slackConnection",
      validate: () => slackConnected && slackChannel.trim().length > 0,
      errorMessage: "Please connect to Slack and select a channel",
    },
    {
      id: "slack-prompt",
      title: "Customize your Slack post content",
      subtitle:
        "Define what information should be shared in Slack after meetings",
      inputType: "slackPrompt",
      validate: () => slackPrompt.trim().length > 0,
      errorMessage: "Please provide instructions for your Slack post content",
    },
    {
      id: "test-meeting",
      title: "Select a meeting to test your Slack post",
      subtitle: "See how your Slack update would look after a real meeting",
      inputType: "testMeeting",
      validate: () => true, // Optional step
    },
    {
      id: "preview-result",
      title: "Review your Slack post preview",
      subtitle: "This is how your automated Slack message will look",
      inputType: "previewResult",
      validate: () => true,
    },
  ];

  // --------------------------
  // Effects
  // --------------------------
  // Update parent component with current data
  useEffect(() => {
    if (onDataChange) {
      onDataChange({
        name: slackName,
        prompt: slackPrompt,
        slackChannel: slackChannel,
        isSlackConnected: slackConnected,
      });
    }
  }, [slackName, slackPrompt, slackChannel, slackConnected, onDataChange]);

  // Check Slack connection status on mount
  useEffect(() => {
    checkSlackStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Focus on input when question changes
  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRefs.current[currentQuestion]) {
        inputRefs.current[currentQuestion]?.focus();
      }
    }, 500);

    // Scroll to top of content when question changes
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }

    return () => clearTimeout(timer);
  }, [currentQuestion]);

  // Handle clicks outside of dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Auto-finalize when workflow is complete
  useEffect(() => {
    if (workflowComplete && !automationCreated) {
      // We'll finalize creation if not done yet
      finalizeAutomationCreation();
      setAutomationCreated(true);
    }
  }, [workflowComplete, automationCreated]);

  // --------------------------
  // Helper Functions
  // --------------------------
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // --------------------------
  // Slack Connection Methods
  // --------------------------
  const checkSlackStatus = async () => {
    setCheckingSlackStatus(true);
    setChannelError("");

    try {
      const resp = await fetch(`${API_BASE}/slack/status`, {
        credentials: "include",
      });

      if (!resp.ok) {
        throw new Error("Failed to check Slack status");
      }

      const data = await resp.json();
      setSlackConnected(data.connected || false);

      if (data.connected) {
        // Load channels if connected
        await loadSlackChannelsFromAPI();
      }
    } catch (err) {
      console.error("Error checking Slack status:", err);
      setChannelError("Failed to check Slack connection status");
    } finally {
      setCheckingSlackStatus(false);
    }
  };

  const loadSlackChannelsFromAPI = async () => {
    setLoadingSlackChannels(true);
    setChannelError("");

    try {
      const resp = await fetch(`${API_BASE}/slack/channels`, {
        credentials: "include",
      });

      if (!resp.ok) {
        throw new Error("Failed to fetch Slack channels");
      }

      const data = await resp.json();
      setSlackChannels(data.channels || []);
    } catch (err) {
      console.error("Error loading Slack channels:", err);
      setChannelError("Failed to load Slack channels. Please try again.");
    } finally {
      setLoadingSlackChannels(false);
    }
  };

  const handleConnectSlack = async () => {
    try {
      const resp = await fetch(`${API_BASE}/slack/connect`, {
        credentials: "include",
      });

      if (!resp.ok) {
        throw new Error("Failed to get Slack connect URL");
      }

      const data = await resp.json();
      if (data.authUrl) {
        window.open(data.authUrl, "_blank");
      }
    } catch (err) {
      setChannelError("Error connecting to Slack: " + err.message);
    }
  };

  const handleSelectChannel = (channelId) => {
    setSlackChannel(channelId);

    // Find the channel name for display
    const selectedChannel = slackChannels.find((ch) => ch.id === channelId);
    if (selectedChannel) {
      console.log(`Selected channel: #${selectedChannel.name}`);
    }
  };

  // --------------------------
  // Create Meeting Action
  // --------------------------
  async function createMeetingAction(actionData) {
    try {
      const response = await fetch(`${API_BASE}/meeting-actions`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(actionData),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to create meeting action: ${response.statusText}`
        );
      }
      return await response.json();
    } catch (error) {
      console.error("Error creating meeting action:", error);
      return null;
    }
  }

  /**
   * finalizeAutomationCreation
   *
   * Gathers the final data from this wizard, creates a meeting action on the server
   * (if possible), then calls onComplete with the new action data.
   */
  async function finalizeAutomationCreation() {
    // Get channel name if available
    const selectedChannelObj = slackChannels.find(
      (ch) => ch.id === slackChannel
    );
    const channelName = selectedChannelObj ? selectedChannelObj.name : "";

    // Build local data object from user inputs
    const completeData = {
      type: "slack_post",
      name: slackName,
      slackPrompt: slackPrompt,
      slackChannelID: slackChannel,
      slackChannelName: channelName,
    };

    // If no meetingTypeId was given, we can still pass the data to parent
    // but we won't be able to attach the action to a meeting type on the server.
    if (!meetingTypeId) {
      console.warn(
        "No meetingTypeId provided; cannot attach Slack action to a meeting type in the database."
      );
      if (onComplete) {
        onComplete({
          ...completeData,
          error: "No meetingTypeId provided, not created on server",
        });
      }
      return;
    }

    // Attempt to create action on server
    setIsTransitioning(true);
    const payload = {
      meetingTypeID: meetingTypeId,
      name: slackName,
      actionType: "slack_post",
      description: `Automated Slack messages for ${meetingTypeName}`,
      customData: JSON.stringify(completeData), // Pass complete config as a JSON string
      isGlobal: false, // Since this action is specific to a meeting type
    };

    try {
      const createdAction = await createMeetingAction(payload);
      setIsTransitioning(false);

      // If creation failed, we can still pass local data
      if (!createdAction) {
        console.warn("Creation on server failed; returning local config only.");
        if (onComplete) {
          onComplete({
            ...completeData,
            error: "Action creation failed on server",
          });
        }
        return;
      }

      // If creation succeeded, pass that final action data to parent
      if (onComplete) {
        onComplete({
          ...completeData,
          ...createdAction, // merges server fields (ID, timestamps, etc.)
        });
      }
    } catch (error) {
      console.error("Failed to create action:", error);
      setIsTransitioning(false);

      // Still call onComplete with error state
      if (onComplete) {
        onComplete({
          ...completeData,
          error: error.message || "Unknown error creating action",
        });
      }
    }
  }

  // Render channel dropdown
  // Updated renderChannelDropdown function with fixed dropdown positioning
  const renderChannelDropdown = () => {
    const filteredChannels =
      channelSearch.trim() === ""
        ? slackChannels
        : slackChannels.filter((ch) =>
            ch.name.toLowerCase().includes(channelSearch.toLowerCase())
          );

    const selectedChannel = slackChannels.find((ch) => ch.id === slackChannel);

    return (
      <div className="relative w-full max-w-sm" ref={dropdownRef}>
        <label
          htmlFor="channel-search"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Select a channel
        </label>

        {/* Selected channel display / search input */}
        <div className="relative border border-gray-300 rounded-lg focus-within:ring-1 focus-within:ring-brand-turq focus-within:border-brand-turq">
          <input
            id="channel-search"
            type="text"
            className="w-full border-none rounded-lg py-2 pl-3 pr-10 text-sm"
            placeholder={
              selectedChannel
                ? `#${selectedChannel.name}`
                : "Search channels..."
            }
            value={
              dropdownOpen
                ? channelSearch
                : selectedChannel
                ? `#${selectedChannel.name}`
                : ""
            }
            onChange={(e) => {
              setChannelSearch(e.target.value);
              // Open dropdown on typing
              if (!dropdownOpen) setDropdownOpen(true);
            }}
            onFocus={() => setDropdownOpen(true)}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">
            <button
              type="button"
              className="flex items-center justify-center h-6 w-6 rounded-full focus:outline-none"
              aria-expanded={dropdownOpen}
              aria-haspopup="listbox"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Dropdown options - Updated positioning */}
        {dropdownOpen && (
          <div
            className="fixed inset-0 z-30 overflow-y-auto"
            onClick={(e) => {
              // Close the dropdown when clicking outside
              if (e.target === e.currentTarget) {
                setDropdownOpen(false);
              }
            }}
          >
            <div
              className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 focus:outline-none ring-1 ring-black ring-opacity-5"
              style={{
                maxHeight: "250px",
                overflowY: "auto",
                top: dropdownRef.current?.getBoundingClientRect().bottom,
                left: dropdownRef.current?.getBoundingClientRect().left,
                width: dropdownRef.current?.offsetWidth,
              }}
            >
              {filteredChannels.length === 0 ? (
                <div className="px-4 py-2 text-sm text-gray-500">
                  No channels found
                </div>
              ) : (
                <ul className="list-none" role="listbox">
                  {filteredChannels.map((channel) => (
                    <li
                      key={channel.id}
                      role="option"
                      aria-selected={channel.id === slackChannel}
                      className={`cursor-pointer select-none relative py-2 pl-8 pr-4 text-sm ${
                        channel.id === slackChannel
                          ? "text-brand-turq bg-brand-turq bg-opacity-10"
                          : "text-gray-900"
                      } hover:bg-gray-50`}
                      onClick={() => {
                        handleSelectChannel(channel.id);
                        setDropdownOpen(false);
                        setChannelSearch("");
                      }}
                    >
                      <span className="block truncate">#{channel.name}</span>

                      {channel.id === slackChannel && (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-1.5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-brand-turq"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  // --------------------------
  // Navigation and Actions
  // --------------------------
  const handleNext = () => {
    const currentQ = questions[currentQuestion];

    if (currentQ.validate && !currentQ.validate(currentQ.value)) {
      return;
    }

    // Special cases for navigation
    if (currentQ.id === "slack-connection" && !slackConnected) {
      return; // Can't proceed if not connected to Slack
    }

    if (currentQ.id === "test-meeting" && selectedTestMeeting) {
      // Generate preview before showing result
      handleGeneratePreview();
      return;
    }

    if (currentQ.id === "test-meeting" && !selectedTestMeeting) {
      // Skip preview if no meeting selected
      setCurrentQuestion(5); // Go to completion
      setWorkflowComplete(true);
      return;
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      // We're at the end
      setWorkflowComplete(true);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
    } else if (onCancel) {
      onCancel();
    }
  };

  // Toggle test meeting search type (quick/transcript)
  const toggleSearchType = () => {
    setTestSearchType((prev) => (prev === "quick" ? "transcript" : "quick"));
  };

  // Fetch meetings for testing
  const fetchTestMeetings = async (query) => {
    if (!query?.trim()) {
      setTestMeetings([]);
      return;
    }

    setLoadingMeetings(true);
    setSearchError("");

    try {
      const params = new URLSearchParams();
      params.set("view", "all_meetings");
      if (userEmail) {
        params.set("email", userEmail);
      }
      params.set("q", query.trim());
      params.set("searchType", testSearchType);

      const url = `${API_BASE}/enhanced-recording?${params.toString()}`;
      const response = await fetch(url, { credentials: "include" });

      if (!response.ok) {
        throw new Error("Failed to fetch meetings");
      }

      const data = await response.json();
      setTestMeetings(data.data || []);
    } catch (error) {
      console.error("Error fetching meetings:", error);
      setSearchError(error.message || "Failed to fetch meetings");
    } finally {
      setLoadingMeetings(false);
    }
  };

  // Select a meeting for testing
  const handleSelectTestMeeting = (meeting) => {
    setSelectedTestMeeting(meeting);
    setMeetingModalOpen(false);
  };

  // Generate preview of Slack post for selected meeting
  const handleGeneratePreview = async () => {
    if (!selectedTestMeeting || !slackPrompt.trim()) {
      return;
    }

    setPreviewLoading(true);
    setPreviewError("");
    setPreviewResult(null);

    try {
      // API call to test the Slack prompt
      const response = await fetch(`${API_BASE}/actions/test/slack-message`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          meetingID: selectedTestMeeting.ID,
          additionalPrompt: slackPrompt,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to generate preview");
      }

      const data = await response.json();
      setPreviewResult(data.draft || {});
      setCurrentQuestion(4); // Move to preview result
    } catch (error) {
      console.error("Error generating preview:", error);
      setPreviewError(error.message || "Failed to generate Slack post preview");
      setCurrentQuestion(4); // Still move to preview step, but with an error
    } finally {
      setPreviewLoading(false);
    }
  };

  // Accept the current configuration and complete
  const handleAcceptConfig = () => {
    setWorkflowComplete(true);
  };

  // --------------------------
  // Render Functions
  // --------------------------
  const renderSlackNameInput = (question) => {
    return (
      <div className="mt-6">
        <div className="relative">
          <input
            ref={(el) => (inputRefs.current[currentQuestion] = el)}
            type="text"
            value={question.value}
            onChange={(e) => question.onChange(e.target.value)}
            className="w-full rounded-lg border-2 border-gray-200 shadow-sm px-4 py-3.5 text-base 
              focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
              hover:border-gray-300"
            placeholder={question.placeholder}
            onKeyDown={(e) => e.key === "Enter" && handleNext()}
          />
        </div>

        <div className="mt-4 p-4 bg-brand-turq bg-opacity-5 border border-brand-turq border-opacity-20 rounded-lg">
          <div className="flex items-start">
            <InformationCircleIcon className="h-4 w-4 text-brand-turq mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-sm text-gray-700">
                This name will help you identify this automation in your
                workflow.
              </p>
              <p className="text-xs text-gray-600 mt-2">
                Giving it a descriptive name like "{meetingTypeName} Slack
                Summary" makes it easy to identify its purpose.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSlackConnectionInput = () => {
    return (
      <div className="mt-6 space-y-4">
        {/* Slack connection status */}
        <div
          className={classNames(
            "p-4 border rounded-lg",
            slackConnected
              ? "bg-green-50 border-green-100"
              : "bg-yellow-50 border-yellow-100"
          )}
        >
          <div className="flex items-start">
            {slackConnected ? (
              <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
            ) : (
              <ExclamationIcon className="h-5 w-5 text-yellow-500 mr-2 flex-shrink-0" />
            )}
            <div>
              <p className="text-sm font-medium text-gray-800">
                {slackConnected
                  ? "Connected to Slack"
                  : "Slack connection required"}
              </p>
              <p className="text-xs text-gray-600 mt-1">
                {slackConnected
                  ? "Your Slack workspace is connected. Select a channel below."
                  : "To post meeting updates in Slack, you need to connect your workspace first."}
              </p>

              {!slackConnected && (
                <div className="mt-3 flex space-x-3">
                  <button
                    onClick={handleConnectSlack}
                    disabled={checkingSlackStatus}
                    className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-brand-turq hover:bg-brand-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq transition-colors"
                  >
                    <ExternalLinkIcon className="h-4 w-4 mr-1.5" />
                    Connect to Slack
                  </button>

                  <button
                    onClick={checkSlackStatus}
                    disabled={checkingSlackStatus}
                    className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-xs font-medium rounded shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq transition-colors"
                  >
                    {checkingSlackStatus ? (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-2 h-4 w-4 text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Checking...
                      </>
                    ) : (
                      <>
                        <RefreshIcon className="h-4 w-4 mr-1.5" />
                        Check Again
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Channel selection - only show when connected */}
        {slackConnected && (
          <>
            {channelError && (
              <div className="p-3 bg-red-50 border border-red-100 rounded-md">
                <div className="flex">
                  <ExclamationIcon className="h-5 w-5 text-red-400 mr-2" />
                  <p className="text-sm text-red-600">{channelError}</p>
                </div>
              </div>
            )}

            <div className="p-4 border border-gray-200 bg-white rounded-lg">
              {loadingSlackChannels ? (
                <div className="text-center py-6">
                  <svg
                    className="animate-spin h-6 w-6 text-brand-turq mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <p className="mt-2 text-sm text-gray-500">
                    Loading channels...
                  </p>
                </div>
              ) : slackChannels.length === 0 ? (
                <p className="text-sm text-gray-500 py-2">
                  No channels found in your workspace
                </p>
              ) : (
                renderChannelDropdown()
              )}
            </div>

            {slackChannel && (
              <div className="flex items-center p-3 bg-gray-50 border border-gray-200 rounded-lg">
                <ChatIcon className="h-5 w-5 text-brand-turq mr-2" />
                <span className="text-sm text-gray-700">
                  Meeting updates will be posted to{" "}
                  <span className="font-medium">
                    {slackChannels.find((ch) => ch.id === slackChannel)
                      ? `#${
                          slackChannels.find((ch) => ch.id === slackChannel)
                            .name
                        }`
                      : slackChannel}
                  </span>
                </span>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderSlackPromptInput = () => {
    return (
      <div className="mt-6 space-y-4">
        <div className="p-4 bg-brand-turq bg-opacity-5 border border-brand-turq border-opacity-20 rounded-lg">
          <div className="flex items-start">
            <InformationCircleIcon className="h-5 w-5 text-brand-turq mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-sm text-gray-700">
                Create instructions for what information should be shared in
                Slack after each meeting
              </p>
              <p className="text-xs text-gray-600 mt-1">
                Be specific about the format and content you want included, such
                as key decisions, action items, or important takeaways.
              </p>
            </div>
          </div>
        </div>

        <div>
          <label
            htmlFor="slack-prompt"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Slack Message Instructions
          </label>
          <textarea
            id="slack-prompt"
            value={slackPrompt}
            onChange={(e) => setSlackPrompt(e.target.value)}
            rows={8}
            className="w-full border-2 border-gray-200 rounded-lg shadow-sm p-3 text-sm
              focus:ring-brand-turq focus:border-brand-turq"
            placeholder="Write instructions for how the AI should create your Slack updates. For example: 'Create a concise Slack message that summarizes the key points from the meeting, lists all action items with owners, and highlights any important decisions made. Format the message with clear section headers and bullet points for readability.'"
            ref={(el) => (inputRefs.current[currentQuestion] = el)}
          />
        </div>
      </div>
    );
  };

  const renderTestMeeting = () => {
    return (
      <div className="mt-6 space-y-4">
        <div className="p-4 bg-brand-turq bg-opacity-5 border border-brand-turq border-opacity-20 rounded-lg">
          <div className="flex items-start">
            <InformationCircleIcon className="h-5 w-5 text-brand-turq mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-sm text-gray-700">
                Select a past meeting to see how your Slack message would look.
              </p>
              <p className="text-xs text-gray-600 mt-1">
                This helps ensure your automation will produce the kind of
                updates you want.
              </p>
            </div>
          </div>
        </div>

        {/* Selected meeting display */}
        {selectedTestMeeting ? (
          <div className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm">
            <div className="flex items-start">
              <div className="flex-shrink-0 h-10 w-10 bg-gray-100 rounded-md flex items-center justify-center text-gray-500">
                <DocumentTextIcon className="h-5 w-5" />
              </div>
              <div className="ml-3 flex-1">
                <div className="flex justify-between">
                  <h4 className="text-sm font-medium text-gray-900">
                    {selectedTestMeeting.meetingTitle || "Untitled Meeting"}
                  </h4>
                  <button
                    onClick={() => setSelectedTestMeeting(null)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <XIcon className="h-4 w-4" />
                  </button>
                </div>
                <p className="text-xs text-gray-500 mt-0.5">
                  {formatDate(selectedTestMeeting.CreatedAt)}
                  {selectedTestMeeting.duration &&
                    ` • ${formatDuration(selectedTestMeeting.duration)}`}
                </p>
                {selectedTestMeeting.attendees?.length > 0 && (
                  <p className="text-xs text-gray-500 mt-0.5">
                    {formatAttendees(selectedTestMeeting.attendees)}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center py-6">
            <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-3">
              <ChatIcon className="h-8 w-8 text-gray-400" />
            </div>
            <p className="text-sm text-gray-600 mb-1">No meeting selected</p>
            <p className="text-xs text-gray-500">
              Select a meeting to see a preview of your Slack message
            </p>
          </div>
        )}

        <div className="flex justify-center space-x-3 pt-4">
          <motion.button
            whileHover={{ y: -2 }}
            whileTap={{ y: 0 }}
            onClick={() => setMeetingModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg shadow-sm text-gray-700 bg-white hover:bg-gray-50 transition-all duration-200"
          >
            <SearchIcon className="h-4 w-4 mr-1.5" />
            {selectedTestMeeting ? "Change Meeting" : "Select a Meeting"}
          </motion.button>

          {selectedTestMeeting && (
            <motion.button
              whileHover={{ y: -2 }}
              whileTap={{ y: 0 }}
              onClick={handleNext}
              disabled={previewLoading}
              className={classNames(
                "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white transition-all duration-200",
                previewLoading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-brand-turq hover:bg-brand-green"
              )}
            >
              {previewLoading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Generating...
                </>
              ) : (
                <>
                  <SparklesIcon className="h-4 w-4 mr-1.5" />
                  Generate Slack Preview
                </>
              )}
            </motion.button>
          )}
        </div>

        <div className="flex justify-center pt-4">
          <button
            onClick={() => {
              setWorkflowComplete(true);
            }}
            className="text-sm text-gray-500 hover:text-brand-turq transition-colors"
          >
            Skip testing and complete setup
          </button>
        </div>
      </div>
    );
  };

  const renderPreviewResult = () => {
    return (
      <div className="mt-6 space-y-4">
        {previewError ? (
          <div className="p-4 bg-red-50 border border-red-100 rounded-lg">
            <div className="flex items-start">
              <ExclamationIcon className="h-5 w-5 text-red-500 mr-2 flex-shrink-0" />
              <div>
                <p className="text-sm font-medium text-red-800">
                  There was an error generating your Slack message preview
                </p>
                <p className="text-xs text-red-700 mt-1">{previewError}</p>
                <p className="text-xs text-red-700 mt-2">
                  You can try again with a different meeting or adjust your
                  instructions.
                </p>
              </div>
            </div>
          </div>
        ) : previewResult ? (
          <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
            <div className="px-4 py-3 bg-gray-50 border-b border-gray-200">
              <h3 className="text-sm font-medium text-gray-700 flex items-center">
                <ChatIcon className="h-4 w-4 mr-1.5 text-brand-turq" />
                Slack Message Preview
                <span className="ml-2 px-2 py-0.5 text-xs rounded bg-gray-200 text-gray-700">
                  {slackChannels.find((ch) => ch.id === slackChannel)
                    ? `#${
                        slackChannels.find((ch) => ch.id === slackChannel).name
                      }`
                    : "channel"}
                </span>
              </h3>
            </div>
            <div className="p-4">{renderSlackPreview(previewResult)}</div>
          </div>
        ) : (
          <div className="text-center py-6">
            <p className="text-sm text-gray-600">No preview available</p>
          </div>
        )}

        <div className="flex justify-center space-x-4 pt-4">
          <button
            onClick={() => setCurrentQuestion(2)} // Go back to edit instructions
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg shadow-sm text-gray-700 bg-white hover:bg-gray-50"
          >
            <PencilIcon className="h-4 w-4 mr-1.5" />
            Edit Instructions
          </button>

          <button
            onClick={handleAcceptConfig}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-brand-turq hover:bg-brand-green"
          >
            <CheckIcon className="h-4 w-4 mr-1.5" />
            Looks Good
          </button>
        </div>
      </div>
    );
  };

  // Helper to render Slack preview based on structure
  function renderSlackPreview(result) {
    if (!result) return null;

    // Handle message as a string
    if (result.message) {
      return (
        <div className="space-y-4">
          <div
            className="p-3 bg-gray-50 border border-gray-200 rounded text-sm text-gray-800"
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{
              __html: result.message
                .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")
                .replace(/\n/g, "<br />"),
            }}
          />
        </div>
      );
    }

    // Handle blocks (Slack block format)
    if (result.blocks) {
      return (
        <div className="space-y-4">
          {result.blocks.map((block, idx) => {
            switch (block.type) {
              case "header":
                return (
                  <div key={idx} className="text-lg font-bold mb-3">
                    {block.text.text}
                  </div>
                );
              case "section":
                return (
                  <div
                    key={idx}
                    className="mb-4"
                    dangerouslySetInnerHTML={{
                      __html: block.text.text
                        .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")
                        .replace(/\n/g, "<br />"),
                    }}
                  />
                );
              case "context":
                return (
                  <div key={idx} className="text-xs text-gray-500 mt-3 italic">
                    {block.elements[0].text}
                  </div>
                );
              default:
                return <div key={idx}>{JSON.stringify(block)}</div>;
            }
          })}
        </div>
      );
    }

    // Fallback to raw JSON
    return (
      <pre className="text-xs text-gray-800 whitespace-pre-wrap bg-gray-50 p-3 border border-gray-200 rounded">
        {JSON.stringify(result, null, 2)}
      </pre>
    );
  }

  const renderComplete = () => {
    return (
      <div className="mt-6 text-center">
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5, type: "spring" }}
          className="w-20 h-20 bg-brand-turq bg-opacity-10 rounded-full flex items-center justify-center mx-auto mb-6"
        >
          <CheckCircleIcon className="h-10 w-10 text-brand-turq" />
        </motion.div>

        <motion.h3
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-2xl font-medium text-gray-900"
        >
          Your Slack post automation is ready!
        </motion.h3>

        <motion.p
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="text-base text-gray-600 mt-3 max-w-md mx-auto"
        >
          You've successfully set up automated Slack updates for your{" "}
          {meetingTypeName.toLowerCase()} meetings.
        </motion.p>

        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="mt-6 text-sm text-gray-600"
        >
          <p>
            This automation will post Slack messages in{" "}
            <span className="font-medium">
              {slackChannels.find((ch) => ch.id === slackChannel)
                ? `#${slackChannels.find((ch) => ch.id === slackChannel).name}`
                : slackChannel}
            </span>{" "}
            after each meeting.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="mt-8"
        >
          <button
            onClick={() => {
              if (onComplete) {
                // Here we've already created the action in finalizeAutomationCreation
                // Just pass the data to close the workflow
                onComplete({
                  name: slackName,
                  type: "slack_post",
                  slackPrompt: slackPrompt,
                  slackChannelID: slackChannel,
                });
              }
            }}
            className="px-5 py-2.5 bg-brand-turq text-white rounded-lg hover:bg-brand-green transition-colors shadow-sm"
          >
            <LightningBoltIcon className="inline-block h-4 w-4 mr-2 -mt-0.5" />
            Finish Setup
          </button>
        </motion.div>
      </div>
    );
  };

  // Helper functions for date/time formatting
  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      return format(parseISO(dateString), "MMM d, yyyy");
    } catch (e) {
      return dateString;
    }
  };

  const formatDuration = (seconds) => {
    if (!seconds) return "";
    const minutes = Math.round(seconds / 60);
    if (minutes < 60) {
      return `${minutes} min${minutes !== 1 ? "s" : ""}`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMins = minutes % 60;
    return `${hours} hr${hours !== 1 ? "s" : ""} ${
      remainingMins > 0
        ? `${remainingMins} min${remainingMins !== 1 ? "s" : ""}`
        : ""
    }`;
  };

  const formatAttendees = (attendees) => {
    if (!attendees || !Array.isArray(attendees)) return "";

    const names = attendees
      .map((attendee) => {
        if (typeof attendee === "string") return attendee;
        return attendee.name || attendee.email || "";
      })
      .filter(Boolean);

    return [...new Set(names)].join(", ");
  };

  // --------------------------
  // Meeting Selection Modal
  // --------------------------
  const renderMeetingModal = () => {
    return (
      <Transition.Root show={meetingModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-50 backdrop-blur-sm"
          onClose={() => setMeetingModalOpen(false)}
        >
          <div className="flex items-center justify-center min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden inline-block align-middle h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 translate-y-4 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-4 scale-95"
            >
              <div className="inline-block w-full max-w-3xl bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all align-middle">
                <div className="flex flex-col h-[650px]">
                  {/* Header */}
                  <div className="px-6 pt-6 pb-4 border-b border-gray-200">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 bg-brand-turq bg-opacity-10 rounded-full p-2">
                        <SearchIcon className="h-6 w-6 text-brand-turq" />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium text-gray-900">
                          Select a meeting to test
                        </h3>
                        <p className="text-sm text-gray-500 mt-1">
                          Find a meeting to see how your Slack post will look
                        </p>
                      </div>
                      <button
                        onClick={() => setMeetingModalOpen(false)}
                        className="ml-auto bg-white rounded-full p-1 hover:bg-gray-100 transition-colors"
                      >
                        <XIcon className="h-5 w-5 text-gray-400" />
                      </button>
                    </div>
                  </div>

                  {/* Search controls */}
                  <div className="p-4 border-b border-gray-200">
                    <div className="relative flex items-center">
                      <div className="relative flex-1">
                        <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                        <input
                          ref={searchInputRef}
                          type="text"
                          value={testSearchTerm}
                          onChange={(e) => setTestSearchTerm(e.target.value)}
                          placeholder="Search by title, attendee, or content"
                          className="w-full pl-10 pr-10 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:ring-brand-turq focus:border-brand-turq transition-all"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              fetchTestMeetings(testSearchTerm);
                            }
                          }}
                        />
                        {testSearchTerm && (
                          <button
                            onClick={() => setTestSearchTerm("")}
                            className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600"
                          >
                            <XIcon className="w-4 h-4" />
                          </button>
                        )}
                      </div>

                      <div className="flex ml-3 space-x-2">
                        <button
                          onClick={toggleSearchType}
                          className={`px-3 py-2 text-xs font-medium rounded-md transition-colors ${
                            testSearchType === "transcript"
                              ? "bg-brand-turq bg-opacity-10 text-brand-turq border border-brand-turq border-opacity-20"
                              : "border border-gray-200 text-gray-600 hover:bg-gray-50"
                          }`}
                        >
                          {testSearchType === "transcript"
                            ? "Transcript search"
                            : "Basic search"}
                        </button>

                        <button
                          onClick={() => fetchTestMeetings(testSearchTerm)}
                          disabled={!testSearchTerm.trim()}
                          className={`px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                            testSearchTerm.trim()
                              ? "text-white bg-brand-turq hover:bg-brand-green"
                              : "text-gray-400 bg-gray-100 cursor-not-allowed"
                          }`}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Results area */}
                  <div className="flex-1 overflow-y-auto">
                    {searchError ? (
                      <div className="p-4 bg-red-50 m-4 rounded-lg border border-red-100 flex items-start">
                        <ExclamationIcon className="h-5 w-5 text-red-500 mr-2 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-red-700">
                            Error searching meetings
                          </p>
                          <p className="text-xs text-red-600 mt-1">
                            {searchError}
                          </p>
                          <button
                            onClick={() => fetchTestMeetings(testSearchTerm)}
                            className="mt-2 px-3 py-1 text-xs font-medium bg-white border border-red-200 rounded-md text-red-600 hover:bg-red-50"
                          >
                            Try again
                          </button>
                        </div>
                      </div>
                    ) : loadingMeetings ? (
                      <div className="flex flex-col items-center justify-center h-48 text-gray-500">
                        <svg
                          className="animate-spin h-8 w-8 text-brand-turq mb-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <p className="text-sm">Searching for meetings...</p>
                      </div>
                    ) : testMeetings.length === 0 ? (
                      <div className="flex flex-col items-center justify-center h-48 text-center px-4">
                        {testSearchTerm ? (
                          <>
                            <SearchIcon className="h-10 w-10 text-gray-300 mb-3" />
                            <h3 className="text-base font-medium text-gray-700">
                              No meetings found
                            </h3>
                            <p className="text-sm text-gray-500 mt-1 max-w-md">
                              Try different search terms or check your spelling.
                              You can also try searching for specific content in
                              the transcript.
                            </p>
                          </>
                        ) : (
                          <>
                            <DocumentTextIcon className="h-10 w-10 text-gray-300 mb-3" />
                            <h3 className="text-base font-medium text-gray-700">
                              Search for meetings
                            </h3>
                            <p className="text-sm text-gray-500 mt-1 max-w-md">
                              Enter keywords above to find meetings to test your
                              Slack post automation
                            </p>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="divide-y divide-gray-100">
                        <div className="px-4 py-2 bg-gray-50 border-b border-gray-200">
                          <div className="flex justify-between items-center">
                            <h4 className="text-xs font-medium text-gray-700">
                              Search results
                            </h4>
                            <span className="text-xs text-gray-500">
                              {testMeetings.length} meeting
                              {testMeetings.length !== 1 ? "s" : ""} found
                            </span>
                          </div>
                        </div>

                        <ul>
                          {testMeetings.map((meeting) => (
                            <li
                              key={meeting.ID}
                              className="border-b border-gray-100 last:border-b-0"
                            >
                              <button
                                className="w-full px-4 py-3 text-left flex items-center hover:bg-gray-50 transition-colors"
                                onClick={() => handleSelectTestMeeting(meeting)}
                              >
                                <div className="flex-1 min-w-0 pr-3">
                                  <div className="font-medium text-sm text-gray-800">
                                    {meeting.meetingTitle || "Untitled Meeting"}
                                  </div>

                                  <div className="flex flex-wrap text-xs text-gray-500 mt-1">
                                    <div className="flex items-center mr-3">
                                      <CalendarIcon className="h-3 w-3 mr-1" />
                                      {formatDate(meeting.CreatedAt)}
                                    </div>

                                    {meeting.duration && (
                                      <div className="flex items-center">
                                        <ClockIcon className="h-3 w-3 mr-1" />
                                        {formatDuration(meeting.duration)}
                                      </div>
                                    )}
                                  </div>

                                  {meeting.attendees &&
                                    meeting.attendees.length > 0 && (
                                      <div className="text-xs text-gray-500 mt-0.5 flex items-center">
                                        <UserCircleIcon className="h-3 w-3 mr-1" />
                                        <span className="truncate max-w-xs">
                                          {formatAttendees(meeting.attendees)}
                                        </span>
                                      </div>
                                    )}
                                </div>

                                <div className="flex-shrink-0 w-8 h-8 rounded-full border border-brand-turq border-opacity-50 bg-white flex items-center justify-center">
                                  <CheckIcon className="h-4 w-4 text-brand-turq" />
                                </div>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* Footer */}
                  <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end items-center">
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 transition-colors"
                      onClick={() => setMeetingModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  // --------------------------
  // Render current question
  // --------------------------
  const renderQuestionInput = (question) => {
    switch (question.inputType) {
      case "text":
        return renderSlackNameInput(question);
      case "slackConnection":
        return renderSlackConnectionInput();
      case "slackPrompt":
        return renderSlackPromptInput();
      case "testMeeting":
        return renderTestMeeting();
      case "previewResult":
        return renderPreviewResult();
      case "complete":
        return renderComplete();
      default:
        return null;
    }
  };

  const renderCurrentQuestion = () => {
    const question = questions[currentQuestion];
    if (!question) return null;

    return (
      <motion.div
        key={question.id}
        className="h-full flex flex-col"
        initial={{ opacity: 0, y: 15 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -15 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex-1">
          {/* Title and subtitle */}
          <div className="mb-4 text-center">
            <motion.h3
              className="text-2xl font-medium text-gray-900 tracking-tight"
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
            >
              {question.title}
            </motion.h3>
            {question.subtitle && (
              <motion.p
                className="mt-2 text-gray-600"
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                {question.subtitle}
              </motion.p>
            )}
          </div>

          {/* Question content */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="overflow-y-auto"
          >
            {workflowComplete
              ? renderComplete()
              : renderQuestionInput(question)}
          </motion.div>
        </div>
      </motion.div>
    );
  };

  // --------------------------
  // Main render
  // --------------------------
  return (
    <div className="h-full flex flex-col">
      <div
        ref={contentRef}
        className="flex-1 bg-white rounded-t-xl shadow-sm border border-gray-200 border-b-0 overflow-hidden"
      >
        <div className="p-6 h-full flex flex-col relative">
          {/* Progress steps - not showing for completion screen */}
          {currentQuestion < questions.length - 1 && !workflowComplete && (
            <div className="flex items-center justify-center mb-6">
              <div className="bg-gray-100 rounded-full h-1.5 w-64 overflow-hidden">
                <motion.div
                  className="h-full bg-brand-turq rounded-full"
                  initial={{ width: "0%" }}
                  animate={{
                    width: `${
                      ((currentQuestion + 1) / (questions.length - 1)) * 100
                    }%`,
                  }}
                  transition={{ duration: 0.3 }}
                />
              </div>
              <div className="ml-3 text-xs text-gray-500 font-medium">
                {currentQuestion + 1}/{questions.length - 1}
              </div>
            </div>
          )}

          {/* Fixed height content area with internal scrolling */}
          <div className="flex-1 overflow-y-auto">
            <AnimatePresence mode="wait">
              {renderCurrentQuestion()}
            </AnimatePresence>
          </div>
        </div>
      </div>

      {/* Footer - conditional rendering based on question type and stage */}
      {currentQuestion < questions.length - 1 && // Don't show for completion
        !workflowComplete &&
        !["testMeeting", "previewResult"].includes(
          questions[currentQuestion].inputType
        ) && (
          <div className="bg-white rounded-b-xl border border-t-0 border-gray-200 shadow-sm">
            <div className="px-6 py-4 flex justify-between items-center bg-gray-50 bg-opacity-70">
              <button
                onClick={handlePrevious}
                className="flex items-center px-4 py-2 text-brand-turq rounded-lg hover:bg-brand-turq hover:bg-opacity-5 transition-colors"
              >
                <ArrowLeftIcon className="h-4 w-4 mr-1.5" />
                <span className="font-medium">Back</span>
              </button>

              <button
                onClick={handleNext}
                disabled={
                  (questions[currentQuestion].validate &&
                    !questions[currentQuestion].validate(
                      questions[currentQuestion].value
                    )) ||
                  isTransitioning ||
                  (currentQuestion === 1 && !slackConnected) // Disabled on Slack connection step if not connected
                }
                className={classNames(
                  "inline-flex items-center px-5 py-2.5 rounded-lg font-medium transition-colors",
                  questions[currentQuestion].validate &&
                    !questions[currentQuestion].validate(
                      questions[currentQuestion].value
                    )
                    ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                    : isTransitioning
                    ? "bg-gray-200 text-gray-400 cursor-wait"
                    : currentQuestion === 1 && !slackConnected
                    ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                    : "bg-brand-turq text-white hover:bg-brand-green shadow-sm"
                )}
              >
                {isTransitioning ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  <>
                    Continue
                    <ArrowRightIcon className="h-5 w-5 ml-2" />
                  </>
                )}
              </button>
            </div>
          </div>
        )}

      {/* Custom footer for test/preview steps */}
      {!workflowComplete &&
        ["testMeeting", "previewResult"].includes(
          questions[currentQuestion]?.inputType
        ) && (
          <div className="bg-white rounded-b-xl border border-t-0 border-gray-200 shadow-sm">
            <div className="px-6 py-4 flex justify-start items-center bg-gray-50 bg-opacity-70">
              <button
                onClick={handlePrevious}
                className="flex items-center px-4 py-2 text-brand-turq rounded-lg hover:bg-brand-turq hover:bg-opacity-5 transition-colors"
              >
                <ArrowLeftIcon className="h-4 w-4 mr-1.5" />
                <span className="font-medium">Back</span>
              </button>
            </div>
          </div>
        )}

      {/* Render meeting selection modal */}
      {renderMeetingModal()}
    </div>
  );
}
