import React, { useState, useEffect, useRef } from "react";
// Keep all your existing imports but remove the Tab-related imports from @headlessui/react

// This is a drop-in replacement for the Tab components
// You'll use this directly in your MeetingPage2 component

function SimpleTabs({ selectedIndex, onChange, children }) {
  return <div className="flex flex-col flex-1 min-h-0">{children}</div>;
}

function SimpleTabList({ children }) {
  return (
    <div className="bg-white px-4 py-3 border-b border-gray-200">
      <div className="flex flex-wrap items-center gap-2">{children}</div>
    </div>
  );
}

function SimpleTab({ selected, onClick, children }) {
  return (
    <button
      onClick={onClick}
      className={
        selected
          ? "flex items-center gap-2 px-3 py-2 text-sm font-medium bg-brand-turq text-white rounded-full shadow-md focus:outline-none transition-transform transform hover:scale-105"
          : "flex items-center gap-2 px-3 py-2 text-sm font-medium bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 focus:outline-none transition-transform transform hover:scale-105"
      }
    >
      {children}
    </button>
  );
}

function SimpleTabPanels({ selectedIndex, children }) {
  const selectedPanel = React.Children.toArray(children)[selectedIndex] || null;
  return (
    // Just a normal container; no overflow-y-auto here
    <div className="flex-1 min-h-0 bg-gray-50">{selectedPanel}</div>
  );
}

function SimpleTabPanel({ children }) {
  // No extra scroll wrappers here
  return <div className="h-full w-full">{children}</div>;
}

export {
  SimpleTabs,
  SimpleTabList,
  SimpleTab,
  SimpleTabPanels,
  SimpleTabPanel,
};
