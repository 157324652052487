import React, { useState, useEffect, Fragment } from "react";
import { Menu, Transition, Dialog } from "@headlessui/react";
import {
  PlusIcon,
  SearchIcon,
  XIcon,
  DocumentTextIcon,
  LightningBoltIcon,
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
  DuplicateIcon,
  ChevronRightIcon,
  ArrowRightIcon,
  CheckIcon,
  ClockIcon,
  UserGroupIcon,
  ExclamationIcon,
  SortAscendingIcon,
  PlusCircleIcon,
  QuestionMarkCircleIcon,
  InformationCircleIcon,
  FilterIcon,
} from "@heroicons/react/outline";
import Navbar from "./Navbar";
import MeetingWorkflowEdit from "./MeetingWorkflowEdit";

/**
 *  MeetingWorkflowsPage.jsx
 *
 *  Reimagined from the ground up:
 *  - Intuitive visual representation of workflow process
 *  - Clear information hierarchy focused on workflow purpose and status
 *  - Direct access to edit functionality with prominent visual cues
 *  - Enhanced empty and loading states
 *  - Streamlined controls with guided assistance
 */

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MeetingWorkflowsPage() {
  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [logout, setLogout] = useState(false);
  const [loading, setLoading] = useState(true);

  const [editWorkflowData, setEditWorkflowData] = useState(null);

  // Data
  const [meetingTypes, setMeetingTypes] = useState([]);
  const [meetingTemplates, setMeetingTemplates] = useState([]);
  const [meetingActions, setMeetingActions] = useState([]);
  const [workflowsLoading, setWorkflowsLoading] = useState(false);

  // Whether or not the "Edit/Create Workflow" view is open
  const [showWorkflowEditor, setShowWorkflowEditor] = useState(false);
  // Which workflow we're editing (null means "create new")
  const [editWorkflowId, setEditWorkflowId] = useState(null);

  // For searching and sorting
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDesc, setSortDesc] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all"); // "all", "complete", "incomplete"

  // Track which workflow card is expanded
  const [expandedWorkflowId, setExpandedWorkflowId] = useState(null);

  // First-time user guidance
  const [showGuidance, setShowGuidance] = useState(false);

  // Confirmation modal
  const [confirmModal, setConfirmModal] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => {},
  });

  // Fetch user info on component mount
  useEffect(() => {
    (async function fetchUserInfo() {
      try {
        const userRes = await fetch("https://backend.scribbl.co/auth/userinfo", {
          method: "POST",
          credentials: "include",
        });
        if (userRes.status === 401) {
          setLogout(true);
          setLoading(false);
          return;
        }
        if (userRes.ok) {
          const userJson = await userRes.json();
          setUser(userJson);
          setUserEmail(userJson.email);
        }
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch user info", error);
        setLoading(false);
      }
    })();
  }, []);

  // Once user is loaded, fetch workflows data
  useEffect(() => {
    if (!userEmail || !user) return;
    fetchWorkflowsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail, user]);

  // Show guidance for first-time users when data loads
  useEffect(() => {
    if (!workflowsLoading && meetingTypes.length === 0) {
      setShowGuidance(true);
    } else {
      setShowGuidance(false);
    }
  }, [workflowsLoading, meetingTypes]);

  async function fetchWorkflowsData() {
    setWorkflowsLoading(true);
    try {
      // Single API call to get all workflow data
      const mtRes = await fetch("https://backend.scribbl.co/meeting-types", {
        credentials: "include",
      });
      if (mtRes.status === 401) {
        setLogout(true);
        setWorkflowsLoading(false);
        return;
      }

      let mts = [];
      if (mtRes.ok) {
        mts = await mtRes.json();
        setMeetingTypes(mts);

        // Extract the templates and actions from the meeting types
        const templates = [];
        const actions = [];

        mts.forEach((mt) => {
          if (mt.meetingTemplate) {
            templates.push(mt.meetingTemplate);
          }
          if (mt.meetingActions && Array.isArray(mt.meetingActions)) {
            mt.meetingActions.forEach((action) => {
              if (!actions.some((a) => a.ID === action.ID)) {
                actions.push(action);
              }
            });
          }
        });

        setMeetingTemplates(templates);
        setMeetingActions(actions);
      }
    } catch (err) {
      console.error("Error fetching workflows data:", err);
    }
    setWorkflowsLoading(false);
  }

  // Update the edit workflow function to pass complete data
  function editWorkflow(mt) {
    // Find the associated template
    const template = getTemplateForMeetingType(mt);
    // Get actions
    const actions = getActionsForMeetingType(mt);

    // Store the complete workflow data
    const workflowData = {
      meetingType: mt,
      meetingTemplate: template,
      meetingActions: actions,
    };

    setEditWorkflowId(mt.ID);
    setEditWorkflowData(workflowData); // New state variable to store the workflow data
    setShowWorkflowEditor(true);
  }

  // Filter and sort meeting types (workflows)
  const filteredWorkflows = meetingTypes
    .filter((mt) => {
      // Text search filter
      const matchesSearch = !searchTerm.trim()
        ? true
        : mt.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          mt.description?.toLowerCase().includes(searchTerm.toLowerCase());

      // Status filter
      if (activeFilter === "all") return matchesSearch;

      const hasTemplate = !!mt.meetingTemplateID;
      const hasActions = getActionsForMeetingType(mt).length > 0;

      if (activeFilter === "complete") {
        return matchesSearch && hasTemplate && hasActions;
      }

      if (activeFilter === "incomplete") {
        return matchesSearch && (!hasTemplate || !hasActions);
      }

      return matchesSearch;
    })
    .sort((a, b) => {
      const nameA = a.name?.toLowerCase() || "";
      const nameB = b.name?.toLowerCase() || "";
      return sortDesc ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
    });

  // Helper to get the associated meeting template (if any)
  function getTemplateForMeetingType(mt) {
    if (!mt.meetingTemplateID) return null;
    return (
      meetingTemplates.find((tpl) => tpl.ID === mt.meetingTemplateID) || null
    );
  }

  // Helper to find the actions for a meetingType
  function getActionsForMeetingType(mt) {
    console.log("what is mt", mt);
    if (mt.meetingActions && Array.isArray(mt.meetingActions)) {
      return mt.meetingActions;
    }
    // fallback approach for the loaded data
    const localActions = meetingActions.filter((act) => {
      if (act.isGlobal && act.orgID === mt.orgID) {
        return true;
      }
      if (Array.isArray(act.MeetingTypes)) {
        return act.MeetingTypes.some((mtt) => mtt.ID === mt.ID);
      }
      return false;
    });
    return localActions;
  }

  // Helper to calculate completion status
  function getWorkflowCompletionStatus(mt) {
    const hasTemplate = !!getTemplateForMeetingType(mt);
    const actionsCount = getActionsForMeetingType(mt).length;
    const hasRegularNotes = !!mt.regularMeetingNotes;

    let completedSteps = 1; // Meeting type is always created
    let totalSteps = 2; // Meeting type + template

    // Consider template complete if it has a template OR uses regular notes
    if (hasTemplate || hasRegularNotes) completedSteps++;
    if (actionsCount > 0) {
      completedSteps++;
      totalSteps++;
    }

    const percentage = Math.round((completedSteps / totalSteps) * 100);

    return {
      completedSteps,
      totalSteps,
      percentage,
      isComplete: completedSteps === totalSteps,
      hasTemplate,
      hasRegularNotes,
      actionsCount,
    };
  }

  // For the avatar letter/color
  function getAvatarLetter(str = "") {
    if (!str) return "M";
    return str.charAt(0).toUpperCase();
  }

  function getAvatarColor(name = "") {
    const colors = [
      "bg-brand-turq",
      "bg-amber-500",
      "bg-pink-500",
      "bg-indigo-500",
      "bg-green-500",
      "bg-purple-500",
    ];
    let sum = 0;
    for (let i = 0; i < name.length; i++) {
      sum += name.charCodeAt(i);
    }
    return colors[sum % colors.length];
  }

  // State for delete operation
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

  // Delete workflow function
  async function deleteWorkflow(workflowId, workflowName) {
    setDeleteLoading(true);
    setDeleteError(null);

    try {
      const response = await fetch(
        `https://backend.scribbl.co/meeting-types/${workflowId}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to delete workflow: ${response.status} ${response.statusText}`
        );
      }

      // Close modal first
      setConfirmModal((prev) => ({ ...prev, open: false }));

      // Show success message
      setShowDeleteSuccess(true);
      setTimeout(() => {
        setShowDeleteSuccess(false);
      }, 3000);

      // Refresh the workflows list
      await fetchWorkflowsData();

      console.log(
        `Workflow "${workflowName}" (ID: ${workflowId}) deleted successfully`
      );
    } catch (error) {
      console.error("Error deleting workflow:", error);
      setDeleteError(error.message);
      // Keep modal open to show error
    } finally {
      setDeleteLoading(false);
    }
  }

  // Delete workflow confirmation
  function confirmDeleteWorkflow(mt) {
    setDeleteError(null);
    setConfirmModal({
      open: true,
      title: "Delete Workflow",
      message: `Are you sure you want to delete the "${mt.name}" workflow? This action cannot be undone.`,
      onConfirm: () => {
        deleteWorkflow(mt.ID, mt.name);
      },
    });
  }

  // For creating a new workflow
  function handleCreateNewWorkflow() {
    setEditWorkflowId(null);
    setShowWorkflowEditor(true);
  }

  // For editing an existing workflow
  function editWorkflow(mt) {
    setEditWorkflowId(mt.ID);
    setShowWorkflowEditor(true);
  }

  // Duplicate workflow
  function duplicateWorkflow(mt) {
    alert(`Duplicate workflow: ${mt.ID}`);
  }

  // Toggle expanded state for a workflow card
  function toggleWorkflowExpand(id) {
    setExpandedWorkflowId(expandedWorkflowId === id ? null : id);
  }

  // Handle search input change
  function handleSearchChange(e) {
    setSearchTerm(e.target.value);
    // Reset expanded workflow when searching
    setExpandedWorkflowId(null);
  }

  // Clear search
  function clearSearch() {
    setSearchTerm("");
  }

  // Handle filter change
  function handleFilterChange(filter) {
    setActiveFilter(filter);
    setShowFilters(false);
  }

  // Handle sort toggle
  function toggleSort() {
    setSortDesc(!sortDesc);
  }

  if (!user && loading) {
    return (
      <Navbar
        setUserEmail={setUserEmail}
        setUser={setUser}
        loading={loading}
        setLoading={setLoading}
        setLogout={setLogout}
        logout={logout}
      >
        <div className="flex items-center justify-center h-screen">
          <p className="text-sm text-gray-500">Loading user info...</p>
        </div>
      </Navbar>
    );
  }

  if (logout) {
    return (
      <Navbar
        setUserEmail={setUserEmail}
        setUser={setUser}
        loading={loading}
        setLoading={setLoading}
        setLogout={setLogout}
        logout={logout}
      >
        <div className="flex items-center justify-center h-screen">
          <p className="text-sm text-gray-500">
            You are not authorized. Please log in again.
          </p>
        </div>
      </Navbar>
    );
  }

  return (
    <Navbar
      setUserEmail={setUserEmail}
      setUser={setUser}
      loading={loading}
      setLoading={setLoading}
      setLogout={setLogout}
      logout={logout}
    >
      {/* Main container */}
      {showWorkflowEditor ? (
        <MeetingWorkflowEdit
          workflowId={editWorkflowId}
          workflowData={editWorkflowData} // Pass the complete data
          onSave={() => {
            setShowWorkflowEditor(false);
            fetchWorkflowsData();
          }}
          onCancel={() => setShowWorkflowEditor(false)}
          userEmail={userEmail}
        />
      ) : (
        <div className="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-gray-50">
          {/* Header with gradient background */}
          <div className="bg-gradient-to-r from-brand-turq to-brand-green text-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
                <div>
                  <h1 className="text-3xl font-bold">Meeting Workflows</h1>
                  <p className="mt-1 text-white text-opacity-90">
                    Create standardized processes for your recurring meetings
                  </p>
                </div>
                <button
                  onClick={handleCreateNewWorkflow}
                  className="inline-flex items-center px-5 py-2.5 rounded-lg shadow-sm bg-white text-brand-turq text-sm font-medium hover:bg-gray-50 transition-colors"
                >
                  <PlusIcon className="h-5 w-5 mr-2" />
                  Create New Workflow
                </button>
              </div>
            </div>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 space-y-6">
            {/* Search and Filter Controls */}
            <div className="bg-white rounded-xl shadow-sm p-4 border border-gray-200">
              <div className="flex flex-col sm:flex-row sm:items-center gap-4">
                <div className="relative flex-grow">
                  <SearchIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  <input
                    type="text"
                    className="block w-full rounded-lg border border-gray-300 pl-10 pr-10 py-2.5 focus:ring-2 focus:ring-brand-turq focus:border-brand-turq"
                    placeholder="Search workflows by name or description..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  {searchTerm && (
                    <button
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                      onClick={clearSearch}
                    >
                      <XIcon className="h-5 w-5" />
                    </button>
                  )}
                </div>

                <div className="flex items-center space-x-3">
                  {/* Filter Button */}
                  <div className="relative">
                    <button
                      onClick={() => setShowFilters(!showFilters)}
                      className="inline-flex items-center px-3 py-2.5 rounded-lg border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      <FilterIcon className="h-4 w-4 mr-1.5" />
                      {activeFilter === "all"
                        ? "All"
                        : activeFilter === "complete"
                        ? "Complete"
                        : "Incomplete"}
                    </button>

                    {/* Filter dropdown */}
                    {showFilters && (
                      <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg border border-gray-200 z-10">
                        <div className="py-1">
                          <button
                            onClick={() => handleFilterChange("all")}
                            className={`flex items-center px-4 py-2 text-sm w-full text-left ${
                              activeFilter === "all"
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700 hover:bg-gray-50"
                            }`}
                          >
                            {activeFilter === "all" && (
                              <CheckIcon className="h-4 w-4 mr-2 text-brand-turq" />
                            )}
                            <span
                              className={activeFilter === "all" ? "ml-6" : ""}
                            >
                              All Workflows
                            </span>
                          </button>
                          <button
                            onClick={() => handleFilterChange("complete")}
                            className={`flex items-center px-4 py-2 text-sm w-full text-left ${
                              activeFilter === "complete"
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700 hover:bg-gray-50"
                            }`}
                          >
                            {activeFilter === "complete" && (
                              <CheckIcon className="h-4 w-4 mr-2 text-brand-turq" />
                            )}
                            <span
                              className={
                                activeFilter === "complete" ? "ml-6" : ""
                              }
                            >
                              Complete Workflows
                            </span>
                          </button>
                          <button
                            onClick={() => handleFilterChange("incomplete")}
                            className={`flex items-center px-4 py-2 text-sm w-full text-left ${
                              activeFilter === "incomplete"
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700 hover:bg-gray-50"
                            }`}
                          >
                            {activeFilter === "incomplete" && (
                              <CheckIcon className="h-4 w-4 mr-2 text-brand-turq" />
                            )}
                            <span
                              className={
                                activeFilter === "incomplete" ? "ml-6" : ""
                              }
                            >
                              Incomplete Workflows
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Sort Button */}
                  <button
                    onClick={toggleSort}
                    className="inline-flex items-center px-3 py-2.5 rounded-lg border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    <SortAscendingIcon
                      className={`h-4 w-4 mr-1.5 ${
                        sortDesc ? "transform rotate-180" : ""
                      }`}
                    />
                    {sortDesc ? "Z-A" : "A-Z"}
                  </button>
                </div>
              </div>
            </div>

            {/* First-Time User Guidance */}
            {showGuidance && !workflowsLoading && (
              <div className="bg-brand-turq bg-opacity-5 rounded-xl border-2 border-dashed border-brand-turq border-opacity-30 p-5">
                <div className="flex items-start">
                  <div className="flex-shrink-0 p-1">
                    <InformationCircleIcon className="h-6 w-6 text-brand-turq" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-lg font-medium text-gray-900">
                      What are Meeting Workflows?
                    </h3>
                    <p className="mt-2 text-sm text-gray-600">
                      Meeting workflows help you standardize and automate your
                      meeting processes. Each workflow includes:
                    </p>
                    <ul className="mt-3 space-y-2">
                      <li className="flex items-start">
                        <div className="flex-shrink-0 mt-0.5 mr-1.5 w-5 h-5 rounded-full bg-brand-turq bg-opacity-10 flex items-center justify-center">
                          <span className="text-xs font-medium text-brand-turq">
                            1
                          </span>
                        </div>
                        <p className="text-sm text-gray-600">
                          <span className="font-medium text-gray-800">
                            Meeting Type
                          </span>{" "}
                          - Define what kind of meeting this is and how to
                          identify it
                        </p>
                      </li>
                      <li className="flex items-start">
                        <div className="flex-shrink-0 mt-0.5 mr-1.5 w-5 h-5 rounded-full bg-brand-turq bg-opacity-10 flex items-center justify-center">
                          <span className="text-xs font-medium text-brand-turq">
                            2
                          </span>
                        </div>
                        <p className="text-sm text-gray-600">
                          <span className="font-medium text-gray-800">
                            Note Template
                          </span>{" "}
                          - Create a standardized structure for meeting notes
                        </p>
                      </li>
                      <li className="flex items-start">
                        <div className="flex-shrink-0 mt-0.5 mr-1.5 w-5 h-5 rounded-full bg-brand-turq bg-opacity-10 flex items-center justify-center">
                          <span className="text-xs font-medium text-brand-turq">
                            3
                          </span>
                        </div>
                        <p className="text-sm text-gray-600">
                          <span className="font-medium text-gray-800">
                            Automations
                          </span>{" "}
                          - Set up automatic actions to happen after meetings
                        </p>
                      </li>
                    </ul>
                    <div className="mt-4">
                      <button
                        onClick={handleCreateNewWorkflow}
                        className="inline-flex items-center px-4 py-2 rounded-lg shadow-sm bg-brand-turq text-white text-sm font-medium hover:bg-opacity-90 transition-colors"
                      >
                        <PlusIcon className="h-4 w-4 mr-1.5" />
                        Create Your First Workflow
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Workflows List */}
            {workflowsLoading ? (
              <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-10 text-center">
                <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-brand-turq bg-opacity-10 mb-4">
                  <svg
                    className="animate-spin h-8 w-8 text-brand-turq"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                    ></path>
                  </svg>
                </div>
                <h3 className="text-lg font-medium text-gray-900">
                  Loading your workflows
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  This will just take a moment...
                </p>
              </div>
            ) : filteredWorkflows.length === 0 ? (
              <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-10 text-center">
                <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-100 mb-4">
                  <DocumentTextIcon className="h-8 w-8 text-gray-400" />
                </div>
                <h3 className="text-lg font-medium text-gray-900">
                  {searchTerm
                    ? "No matching workflows found"
                    : "No workflows yet"}
                </h3>
                <p className="mt-1 text-sm text-gray-500 max-w-md mx-auto">
                  {searchTerm
                    ? `We couldn't find any workflows matching "${searchTerm}". Try different keywords or clear your search.`
                    : "Get started by creating your first meeting workflow to standardize your process."}
                </p>
                {searchTerm ? (
                  <button
                    onClick={clearSearch}
                    className="mt-4 inline-flex items-center px-4 py-2 rounded-lg border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 shadow-sm"
                  >
                    <XIcon className="h-4 w-4 mr-1.5" />
                    Clear Search
                  </button>
                ) : (
                  <button
                    onClick={handleCreateNewWorkflow}
                    className="mt-4 inline-flex items-center px-4 py-2 rounded-lg shadow-sm bg-brand-turq text-white text-sm font-medium hover:bg-opacity-90 transition-colors"
                  >
                    <PlusIcon className="h-4 w-4 mr-1.5" />
                    Create First Workflow
                  </button>
                )}
              </div>
            ) : (
              <div className="space-y-4">
                {filteredWorkflows.map((workflow) => {
                  const template = getTemplateForMeetingType(workflow);
                  const actions = getActionsForMeetingType(workflow);
                  const status = getWorkflowCompletionStatus(workflow);
                  const isExpanded = expandedWorkflowId === workflow.ID;

                  return (
                    <div
                      key={workflow.ID}
                      className={classNames(
                        "bg-white rounded-xl shadow-sm border transition-all duration-300",
                        isExpanded ? "border-brand-turq" : "border-gray-200",
                        status.isComplete
                          ? "border-l-4 border-l-green-500"
                          : "border-l-4 border-l-amber-500"
                      )}
                    >
                      {/* Card Header - Always visible */}
                      <div
                        className={classNames(
                          "px-5 py-4 flex items-center justify-between cursor-pointer",
                          isExpanded ? "border-b border-gray-100" : ""
                        )}
                        onClick={() => toggleWorkflowExpand(workflow.ID)}
                      >
                        <div className="flex items-center space-x-4">
                          <div
                            className={classNames(
                              "w-12 h-12 rounded-lg flex items-center justify-center text-white text-xl font-bold flex-shrink-0",
                              getAvatarColor(workflow.name || "")
                            )}
                          >
                            {getAvatarLetter(workflow.name)}
                          </div>
                          <div>
                            <div className="flex items-center">
                              <h2 className="text-lg font-semibold text-gray-900">
                                {workflow.name}
                              </h2>
                              <div
                                className={classNames(
                                  "ml-3 px-2 py-0.5 text-xs font-medium rounded-full",
                                  status.isComplete
                                    ? "bg-green-100 text-green-800"
                                    : "bg-amber-100 text-amber-800"
                                )}
                              >
                                {status.isComplete ? "Complete" : "In Progress"}
                              </div>
                            </div>
                            <p className="text-sm text-gray-500 line-clamp-1">
                              {workflow.description ||
                                "No description provided"}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-center space-x-3">
                          <div className="hidden md:block w-32">
                            <div className="text-xs text-gray-500 mb-1 flex justify-between">
                              <span>Progress</span>
                              <span>{status.percentage}%</span>
                            </div>
                            <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                              <div
                                className={classNames(
                                  "h-full rounded-full",
                                  status.percentage === 100
                                    ? "bg-green-500"
                                    : "bg-brand-turq"
                                )}
                                style={{ width: `${status.percentage}%` }}
                              ></div>
                            </div>
                          </div>

                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              console.log("edit ", workflow);
                              editWorkflow(workflow);
                            }}
                            className="hidden md:inline-flex items-center px-3 py-1.5 rounded-lg bg-brand-turq bg-opacity-10 text-brand-turq text-sm font-medium hover:bg-opacity-20 transition-colors"
                          >
                            <PencilIcon className="h-3.5 w-3.5 mr-1" />
                            Edit
                          </button>

                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <Menu.Button
                              onClick={(e) => e.stopPropagation()}
                              className="flex items-center rounded-full p-1.5 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-brand-turq"
                            >
                              <DotsVerticalIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 py-1 divide-y divide-gray-100">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          editWorkflow(workflow);
                                        }}
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "flex items-center px-4 py-2 text-sm text-gray-700 w-full text-left"
                                        )}
                                      >
                                        <PencilIcon className="h-4 w-4 mr-3 text-gray-500" />
                                        Edit Workflow
                                      </button>
                                    )}
                                  </Menu.Item>
                                </div>
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          duplicateWorkflow(workflow);
                                        }}
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "flex items-center px-4 py-2 text-sm text-gray-700 w-full text-left"
                                        )}
                                      >
                                        <DuplicateIcon className="h-4 w-4 mr-3 text-gray-500" />
                                        Duplicate
                                      </button>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          confirmDeleteWorkflow(workflow);
                                        }}
                                        className={classNames(
                                          active ? "bg-red-50" : "",
                                          "flex items-center px-4 py-2 text-sm text-red-600 w-full text-left"
                                        )}
                                      >
                                        <TrashIcon className="h-4 w-4 mr-3 text-red-500" />
                                        Delete
                                      </button>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>

                          <div
                            className="flex items-center justify-center h-7 w-7 rounded-full hover:bg-gray-100"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleWorkflowExpand(workflow.ID);
                            }}
                          >
                            <ChevronRightIcon
                              className={classNames(
                                "h-5 w-5 text-gray-400 transition-transform duration-200",
                                isExpanded ? "transform rotate-90" : ""
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Expanded Content - Workflow Details */}
                      <div
                        className={classNames(
                          "overflow-hidden transition-all duration-300 ease-in-out",
                          isExpanded
                            ? "max-h-[600px] opacity-100"
                            : "max-h-0 opacity-0"
                        )}
                      >
                        <div className="px-5 py-5">
                          {/* Workflow Process Visualization */}
                          <div className="relative pb-4">
                            <div className="text-sm font-medium text-gray-700 mb-4">
                              Workflow Process
                            </div>

                            <div className="relative">
                              {/* Connecting line */}
                              <div className="absolute left-6 top-6 bottom-6 w-0.5 bg-gray-200"></div>

                              {/* Meeting Type Step */}
                              <div className="relative flex items-start mb-5">
                                <div className="flex-shrink-0">
                                  <div
                                    className={classNames(
                                      "w-12 h-12 rounded-lg flex items-center justify-center text-white shadow-sm z-10",
                                      getAvatarColor(workflow.name || "")
                                    )}
                                  >
                                    <span className="text-lg font-bold">1</span>
                                  </div>
                                </div>
                                <div className="ml-4 bg-gray-50 rounded-lg p-4 border border-gray-200 flex-grow">
                                  <h3 className="font-medium text-gray-900">
                                    Meeting Type
                                  </h3>
                                  <p className="text-sm text-gray-600 mt-1">
                                    {workflow.description ||
                                      "No description provided"}
                                  </p>
                                  <div className="mt-3 flex items-center text-xs text-gray-500">
                                    <CheckIcon className="h-4 w-4 text-green-500 mr-1" />
                                    Complete
                                  </div>
                                </div>
                              </div>

                              {/* Note Template Step */}
                              <div className="relative flex items-start mb-5">
                                <div className="flex-shrink-0">
                                  <div
                                    className={classNames(
                                      "w-12 h-12 rounded-lg flex items-center justify-center text-white shadow-sm z-10",
                                      template || workflow.regularMeetingNotes
                                        ? "bg-blue-500"
                                        : "bg-gray-400"
                                    )}
                                  >
                                    <span className="text-lg font-bold">2</span>
                                  </div>
                                </div>
                                <div className="ml-4 bg-gray-50 rounded-lg p-4 border border-gray-200 flex-grow">
                                  <h3 className="font-medium text-gray-900">
                                    Note Template
                                  </h3>
                                  {template ? (
                                    <>
                                      <p className="text-sm text-gray-600 mt-1">
                                        {template.title}
                                      </p>
                                      <div className="mt-3 flex items-center text-xs text-gray-500">
                                        <CheckIcon className="h-4 w-4 text-green-500 mr-1" />
                                        Template configured
                                      </div>
                                    </>
                                  ) : workflow.regularMeetingNotes ? (
                                    <>
                                      <p className="text-sm text-gray-600 mt-1">
                                        Standard meeting notes format
                                      </p>
                                      <div className="mt-3 flex items-center text-xs text-gray-500">
                                        <CheckIcon className="h-4 w-4 text-green-500 mr-1" />
                                        Default notes
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <p className="text-sm text-gray-600 mt-1">
                                        No template configured yet
                                      </p>
                                      <div className="mt-3">
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            editWorkflow(workflow);
                                          }}
                                          className="inline-flex items-center px-2 py-1 text-xs font-medium rounded bg-brand-turq text-white hover:bg-opacity-90 transition-colors"
                                        >
                                          <PlusIcon className="h-3 w-3 mr-1" />
                                          Add Template
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>

                              {/* Automations Step */}
                              <div className="relative flex items-start">
                                <div className="flex-shrink-0">
                                  <div
                                    className={classNames(
                                      "w-12 h-12 rounded-lg flex items-center justify-center text-white shadow-sm z-10",
                                      actions.length > 0
                                        ? "bg-purple-500"
                                        : "bg-gray-400"
                                    )}
                                  >
                                    <span className="text-lg font-bold">3</span>
                                  </div>
                                </div>
                                <div className="ml-4 bg-gray-50 rounded-lg p-4 border border-gray-200 flex-grow">
                                  <h3 className="font-medium text-gray-900">
                                    Automations
                                  </h3>
                                  {actions.length > 0 ? (
                                    <>
                                      <div className="mt-2 space-y-2">
                                        {actions
                                          .slice(0, 2)
                                          .map((action, index) => (
                                            <div
                                              key={action.ID || index}
                                              className="flex items-center"
                                            >
                                              <LightningBoltIcon className="h-4 w-4 text-purple-500 mr-2" />
                                              <span className="text-sm text-gray-600">
                                                {action.name}
                                              </span>
                                            </div>
                                          ))}
                                        {actions.length > 2 && (
                                          <div className="text-xs text-gray-500">
                                            + {actions.length - 2} more
                                            automation
                                            {actions.length - 2 !== 1
                                              ? "s"
                                              : ""}
                                          </div>
                                        )}
                                      </div>
                                      <div className="mt-3 flex items-center text-xs text-gray-500">
                                        <CheckIcon className="h-4 w-4 text-green-500 mr-1" />
                                        {actions.length} automation
                                        {actions.length !== 1 ? "s" : ""}{" "}
                                        configured
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <p className="text-sm text-gray-600 mt-1">
                                        No automations configured yet
                                      </p>
                                      <div className="mt-3">
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            editWorkflow(workflow);
                                          }}
                                          className="inline-flex items-center px-2 py-1 text-xs font-medium rounded bg-brand-turq text-white hover:bg-opacity-90 transition-colors"
                                        >
                                          <PlusIcon className="h-3 w-3 mr-1" />
                                          Add Automation
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Edit Button (always visible on expanded card) */}
                          <div className="mt-4 flex justify-end">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                editWorkflow(workflow);
                              }}
                              className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-brand-turq hover:bg-opacity-90 transition-colors"
                            >
                              <PencilIcon className="h-4 w-4 mr-2" />
                              Edit Workflow
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Success notification */}
      <Transition
        show={showDeleteSuccess}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed top-4 right-4 z-50"
      >
        <div className="bg-green-50 border border-green-100 text-green-700 px-4 py-3 rounded-lg shadow-lg flex items-start">
          <CheckIcon className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-0.5" />
          <div>
            <p className="font-medium">Workflow deleted successfully</p>
            <p className="text-sm text-green-600 mt-1">
              The workflow has been removed.
            </p>
          </div>
        </div>
      </Transition>

      {/* CONFIRMATION MODAL */}
      <Transition.Root show={confirmModal.open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[9999]"
          onClose={() => setConfirmModal((prev) => ({ ...prev, open: false }))}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-xl p-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon className="h-6 w-6 text-red-600" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {confirmModal.title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {confirmModal.message}
                        </p>
                      </div>

                      {/* Error message */}
                      {deleteError && (
                        <div className="mt-4 p-3 bg-red-50 border border-red-100 rounded-md">
                          <div className="flex">
                            <ExclamationIcon className="h-5 w-5 text-red-500 mr-2 flex-shrink-0" />
                            <div className="text-sm text-red-700">
                              {deleteError}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className={`w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white ${
                        deleteLoading
                          ? "opacity-75 cursor-not-allowed"
                          : "hover:bg-red-700"
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`}
                      onClick={confirmModal.onConfirm}
                      disabled={deleteLoading}
                    >
                      {deleteLoading ? (
                        <>
                          <svg
                            className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Deleting...
                        </>
                      ) : (
                        "Delete"
                      )}
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() =>
                        setConfirmModal((prev) => ({ ...prev, open: false }))
                      }
                      disabled={deleteLoading}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Navbar>
  );
}
