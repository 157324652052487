import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  DocumentTextIcon,
  SearchIcon,
  XIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  CheckIcon,
  PlusIcon,
  ExclamationIcon,
  ArrowRightIcon,
  SparklesIcon,
  ArrowLeftIcon,
  LightningBoltIcon,
  DocumentDuplicateIcon,
  PencilIcon,
  ExternalLinkIcon,
  CalendarIcon,
  ClockIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { format, parseISO } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowsOut, ArrowsIn } from "phosphor-react";
import RichJsonRenderer from "../RichJsonRenderer";
import { isEqual } from "lodash";

/**
 * ConversationalNoteTemplateStep Component - Improved Q/A Flow
 *
 * Self-sufficient approach to creating and configuring meeting note templates.
 * It handles its own data fetching and saving. Once completed, it calls
 * `setMeetingNoteTemplate(finalTemplateData)` to provide the created/updated
 * note template back to the parent.
 *
 * Props:
 * - meetingTypeId (string or number): The ID of the meeting type this template belongs to
 * - meetingTypeName (string): The name of the meeting type (for default labeling)
 * - userEmail (string): The current user's email for searching transcripts, etc.
 * - initialData (object): Prefilled data for this template (e.g., {name, content, templateId, isDefault, regularMeetingNotes})
 * - setMeetingNoteTemplate (function): Callback to pass the final note template (and any related data) to the parent
 *
 * There are no UI or UX changes from the original code—only internal refactoring
 * so that this component handles all data, API calls, and final save logic itself.
 */
export default function ConversationalNoteTemplateStep({
  meetingTypeId = null,
  meetingTypeName = "Meeting",
  userEmail = "",
  initialData = {},
  setMeetingNoteTemplate,
}) {
  // -------------------
  // State Management
  // -------------------
  const [templateName, setTemplateName] = useState(
    initialData.title || `${meetingTypeName} Notes`
  );
  const [templateContent, setTemplateContent] = useState(
    initialData.content || ""
  );
  const [selectedTemplate, setSelectedTemplate] = useState(
    initialData.templateId || null
  );

  // Path tracking: "custom", "default", "manual", "ai"
  const [selectedPath, setSelectedPath] = useState(null);

  // Local step-based flow
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [workflowComplete, setWorkflowComplete] = useState(false);

  // AI generation states
  const [aiGenerated, setAiGenerated] = useState(false);
  const [aiGenerating, setAiGenerating] = useState(false);
  const [aiError, setAiError] = useState("");
  const [aiGenerationStep, setAiGenerationStep] = useState(0);

  // Summaries for AI generation
  const [aiAnswers, setAiAnswers] = useState({
    purpose: "",
    keyContent: "",
    noteLength: "balanced", // Default to "balanced"
    sampleStructure: "",
    additionalDetails: "",
  });

  // Meeting selection & preview states
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [meetingResults, setMeetingResults] = useState([]);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("quick"); // "quick" or "transcript"
  const [selectedTestMeeting, setSelectedTestMeeting] = useState(null);
  const [generatingPreview, setGeneratingPreview] = useState(false);
  const [previewResult, setPreviewResult] = useState(null);
  const [previewError, setPreviewError] = useState("");
  const [previewGenerationStep, setPreviewGenerationStep] = useState(0);
  const [previewNotesCollapsed, setPreviewNotesCollapsed] = useState(false);

  // Validation & final save
  const completeProcessed = useRef(false);

  // Refs
  const inputRefs = useRef([]);
  const contentRef = useRef(null);
  const textareaRef = useRef(null);

  // For code clarity, define a default template content:
  const defaultTemplateContent =
    "Please provide a comprehensive summary of the meeting with the following sections:\n\n1. SUMMARY\nProvide a brief overview of what was discussed in 2-3 sentences.\n\n2. KEY POINTS\nList the most important topics that were discussed.\n\n3. ACTION ITEMS\nList all action items mentioned, who is responsible, and due dates if specified.\n\n4. DECISIONS MADE\nSummarize any decisions that were agreed upon during the meeting.\n\n5. NEXT STEPS\nOutline what will happen next.";

  // -----------------------------
  // Q/A Conversational Flow Setup
  // -----------------------------
  const questions = [
    {
      id: "customize-choice",
      title: `Do you want to customize the meeting notes for this meeting type?`,
      subtitle: "Choose an option to get started",
      inputType: "customizeChoice",
      choices: [
        {
          id: "custom",
          label: "Yes, let's create a custom meeting note template",
          description: "Customize how your meeting notes are structured",
        },
        {
          id: "default",
          label: "No, regular meeting notes are fine",
          description: "Use the standard meeting note template",
        },
      ],
      validate: () => true,
    },
    {
      id: "template-name",
      title: "What would you like to name your notes template?",
      subtitle: "This will help you identify this template later",
      validate: (value) => value && value.trim().length > 0,
      errorMessage: "Please provide a name for your template",
      inputType: "text",
      value: templateName,
      placeholder: `${meetingTypeName} Notes`,
    },
    {
      id: "create-method",
      title: "How would you like to create your template?",
      subtitle: "You can write it yourself or let AI help you create it",
      inputType: "createMethodChoice",
      choices: [
        {
          id: "ai",
          label: "Create with AI",
          description:
            "Answer a few questions to generate the perfect template",
          recommended: true,
        },
        {
          id: "manual",
          label: "Write it myself",
          description: "Manually define the template structure and content",
        },
      ],
      validate: () => true,
    },
    {
      id: "ai-purpose",
      title: "What is the main purpose of these meetings?",
      subtitle: "This helps AI understand what's important to capture",
      validate: (value) => value && value.trim().length > 0,
      errorMessage: "Please describe the purpose of these meetings",
      inputType: "textarea",
      value: aiAnswers.purpose,
      placeholder:
        "e.g. Meeting with potential customers to understand their needs and present our solution",
    },
    {
      id: "ai-key-content",
      title: "What key information should be captured in the notes?",
      subtitle: "Describe the sections and details that would be most valuable",
      validate: (value) => value && value.trim().length > 0,
      errorMessage: "Please specify what information to capture",
      inputType: "textarea",
      value: aiAnswers.keyContent,
      placeholder:
        "e.g. Customer background, pain points, proposed solutions, action items, and next steps",
    },
    {
      id: "note-length-preference",
      title: "What length of notes do you prefer?",
      subtitle: "This helps us create notes that match your team's needs",
      inputType: "noteLengthChoice",
      validate: () => true,
      value: aiAnswers.noteLength,
    },
    {
      id: "sample-structure",
      title: "Do you have any sample note structures to share?",
      subtitle: "This helps us understand your preferred format and style",
      inputType: "sampleInput",
      validate: () => true,
      value: aiAnswers.sampleStructure,
      placeholder:
        "Paste an example of notes you like, or describe your preferred structure",
    },
    {
      id: "additional-details",
      title: "Anything else we should know?",
      subtitle: "Add any other preferences or requirements for your notes",
      inputType: "textarea",
      validate: () => true,
      value: aiAnswers.additionalDetails,
      placeholder:
        "e.g. Always include a follow-up section, use bullet points for action items, etc.",
    },
    {
      id: "template-review",
      title: "Review your template",
      subtitle:
        "This includes a brief description and example format for your meeting notes",
      inputType: "templateReview",
      validate: (value) => value && value.trim().length > 0,
      errorMessage: "Template content can't be empty",
      value: templateContent,
    },
    {
      id: "generating-template",
      title: "Creating your custom template",
      subtitle:
        "Using AI to generate the perfect template based on your answers",
      inputType: "aiGeneration",
      validate: () => true,
      nextStep: 8, // Go to template review
    },
    {
      id: "select-test-meeting",
      title: "Select a meeting to test your template",
      subtitle: "Choose a meeting to see how your template works in practice",
      inputType: "selectTestMeeting",
      validate: () => true,
    },
    {
      id: "generating-preview",
      title: "Generating preview",
      subtitle: "Creating notes based on your template",
      inputType: "generatingPreview",
      validate: () => true,
    },
    {
      id: "preview-results",
      title: "Preview results",
      subtitle: "Here's how your notes will look with this template",
      inputType: "previewResults",
      validate: () => true,
    },
    {
      id: "template-complete",
      title: "Note template configured successfully!",
      subtitle: "Your template is ready to use",
      inputType: "complete",
      validate: () => true,
    },
  ];

  // -----------------------------
  // Effects
  // -----------------------------

  // Focus the appropriate input when the question changes
  useEffect(() => {
    const focusTimer = setTimeout(() => {
      if (inputRefs.current[currentQuestion]) {
        inputRefs.current[currentQuestion]?.focus();
      }
    }, 500);
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
    return () => clearTimeout(focusTimer);
  }, [currentQuestion]);

  // If we've marked workflowComplete, do a final pass to ensure we only process once
  // In ConversationalNoteTemplateStep component (paste.txt)
  useEffect(() => {
    if (workflowComplete && !completeProcessed.current) {
      completeProcessed.current = true;

      const finalData = {
        title: templateName,
        content: templateContent,
        isDefault: selectedPath === "default",
        templateId: selectedTemplate,
        meetingTypeId,
      };

      // If "default" path and we have a meetingTypeId, update the meeting type
      // to use regularMeetingNotes = true (or do other logic if desired)
      if (selectedPath === "default" && meetingTypeId) {
        // Mark it as regularMeetingNotes in the backend
        saveDefaultToMeetingType(meetingTypeId)
          .then((updatedMeetingType) => {
            // Pass back the updated meeting type to the parent
            setMeetingNoteTemplate({
              ...finalData,
              updatedMeetingType, // Include the updated meeting type
            });
          })
          .catch((error) => {
            console.error("Error saving default template:", error);
            setMeetingNoteTemplate(finalData);
          });
      }
      // If "custom" path, we finalize by ensuring we have a template saved and
      // the meeting type is updated. This is handled in handleAcceptTemplate or
      // right before we set the workflow to complete. If something was missed,
      // do a final call here. But let's assume it's already done.
      else {
        setMeetingNoteTemplate(finalData);
      }
    }
  }, [
    workflowComplete,
    selectedPath,
    templateName,
    templateContent,
    selectedTemplate,
    meetingTypeId,
    setMeetingNoteTemplate,
  ]);

  // ----------------------------------------
  // API helper for default note selection
  // ----------------------------------------
  // In ConversationalNoteTemplateStep component (paste.txt)
  const saveDefaultToMeetingType = async (typeId) => {
    try {
      // First, fetch the current meeting type to preserve its fields
      const response = await fetch(
        `https://backend.scribbl.co/meeting-types/${typeId}`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch meeting type");
      }

      const meetingTypeData = await response.json();
      const existingMeetingType =
        meetingTypeData.meetingType || meetingTypeData;

      // Update with regularMeetingNotes while preserving other fields
      const updateResponse = await fetch(
        `https://backend.scribbl.co/meeting-types/${typeId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            ...existingMeetingType, // Preserve all existing fields
            regularMeetingNotes: true,
            meetingTemplateID: null,
          }),
        }
      );

      if (!updateResponse.ok) {
        throw new Error("Failed to update meeting type");
      }

      const updatedData = await updateResponse.json();
      return (
        updatedData.meetingType || {
          ...existingMeetingType,
          regularMeetingNotes: true,
        }
      );
    } catch (error) {
      console.error("Failed to save default template to meeting type:", error);
      throw error;
    }
  };

  // ----------------------------------------
  // Meeting search
  // ----------------------------------------
  const fetchMeetings = async () => {
    if (searchTerm.trim().length < 2) {
      setMeetingResults([]);
      return;
    }
    setLoadingMeetings(true);
    setSearchError("");
    try {
      const params = new URLSearchParams();
      params.set("view", "all_meetings");
      if (userEmail) {
        params.set("email", userEmail);
      }
      params.set("q", searchTerm.trim());
      params.set("searchType", searchType);

      const url = `https://backend.scribbl.co/enhanced-recording?${params.toString()}`;
      const resp = await fetch(url, { credentials: "include" });
      if (!resp.ok) {
        throw new Error("Failed to fetch meetings");
      }
      const data = await resp.json();
      setMeetingResults(data.data || []);
    } catch (error) {
      setSearchError("Failed to fetch meetings. Please try again.");
      setMeetingResults([]);
    } finally {
      setLoadingMeetings(false);
    }
  };

  // ----------------------------------------
  // AI Template Generation
  // ----------------------------------------
  const generateTemplateFromAnswers = async () => {
    // Move to the AI generation step
    setCurrentQuestion(9);
    setAiGenerating(true);
    setAiError("");
    setAiGenerationStep(1);

    const interval = setInterval(() => {
      setAiGenerationStep((prev) => (prev < 3 ? prev + 1 : prev));
    }, 800);

    try {
      // POST to our AI generation endpoint
      const response = await fetch(
        "https://backend.scribbl.co/meeting-templates/generate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            purpose: aiAnswers.purpose,
            keyContent: aiAnswers.keyContent,
            noteLength: aiAnswers.noteLength,
            sampleStructure: aiAnswers.sampleStructure,
            additionalDetails: aiAnswers.additionalDetails,
            meetingTypeName: meetingTypeName || "Meeting",
            userProvidedName: templateName,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Server responded with ${response.status}: ${errorText}`
        );
      }

      const data = await response.json();
      if (data.success && data.template) {
        setTemplateContent(data.template);
        setAiGenerated(true);
        // Move to template review step
        setCurrentQuestion(8);
      } else {
        throw new Error(data.message || "Failed to generate template");
      }
    } catch (error) {
      console.error("Template generation error:", error);
      setAiError(
        "Failed to generate template: " + (error.message || "Unknown error")
      );
    } finally {
      clearInterval(interval);
      setAiGenerating(false);
      setAiGenerationStep(3);
    }
  };

  // ----------------------------------------
  // Preview Generation
  // ----------------------------------------
  const handleGeneratePreview = async () => {
    // Move to the generating preview step
    setCurrentQuestion(11);
    setGeneratingPreview(true);
    setPreviewError("");
    setPreviewResult(null);
    setPreviewGenerationStep(1);

    const interval = setInterval(() => {
      setPreviewGenerationStep((prev) => (prev < 3 ? prev + 1 : prev));
    }, 800);

    try {
      if (!selectedTestMeeting) {
        throw new Error("No meeting selected");
      }
      if (!templateContent.trim()) {
        throw new Error("Template content cannot be empty");
      }
      const meetingIdentifier =
        String(
          selectedTestMeeting.identifier || selectedTestMeeting.ID || ""
        ) || "";

      if (!meetingIdentifier) {
        throw new Error("Selected meeting has no valid identifier");
      }

      const requestBody = {
        meetingID: meetingIdentifier,
        templateContent,
        noteLength: aiAnswers.noteLength,
      };

      const response = await fetch(
        "https://backend.scribbl.co/meeting-templates/test-preview",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(requestBody),
        }
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Server responded with ${response.status}: ${errorText}`
        );
      }

      const data = await response.json();
      if (data.success) {
        setPreviewResult({
          title:
            data.title || selectedTestMeeting.meetingTitle || "Meeting Notes",
          content: data.content, // JSON or fallback string from backend
        });
        setCurrentQuestion(12); // Show results
      } else {
        throw new Error(data.error || "Failed to generate preview");
      }
    } catch (err) {
      console.error("Preview generation error:", err);
      setPreviewError("Error generating preview: " + err.message);
      setCurrentQuestion(12); // Show results even with error
    } finally {
      clearInterval(interval);
      setGeneratingPreview(false);
      setPreviewGenerationStep(3);
    }
  };

  // ----------------------------------------
  // Finalizing & Saving Custom Templates
  // ----------------------------------------

  // Called once the user says "Looks Good!" on preview results
  // 3. Update the handleAcceptTemplate function to ensure it properly handles template creation
  // and navigation (around line 1241)
  const handleAcceptTemplate = () => {
    // If there's no meetingTypeId, we just finalize locally
    if (!meetingTypeId || !templateContent.trim()) {
      // Mark the flow complete
      setCurrentQuestion(questions.length - 1);
      setWorkflowComplete(true);
      return;
    }

    // If we already have a template ID, assume it's updated on the server
    if (selectedTemplate) {
      updateMeetingTypeWithTemplate(
        meetingTypeId,
        selectedTemplate,
        templateName
      )
        .catch((error) => {
          console.error(
            "Error updating meeting type with existing template:",
            error
          );
        })
        .finally(() => {
          // Go to the final screen
          setCurrentQuestion(questions.length - 1);
          setWorkflowComplete(true);
        });
      return;
    }

    // Otherwise, create a new template, then attach
    createTemplateAndAttach(meetingTypeId, templateName, templateContent)
      .then((newTemplateId) => {
        // Make sure we update selectedTemplate with the new ID
        setSelectedTemplate(newTemplateId);
      })
      .catch((error) => {
        console.error(
          "Error creating template or updating meeting type:",
          error
        );
      })
      .finally(() => {
        setCurrentQuestion(questions.length - 1);
        setWorkflowComplete(true);
      });
  };

  const createTemplateAndAttach = async (typeId, name, content) => {
    try {
      const createResp = await fetch(
        "https://backend.scribbl.co/meeting-templates",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            title: name, // Changed from title to name
            content,
          }),
        }
      );
      if (!createResp.ok) {
        throw new Error(`Failed to create template: ${createResp.statusText}`);
      }
      const newTemplate = await createResp.json();
      if (!newTemplate?.ID) {
        throw new Error("No template ID returned from creation");
      }
      setSelectedTemplate(newTemplate.ID);
      await updateMeetingTypeWithTemplate(typeId, newTemplate.ID);
    } catch (error) {
      throw error;
    }
  };

  // Helper: update meeting type with an existing template ID
  const updateMeetingTypeWithTemplate = async (typeId, templateID) => {
    try {
      // First, fetch the current meeting type to preserve its fields
      const getResp = await fetch(
        `https://backend.scribbl.co/meeting-types/${typeId}`,
        {
          credentials: "include",
        }
      );

      if (!getResp.ok) {
        throw new Error(`Failed to fetch meeting type: ${getResp.statusText}`);
      }

      const meetingTypeData = await getResp.json();
      const currentMeetingType = meetingTypeData.meetingType || meetingTypeData;

      // Update only what's needed, preserving all existing fields
      const resp = await fetch(
        `https://backend.scribbl.co/meeting-types/${typeId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            ...currentMeetingType, // Keep all existing fields
            meetingTemplateID: templateID,
            regularMeetingNotes: false,
          }),
        }
      );

      if (!resp.ok) {
        throw new Error(`Failed to update meeting type: ${resp.statusText}`);
      }
    } catch (error) {
      throw error;
    }
  };

  // ----------------------------------------
  // Flow Navigation
  // ----------------------------------------
  const handlePathChoice = (choiceId) => {
    // "default" or "custom"
    setSelectedPath(choiceId);
    if (choiceId === "default") {
      // Use default notes, mark component complete
      setTemplateContent(defaultTemplateContent);

      // UPDATED: Instead of jumping to the last step in the workflow steps list
      // Just move to the component's completion view
      setCurrentQuestion(questions.length - 1); // This is the "complete" question inside this component
      setWorkflowComplete(true);
    } else {
      // "custom" path
      setCurrentQuestion(1); // Next question: template name
    }
  };

  const handleCreateMethodChoice = (choiceId) => {
    // "ai" or "manual"
    setSelectedPath(choiceId);
    if (choiceId === "manual") {
      // For manual, skip AI Q's -> go directly to template editor
      setTemplateContent(defaultTemplateContent);
      setCurrentQuestion(8); // template-review
    } else {
      // "ai" path -> go to first AI question
      setCurrentQuestion(3);
    }
  };

  const handleNoteLengthChoice = (choice) => {
    setAiAnswers((prev) => ({ ...prev, noteLength: choice }));
  };

  const handleNext = () => {
    const question = questions[currentQuestion];
    // Validate if needed
    if (question.validate && !question.validate(question.value)) {
      return; // block if invalid
    }

    // Special logic for certain steps
    if (question.id === "additional-details") {
      // Last AI question, generate or skip
      if (selectedPath === "ai") {
        generateTemplateFromAnswers();
        return;
      } else {
        // manual -> go to template review
        setCurrentQuestion(8);
        return;
      }
    }
    if (question.id === "template-review") {
      // Move to test-meeting
      setCurrentQuestion(10);
      return;
    }
    if (question.id === "select-test-meeting") {
      if (selectedTestMeeting) {
        handleGeneratePreview();
      } else {
        // Skip testing - call handleAcceptTemplate instead of directly setting states
        handleAcceptTemplate();
      }
      return;
    }

    // If question has nextStep specified, go there
    if (question.nextStep !== undefined) {
      setCurrentQuestion(question.nextStep);
    } else {
      // Just go next
      setCurrentQuestion((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    // Custom "back" logic for certain steps
    if (currentQuestion === 8) {
      // template-review
      // If AI path, go back to additional details
      if (selectedPath === "ai") {
        setCurrentQuestion(7);
      } else {
        setCurrentQuestion(7);
      }
      return;
    }
    if (currentQuestion === 10) {
      // select-test-meeting
      setCurrentQuestion(8);
      return;
    }
    if (currentQuestion === 12) {
      // preview-results
      setCurrentQuestion(8);
      return;
    }
    if (currentQuestion === 7) {
      // additional-details -> sample structure
      setCurrentQuestion(6);
      return;
    }
    if (currentQuestion === 6) {
      // sample-structure -> note-length
      setCurrentQuestion(5);
      return;
    }
    if (currentQuestion === 5) {
      // note-length
      if (selectedPath === "ai") {
        setCurrentQuestion(4);
      } else {
        setCurrentQuestion(2);
      }
      return;
    }
    if (currentQuestion === 3 || currentQuestion === 4) {
      setCurrentQuestion(2);
      return;
    }
    setCurrentQuestion((prev) => Math.max(0, prev - 1));
  };

  // For toggling collapsed/expanded notes in the preview
  const toggleCollapseNotes = () => {
    setPreviewNotesCollapsed((prev) => !prev);
  };

  // For the "Revise Template" button from the preview results
  const handleReviseTemplate = () => {
    setCurrentQuestion(8); // Return to template editor
  };

  // Utility
  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      return format(parseISO(dateString), "MMM d, yyyy");
    } catch {
      return dateString;
    }
  };

  const formatDuration = (seconds) => {
    if (!seconds) return "";
    const minutes = Math.round(seconds / 60);
    if (minutes < 60) {
      return `${minutes} min${minutes !== 1 ? "s" : ""}`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMins = minutes % 60;
    return `${hours} hr${hours !== 1 ? "s" : ""} ${
      remainingMins > 0
        ? `${remainingMins} min${remainingMins !== 1 ? "s" : ""}`
        : ""
    }`;
  };

  const formatAttendees = (attendees) => {
    if (!attendees || !Array.isArray(attendees)) return "";
    const names = attendees.map((a) => a.name || a.email || "").filter(Boolean);
    return [...new Set(names)].join(", ");
  };

  const classNames = (...classes) => classes.filter(Boolean).join(" ");

  // -------------------
  // Renderers
  // -------------------
  const renderMeetingModal = () => {
    return (
      <Transition.Root show={showMeetingModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-50 backdrop-blur-sm"
          onClose={() => setShowMeetingModal(false)}
        >
          <div className="flex items-center justify-center min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden inline-block align-middle h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 translate-y-4 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-4 scale-95"
            >
              <div className="inline-block w-full max-w-3xl bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all align-middle">
                <div className="flex flex-col h-[650px]">
                  {/* Header */}
                  <div className="px-6 pt-6 pb-4 border-b border-gray-200">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 bg-brand-turq bg-opacity-10 rounded-full p-2">
                        <SearchIcon className="h-6 w-6 text-brand-turq" />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium text-gray-900">
                          Select a meeting to test
                        </h3>
                        <p className="text-sm text-gray-500 mt-1">
                          Find a meeting to see how your template works in
                          practice
                        </p>
                      </div>
                      <button
                        onClick={() => setShowMeetingModal(false)}
                        className="ml-auto bg-white rounded-full p-1 hover:bg-gray-100 transition-colors"
                      >
                        <XIcon className="h-5 w-5 text-gray-400" />
                      </button>
                    </div>
                  </div>

                  {/* Search controls */}
                  <div className="p-4 border-b border-gray-200">
                    <div className="relative flex items-center">
                      <div className="relative flex-1">
                        <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                        <input
                          ref={(el) => {
                            inputRefs.current["modal-search"] = el;
                          }}
                          type="text"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          placeholder="Search by title, attendee, or content"
                          className="w-full pl-10 pr-10 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:ring-brand-turq focus:border-brand-turq transition-all"
                          autoFocus
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              fetchMeetings();
                            }
                          }}
                        />
                        {searchTerm && (
                          <button
                            onClick={() => setSearchTerm("")}
                            className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600"
                          >
                            <XIcon className="w-4 h-4" />
                          </button>
                        )}
                      </div>

                      <div className="flex ml-3 space-x-2">
                        <button
                          onClick={() =>
                            setSearchType((prev) =>
                              prev === "quick" ? "transcript" : "quick"
                            )
                          }
                          className={classNames(
                            "px-3 py-2 text-xs font-medium rounded-md transition-colors",
                            searchType === "transcript"
                              ? "bg-brand-turq bg-opacity-10 text-brand-turq border border-brand-turq border-opacity-20"
                              : "border border-gray-200 text-gray-600 hover:bg-gray-50"
                          )}
                        >
                          {searchType === "transcript"
                            ? "Transcript search"
                            : "Basic search"}
                        </button>
                        <button
                          onClick={fetchMeetings}
                          disabled={!searchTerm.trim()}
                          className={classNames(
                            "px-3 py-2 text-sm font-medium rounded-md transition-colors",
                            searchTerm.trim()
                              ? "text-white bg-brand-turq hover:bg-brand-green"
                              : "text-gray-400 bg-gray-100 cursor-not-allowed"
                          )}
                        >
                          Search
                        </button>
                      </div>
                    </div>

                    {/* Search guidance */}
                    <div className="mt-2 flex justify-between items-center">
                      <div className="text-xs text-gray-500">
                        {searchType === "transcript"
                          ? "Searching in meeting transcripts for specific content"
                          : "Searching by meeting title and attendee names"}
                      </div>

                      {loadingMeetings && (
                        <div className="text-xs text-gray-500 flex items-center">
                          <svg
                            className="animate-spin h-3 w-3 mr-1 text-brand-turq"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 
                                5.373 0 12h4zm2 5.291A7.962 
                                7.962 0 014 12H0c0 3.042 
                                1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Searching...
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Results */}
                  <div className="flex-1 overflow-y-auto">
                    {searchError ? (
                      <div className="p-4 bg-red-50 m-4 rounded-lg border border-red-100 flex items-start">
                        <ExclamationIcon className="h-5 w-5 text-red-500 mr-2 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-red-700">
                            Error searching meetings
                          </p>
                          <p className="text-xs text-red-600 mt-1">
                            {searchError}
                          </p>
                          <button
                            onClick={fetchMeetings}
                            className="mt-2 px-3 py-1 text-xs font-medium bg-white border border-red-200 rounded-md text-red-600 hover:bg-red-50"
                          >
                            Try again
                          </button>
                        </div>
                      </div>
                    ) : searchTerm && meetingResults.length === 0 ? (
                      loadingMeetings ? (
                        <div className="flex flex-col items-center justify-center h-48 text-gray-500">
                          <svg
                            className="animate-spin h-8 w-8 text-brand-turq mb-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 
                                5.373 0 12h4zm2 5.291A7.962 
                                7.962 0 014 12H0c0 3.042 
                                1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          <p className="text-sm">Searching for meetings...</p>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center justify-center h-48 text-center px-4">
                          <SearchIcon className="h-10 w-10 text-gray-300 mb-3" />
                          <h3 className="text-base font-medium text-gray-700">
                            No meetings found
                          </h3>
                          <p className="text-sm text-gray-500 mt-1 max-w-md">
                            Try different search terms or check your spelling.
                            You can also try searching for specific content in
                            the transcript.
                          </p>
                        </div>
                      )
                    ) : !searchTerm ? (
                      <div className="flex flex-col items-center justify-center h-48 text-center px-4">
                        <DocumentTextIcon className="h-10 w-10 text-gray-300 mb-3" />
                        <h3 className="text-base font-medium text-gray-700">
                          Search for meetings
                        </h3>
                        <p className="text-sm text-gray-500 mt-1 max-w-md">
                          Enter keywords above to find meetings to test your
                          template
                        </p>
                      </div>
                    ) : (
                      <div className="divide-y divide-gray-100">
                        <div className="px-4 py-2 bg-gray-50 border-b border-gray-200">
                          <div className="flex justify-between items-center">
                            <h4 className="text-xs font-medium text-gray-700">
                              Search results
                            </h4>
                            <span className="text-xs text-gray-500">
                              {meetingResults.length} meeting
                              {meetingResults.length !== 1 ? "s" : ""} found
                            </span>
                          </div>
                        </div>

                        <ul>
                          {meetingResults.map((meeting) => (
                            <li
                              key={meeting.ID}
                              className="border-b border-gray-100 last:border-b-0"
                            >
                              <button
                                className="w-full px-4 py-3 text-left flex items-center hover:bg-gray-50 transition-colors"
                                onClick={() => {
                                  setSelectedTestMeeting(meeting);
                                  setShowMeetingModal(false);
                                }}
                              >
                                <div className="flex-1 min-w-0 pr-3">
                                  <div className="font-medium text-sm text-gray-800">
                                    {meeting.meetingTitle}
                                  </div>
                                  <div className="flex flex-wrap text-xs text-gray-500 mt-1">
                                    <div className="flex items-center mr-3">
                                      <CalendarIcon className="h-3 w-3 mr-1" />
                                      {formatDate(meeting.CreatedAt)}
                                    </div>
                                    {meeting.duration && (
                                      <div className="flex items-center">
                                        <ClockIcon className="h-3 w-3 mr-1" />
                                        {formatDuration(meeting.duration)}
                                      </div>
                                    )}
                                  </div>
                                  {meeting.attendees?.length > 0 && (
                                    <div className="text-xs text-gray-500 mt-0.5 flex items-center">
                                      <UserCircleIcon className="h-3 w-3 mr-1" />
                                      <span className="truncate max-w-xs">
                                        {formatAttendees(meeting.attendees)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="flex-shrink-0 w-8 h-8 rounded-full border border-brand-turq border-opacity-50 bg-white flex items-center justify-center">
                                  <CheckIcon className="h-4 w-4 text-brand-turq" />
                                </div>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* Footer */}
                  <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end items-center">
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 transition-colors"
                      onClick={() => setShowMeetingModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderAIGenerationProgress = () => {
    const steps = [
      "Analyzing your answers",
      "Creating note instructions",
      "Finalizing template",
    ];
    return (
      <div className="space-y-5">
        <div className="space-y-3">
          {steps.map((step, index) => {
            const stepNumber = index + 1;
            const isActive = aiGenerationStep === stepNumber;
            const isComplete =
              aiGenerationStep > stepNumber ||
              (!aiGenerating && aiGenerationStep === stepNumber);
            return (
              <div
                key={`step-${index}`}
                className={`flex items-center ${
                  isComplete || isActive ? "text-brand-turq" : "text-gray-400"
                }`}
              >
                {isComplete ? (
                  <div className="h-6 w-6 rounded-full bg-brand-turq bg-opacity-10 flex items-center justify-center mr-3">
                    <CheckIcon className="h-4 w-4 text-brand-turq" />
                  </div>
                ) : isActive ? (
                  <div className="h-6 w-6 flex items-center justify-center mr-3">
                    <svg
                      className="animate-spin h-4 w-4 text-brand-turq"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 
                        0 5.373 0 12h4zm2 5.291A7.962 
                        7.962 0 014 12H0c0 3.042 
                        1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  <div className="h-6 w-6 rounded-full border-2 border-gray-200 flex items-center justify-center mr-3">
                    <span className="text-xs text-gray-400">{stepNumber}</span>
                  </div>
                )}
                <span className="text-sm">{step}</span>
              </div>
            );
          })}
        </div>
        {aiError && (
          <div className="mt-4 p-4 bg-red-50 border border-red-100 rounded-lg flex items-start">
            <ExclamationIcon className="h-5 w-5 text-red-500 mr-3 flex-shrink-0" />
            <div className="flex-1">
              <p className="text-sm font-medium text-red-700">
                Note instructions generation failed
              </p>
              <p className="text-xs text-red-600 mt-1">{aiError}</p>
              <button
                onClick={() => {
                  setAiError("");
                  generateTemplateFromAnswers();
                }}
                className="mt-3 px-3 py-1.5 text-xs font-medium bg-white border border-red-200 rounded-md text-red-600 hover:bg-red-50 transition-colors"
              >
                Try again
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderGeneratingPreviewProgress = () => {
    const steps = [
      "Processing meeting transcript",
      "Applying your template format",
      "Finalizing notes content",
    ];
    return (
      <div className="space-y-5 py-10">
        <div className="flex flex-col items-center justify-center mb-6">
          <div className="w-16 h-16 bg-brand-turq bg-opacity-10 rounded-full flex items-center justify-center mb-6">
            <SparklesIcon className="h-8 w-8 text-brand-turq" />
          </div>
        </div>
        <div className="space-y-3">
          {steps.map((step, index) => {
            const stepNumber = index + 1;
            const isActive = previewGenerationStep === stepNumber;
            const isComplete =
              previewGenerationStep > stepNumber ||
              (!generatingPreview && previewGenerationStep === stepNumber);
            return (
              <div
                key={`preview-step-${index}`}
                className={`flex items-center ${
                  isComplete || isActive ? "text-brand-turq" : "text-gray-400"
                }`}
              >
                {isComplete ? (
                  <div className="h-6 w-6 rounded-full bg-brand-turq bg-opacity-10 flex items-center justify-center mr-3">
                    <CheckIcon className="h-4 w-4 text-brand-turq" />
                  </div>
                ) : isActive ? (
                  <div className="h-6 w-6 flex items-center justify-center mr-3">
                    <svg
                      className="animate-spin h-4 w-4 text-brand-turq"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 
                        018-8V0C5.373 0 0 
                        5.373 0 12h4zm2 
                        5.291A7.962 7.962 0 
                        014 12H0c0 3.042 
                        1.135 5.824 3 
                        7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  <div className="h-6 w-6 rounded-full border-2 border-gray-200 flex items-center justify-center mr-3">
                    <span className="text-xs text-gray-400">{stepNumber}</span>
                  </div>
                )}
                <span className="text-sm">{step}</span>
              </div>
            );
          })}
        </div>
        {previewError && (
          <div className="mt-4 p-4 bg-red-50 border border-red-100 rounded-lg flex items-start">
            <ExclamationIcon className="h-5 w-5 text-red-500 mr-3 flex-shrink-0" />
            <div className="flex-1">
              <p className="text-sm font-medium text-red-700">
                Preview generation failed
              </p>
              <p className="text-xs text-red-600 mt-1">{previewError}</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  // A basic enhanced template editor
  const renderEnhancedTemplateEditor = () => {
    return (
      <div className="h-full">
        <div className="relative h-full flex flex-col">
          {/* Editor header */}
          <div className="flex items-center mb-3">
            {aiGenerated && (
              <span className="mr-3 px-2 py-0.5 text-xs font-medium bg-brand-turq bg-opacity-10 text-brand-turq rounded-full flex items-center">
                <SparklesIcon className="h-3 w-3 mr-1" />
                AI Generated
              </span>
            )}
          </div>
          {/* Textarea */}
          <div className="flex-1 relative">
            <textarea
              ref={(el) => {
                textareaRef.current = el;
                inputRefs.current[currentQuestion] = el;
              }}
              value={templateContent}
              onChange={(e) => {
                setTemplateContent(e.target.value);
                if (aiGenerated && e.target.value !== templateContent) {
                  setAiGenerated(false);
                }
              }}
              className="w-full rounded-lg border-2 border-gray-200 shadow-sm px-4 py-3.5 text-sm font-mono 
                focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
                hover:border-gray-300 resize-none h-full"
              style={{ minHeight: 350 }}
              placeholder="A brief description of how to structure these notes, followed by a template example."
            />
          </div>
        </div>
      </div>
    );
  };

  // Render a specific question's input
  const renderQuestionInput = (question) => {
    switch (question.inputType) {
      case "customizeChoice":
        return (
          <div className="mt-6 space-y-4">
            {question.choices.map((choice) => (
              <motion.button
                key={choice.id}
                whileHover={{ y: -2 }}
                whileTap={{ y: 0 }}
                onClick={() => handlePathChoice(choice.id)}
                className="w-full flex items-start p-4 rounded-lg border-2 border-gray-200 hover:border-brand-turq hover:shadow-sm transition-all duration-200"
              >
                <div className="flex-shrink-0 h-10 w-10 rounded-lg bg-brand-turq bg-opacity-10 flex items-center justify-center text-brand-turq mr-3">
                  {choice.id === "custom" ? (
                    <PencilIcon className="h-5 w-5" />
                  ) : (
                    <DocumentTextIcon className="h-5 w-5" />
                  )}
                </div>
                <div className="flex-1">
                  <h3 className="text-base font-medium text-gray-900">
                    {choice.label}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {choice.description}
                  </p>
                </div>
              </motion.button>
            ))}
          </div>
        );

      case "text":
        return (
          <div className="mt-6">
            <div className="relative">
              <input
                ref={(el) => (inputRefs.current[currentQuestion] = el)}
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                className="w-full rounded-lg border-2 border-gray-200 shadow-sm px-4 py-3.5 text-base 
                  focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
                  hover:border-gray-300"
                placeholder={question.placeholder}
                onKeyDown={(e) => e.key === "Enter" && handleNext()}
                autoFocus
              />
            </div>
          </div>
        );

      case "createMethodChoice":
        return (
          <div className="mt-6 space-y-4">
            {question.choices.map((choice) => (
              <motion.button
                key={choice.id}
                whileHover={{ y: -2 }}
                whileTap={{ y: 0 }}
                onClick={() => handleCreateMethodChoice(choice.id)}
                className={`w-full flex items-start p-4 rounded-lg border-2 ${
                  choice.recommended
                    ? "border-brand-turq shadow-sm"
                    : "border-gray-200"
                } hover:border-brand-turq hover:shadow-sm transition-all duration-200`}
              >
                <div className="flex-shrink-0 h-10 w-10 rounded-lg bg-brand-turq bg-opacity-10 flex items-center justify-center text-brand-turq mr-3">
                  {choice.id === "manual" ? (
                    <PencilIcon className="h-5 w-5" />
                  ) : (
                    <SparklesIcon className="h-5 w-5" />
                  )}
                </div>
                <div className="flex-1">
                  <div className="flex items-center">
                    <h3 className="text-base font-medium text-gray-900">
                      {choice.label}
                    </h3>
                    {choice.recommended && (
                      <span className="ml-2 px-2 py-0.5 text-xs font-medium bg-brand-turq bg-opacity-10 text-brand-turq rounded">
                        Recommended
                      </span>
                    )}
                  </div>
                  <p className="mt-1 text-sm text-gray-500">
                    {choice.description}
                  </p>
                </div>
              </motion.button>
            ))}
          </div>
        );

      case "noteLengthChoice":
        return (
          <div className="mt-6 space-y-4">
            {[
              {
                id: "concise",
                label: "Concise",
                description:
                  "Brief notes focusing only on the most essential points",
                icon: <LightningBoltIcon className="h-5 w-5" />,
              },
              {
                id: "balanced",
                label: "Balanced",
                description:
                  "Moderate detail with all important information included",
                icon: <CheckCircleIcon className="h-5 w-5" />,
                recommended: true,
              },
              {
                id: "comprehensive",
                label: "Comprehensive",
                description:
                  "Detailed notes capturing as much context as possible",
                icon: <DocumentDuplicateIcon className="h-5 w-5" />,
              },
            ].map((choice) => (
              <motion.button
                key={choice.id}
                whileHover={{ y: -2 }}
                whileTap={{ y: 0 }}
                onClick={() => handleNoteLengthChoice(choice.id)}
                className={`w-full flex items-start p-4 rounded-lg border-2 ${
                  aiAnswers.noteLength === choice.id
                    ? "border-brand-turq shadow-sm bg-brand-turq bg-opacity-5"
                    : "border-gray-200"
                } hover:border-brand-turq hover:shadow-sm transition-all duration-200`}
              >
                <div className="flex-shrink-0 h-10 w-10 rounded-lg bg-brand-turq bg-opacity-10 flex items-center justify-center text-brand-turq mr-3">
                  {choice.icon}
                </div>
                <div className="flex-1">
                  <div className="flex items-center">
                    <h3 className="text-base font-medium text-gray-900">
                      {choice.label}
                    </h3>
                    {choice.recommended && (
                      <span className="ml-2 px-2 py-0.5 text-xs font-medium bg-brand-turq bg-opacity-10 text-brand-turq rounded">
                        Recommended
                      </span>
                    )}
                  </div>
                  <p className="mt-1 text-sm text-gray-500">
                    {choice.description}
                  </p>
                </div>
              </motion.button>
            ))}
          </div>
        );

      case "sampleInput":
        return (
          <div className="mt-6">
            <div className="relative">
              <textarea
                ref={(el) => {
                  inputRefs.current[currentQuestion] = el;
                }}
                value={aiAnswers.sampleStructure}
                onChange={(e) =>
                  setAiAnswers((prev) => ({
                    ...prev,
                    sampleStructure: e.target.value,
                  }))
                }
                rows={5}
                className="w-full rounded-lg border-2 border-gray-200 shadow-sm px-4 py-3.5 text-base 
                focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
                hover:border-gray-300"
                placeholder={question.placeholder}
              />
            </div>
          </div>
        );

      case "textarea":
        if (question.id === "ai-purpose") {
          return (
            <div className="mt-6 flex flex-col h-full">
              <div className="relative flex-1">
                <textarea
                  ref={(el) => (inputRefs.current[currentQuestion] = el)}
                  value={aiAnswers.purpose}
                  onChange={(e) =>
                    setAiAnswers((prev) => ({
                      ...prev,
                      purpose: e.target.value,
                    }))
                  }
                  rows={8}
                  className="w-full h-full rounded-lg border-2 border-gray-200 shadow-sm px-4 py-3.5 text-base 
                focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
                hover:border-gray-300"
                  placeholder={question.placeholder}
                />
              </div>
            </div>
          );
        }
        if (question.id === "ai-key-content") {
          return (
            <div className="mt-6 flex flex-col h-full">
              <div className="relative flex-1">
                <textarea
                  ref={(el) => (inputRefs.current[currentQuestion] = el)}
                  value={aiAnswers.keyContent}
                  onChange={(e) =>
                    setAiAnswers((prev) => ({
                      ...prev,
                      keyContent: e.target.value,
                    }))
                  }
                  rows={8}
                  className="w-full h-full rounded-lg border-2 border-gray-200 shadow-sm px-4 py-3.5 text-base 
                focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
                hover:border-gray-300"
                  placeholder={question.placeholder}
                />
              </div>
            </div>
          );
        }
        if (question.id === "additional-details") {
          return (
            <div className="mt-6 flex flex-col h-full">
              <div className="relative flex-1">
                <textarea
                  ref={(el) => (inputRefs.current[currentQuestion] = el)}
                  value={aiAnswers.additionalDetails}
                  onChange={(e) =>
                    setAiAnswers((prev) => ({
                      ...prev,
                      additionalDetails: e.target.value,
                    }))
                  }
                  rows={5}
                  className="w-full h-full rounded-lg border-2 border-gray-200 shadow-sm px-4 py-3.5 text-base 
                focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
                hover:border-gray-300"
                  placeholder={question.placeholder}
                />
              </div>
            </div>
          );
        }
        return (
          <div className="mt-6">
            <div className="relative">
              <textarea
                ref={(el) => (inputRefs.current[currentQuestion] = el)}
                rows={5}
                className="w-full rounded-lg border-2 border-gray-200 shadow-sm px-4 py-3.5 text-base 
              focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
              hover:border-gray-300"
                placeholder={question.placeholder}
              />
            </div>
          </div>
        );

      case "aiGeneration":
        return (
          <div className="flex flex-col items-center justify-center py-10 space-y-6">
            <div className="w-16 h-16 bg-brand-turq bg-opacity-10 rounded-full flex items-center justify-center">
              <SparklesIcon className="h-8 w-8 text-brand-turq" />
            </div>
            {renderAIGenerationProgress()}
          </div>
        );

      case "templateReview":
        return (
          <div className="flex flex-col h-full mt-4">
            <div className="flex-1 overflow-hidden">
              {renderEnhancedTemplateEditor()}
            </div>
          </div>
        );

      case "selectTestMeeting":
        return (
          <div className="mt-6 space-y-4">
            {selectedTestMeeting ? (
              <div className="bg-white border border-gray-200 rounded-lg p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-9 w-9 bg-gray-100 rounded-md flex items-center justify-center text-gray-500">
                    <DocumentTextIcon className="h-5 w-5" />
                  </div>
                  <div className="ml-3 flex-1 min-w-0">
                    <div className="flex justify-between">
                      <h4 className="text-sm font-medium text-gray-900">
                        {selectedTestMeeting.meetingTitle}
                      </h4>
                      <button
                        onClick={() => setSelectedTestMeeting(null)}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <XIcon className="h-4 w-4" />
                      </button>
                    </div>
                    <p className="text-xs text-gray-500 mt-0.5">
                      {formatDate(selectedTestMeeting.CreatedAt)} •{" "}
                      {formatDuration(selectedTestMeeting.duration)}
                    </p>
                    <p className="text-xs text-gray-500 mt-0.5">
                      {formatAttendees(selectedTestMeeting.attendees)}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="py-8 text-center">
                <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-3">
                  <SearchIcon className="h-8 w-8 text-gray-400" />
                </div>
                <p className="text-sm text-gray-500">
                  Select a meeting to test your template
                </p>
                <p className="text-xs text-gray-400 mt-1">
                  Or skip this step if you don't want to test it now
                </p>
              </div>
            )}

            <div className="flex justify-center space-x-3 pt-2">
              <motion.button
                whileHover={{ y: -2 }}
                whileTap={{ y: 0 }}
                onClick={() => setShowMeetingModal(true)}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg shadow-sm text-gray-700 bg-white hover:bg-gray-50 transition-all duration-200"
              >
                <SearchIcon className="h-4 w-4 mr-1.5" />
                {selectedTestMeeting ? "Change Meeting" : "Select Meeting"}
              </motion.button>

              {selectedTestMeeting && (
                <motion.button
                  whileHover={{ y: -2 }}
                  whileTap={{ y: 0 }}
                  onClick={handleNext}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-brand-turq hover:bg-brand-green transition-all duration-200"
                >
                  <SparklesIcon className="h-4 w-4 mr-1.5" />
                  Test with This Meeting
                </motion.button>
              )}
            </div>

            <div className="flex justify-center pt-4">
              <motion.button
                whileHover={{ y: -2 }}
                whileTap={{ y: 0 }}
                onClick={() => {
                  handleAcceptTemplate();
                }}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800 transition-colors"
              >
                Skip Testing
              </motion.button>
            </div>
          </div>
        );

      case "generatingPreview":
        return renderGeneratingPreviewProgress();

      case "previewResults":
        let parsedNotes = previewResult?.content;

        return (
          <div className="mt-4 space-y-5">
            {previewError ? (
              <div className="p-4 text-sm text-red-500 flex items-center bg-red-50 rounded-lg border border-red-200">
                <ExclamationIcon className="h-5 w-5 mr-2 flex-shrink-0" />
                <span>{previewError}</span>
              </div>
            ) : (
              previewResult && (
                <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                  <div className="px-4 py-3 bg-gray-50 border-b border-gray-200 flex justify-between items-center">
                    <h3 className="text-sm font-medium text-gray-700 flex items-center">
                      <DocumentTextIcon className="h-4 w-4 mr-1.5 text-brand-turq" />
                      Meeting Notes
                    </h3>
                    {parsedNotes && typeof parsedNotes === "object" && (
                      <button
                        onClick={toggleCollapseNotes}
                        className="inline-flex items-center gap-1.5 px-2.5 py-1.5 text-sm font-medium
                          rounded-md bg-gray-100 text-gray-700 hover:bg-gray-200
                          border border-gray-200 transition-all"
                      >
                        {previewNotesCollapsed ? (
                          <>
                            <ArrowsOut className="h-4 w-4 text-gray-500" />
                            <span>Expand All</span>
                          </>
                        ) : (
                          <>
                            <ArrowsIn className="h-4 w-4 text-gray-500" />
                            <span>Collapse All</span>
                          </>
                        )}
                      </button>
                    )}
                  </div>
                  <div className="p-4 max-h-96 overflow-y-auto">
                    {parsedNotes && typeof parsedNotes === "object" ? (
                      <RichJsonRenderer
                        data={parsedNotes}
                        collapseAll={previewNotesCollapsed}
                        onTimeJump={null}
                      />
                    ) : (
                      <>
                        <h2 className="text-xl font-bold text-gray-900 mb-4">
                          {previewResult.title}
                        </h2>
                        <div
                          className="prose prose-sm max-w-none text-gray-800"
                          dangerouslySetInnerHTML={{
                            __html: parsedNotes || "",
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              )
            )}
            <div className="flex justify-center space-x-3 pt-4 pb-6">
              <button
                onClick={handleReviseTemplate}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg shadow-sm text-gray-700 bg-white hover:bg-gray-50 transition-all duration-200"
              >
                <PencilIcon className="h-4 w-4 mr-1.5" />
                Revise Template
              </button>
              <button
                onClick={handleAcceptTemplate}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-brand-turq hover:bg-brand-green transition-all duration-200"
              >
                <CheckIcon className="h-4 w-4 mr-1.5" />
                Looks Good!
              </button>
            </div>
          </div>
        );

      case "complete":
        return (
          <div className="flex flex-col items-center justify-center py-8">
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, type: "spring" }}
              className="w-20 h-20 bg-brand-turq bg-opacity-10 rounded-full flex items-center justify-center mb-6"
            >
              <CheckCircleIcon className="h-10 w-10 text-brand-turq" />
            </motion.div>
            <motion.h3
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-2xl font-medium text-gray-900 text-center"
            >
              Your workflow is taking shape
            </motion.h3>
            <motion.p
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="text-base text-gray-600 mt-3 text-center max-w-md"
            >
              You've set up your note template. What would you like to do next?
            </motion.p>
            <div className="flex items-center justify-center space-x-4 mt-10">
              {/* 
                "Complete Setup" will finalize if there's any leftover logic 
                or if you want to do something extra. But we've already done 
                the main logic in the effect hooking into `workflowComplete`.
              */}
              <motion.button
                whileHover={{ y: -2 }}
                whileTap={{ y: 0 }}
                onClick={() => {
                  // It's all done; just trigger the final step if it wasn't triggered.
                  if (!workflowComplete) {
                    setWorkflowComplete(true);
                  }
                }}
                className="inline-flex items-center px-5 py-3 border border-gray-300 rounded-lg shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
              >
                <CheckIcon className="h-5 w-5 mr-2 text-gray-500" />
                Complete Setup
              </motion.button>

              {/* Example optional button for next step if parent wants to add automations */}
              <motion.button
                whileHover={{ y: -2 }}
                whileTap={{ y: 0 }}
                onClick={() => {
                  // Possibly you can call some "go to next step" in the parent
                  // or just do nothing here. It's purely illustrative.
                }}
                className="inline-flex items-center px-5 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-brand-turq hover:bg-brand-green transition-colors"
              >
                <LightningBoltIcon className="h-5 w-5 mr-2" />
                Add Automation
              </motion.button>
            </div>
          </div>
        );

      default:
        return <div>Unknown input type: {question.inputType}</div>;
    }
  };

  // Show/hide nav buttons for certain steps
  const shouldShowNavButtons = (questionType) => {
    // Steps that show standard back/next:
    const standardTypes = [
      "text",
      "textarea",
      "noteLengthChoice",
      "sampleInput",
      "templateReview",
    ];
    return standardTypes.includes(questionType);
  };

  // -------------------
  // Main Render
  // -------------------
  return (
    <div className="h-full flex flex-col">
      <div
        ref={contentRef}
        className="flex-1 overflow-y-auto bg-white rounded-t-xl shadow-sm border border-gray-200 border-b-0"
      >
        <div className="p-6 h-full flex flex-col">
          {/* Show a simple progress bar except on certain steps */}
          {(() => {
            const skipIds = [
              "customize-choice",
              "template-complete",
              "generating-preview",
              "aiGeneration",
            ];
            const currentQ = questions[currentQuestion];
            if (
              !skipIds.includes(currentQ.id) &&
              currentQ.inputType !== "previewResults"
            ) {
              // We'll define possible path lengths
              let totalSteps = 8;
              let currentStep = 1;

              if (selectedPath === "default") {
                // no real steps, it's basically instant
                totalSteps = 1;
                currentStep = 1;
              } else if (selectedPath === "custom") {
                // customize yes, name, method choice, note length, sample, additional details, template review, test
                totalSteps = 8;
                // A small map from question index -> step number
                const customStepMap = {
                  0: 1, // customize choice
                  1: 2, // name
                  2: 3, // method choice
                  5: 4, // note length
                  6: 5, // sample
                  7: 6, // additional details
                  8: 7, // template review
                  10: 8, // test
                  12: 8, // preview results (same step for progress)
                };
                currentStep = customStepMap[currentQuestion] || 1;
              } else if (selectedPath === "manual") {
                // name, method choice, note length, sample, additional details, template review, test
                // effectively 7 steps
                totalSteps = 7;
                const manualStepMap = {
                  1: 1,
                  2: 2,
                  5: 3,
                  6: 4,
                  7: 5,
                  8: 6,
                  10: 7,
                  12: 7,
                };
                currentStep = manualStepMap[currentQuestion] || 1;
              } else if (selectedPath === "ai") {
                // name, method choice, ai purpose/key content(??), note length, sample, additional, review, test
                totalSteps = 8;
                const aiStepMap = {
                  1: 1,
                  2: 2,
                  3: 3,
                  4: 3, // combine these two steps for progress
                  5: 4,
                  6: 5,
                  7: 6,
                  8: 7,
                  10: 8,
                  12: 8,
                };
                currentStep = aiStepMap[currentQuestion] || 1;
              } else {
                // Not selected yet
                totalSteps = 8;
                currentStep = 1;
              }

              return (
                <div className="flex items-center justify-center mb-6">
                  <div className="bg-gray-100 rounded-full h-1.5 w-64 overflow-hidden">
                    <motion.div
                      className="h-full bg-brand-turq rounded-full"
                      initial={{ width: "0%" }}
                      animate={{
                        width: `${Math.min(
                          (currentStep / totalSteps) * 100,
                          100
                        )}%`,
                      }}
                      transition={{ duration: 0.3 }}
                    />
                  </div>
                  <div className="ml-3 text-xs text-gray-500 font-medium">
                    {currentStep}/{totalSteps}
                  </div>
                </div>
              );
            }
            return null;
          })()}

          <AnimatePresence mode="wait">
            <motion.div
              key={questions[currentQuestion].id}
              className="h-full flex flex-col"
              initial={{ opacity: 0, y: 15 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -15 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex-1">
                {/* Title & subtitle */}
                <div className="mb-4">
                  <motion.h3
                    className="text-2xl font-medium text-gray-900 tracking-tight"
                    initial={{ opacity: 0, y: 5 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1 }}
                  >
                    {questions[currentQuestion].title}
                  </motion.h3>
                  {questions[currentQuestion].subtitle && (
                    <motion.p
                      className="mt-2 text-gray-600"
                      initial={{ opacity: 0, y: 5 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2 }}
                    >
                      {questions[currentQuestion].subtitle}
                    </motion.p>
                  )}
                </div>

                {/* Question input */}
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                  className={`${
                    questions[currentQuestion].inputType === "templateReview"
                      ? "flex-1 flex flex-col"
                      : ""
                  }`}
                >
                  {renderQuestionInput(questions[currentQuestion])}
                </motion.div>

                {/* Error feedback for invalid input (like empty text) */}
                {questions[currentQuestion].errorMessage &&
                  questions[currentQuestion].validate &&
                  !questions[currentQuestion].validate(
                    questions[currentQuestion].value
                  ) &&
                  questions[currentQuestion].value && (
                    <p className="mt-2 text-sm text-red-600 flex items-center">
                      <ExclamationIcon className="h-4 w-4 mr-1" />
                      {questions[currentQuestion].errorMessage}
                    </p>
                  )}
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>

      {/* Footer Nav for certain steps */}
      {shouldShowNavButtons(questions[currentQuestion].inputType) && (
        <div className="bg-white rounded-b-xl border border-t-0 border-gray-200 shadow-sm">
          <div className="px-6 py-4 flex justify-between items-center bg-gray-50 bg-opacity-70">
            <button
              onClick={handlePrevious}
              className="flex items-center px-4 py-2 text-brand-turq rounded-lg hover:bg-brand-turq hover:bg-opacity-5 transition-colors"
            >
              <ArrowLeftIcon className="h-4 w-4 mr-1.5" />
              <span className="font-medium">Back</span>
            </button>
            <button
              onClick={handleNext}
              className={classNames(
                "inline-flex items-center px-5 py-2.5 rounded-lg font-medium transition-colors",
                (!questions[currentQuestion].validate ||
                  questions[currentQuestion].validate(
                    questions[currentQuestion].value
                  )) &&
                  "bg-brand-turq text-white hover:bg-brand-green shadow-sm",
                questions[currentQuestion].validate &&
                  !questions[currentQuestion].validate(
                    questions[currentQuestion].value
                  ) &&
                  "bg-gray-200 text-gray-400 cursor-not-allowed"
              )}
            >
              {(() => {
                const q = questions[currentQuestion];
                if (q.id === "additional-details" && selectedPath === "ai") {
                  return (
                    <>
                      <SparklesIcon className="h-4 w-4 mr-2" />
                      Generate Note Instructions
                    </>
                  );
                }
                if (q.id === "template-review") {
                  return (
                    <>
                      Continue to Testing
                      <ArrowRightIcon className="h-5 w-5 ml-2" />
                    </>
                  );
                }
                return (
                  <>
                    Continue
                    <ArrowRightIcon className="h-5 w-5 ml-2" />
                  </>
                );
              })()}
            </button>
          </div>
        </div>
      )}

      {/* Additional nav for selectTestMeeting step */}
      {questions[currentQuestion].id === "select-test-meeting" && (
        <div className="bg-white rounded-b-xl border border-t-0 border-gray-200 shadow-sm">
          <div className="px-6 py-4 flex justify-between items-center bg-gray-50 bg-opacity-70">
            <button
              onClick={handlePrevious}
              className="flex items-center px-4 py-2 text-brand-turq rounded-lg hover:bg-brand-turq hover:bg-opacity-5 transition-colors"
            >
              <ArrowLeftIcon className="h-4 w-4 mr-1.5" />
              <span className="font-medium">Back to Template</span>
            </button>
            <div></div>
          </div>
        </div>
      )}

      {renderMeetingModal()}
    </div>
  );
}
