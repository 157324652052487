import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  CheckCircleIcon,
  PencilAltIcon,
  RefreshIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { motion } from "framer-motion";
import _, { initial } from "lodash";

/**
 * EditNoteTemplateStep
 * - A form-based component for editing existing note templates.
 * - Now includes direct API call functionality and better state management.
 */
export default function EditNoteTemplateStep({
  initialData = {},
  onDataChange,
  onComplete, // Still keep this for notification purposes
  userEmail,
  onSwitchToConversational,
}) {
  // Store the initial values from props in a ref to avoid them changing
  const initialValueRef = useRef({
    title: initialData.title || "",
    content: initialData.content || "",
    // Fix: Handle both uppercase 'ID' and lowercase 'id' to ensure we capture the ID
    id: initialData.ID || initialData.id,
  });

  // Set initial state once on mount
  const [formState, setFormState] = useState({
    title: initialValueRef.current.title,
    content: initialValueRef.current.content,
  });

  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  // Keep a ref of the last data we sent to onDataChange
  const lastDataRef = useRef(null);

  // Calculate if form is dirty (don't store in state to avoid synchronization issues)
  const isDirty =
    formState.title !== initialValueRef.current.title ||
    formState.content !== initialValueRef.current.content;

  // Debug logging to track ID value
  useEffect(() => {
    console.log("Initial template ref values:", initialValueRef.current);
  }, []);

  // Reset success message after a delay
  useEffect(() => {
    if (saveSuccess) {
      const timer = setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [saveSuccess]);

  // Validation function
  const validate = useCallback(() => {
    const errors = {};
    if (!formState.title.trim()) {
      errors.title = "Template name is required";
    }
    if (!formState.content.trim()) {
      errors.content = "Template content is required";
    }
    return errors;
  }, [formState.title, formState.content]);

  // Update validation errors when form changes
  useEffect(() => {
    setValidationErrors(validate());
  }, [formState, validate]);

  // Call onDataChange when form changes
  useEffect(() => {
    if (!onDataChange) return;

    if (!_.isEqual(formState, lastDataRef.current)) {
      // Fix: Include the ID in the data we send back to parent
      const updatedData = {
        ...formState,
        ID: initialValueRef.current.id, // Ensure we include the ID
      };
      onDataChange(updatedData);
      lastDataRef.current = formState;
    }
  }, [formState, onDataChange]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear any previous save messages when the form changes
    setSaveError(null);
    setSaveSuccess(false);
  };

  // Direct API call to save the template
  const saveTemplate = async (templateData) => {
    // Fix: Check for id in both ways to be safe
    const templateId = initialValueRef.current.id;

    console.log("Attempting to save template with ID:", templateId);

    if (!templateId) {
      throw new Error("Cannot update template: missing ID");
    }

    // Fix: Make sure URL is absolute and correctly formatted
    const url = `https://backend.scribbl.co/meeting-templates/${templateId}`;
    console.log("Making PUT request to:", url);

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: templateData.title,
        content: templateData.content,
      }),
      credentials: "include", // Important for cookies/auth
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `Failed to save template: ${response.status} ${errorText}`
      );
    }

    return await response.json();
  };

  // Handle save button click with direct API call
  const handleSave = async () => {
    const errors = validate();
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) return;

    setIsSaving(true);
    setSaveError(null);
    setSaveSuccess(false);

    try {
      // Create the data to be saved
      const saveData = {
        title: formState.title,
        content: formState.content,
      };

      // Make the direct API call
      const result = await saveTemplate(saveData);

      // Update initial values ref to reflect saved state
      initialValueRef.current = {
        ...initialValueRef.current,
        title: formState.title,
        content: formState.content,
      };

      // Set success message
      setSaveSuccess(true);

      // Still call onComplete for parent component notification
      if (onComplete) {
        onComplete({
          ...saveData,
          ID: initialValueRef.current.id, // Ensure we pass back the ID
        });
      }

      console.log("Save successful!", result);
    } catch (error) {
      console.error("Error saving template:", error);
      setSaveError(
        error.message || "Failed to save the template. Please try again."
      );
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
      <div className="p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">
            Edit Note Template
          </h2>
          {onSwitchToConversational && (
            <button
              onClick={onSwitchToConversational}
              className="flex items-center px-3 py-2 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200"
            >
              <RefreshIcon className="h-4 w-4 mr-2" />
              Start Over
            </button>
          )}
        </div>

        {/* Success Message */}
        {saveSuccess && (
          <div className="mb-4 p-3 bg-green-50 border border-green-100 rounded-md">
            <p className="text-sm text-green-600 flex items-center">
              <CheckCircleIcon className="h-4 w-4 mr-2" />
              Template saved successfully!
            </p>
          </div>
        )}

        {/* Error Message */}
        {saveError && (
          <div className="mb-4 p-3 bg-red-50 border border-red-100 rounded-md">
            <p className="text-sm text-red-600 flex items-center">
              <ExclamationIcon className="h-4 w-4 mr-2" />
              {saveError}
            </p>
          </div>
        )}

        {/* Template ID Debug Info - can be removed in production */}
        <div className="mb-4 p-2 bg-gray-50 border border-gray-100 rounded-md text-xs text-gray-500">
          Template ID: {initialValueRef.current.id || "Not available"}
        </div>

        {/* Name */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Template Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="title"
            value={formState.title}
            onChange={handleChange}
            className={`w-full rounded-lg shadow-sm px-4 py-2 border ${
              validationErrors.title ? "border-red-300" : "border-gray-300"
            } focus:ring-brand-turq focus:border-brand-turq`}
          />
          {validationErrors.title && (
            <p className="mt-1 text-sm text-red-600">
              {validationErrors.title}
            </p>
          )}
        </div>

        {/* Content */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Template Content <span className="text-red-500">*</span>
          </label>
          <textarea
            name="content"
            value={formState.content}
            onChange={handleChange}
            rows={8}
            className={`w-full rounded-lg shadow-sm px-4 py-2 border ${
              validationErrors.content ? "border-red-300" : "border-gray-300"
            } focus:ring-brand-turq focus:border-brand-turq`}
          />
          {validationErrors.content && (
            <p className="mt-1 text-sm text-red-600">
              {validationErrors.content}
            </p>
          )}
        </div>

        {/* Footer */}
        <div className="mt-8 flex justify-end space-x-3">
          <button
            onClick={handleSave}
            disabled={
              !isDirty || Object.keys(validationErrors).length > 0 || isSaving
            }
            className={`px-5 py-2.5 rounded-lg text-white text-sm font-medium flex items-center
              ${
                !isDirty || Object.keys(validationErrors).length > 0 || isSaving
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-brand-turq hover:bg-brand-green"
              }`}
          >
            {isSaving ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Saving...
              </>
            ) : isDirty ? (
              "Save Changes"
            ) : (
              "No Changes"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

/**
 * DefaultNoteTemplateScreen Component
 *
 * Unchanged from original
 */
export function DefaultNoteTemplateScreen({
  meetingTypeName = "Meeting",
  onSwitchToCustom,
  onComplete,
  onProceedToNextStep,
  meetingTypeId = null,
}) {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden h-full flex flex-col">
      <div className="flex-1 p-6 flex flex-col">
        <div className="flex flex-col items-center justify-center mb-8 pt-4">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, type: "spring" }}
            className="w-20 h-20 bg-brand-turq bg-opacity-10 rounded-full flex items-center justify-center mb-5"
          >
            <CheckCircleIcon className="h-10 w-10 text-brand-turq" />
          </motion.div>

          <motion.h2
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-2xl font-medium text-gray-900 text-center"
          >
            Standard AI Meeting Notes Selected
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="mt-2 text-gray-600 text-center max-w-lg"
          >
            You've chosen to use our standard AI-powered meeting notes format
            for <span className="font-medium">{meetingTypeName}</span> meetings.
          </motion.p>
        </div>

        {/* "Complete" or "Continue" button */}
        <div className="flex flex-col items-center justify-center">
          <div className="mt-6">
            <div className="bg-brand-turq bg-opacity-5 rounded-lg p-5 border border-brand-turq border-opacity-20 text-center max-w-2xl">
              <h3 className="font-medium text-gray-900 mb-2">
                Want to customize your notes?
              </h3>
              <p className="text-sm text-gray-600 mb-4">
                Create a specialized template with sections tailored to{" "}
                {meetingTypeName} meetings.
              </p>
              <button
                onClick={onSwitchToCustom}
                className="inline-flex items-center px-4 py-2.5 border border-brand-turq text-sm font-medium rounded-md text-brand-turq bg-white hover:bg-brand-turq hover:text-white transition-colors"
              >
                <PencilAltIcon className="h-4 w-4 mr-2" />
                Create Custom Template
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
