import React, { useState, useEffect } from "react";
import {
  CheckIcon,
  XIcon,
  RefreshIcon,
  ChatIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { updateMeetingAction } from "./MeetingWorkflowEdit";

/**
 * EditSlackAutomation Component
 *
 * A simplified form-based UI for editing Slack channel automation.
 */
export default function EditSlackAutomation({
  action,
  meetingType,
  userEmail = "",
  onComplete,
  onCancel,
  onSwitchToConversational,
}) {
  // Get actionData from action prop
  const actionId = action?.id || action?.ID;
  const actionName = action?.name || "";

  // Parse customData if it exists and is a string
  let customData = {};
  if (action?.customData) {
    if (typeof action.customData === "string") {
      try {
        customData = JSON.parse(action.customData);
      } catch (e) {
        console.error("Error parsing customData:", e);
      }
    } else {
      customData = action.customData;
    }
  }

  // Extract data from customData or directly from action
  console.log("action", action);

  const initialSlackPrompt =
    customData?.slackPrompt || action?.slackPrompt || "";
  const initialSlackChannelID =
    customData?.slackChannelID || action?.slackChannelID || "";

  // State management
  const [automationName, setAutomationName] = useState(
    actionName || `${meetingType?.name || "Meeting"} Slack Update`
  );
  const [slackPrompt, setSlackPrompt] = useState(initialSlackPrompt);
  const [selectedChannelId, setSelectedChannelId] = useState(
    initialSlackChannelID
  );
  const [channels, setChannels] = useState([]);
  const [isLoadingChannels, setIsLoadingChannels] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dirty, setDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [notification, setNotification] = useState(null);

  // Fetch Slack channels when component mounts
  useEffect(() => {
    fetchSlackChannels();
  }, []);

  // Validation
  useEffect(() => {
    const errors = {};

    if (!automationName.trim()) {
      errors.name = "Automation name is required";
    }

    if (!slackPrompt.trim()) {
      errors.prompt = "Slack message instructions are required";
    }

    if (!selectedChannelId) {
      errors.channel = "Please select a Slack channel";
    }

    setValidationErrors(errors);
  }, [automationName, slackPrompt, selectedChannelId]);

  // Mark as dirty when changes are made
  useEffect(() => {
    if (
      (automationName !== actionName &&
        automationName !== `${meetingType?.name || "Meeting"} Slack Update`) ||
      slackPrompt !== initialSlackPrompt ||
      selectedChannelId !== initialSlackChannelID
    ) {
      setDirty(true);
    }
  }, [
    automationName,
    slackPrompt,
    selectedChannelId,
    actionName,
    initialSlackPrompt,
    initialSlackChannelID,
    meetingType?.name,
  ]);

  // Auto-hide notification after 5 seconds
  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  // Helper function for class names
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Show notification
  const showNotification = (message, type = "success") => {
    setNotification({ message, type });
  };

  // Fetch available Slack channels
  const fetchSlackChannels = async () => {
    setIsLoadingChannels(true);
    try {
      const response = await fetch("https://backend.scribbl.co/slack/channels", {
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to load Slack channels");
      }

      const data = await response.json();
      setChannels(data.channels || []);
    } catch (error) {
      console.error("Error fetching Slack channels:", error);
      setError(
        "Failed to load Slack channels. Please check your Slack integration."
      );
    } finally {
      setIsLoadingChannels(false);
    }
  };

  const handleSave = async () => {
    // Validate form
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    setIsSubmitting(true);
    setError("");

    // Prepare the data that will be sent to the server
    const slackData = {
      name: automationName,
      type: "slack_post",
      slackPrompt,
      slackChannelID: selectedChannelId,
    };

    if (actionId) {
      // Update existing action
      const result = await updateMeetingAction(
        slackData,
        actionId,
        meetingType?.ID || meetingType?.id
      );

      setIsSubmitting(false);

      if (result.success) {
        // Show success notification
        showNotification(`"${automationName}" saved successfully`);

        // Notify parent component
        if (onComplete) {
          onComplete(result.data);
        }
      } else {
        // Show error
        setError(result.error || "Failed to update automation");
        showNotification("Failed to save changes", "error");
      }
    } else {
      // For new actions or when we don't have an actionId
      setIsSubmitting(false);

      if (onComplete) {
        onComplete(slackData);
      }
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden h-full flex flex-col">
      <div className="p-6 flex flex-col flex-1 overflow-hidden">
        <div className="flex items-center justify-between mb-6 flex-shrink-0">
          <h2 className="text-xl font-semibold text-gray-900">
            Edit Slack Channel Automation
          </h2>
          <div className="flex space-x-3">
            {onSwitchToConversational && (
              <button
                onClick={onSwitchToConversational}
                className="flex items-center px-3 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                <RefreshIcon className="h-4 w-4 mr-2" />
                Start Over
              </button>
            )}
          </div>
        </div>

        {/* Notification */}
        {notification && (
          <div
            className={`mb-4 p-3 rounded-md flex items-start ${
              notification.type === "success"
                ? "bg-green-50 text-green-700"
                : "bg-red-50 text-red-700"
            }`}
          >
            <div className="flex-shrink-0">
              {notification.type === "success" ? (
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              ) : (
                <XCircleIcon className="h-5 w-5 text-red-500" />
              )}
            </div>
            <div className="ml-3 flex-1">
              <p className="text-sm font-medium">{notification.message}</p>
            </div>
            <button
              className="flex-shrink-0 ml-auto"
              onClick={() => setNotification(null)}
            >
              <XIcon className="h-4 w-4 text-gray-400 hover:text-gray-600" />
            </button>
          </div>
        )}

        <div className="space-y-6 overflow-y-auto flex-1 pr-1">
          {/* Name field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Automation Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={automationName}
              onChange={(e) => setAutomationName(e.target.value)}
              className={`w-full rounded-lg ${
                validationErrors.name ? "border-red-300" : "border-gray-200"
              } shadow-sm px-4 py-3 text-base focus:ring-brand-turq focus:border-brand-turq`}
              placeholder={`${meetingType?.name || "Meeting"} Slack Update`}
            />
            {validationErrors.name && (
              <p className="mt-1 text-sm text-red-600">
                {validationErrors.name}
              </p>
            )}
          </div>

          {/* Slack channel selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Slack Channel <span className="text-red-500">*</span>
            </label>
            <p className="text-sm text-gray-500 mb-3">
              Select the Slack channel where meeting updates will be posted
            </p>

            <select
              value={selectedChannelId}
              onChange={(e) => setSelectedChannelId(e.target.value)}
              disabled={isLoadingChannels}
              className={`w-full rounded-lg ${
                validationErrors.channel ? "border-red-300" : "border-gray-200"
              } shadow-sm px-4 py-3 text-base focus:ring-brand-turq focus:border-brand-turq`}
            >
              <option value="">Select a channel</option>
              {channels.map((channel) => (
                <option key={channel.id} value={channel.id}>
                  #{channel.name}
                </option>
              ))}
            </select>

            {isLoadingChannels && (
              <p className="mt-1 text-sm text-gray-500">Loading channels...</p>
            )}

            {validationErrors.channel && (
              <p className="mt-1 text-sm text-red-600">
                {validationErrors.channel}
              </p>
            )}
          </div>

          {/* Slack prompt */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Slack Message Instructions <span className="text-red-500">*</span>
            </label>
            <p className="text-sm text-gray-500 mb-3">
              Define how the AI should structure and write your Slack messages
            </p>
            <textarea
              value={slackPrompt}
              onChange={(e) => setSlackPrompt(e.target.value)}
              rows={8}
              className={`w-full rounded-lg ${
                validationErrors.prompt ? "border-red-300" : "border-gray-200"
              } shadow-sm px-4 py-3 text-base focus:ring-brand-turq focus:border-brand-turq`}
              placeholder="Write instructions for how the AI should create your Slack messages. For example: 'Create a concise Slack message that highlights the key points from the meeting, lists action items with owners, and mentions any important dates or deadlines.'"
            />
            {validationErrors.prompt && (
              <p className="mt-1 text-sm text-red-600">
                {validationErrors.prompt}
              </p>
            )}
          </div>

          {/* Error message */}
          {error && (
            <div className="bg-red-50 border border-red-200 rounded-md p-3">
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}
        </div>

        {/* Action buttons */}
        <div className="mt-8 flex justify-between flex-shrink-0">
          <div>
            {onSwitchToConversational && (
              <button
                onClick={onSwitchToConversational}
                className="flex items-center px-4 py-2.5 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                disabled={isSubmitting}
              >
                <RefreshIcon className="h-4 w-4 mr-2" />
                Start Over with Guided Flow
              </button>
            )}
          </div>

          <div className="flex space-x-3">
            {onCancel && (
              <button
                onClick={onCancel}
                className="px-4 py-2.5 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                disabled={isSubmitting}
              >
                Cancel
              </button>
            )}
            <button
              onClick={handleSave}
              className={`px-5 py-2.5 rounded-lg text-white text-sm font-medium transition-colors ${
                Object.keys(validationErrors).length > 0 || isSubmitting
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-brand-turq hover:bg-brand-green shadow-sm"
              }`}
              disabled={
                Object.keys(validationErrors).length > 0 || isSubmitting
              }
            >
              {isSubmitting ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Saving...
                </>
              ) : dirty ? (
                "Save Changes"
              ) : (
                "Complete Step"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
