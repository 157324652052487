import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  MailIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  XIcon,
  SearchIcon,
  DocumentTextIcon,
  CheckIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  SparklesIcon,
  PencilIcon,
  ExclamationIcon,
  CalendarIcon,
  ClockIcon,
  UserCircleIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { format, parseISO } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { Dialog, Transition } from "@headlessui/react";

/**
 * MeetingWorkflowFollowUpEmail Component
 *
 * Self-sufficient wizard that configures a 'Follow-up Email' action and creates
 * it on the server. When finished, calls `onComplete(createdActionData)`.
 *
 * Props:
 * - meetingTypeId?: number|string
 * - userEmail?: string
 * - meetingTypeName?: string
 * - onComplete?: function(finalActionData)
 * - onCancel?: function()
 *
 * No external data management is required; this component does everything internally
 * and then returns final data to `onComplete`.
 */
export default function MeetingWorkflowFollowUpEmail({
  // Optionally, associate this automation with an existing meeting type
  meetingTypeId = null,
  userEmail = "",
  meetingTypeName = "Meeting",
  onComplete,
  onCancel,

  // You can optionally pass in initial config if editing an existing action
  initialData = {},
}) {
  // --------------------------
  // State management
  // --------------------------
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [workflowComplete, setWorkflowComplete] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Email configuration state
  // Provide defaults if fields are absent
  const [emailName, setEmailName] = useState(
    initialData.name || `${meetingTypeName} Follow-up Email`
  );
  const didMountRef = useRef(false);

  const [hasSampleEmails, setHasSampleEmails] = useState(
    !!(initialData.sampleEmails && initialData.sampleEmails.length > 0)
  );
  const [sampleEmails, setSampleEmails] = useState(
    initialData.sampleEmails || [""]
  );
  const [emailPrompt, setEmailPrompt] = useState(initialData.emailPrompt || "");
  const [generatingPrompt, setGeneratingPrompt] = useState(false);
  const [generationError, setGenerationError] = useState("");

  // Test meeting state
  const [meetingModalOpen, setMeetingModalOpen] = useState(false);
  const [testSearchTerm, setTestSearchTerm] = useState("");
  const [testSearchType, setTestSearchType] = useState("quick");
  const [testMeetings, setTestMeetings] = useState([]);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [selectedTestMeeting, setSelectedTestMeeting] = useState(null);

  // Preview state
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewError, setPreviewError] = useState("");
  const [previewResult, setPreviewResult] = useState(null);

  // Refs for focusing inputs
  const inputRefs = useRef([]);
  const contentRef = useRef(null);
  const searchInputRef = useRef(null);

  // --------------------------
  // Questions / Steps
  // --------------------------
  const questions = [
    {
      id: "email-name",
      title: "Name your follow-up email automation",
      subtitle:
        "This name will help you identify this automation in your workflow",
      inputType: "emailNameInput",
      validate: (value) => value && value.trim().length > 0,
      errorMessage: "Please provide a name for your follow-up email automation",
      value: emailName,
      onChange: setEmailName,
    },
    {
      id: "sample-emails-question",
      title: "Do you have any examples of follow-up emails you've sent before?",
      subtitle:
        "We can use these as inspiration to craft the perfect automated email",
      inputType: "sampleEmailChoice",
      validate: () => true, // Always valid
    },
    {
      id: "sample-emails-input",
      title: "Share up to three example follow-up emails",
      subtitle:
        "These will help us understand your style and what information you typically include",
      inputType: "sampleEmailsInput",
      validate: () => true, // Optional input
    },
    {
      id: "email-prompt",
      title: "Customize the instructions for generating your follow-up emails",
      subtitle:
        "This prompt guides the AI on how to structure your emails and what to include",
      inputType: "emailPrompt",
      validate: () => emailPrompt.trim().length > 0,
      errorMessage:
        "Please provide instructions for generating your follow-up emails",
    },
    {
      id: "test-meeting",
      title: "Select a meeting to test your follow-up email",
      subtitle: "See how your follow-up email would look after a real meeting",
      inputType: "testMeeting",
      validate: () => true, // Optional
    },
    {
      id: "preview-result",
      title: "Review your follow-up email draft",
      subtitle: "This is how your automated email will look after meetings",
      inputType: "previewResult",
      validate: () => true,
    },
    {
      id: "complete",
      title: "Your follow-up email automation is ready!",
      subtitle: "It will now be available in your workflow",
      inputType: "complete",
      validate: () => true,
    },
  ];

  // --------------------------
  // Effects
  // --------------------------
  // Focus on input and scroll to top when question changes
  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRefs.current[currentQuestion]) {
        inputRefs.current[currentQuestion]?.focus();
      }
    }, 500);

    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }

    return () => clearTimeout(timer);
  }, [currentQuestion]);

  // Once we show the "Complete" screen, we auto-create the action on the server
  // or finalize if we haven't yet.
  // (You can keep or remove this auto-finish logic; some prefer a "Finish" button instead.)
  useEffect(() => {
    if (workflowComplete) {
      // We'll finalize creation if not done yet
      finalizeAutomationCreation();
    }
  }, [workflowComplete]);

  // --------------------------
  // Create Meeting Action
  // --------------------------
  async function createMeetingAction(actionData) {
    try {
      const response = await fetch("https://backend.scribbl.co/meeting-actions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(actionData),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to create meeting action: ${response.statusText}`
        );
      }
      return await response.json();
    } catch (error) {
      console.error("Error creating meeting action:", error);
      return null;
    }
  }

  /**
   * finalizeAutomationCreation
   *
   * Gathers the final data from this wizard, creates a meeting action on the server
   * (if possible), then calls onComplete with the new action data.
   */
  async function finalizeAutomationCreation() {
    // Build local data object from user inputs
    const completeData = {
      type: "follow_up_email",
      name: emailName,
      emailPrompt,
      sampleEmails: hasSampleEmails ? sampleEmails : [],
      promptApproach: hasSampleEmails ? "samples" : "manual",
    };

    // If no meetingTypeId was given, we can still pass the data to parent
    // but we won't be able to attach the action to a meeting type on the server.
    if (!meetingTypeId) {
      console.warn(
        "No meetingTypeId provided; cannot attach email action to a meeting type in the database."
      );
      if (onComplete) {
        onComplete({
          ...completeData,
          error: "No meetingTypeId provided, not created on server",
        });
      }
      return;
    }

    // Attempt to create action on server
    setIsTransitioning(true);
    const payload = {
      meetingTypeID: meetingTypeId,
      name: emailName,
      actionType: "follow_up_email",
      description: `Automated action for ${emailName}`,
      customData: completeData,
      isGlobal: false,
    };

    try {
      const createdAction = await createMeetingAction(payload);
      setIsTransitioning(false);

      // If creation failed, we can still pass local data
      if (!createdAction) {
        console.warn("Creation on server failed; returning local config only.");
        if (onComplete) {
          onComplete({
            ...completeData,
            error: "Action creation failed on server",
          });
        }
        return;
      }

      // If creation succeeded, pass that final action data to parent
      if (onComplete) {
        onComplete({
          ...completeData,
          ...createdAction, // merges server fields (ID, timestamps, etc.)
        });
      }
    } catch (error) {
      console.error("Failed to create action:", error);
      setIsTransitioning(false);

      // Still call onComplete with error state
      if (onComplete) {
        onComplete({
          ...completeData,
          error: error.message || "Unknown error creating action",
        });
      }
    }
  }

  // --------------------------
  // Wizard Navigation
  // --------------------------
  const handleNext = () => {
    const question = questions[currentQuestion];
    // Validate
    if (question.validate && !question.validate(question.value)) {
      return;
    }

    switch (question.id) {
      case "sample-emails-input":
        // Generate prompt from samples
        handleGeneratePrompt();
        break;

      case "test-meeting":
        if (!selectedTestMeeting) {
          // Skip preview if no meeting selected
          setCurrentQuestion(questions.length - 1);
          setWorkflowComplete(true);
        } else {
          // Generate preview
          handleGeneratePreview();
        }
        break;

      default:
        if (currentQuestion < questions.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
        } else {
          setWorkflowComplete(true);
        }
    }
  };

  const handlePrevious = () => {
    if (currentQuestion === 0) {
      // Cancel entirely
      if (onCancel) {
        onCancel();
      }
      return;
    }

    // If user is on email prompt step and hasSampleEmails is false, skip back to step 0
    if (currentQuestion === 3 && !hasSampleEmails) {
      setCurrentQuestion(1);
      return;
    }

    // If user is on preview step, go back to meeting selection
    if (currentQuestion === 5) {
      setCurrentQuestion(4);
      setPreviewResult(null);
      return;
    }

    // Otherwise, normal back
    setCurrentQuestion((prev) => Math.max(0, prev - 1));
  };

  // --------------------------
  // Sample Email Handling
  // --------------------------
  const handleSampleEmailsChoice = (choice) => {
    setHasSampleEmails(choice === "yes");
    if (choice === "yes") {
      setCurrentQuestion(2);
    } else {
      // Jump to email prompt
      setCurrentQuestion(3);
    }
  };

  const addSampleEmail = () => {
    if (sampleEmails.length < 3) {
      setSampleEmails((prev) => [...prev, ""]);
    }
  };

  const removeSampleEmail = (index) => {
    const newArr = [...sampleEmails];
    newArr.splice(index, 1);
    setSampleEmails(newArr);
  };

  const updateSampleEmail = (index, value) => {
    const newArr = [...sampleEmails];
    newArr[index] = value;
    setSampleEmails(newArr);
  };

  // --------------------------
  // Generate Prompt from Samples
  // --------------------------
  const handleGeneratePrompt = async () => {
    // If no actual samples, just go forward
    if (!hasSampleEmails || sampleEmails.every((e) => !e.trim())) {
      setCurrentQuestion(3);
      return;
    }

    setGeneratingPrompt(true);
    setGenerationError("");

    try {
      const response = await fetch(
        "https://backend.scribbl.co/actions/generate-prompt-from-samples",
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ sampleEmails }),
        }
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to generate prompt");
      }

      const data = await response.json();
      if (data.prompt) {
        setEmailPrompt(data.prompt);
      } else {
        throw new Error("No prompt returned");
      }
    } catch (err) {
      console.error("Error generating prompt:", err);
      setGenerationError(err.message || "Failed to generate email prompt");
    } finally {
      setGeneratingPrompt(false);
      // Move to next step either way
      setCurrentQuestion(3);
    }
  };

  // --------------------------
  // Test Meeting Search
  // --------------------------
  const toggleSearchType = () => {
    setTestSearchType((prev) => (prev === "quick" ? "transcript" : "quick"));
  };

  const fetchTestMeetings = async () => {
    if (!testSearchTerm.trim()) {
      setTestMeetings([]);
      return;
    }

    setLoadingMeetings(true);
    setSearchError("");

    try {
      const params = new URLSearchParams();
      params.set("view", "all_meetings");
      if (userEmail) {
        params.set("email", userEmail);
      }
      params.set("q", testSearchTerm.trim());
      params.set("searchType", testSearchType);

      const url = `https://backend.scribbl.co/enhanced-recording?${params.toString()}`;
      const response = await fetch(url, { credentials: "include" });

      if (!response.ok) {
        throw new Error("Failed to fetch meetings");
      }

      const data = await response.json();
      setTestMeetings(data.data || []);
    } catch (error) {
      console.error("Error fetching meetings:", error);
      setSearchError(error.message || "Failed to fetch meetings");
    } finally {
      setLoadingMeetings(false);
    }
  };

  const handleSelectTestMeeting = (meeting) => {
    setSelectedTestMeeting(meeting);
    setMeetingModalOpen(false);
  };

  // --------------------------
  // Preview
  // --------------------------
  const handleGeneratePreview = async () => {
    if (!selectedTestMeeting || !emailPrompt.trim()) {
      return;
    }

    setPreviewLoading(true);
    setPreviewError("");
    setPreviewResult(null);

    try {
      const response = await fetch(
        "https://backend.scribbl.co/actions/test-email-prompt",
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            meetingID: selectedTestMeeting.ID,
            prompt: emailPrompt,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to generate preview");
      }

      const data = await response.json();
      setPreviewResult(data);

      // Move to preview step
      setCurrentQuestion(5);
    } catch (err) {
      console.error("Error generating preview:", err);
      setPreviewError(err.message || "Failed to generate email preview");
      setCurrentQuestion(5);
    } finally {
      setPreviewLoading(false);
    }
  };

  // --------------------------
  // "Looks Good" => Finalize
  // --------------------------
  const handleAcceptConfig = () => {
    // Move to final step
    setCurrentQuestion(6);
    setWorkflowComplete(true);

    // This ensures we call finalizeAutomationCreation
    // which will in turn call onComplete with the final data
    finalizeAutomationCreation();
  };

  // --------------------------
  // Renderers
  // --------------------------
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      return format(parseISO(dateString), "MMM d, yyyy");
    } catch (e) {
      return dateString;
    }
  };

  const formatDuration = (seconds) => {
    if (!seconds) return "";
    const minutes = Math.round(seconds / 60);
    if (minutes < 60) {
      return `${minutes} min${minutes !== 1 ? "s" : ""}`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMins = minutes % 60;
    return `${hours} hr${hours !== 1 ? "s" : ""} ${
      remainingMins > 0
        ? `${remainingMins} min${remainingMins !== 1 ? "s" : ""}`
        : ""
    }`;
  };

  const formatAttendees = (attendees) => {
    if (!attendees || !Array.isArray(attendees)) return "";
    const names = attendees
      .map((attendee) => {
        if (typeof attendee === "string") return attendee;
        return attendee.name || attendee.email || "";
      })
      .filter(Boolean);
    return [...new Set(names)].join(", ");
  };

  // -- Step Renderers

  const renderEmailNameInput = (question) => {
    return (
      <div className="mt-6">
        <div className="relative">
          <input
            ref={(el) => (inputRefs.current[currentQuestion] = el)}
            type="text"
            value={emailName}
            onChange={(e) => setEmailName(e.target.value)}
            className="w-full rounded-lg border-2 border-gray-200 shadow-sm px-4 py-3.5 text-base 
              focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
              hover:border-gray-300"
            placeholder={`${meetingTypeName} Follow-up Email`}
            onKeyDown={(e) => e.key === "Enter" && handleNext()}
          />
        </div>

        <div className="mt-4 p-4 bg-brand-turq bg-opacity-5 border border-brand-turq border-opacity-20 rounded-lg">
          <div className="flex items-start">
            <InformationCircleIcon className="h-4 w-4 text-brand-turq mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-sm text-gray-700">
                Choose a descriptive name that helps you identify this
                automation in your workflow.
              </p>
              <p className="text-xs text-gray-600 mt-2">
                For example: "{meetingTypeName} Action Items Email" or "Weekly
                Team Recap Email"
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSampleEmailChoice = () => {
    return (
      <div className="mt-6 space-y-4">
        <motion.button
          whileHover={{ y: -2 }}
          whileTap={{ y: 0 }}
          onClick={() => handleSampleEmailsChoice("yes")}
          className="w-full flex items-start p-4 rounded-lg border-2 border-gray-200 hover:border-brand-turq hover:shadow-sm transition-all duration-200"
        >
          <div className="flex-shrink-0 h-10 w-10 rounded-lg bg-brand-turq bg-opacity-10 flex items-center justify-center text-brand-turq mr-3">
            <DocumentTextIcon className="h-5 w-5" />
          </div>
          <div className="flex-1">
            <h3 className="text-base font-medium text-gray-900">
              Yes, I'd like to share some examples
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              We'll analyze your examples to create a template that matches your
              style and content preferences
            </p>
          </div>
        </motion.button>

        <motion.button
          whileHover={{ y: -2 }}
          whileTap={{ y: 0 }}
          onClick={() => handleSampleEmailsChoice("no")}
          className="w-full flex items-start p-4 rounded-lg border-2 border-gray-200 hover:border-brand-turq hover:shadow-sm transition-all duration-200"
        >
          <div className="flex-shrink-0 h-10 w-10 rounded-lg bg-brand-turq bg-opacity-10 flex items-center justify-center text-brand-turq mr-3">
            <PencilIcon className="h-5 w-5" />
          </div>
          <div className="flex-1">
            <h3 className="text-base font-medium text-gray-900">
              No, I'll create instructions from scratch
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              You'll write your own instructions for how follow-up emails should
              be structured
            </p>
          </div>
        </motion.button>
      </div>
    );
  };

  const renderSampleEmailsInput = () => {
    return (
      <div className="mt-6 space-y-4">
        <div className="p-4 bg-brand-turq bg-opacity-5 border border-brand-turq border-opacity-20 rounded-lg">
          <div className="flex items-start">
            <InformationCircleIcon className="h-4 w-4 text-brand-turq mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-sm text-gray-700">
                Paste examples of follow-up emails you've sent after{" "}
                {meetingTypeName.toLowerCase()} meetings.
              </p>
              <p className="text-xs text-gray-600 mt-2">
                We'll analyze these to understand your tone, structure, and what
                information you typically include.
              </p>
            </div>
          </div>
        </div>

        {sampleEmails.map((email, index) => (
          <div
            key={`sample-${index}`}
            className="border border-gray-200 rounded-lg p-4"
          >
            <div className="flex items-center justify-between mb-2">
              <h4 className="text-sm font-medium text-gray-700">
                Example Email {index + 1}
              </h4>
              {sampleEmails.length > 1 && (
                <button
                  onClick={() => removeSampleEmail(index)}
                  className="text-gray-400 hover:text-red-500 transition-colors"
                >
                  <XIcon className="h-5 w-5" />
                </button>
              )}
            </div>
            <textarea
              value={email}
              onChange={(e) => updateSampleEmail(index, e.target.value)}
              rows={5}
              className="w-full border border-gray-200 rounded-md p-3 text-sm focus:ring-brand-turq focus:border-brand-turq"
              placeholder="Paste your example email here..."
              ref={
                index === 0
                  ? (el) => (inputRefs.current[currentQuestion] = el)
                  : null
              }
            />
          </div>
        ))}

        {sampleEmails.length < 3 && (
          <button
            onClick={addSampleEmail}
            className="inline-flex items-center px-3 py-2 text-sm font-medium border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            <PlusIcon className="h-4 w-4 mr-1.5" />
            Add Another Example
          </button>
        )}

        {generatingPrompt && (
          <div className="text-center py-4">
            <svg
              className="animate-spin h-6 w-6 text-brand-turq mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <p className="mt-2 text-sm text-gray-600">
              Analyzing your examples...
            </p>
          </div>
        )}
      </div>
    );
  };

  const renderEmailPrompt = () => {
    return (
      <div className="mt-6 space-y-4">
        {generationError && (
          <div className="p-4 bg-red-50 border border-red-100 rounded-lg">
            <div className="flex items-start">
              <ExclamationIcon className="h-5 w-5 text-red-500 mr-2 flex-shrink-0" />
              <div>
                <p className="text-sm font-medium text-red-800">
                  There was an error generating your prompt
                </p>
                <p className="text-xs text-red-700 mt-1">{generationError}</p>
                <p className="text-xs text-red-700 mt-1">
                  You can still proceed by writing your own instructions below.
                </p>
              </div>
            </div>
          </div>
        )}

        {hasSampleEmails && !generationError && (
          <div className="p-4 bg-green-50 border border-green-100 rounded-lg">
            <div className="flex items-start">
              <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
              <div>
                <p className="text-sm font-medium text-green-800">
                  We've analyzed your example emails
                </p>
                <p className="text-xs text-green-700 mt-1">
                  Based on your examples, we've created instructions for your
                  follow-up emails. Feel free to edit them to better match your
                  preferences.
                </p>
              </div>
            </div>
          </div>
        )}

        <div>
          <label
            htmlFor="email-prompt"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Follow-up Email Instructions
          </label>
          <textarea
            id="email-prompt"
            value={emailPrompt}
            onChange={(e) => setEmailPrompt(e.target.value)}
            rows={8}
            className="w-full border-2 border-gray-200 rounded-lg shadow-sm p-3 text-sm
              focus:ring-brand-turq focus:border-brand-turq"
            placeholder="Write instructions for how the AI should create your follow-up emails..."
            ref={(el) => (inputRefs.current[currentQuestion] = el)}
          />
        </div>

        <div className="p-4 bg-gray-50 border border-gray-200 rounded-lg">
          <h4 className="text-sm font-medium text-gray-800 mb-2">
            Tips for effective instructions:
          </h4>
          <ul className="space-y-2 text-sm text-gray-600">
            <li className="flex items-start">
              <span className="mr-2">•</span>
              <span>
                Specify the tone you want (formal, casual, friendly,
                professional)
              </span>
            </li>
            <li className="flex items-start">
              <span className="mr-2">•</span>
              <span>
                Mention specific sections to include (summary, action items,
                next steps)
              </span>
            </li>
            <li className="flex items-start">
              <span className="mr-2">•</span>
              <span>
                Note if you want to highlight certain types of information
              </span>
            </li>
            <li className="flex items-start">
              <span className="mr-2">•</span>
              <span>
                Include any standard phrases or sign-offs you typically use
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderTestMeeting = () => {
    return (
      <div className="mt-6 space-y-4">
        <div className="p-4 bg-brand-turq bg-opacity-5 border border-brand-turq border-opacity-20 rounded-lg">
          <div className="flex items-start">
            <InformationCircleIcon className="h-5 w-5 text-brand-turq mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-sm text-gray-700">
                Select a past meeting to see how your follow-up email would
                look.
              </p>
              <p className="text-xs text-gray-600 mt-1">
                This helps ensure your automation will produce the kind of
                emails you want.
              </p>
            </div>
          </div>
        </div>

        {/* Selected meeting display */}
        {selectedTestMeeting ? (
          <div className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm">
            <div className="flex items-start">
              <div className="flex-shrink-0 h-10 w-10 bg-gray-100 rounded-md flex items-center justify-center text-gray-500">
                <DocumentTextIcon className="h-5 w-5" />
              </div>
              <div className="ml-3 flex-1">
                <div className="flex justify-between">
                  <h4 className="text-sm font-medium text-gray-900">
                    {selectedTestMeeting.meetingTitle || "Untitled Meeting"}
                  </h4>
                  <button
                    onClick={() => setSelectedTestMeeting(null)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <XIcon className="h-4 w-4" />
                  </button>
                </div>
                <p className="text-xs text-gray-500 mt-0.5">
                  {formatDate(selectedTestMeeting.CreatedAt)}
                  {selectedTestMeeting.duration &&
                    ` • ${formatDuration(selectedTestMeeting.duration)}`}
                </p>
                {selectedTestMeeting.attendees?.length > 0 && (
                  <p className="text-xs text-gray-500 mt-0.5">
                    {formatAttendees(selectedTestMeeting.attendees)}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center py-6">
            <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-3">
              <MailIcon className="h-8 w-8 text-gray-400" />
            </div>
            <p className="text-sm text-gray-600 mb-1">No meeting selected</p>
            <p className="text-xs text-gray-500">
              Select a meeting to see a preview of your follow-up email
            </p>
          </div>
        )}

        <div className="flex justify-center space-x-3 pt-4">
          <motion.button
            whileHover={{ y: -2 }}
            whileTap={{ y: 0 }}
            onClick={() => setMeetingModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg shadow-sm text-gray-700 bg-white hover:bg-gray-50 transition-all duration-200"
          >
            <SearchIcon className="h-4 w-4 mr-1.5" />
            {selectedTestMeeting ? "Change Meeting" : "Select a Meeting"}
          </motion.button>

          {selectedTestMeeting && (
            <motion.button
              whileHover={{ y: -2 }}
              whileTap={{ y: 0 }}
              onClick={handleGeneratePreview}
              disabled={previewLoading}
              className={classNames(
                "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white transition-all duration-200",
                previewLoading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-brand-turq hover:bg-brand-green"
              )}
            >
              {previewLoading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Generating...
                </>
              ) : (
                <>
                  <SparklesIcon className="h-4 w-4 mr-1.5" />
                  Generate Email Preview
                </>
              )}
            </motion.button>
          )}
        </div>

        <div className="flex justify-center pt-4">
          <button
            onClick={() => {
              setCurrentQuestion(questions.length - 1);
              setWorkflowComplete(true);
            }}
            className="text-sm text-gray-500 hover:text-brand-turq transition-colors"
          >
            Skip testing and complete setup
          </button>
        </div>
      </div>
    );
  };

  const renderPreviewResult = () => {
    return (
      <div className="mt-6 space-y-4">
        {previewError ? (
          <div className="p-4 bg-red-50 border border-red-100 rounded-lg">
            <div className="flex items-start">
              <ExclamationIcon className="h-5 w-5 text-red-500 mr-2 flex-shrink-0" />
              <div>
                <p className="text-sm font-medium text-red-800">
                  There was an error generating your email preview
                </p>
                <p className="text-xs text-red-700 mt-1">{previewError}</p>
                <p className="text-xs text-red-700 mt-2">
                  You can try again with a different meeting or adjust your
                  instructions.
                </p>
              </div>
            </div>
          </div>
        ) : previewResult ? (
          <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
            <div className="px-4 py-3 bg-gray-50 border-b border-gray-200">
              <h3 className="text-sm font-medium text-gray-700 flex items-center">
                <MailIcon className="h-4 w-4 mr-1.5 text-brand-turq" />
                Follow-up Email Preview
              </h3>
            </div>
            <div className="p-4 space-y-4">
              {previewResult.subject && (
                <div>
                  <h4 className="text-xs font-medium text-gray-500 mb-1">
                    SUBJECT
                  </h4>
                  <div className="p-2 bg-gray-50 border border-gray-200 rounded text-sm">
                    {previewResult.subject}
                  </div>
                </div>
              )}
              {previewResult.body && (
                <div>
                  <h4 className="text-xs font-medium text-gray-500 mb-1">
                    EMAIL BODY
                  </h4>
                  <div className="p-3 bg-gray-50 border border-gray-200 rounded text-sm whitespace-pre-wrap">
                    {previewResult.body}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="text-center py-6">
            <p className="text-sm text-gray-600">No preview available</p>
          </div>
        )}

        <div className="flex justify-center space-x-4 pt-4">
          <button
            onClick={() => setCurrentQuestion(3)} // Go back to instructions
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg shadow-sm text-gray-700 bg-white hover:bg-gray-50"
          >
            <PencilIcon className="h-4 w-4 mr-1.5" />
            Edit Instructions
          </button>

          <button
            onClick={handleAcceptConfig}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-brand-turq hover:bg-brand-green"
          >
            <CheckIcon className="h-4 w-4 mr-1.5" />
            Looks Good
          </button>
        </div>
      </div>
    );
  };

  const renderComplete = () => (
    <div className="mt-6 text-center">
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, type: "spring" }}
        className="w-20 h-20 bg-brand-turq bg-opacity-10 rounded-full flex items-center justify-center mx-auto mb-6"
      >
        <CheckCircleIcon className="h-10 w-10 text-brand-turq" />
      </motion.div>

      <motion.h3
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="text-2xl font-medium text-gray-900"
      >
        Your follow-up email automation is ready!
      </motion.h3>

      <motion.p
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="text-base text-gray-600 mt-3 max-w-md mx-auto"
      >
        You've successfully set up <strong>{emailName}</strong> for your{" "}
        {meetingTypeName.toLowerCase()} meetings.
      </motion.p>

      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="mt-6 text-sm text-gray-600"
      >
        <p>
          This automation will generate email drafts based on your instructions.
        </p>
      </motion.div>
    </div>
  );

  // Rendering the current question
  const renderQuestionInput = (question) => {
    switch (question.inputType) {
      case "emailNameInput":
        return renderEmailNameInput(question);
      case "sampleEmailChoice":
        return renderSampleEmailChoice();
      case "sampleEmailsInput":
        return renderSampleEmailsInput();
      case "emailPrompt":
        return renderEmailPrompt();
      case "testMeeting":
        return renderTestMeeting();
      case "previewResult":
        return renderPreviewResult();
      case "complete":
        return renderComplete();
      default:
        return null;
    }
  };

  const renderCurrentQuestion = () => {
    const question = questions[currentQuestion];
    return (
      <motion.div
        key={question.id}
        className="h-full flex flex-col"
        initial={{ opacity: 0, y: 15 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -15 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex-1">
          <div className="mb-4 text-center">
            <motion.h3
              className="text-2xl font-medium text-gray-900 tracking-tight"
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
            >
              {question.title}
            </motion.h3>
            {question.subtitle && (
              <motion.p
                className="mt-2 text-gray-600"
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                {question.subtitle}
              </motion.p>
            )}
          </div>

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="overflow-y-auto"
          >
            {renderQuestionInput(question)}
          </motion.div>
        </div>
      </motion.div>
    );
  };

  // --------------------------
  // Meeting Selection Modal
  // --------------------------
  const renderMeetingModal = () => {
    return (
      <Transition.Root show={meetingModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-50 backdrop-blur-sm"
          onClose={() => setMeetingModalOpen(false)}
        >
          <div className="flex items-center justify-center min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden inline-block align-middle h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 translate-y-4 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-4 scale-95"
            >
              <div className="inline-block w-full max-w-3xl bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all align-middle">
                <div className="flex flex-col h-[650px]">
                  {/* Header */}
                  <div className="px-6 pt-6 pb-4 border-b border-gray-200">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 bg-brand-turq bg-opacity-10 rounded-full p-2">
                        <SearchIcon className="h-6 w-6 text-brand-turq" />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium text-gray-900">
                          Select a meeting to test
                        </h3>
                        <p className="text-sm text-gray-500 mt-1">
                          Find a meeting to see how your follow-up email will
                          look
                        </p>
                      </div>
                      <button
                        onClick={() => setMeetingModalOpen(false)}
                        className="ml-auto bg-white rounded-full p-1 hover:bg-gray-100 transition-colors"
                      >
                        <XIcon className="h-5 w-5 text-gray-400" />
                      </button>
                    </div>
                  </div>

                  {/* Search controls */}
                  <div className="p-4 border-b border-gray-200">
                    <div className="relative flex items-center">
                      <div className="relative flex-1">
                        <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                        <input
                          ref={searchInputRef}
                          type="text"
                          value={testSearchTerm}
                          onChange={(e) => setTestSearchTerm(e.target.value)}
                          placeholder="Search by title, attendee, or content"
                          className="w-full pl-10 pr-10 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:ring-brand-turq focus:border-brand-turq transition-all"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              fetchTestMeetings();
                            }
                          }}
                        />
                        {testSearchTerm && (
                          <button
                            onClick={() => setTestSearchTerm("")}
                            className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600"
                          >
                            <XIcon className="w-4 h-4" />
                          </button>
                        )}
                      </div>

                      <div className="flex ml-3 space-x-2">
                        <button
                          onClick={toggleSearchType}
                          className={`px-3 py-2 text-xs font-medium rounded-md transition-colors ${
                            testSearchType === "transcript"
                              ? "bg-brand-turq bg-opacity-10 text-brand-turq border border-brand-turq border-opacity-20"
                              : "border border-gray-200 text-gray-600 hover:bg-gray-50"
                          }`}
                        >
                          {testSearchType === "transcript"
                            ? "Transcript search"
                            : "Basic search"}
                        </button>

                        <button
                          onClick={fetchTestMeetings}
                          disabled={!testSearchTerm.trim()}
                          className={`px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                            testSearchTerm.trim()
                              ? "text-white bg-brand-turq hover:bg-brand-green"
                              : "text-gray-400 bg-gray-100 cursor-not-allowed"
                          }`}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Results area */}
                  <div className="flex-1 overflow-y-auto">
                    {searchError ? (
                      <div className="p-4 bg-red-50 m-4 rounded-lg border border-red-100 flex items-start">
                        <ExclamationIcon className="h-5 w-5 text-red-500 mr-2 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-red-700">
                            Error searching meetings
                          </p>
                          <p className="text-xs text-red-600 mt-1">
                            {searchError}
                          </p>
                          <button
                            onClick={fetchTestMeetings}
                            className="mt-2 px-3 py-1 text-xs font-medium bg-white border border-red-200 rounded-md text-red-600 hover:bg-red-50"
                          >
                            Try again
                          </button>
                        </div>
                      </div>
                    ) : loadingMeetings ? (
                      <div className="flex flex-col items-center justify-center h-48 text-gray-500">
                        <svg
                          className="animate-spin h-8 w-8 text-brand-turq mb-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <p className="text-sm">Searching for meetings...</p>
                      </div>
                    ) : testMeetings.length === 0 ? (
                      <div className="flex flex-col items-center justify-center h-48 text-center px-4">
                        {testSearchTerm ? (
                          <>
                            <SearchIcon className="h-10 w-10 text-gray-300 mb-3" />
                            <h3 className="text-base font-medium text-gray-700">
                              No meetings found
                            </h3>
                            <p className="text-sm text-gray-500 mt-1 max-w-md">
                              Try different search terms or check your spelling.
                              You can also try searching for specific content in
                              the transcript.
                            </p>
                          </>
                        ) : (
                          <>
                            <DocumentTextIcon className="h-10 w-10 text-gray-300 mb-3" />
                            <h3 className="text-base font-medium text-gray-700">
                              Search for meetings
                            </h3>
                            <p className="text-sm text-gray-500 mt-1 max-w-md">
                              Enter keywords above to find meetings to test your
                              follow-up email automation
                            </p>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="divide-y divide-gray-100">
                        <div className="px-4 py-2 bg-gray-50 border-b border-gray-200">
                          <div className="flex justify-between items-center">
                            <h4 className="text-xs font-medium text-gray-700">
                              Search results
                            </h4>
                            <span className="text-xs text-gray-500">
                              {testMeetings.length} meeting
                              {testMeetings.length !== 1 ? "s" : ""} found
                            </span>
                          </div>
                        </div>

                        <ul>
                          {testMeetings.map((meeting) => (
                            <li
                              key={meeting.ID}
                              className="border-b border-gray-100 last:border-b-0"
                            >
                              <button
                                className="w-full px-4 py-3 text-left flex items-center hover:bg-gray-50 transition-colors"
                                onClick={() => handleSelectTestMeeting(meeting)}
                              >
                                <div className="flex-1 min-w-0 pr-3">
                                  <div className="font-medium text-sm text-gray-800">
                                    {meeting.meetingTitle || "Untitled Meeting"}
                                  </div>

                                  <div className="flex flex-wrap text-xs text-gray-500 mt-1">
                                    <div className="flex items-center mr-3">
                                      <CalendarIcon className="h-3 w-3 mr-1" />
                                      {formatDate(meeting.CreatedAt)}
                                    </div>

                                    {meeting.duration && (
                                      <div className="flex items-center">
                                        <ClockIcon className="h-3 w-3 mr-1" />
                                        {formatDuration(meeting.duration)}
                                      </div>
                                    )}
                                  </div>

                                  {meeting.attendees &&
                                    meeting.attendees.length > 0 && (
                                      <div className="text-xs text-gray-500 mt-0.5 flex items-center">
                                        <UserCircleIcon className="h-3 w-3 mr-1" />
                                        <span className="truncate max-w-xs">
                                          {formatAttendees(meeting.attendees)}
                                        </span>
                                      </div>
                                    )}
                                </div>

                                <div className="flex-shrink-0 w-8 h-8 rounded-full border border-brand-turq border-opacity-50 bg-white flex items-center justify-center">
                                  <CheckIcon className="h-4 w-4 text-brand-turq" />
                                </div>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* Footer */}
                  <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end items-center">
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 transition-colors"
                      onClick={() => setMeetingModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  // --------------------------
  // Main Render
  // --------------------------
  return (
    <div className="h-full flex flex-col">
      <div
        ref={contentRef}
        className="flex-1 bg-white rounded-t-xl shadow-sm border border-gray-200 border-b-0 overflow-hidden"
      >
        <div className="p-6 h-full flex flex-col relative">
          {/* Progress steps - not showing for completion screen */}
          {currentQuestion < questions.length - 1 && (
            <div className="flex items-center justify-center mb-6">
              <div className="bg-gray-100 rounded-full h-1.5 w-64 overflow-hidden">
                <motion.div
                  className="h-full bg-brand-turq rounded-full"
                  initial={{ width: "0%" }}
                  animate={{
                    width: `${
                      ((currentQuestion + 1) / (questions.length - 1)) * 100
                    }%`,
                  }}
                  transition={{ duration: 0.3 }}
                />
              </div>
              <div className="ml-3 text-xs text-gray-500 font-medium">
                {currentQuestion + 1}/{questions.length - 1}
              </div>
            </div>
          )}

          {/* Step content */}
          <div className="flex-1 overflow-y-auto">
            <AnimatePresence mode="wait">
              {renderCurrentQuestion()}
            </AnimatePresence>
          </div>
        </div>
      </div>

      {/* Footer (conditional) */}
      {currentQuestion < questions.length - 1 &&
        !["sampleEmailChoice", "testMeeting", "previewResult"].includes(
          questions[currentQuestion].inputType
        ) && (
          <div className="bg-white rounded-b-xl border border-t-0 border-gray-200 shadow-sm">
            <div className="px-6 py-4 flex justify-between items-center bg-gray-50 bg-opacity-70">
              <button
                onClick={handlePrevious}
                className="flex items-center px-4 py-2 text-brand-turq rounded-lg hover:bg-brand-turq hover:bg-opacity-5 transition-colors"
              >
                <ArrowLeftIcon className="h-4 w-4 mr-1.5" />
                <span className="font-medium">Back</span>
              </button>

              <button
                onClick={handleNext}
                disabled={
                  (questions[currentQuestion].validate &&
                    !questions[currentQuestion].validate(
                      questions[currentQuestion].value
                    )) ||
                  isTransitioning ||
                  generatingPrompt
                }
                className={classNames(
                  "inline-flex items-center px-5 py-2.5 rounded-lg font-medium transition-colors",
                  questions[currentQuestion].validate &&
                    !questions[currentQuestion].validate(
                      questions[currentQuestion].value
                    )
                    ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                    : isTransitioning || generatingPrompt
                    ? "bg-gray-200 text-gray-400 cursor-wait"
                    : "bg-brand-turq text-white hover:bg-brand-green shadow-sm"
                )}
              >
                {isTransitioning || generatingPrompt ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Processing...
                  </>
                ) : questions[currentQuestion].id === "sample-emails-input" ? (
                  <>
                    <SparklesIcon className="h-4 w-4 mr-2" />
                    Generate Prompt
                  </>
                ) : (
                  <>
                    Continue
                    <ArrowRightIcon className="h-5 w-5 ml-2" />
                  </>
                )}
              </button>
            </div>
          </div>
        )}

      {/* Alternative back button for certain steps */}
      {["testMeeting", "previewResult"].includes(
        questions[currentQuestion].inputType
      ) && (
        <div className="bg-white rounded-b-xl border border-t-0 border-gray-200 shadow-sm">
          <div className="px-6 py-4 flex justify-start items-center bg-gray-50 bg-opacity-70">
            <button
              onClick={handlePrevious}
              className="flex items-center px-4 py-2 text-brand-turq rounded-lg hover:bg-brand-turq hover:bg-opacity-5 transition-colors"
            >
              <ArrowLeftIcon className="h-4 w-4 mr-1.5" />
              <span className="font-medium">Back</span>
            </button>
          </div>
        </div>
      )}

      {/* Meeting selection modal */}
      {renderMeetingModal()}
    </div>
  );
}
