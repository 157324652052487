import React, { useState, useEffect, useRef } from "react";
import {
  DocumentTextIcon,
  CheckCircleIcon,
  ExclamationIcon,
  RefreshIcon,
  ExternalLinkIcon,
  FolderIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { updateMeetingAction } from "./MeetingWorkflowEdit";

/**
 * EditGoogleDocAutomation Component
 *
 * A form-based editor for Google Doc automation settings.
 * Used in the MeetingWorkflowEdit component to handle Google Doc automation steps.
 */
export default function EditGoogleDocAutomation({
  action,
  meetingType,
  userEmail,
  onComplete,
  onCancel,
  onSwitchToConversational,
}) {
  // Parse customData if it exists and is a string
  let customData = {};
  if (action?.customData) {
    if (typeof action.customData === "string") {
      try {
        customData = JSON.parse(action.customData);
      } catch (e) {
        console.error("Error parsing customData:", e);
      }
    } else {
      customData = action.customData;
    }
  }

  // Form state - extract all fields from either customData OR directly from action
  const [name, setName] = useState(action?.name || "Google Doc Export");
  const [docPrompt, setDocPrompt] = useState(
    customData?.docPrompt || action?.docPrompt || ""
  );

  // Google Drive folder state
  const [selectedFolder, setSelectedFolder] = useState(
    customData?.folderId || action?.folderId || ""
  );
  const [folderName, setFolderName] = useState(
    customData?.folderName || action?.folderName || ""
  );
  const [googleDriveConnected, setGoogleDriveConnected] = useState(false);
  const [checkingDriveStatus, setCheckingDriveStatus] = useState(false);
  const [folderError, setFolderError] = useState("");

  // Google Picker state
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  // UI state
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [dirty, setDirty] = useState(false);

  // API base URL
  const API_BASE = "https://backend.scribbl.co";

  // Load custom data when action changes
  useEffect(() => {
    if (!action) return;

    setName(action.name || "Google Doc Export");

    // Set form fields based on customData OR directly from action
    const extractedDocPrompt =
      (action.customData && typeof action.customData === "string"
        ? JSON.parse(action.customData).docPrompt
        : action.customData?.docPrompt) ||
      action.docPrompt ||
      "";

    setDocPrompt(extractedDocPrompt);

    // Set folder information
    const extractedFolderId =
      (action.customData && typeof action.customData === "string"
        ? JSON.parse(action.customData).folderId
        : action.customData?.folderId) ||
      action.folderId ||
      "";

    const extractedFolderName =
      (action.customData && typeof action.customData === "string"
        ? JSON.parse(action.customData).folderName
        : action.customData?.folderName) ||
      action.folderName ||
      "";

    setSelectedFolder(extractedFolderId);
    setFolderName(extractedFolderName);

    // Reset form state
    setDirty(false);
    setValidationErrors({});
    setErrorMessage("");
    setSuccessMessage("");

    // Check Google Drive status and load Google Picker
    checkGoogleDriveStatus();
    loadGooglePicker();
  }, [action]);

  // Monitor for changes to mark form as dirty
  useEffect(() => {
    if (!action) return;

    // Extract customData from the action to compare against current values
    let customData = {};
    if (action.customData) {
      if (typeof action.customData === "string") {
        try {
          customData = JSON.parse(action.customData);
        } catch (e) {
          console.error("Error parsing customData:", e);
        }
      } else {
        customData = action.customData;
      }
    }

    const originalName = action.name || "Google Doc Export";
    const originalDocPrompt = customData.docPrompt || action.docPrompt || "";
    const originalFolderId = customData.folderId || action.folderId || "";
    const originalFolderName = customData.folderName || action.folderName || "";

    const isNameChanged = name !== originalName;
    const isDocPromptChanged = docPrompt !== originalDocPrompt;
    const isFolderChanged =
      selectedFolder !== originalFolderId || folderName !== originalFolderName;

    setDirty(isNameChanged || isDocPromptChanged || isFolderChanged);
  }, [name, docPrompt, selectedFolder, folderName, action]);

  // Google Drive connection and picker functionality
  const checkGoogleDriveStatus = async () => {
    setCheckingDriveStatus(true);
    setFolderError("");

    try {
      const resp = await fetch(`${API_BASE}/google-drive/status`, {
        credentials: "include",
      });
      if (!resp.ok) {
        throw new Error("Failed to check Google Drive status");
      }
      const data = await resp.json();
      setGoogleDriveConnected(data.connected || false);
    } catch (err) {
      console.error("Error checking Google Drive status:", err);
      setFolderError("Failed to check Google Drive connection status");
    } finally {
      setCheckingDriveStatus(false);
    }
  };

  const handleConnectGoogleDrive = async () => {
    try {
      const resp = await fetch(`${API_BASE}/google-drive/connect`, {
        credentials: "include",
      });
      if (!resp.ok) {
        throw new Error("Failed to get Google Drive connect URL");
      }
      const data = await resp.json();
      if (data.authUrl) {
        window.open(data.authUrl, "_blank");
      }
    } catch (err) {
      setFolderError("Error connecting to Google Drive: " + err.message);
    }
  };

  const loadGooglePicker = () => {
    const scriptId = "google-api-script";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://apis.google.com/js/api.js";
      script.onload = () => {
        window.gapi.load("picker", {
          callback: () => setPickerApiLoaded(true),
        });
      };
      document.body.appendChild(script);
    } else {
      window.gapi.load("picker", { callback: () => setPickerApiLoaded(true) });
    }
    fetchAccessToken();
  };

  const fetchAccessToken = async () => {
    try {
      const response = await fetch(`${API_BASE}/google-drive/access-token`, {
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch Google Drive access token");
      }
      const data = await response.json();
      setAccessToken(data.accessToken || null);
    } catch (err) {
      setFolderError(
        `Error fetching Google Drive access token: ${err.message}`
      );
    }
  };

  const openGooglePicker = () => {
    if (!pickerApiLoaded) {
      setFolderError("Google Picker API is not loaded yet.");
      return;
    }
    if (!accessToken) {
      setFolderError("No access token available. Please reconnect or refresh.");
      return;
    }

    const docsView = new window.google.picker.DocsView()
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true)
      .setMimeTypes("application/vnd.google-apps.folder");

    const picker = new window.google.picker.PickerBuilder()
      .addView(docsView)
      .setOAuthToken(accessToken)
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);
  };

  const pickerCallback = (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      const doc = data.docs && data.docs[0];
      if (doc) {
        setSelectedFolder(doc.id);
        setFolderName(doc.name);
        setFolderError(""); // Clear any previous errors
      }
    }
  };

  // Validation
  const validateForm = () => {
    const errors = {};

    if (!name.trim()) {
      errors.name = "Automation name is required";
    }

    if (googleDriveConnected && !selectedFolder) {
      errors.folder = "Please select a Google Drive folder";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Save automation
  const handleSave = async () => {
    if (!validateForm()) return;

    setIsSaving(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      // Prepare customData for API
      const customData = {
        docPrompt: docPrompt,
        folderId: selectedFolder,
        folderName: folderName,
        version: 1,
      };

      // Make sure customData is properly formatted as a string for the API
      const formattedCustomData =
        typeof customData === "string"
          ? customData
          : JSON.stringify(customData);

      // Prepare action data - include all fields directly on the actionData object
      const actionData = {
        ...action,
        name: name,
        type: "google_doc",
        actionType: "google_doc",
        description: `Google Doc export for ${meetingType?.name || "meetings"}`,
        customData: formattedCustomData,
        docPrompt: docPrompt,
        folderId: selectedFolder,
        folderName: folderName,
      };

      console.log("Sending actionData:", actionData);

      // Update or create the action
      const result = await updateMeetingAction(
        actionData,
        action.ID || action.id,
        meetingType?.ID || meetingType?.id
      );

      if (!result.success) {
        throw new Error(result.error || "Failed to save automation");
      }

      setSuccessMessage("Google Doc automation saved successfully!");
      setDirty(false);

      // Notify parent component
      if (onComplete) {
        onComplete(result.data || actionData);
      }
    } catch (error) {
      console.error("Error saving Google Doc automation:", error);
      setErrorMessage(error.message || "Failed to save automation");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
      <div className="p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900 flex items-center">
            <DocumentTextIcon className="h-5 w-5 mr-2 text-blue-500" />
            Edit Google Doc Automation
          </h2>

          {onSwitchToConversational && (
            <button
              onClick={onSwitchToConversational}
              className="flex items-center px-3 py-2 text-sm font-medium text-gray-600 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200"
            >
              <RefreshIcon className="h-4 w-4 mr-2" />
              Start Over
            </button>
          )}
        </div>

        {/* Form content */}
        <div className="space-y-6">
          {/* Name field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Automation Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={`w-full rounded-lg ${
                validationErrors.name ? "border-red-300" : "border-gray-200"
              } shadow-sm px-4 py-3 text-base focus:ring-brand-turq focus:border-brand-turq`}
              placeholder="e.g. Client Meeting Notes Export"
            />
            {validationErrors.name && (
              <p className="mt-1 text-sm text-red-600">
                {validationErrors.name}
              </p>
            )}
          </div>

          {/* Google Drive connection section */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">
              Google Drive Folder
            </label>

            <div
              className={
                googleDriveConnected
                  ? "p-4 border rounded-lg bg-green-50 border-green-100 mb-4"
                  : "p-4 border rounded-lg bg-yellow-50 border-yellow-100 mb-4"
              }
            >
              <div className="flex items-start">
                {googleDriveConnected ? (
                  <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                ) : (
                  <ExclamationIcon className="h-5 w-5 text-yellow-500 mr-2 flex-shrink-0" />
                )}
                <div>
                  <p className="text-sm font-medium text-gray-800">
                    {googleDriveConnected
                      ? "Connected to Google Drive"
                      : "Google Drive connection required"}
                  </p>
                  <p className="text-xs text-gray-600 mt-1">
                    {googleDriveConnected
                      ? "Your Google Drive is connected. Choose a folder below."
                      : "To create meeting documents in Google Drive, you need to connect your account first."}
                  </p>

                  {!googleDriveConnected && (
                    <div className="mt-3 flex space-x-3">
                      <button
                        onClick={handleConnectGoogleDrive}
                        disabled={checkingDriveStatus}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-brand-turq hover:bg-brand-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq transition-colors"
                      >
                        <ExternalLinkIcon className="h-4 w-4 mr-1.5" />
                        Connect to Google Drive
                      </button>

                      <button
                        onClick={checkGoogleDriveStatus}
                        disabled={checkingDriveStatus}
                        className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-xs font-medium rounded shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq transition-colors"
                      >
                        {checkingDriveStatus ? (
                          <>
                            <svg
                              className="animate-spin -ml-1 mr-2 h-4 w-4 text-gray-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Checking...
                          </>
                        ) : (
                          <>
                            <RefreshIcon className="h-4 w-4 mr-1.5" />
                            Check Again
                          </>
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Folder selection when connected */}
            {googleDriveConnected && (
              <>
                {folderError && (
                  <div className="p-3 bg-red-50 border border-red-100 rounded-md mb-4">
                    <div className="flex">
                      <ExclamationIcon className="h-5 w-5 text-red-400 mr-2" />
                      <p className="text-sm text-red-600">{folderError}</p>
                    </div>
                  </div>
                )}

                {validationErrors.folder && (
                  <p className="mt-1 mb-3 text-sm text-red-600">
                    {validationErrors.folder}
                  </p>
                )}

                <div className="flex items-center mb-4">
                  <button
                    onClick={openGooglePicker}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq shadow-sm transition-colors duration-150"
                  >
                    {selectedFolder
                      ? "Change Folder"
                      : "Choose Google Drive Folder"}
                  </button>

                  {accessToken === null && (
                    <button
                      onClick={fetchAccessToken}
                      className="ml-3 inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
                    >
                      <RefreshIcon className="h-4 w-4 mr-1.5" />
                      Refresh Token
                    </button>
                  )}
                </div>

                {selectedFolder ? (
                  <div className="flex items-center p-3 bg-gray-50 border border-gray-200 rounded-lg">
                    <FolderIcon className="h-5 w-5 text-brand-turq mr-2" />
                    <span className="text-sm text-gray-700">
                      Selected folder:{" "}
                      <span className="font-medium">{folderName}</span>
                    </span>
                  </div>
                ) : (
                  <div className="flex items-center p-3 bg-gray-50 border border-gray-200 rounded-lg text-gray-500 text-sm">
                    <InformationCircleIcon className="h-5 w-5 mr-2" />
                    No folder selected.
                  </div>
                )}
              </>
            )}
          </div>

          {/* Doc Prompt field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Google Doc Instructions
            </label>
            <textarea
              value={docPrompt}
              onChange={(e) => setDocPrompt(e.target.value)}
              rows={4}
              className="w-full rounded-lg border-gray-200 shadow-sm px-4 py-3 text-base focus:ring-brand-turq focus:border-brand-turq"
              placeholder="Instructions for how the document should be formatted and structured"
            />
            <p className="mt-1 text-xs text-gray-500">
              Provide additional formatting guidelines for the Google Doc export
            </p>
          </div>
        </div>

        {/* Error and success messages */}
        {errorMessage && (
          <div className="mt-6 p-3 bg-red-50 border border-red-200 rounded-lg text-sm text-red-600 flex items-start">
            <ExclamationIcon className="h-5 w-5 mr-2 flex-shrink-0" />
            <span>{errorMessage}</span>
          </div>
        )}

        {successMessage && (
          <div className="mt-6 p-3 bg-green-50 border border-green-200 rounded-lg text-sm text-green-600 flex items-start">
            <CheckCircleIcon className="h-5 w-5 mr-2 flex-shrink-0" />
            <span>{successMessage}</span>
          </div>
        )}

        {/* Form actions */}
        <div className="mt-8 flex justify-end space-x-3">
          {onCancel && (
            <button
              onClick={onCancel}
              className="px-4 py-2.5 rounded-lg border border-gray-300 text-gray-700 text-sm font-medium hover:bg-gray-50"
              disabled={isSaving}
            >
              Cancel
            </button>
          )}

          <button
            onClick={handleSave}
            disabled={
              !dirty || isSaving || Object.keys(validationErrors).length > 0
            }
            className={`px-5 py-2.5 rounded-lg text-white text-sm font-medium transition-colors ${
              !dirty || isSaving || Object.keys(validationErrors).length > 0
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-brand-turq hover:bg-brand-green"
            }`}
          >
            {isSaving ? (
              <span className="flex items-center">
                <svg
                  className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Saving...
              </span>
            ) : dirty ? (
              "Save Changes"
            ) : (
              "No Changes"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
