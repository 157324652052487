import { Transition, Dialog } from "@headlessui/react";
import { PuzzlePiece } from "phosphor-react";
import React, { useState, useEffect, Fragment } from "react";
import { XIcon, PencilIcon, TrashIcon } from "lucide-react";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import remarkGfm from "remark-gfm";

export function CustomOutputTab({ meeting, amplitude }) {
  // Templates + generation
  const [customTemplates, setCustomTemplates] = useState([]);
  const [selectedCustomTemplateId, setSelectedCustomTemplateId] = useState("");
  const [generatedOutput, setGeneratedOutput] = useState("");
  const [generatedOutputTitle, setGeneratedOutputTitle] = useState("");

  // For the main "Generate Output" spinner
  const [isLoadingMeetingGeneration, setIsLoadingMeetingGeneration] =
    useState(false);

  // Manager Modal
  const [managerOpen, setManagerOpen] = useState(false);

  // ----- Fetch templates on mount -----
  useEffect(() => {
    if (!meeting?.ID) return;
    fetchTemplates();
  }, [meeting]);

  // If there's a previously generated custom output for this meeting, show the most recent
  useEffect(() => {
    if (
      meeting?.CustomOutputGenerations?.length > 0 &&
      customTemplates.length > 0
    ) {
      const mostRecentOutput = meeting.CustomOutputGenerations.reduce(
        (latest, current) =>
          new Date(latest.CreatedAt) > new Date(current.CreatedAt)
            ? latest
            : current
      );
      setGeneratedOutput(mostRecentOutput.output);

      // find the corresponding template's name for display
      const correspondingTemplate = customTemplates.find(
        (t) => t.ID === mostRecentOutput.CustomOutputTemplateID
      );
      if (correspondingTemplate) {
        setGeneratedOutputTitle(correspondingTemplate.name);
      }
    }
  }, [meeting, customTemplates]);

  // -------------------
  //   Fetch Templates
  // -------------------
  async function fetchTemplates() {
    try {
      // Adjust your endpoint/logic as needed
      const response = await fetch(
        "https://backend.scribbl.co/custom-outputs/templates",
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCustomTemplates(data);
      } else {
        console.error("Error fetching templates:", await response.text());
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  }

  // -------------------
  //   Apply Template
  // -------------------
  async function handleGenerateOutput() {
    if (!selectedCustomTemplateId || !meeting) return;
    setIsLoadingMeetingGeneration(true);

    try {
      const response = await fetch(
        "https://backend.scribbl.co/custom-outputs/apply-template",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            templateID: Number(selectedCustomTemplateId),
            meetingID: Number(meeting.ID),
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setGeneratedOutput(data.output);

        // find template name
        const matchingTemplate = customTemplates.find(
          (t) => t.ID === Number(selectedCustomTemplateId)
        );
        if (matchingTemplate) {
          setGeneratedOutputTitle(matchingTemplate.name);
        }

        amplitude
          ?.getInstance?.()
          .logEvent("custom output ai applied generation");
      } else {
        console.error("Error generating output:", await response.text());
      }
    } catch (error) {
      console.error("Error generating output:", error);
    }
    setIsLoadingMeetingGeneration(false);
  }

  // Manager modal
  function openManagerModal() {
    setManagerOpen(true);
  }
  function closeManagerModal() {
    setManagerOpen(false);
  }

  return (
    <div className="h-full min-h-full">
      {/* Spinner overlay for main generation */}
      <Transition show={isLoadingMeetingGeneration} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/60" />
          </Transition.Child>
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-90"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-90"
            >
              <Dialog.Panel className="bg-white rounded-lg shadow-xl p-6 w-full max-w-sm flex flex-col items-center space-y-4">
                <svg
                  className="animate-spin h-8 w-8 text-brand-turq"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="none"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                  />
                </svg>
                <Dialog.Title className="text-lg font-medium text-gray-700 text-center">
                  Generating/Loading...
                </Dialog.Title>
                <p className="text-sm text-gray-500 text-center">
                  Please wait while we process your request.
                </p>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* MAIN CARD */}
      <div className="bg-white border border-gray-200 rounded-xl p-4 shadow-sm h-full flex flex-col space-y-4">
        <div className="flex items-center space-x-2">
          <PuzzlePiece className="text-brand-turq" size={20} weight="fill" />
          <h2 className="text-base font-semibold text-gray-800">
            Custom GPT Output
          </h2>
        </div>

        <div className="text-sm text-gray-600">
          Select an existing template below or create your own, then generate a
          more specific summary or analysis of this meeting.
        </div>

        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
          {/* Template selector */}
          <select
            className="border border-gray-300 rounded-md px-2 py-1 text-sm focus:outline-none focus:ring-1 focus:ring-brand-turq"
            value={selectedCustomTemplateId}
            onChange={(e) => setSelectedCustomTemplateId(e.target.value)}
          >
            <option value="">Select a Template</option>
            {customTemplates.map((t) => (
              <option key={t.ID} value={t.ID}>
                {t.name}
              </option>
            ))}
          </select>

          {/* Generate button */}
          <button
            onClick={handleGenerateOutput}
            className="px-3 py-1.5 bg-brand-turq text-white text-sm rounded-md hover:bg-brand-turq/90 transition disabled:opacity-50"
            disabled={!selectedCustomTemplateId || isLoadingMeetingGeneration}
          >
            Generate Output
          </button>

          {/* Manage templates */}
          <button
            onClick={openManagerModal}
            className="px-3 py-1.5 bg-gray-100 text-gray-700 text-sm rounded-md border border-gray-200 hover:bg-gray-200 transition"
          >
            Manage Templates
          </button>
        </div>

        {/* Generated output display */}
        {generatedOutput && (
          <div className="flex-1 overflow-auto border border-gray-200 rounded-md bg-gray-50 p-4">
            <h4 className="font-semibold text-sm text-gray-700 mb-2">
              {generatedOutputTitle || "Custom Output"}
            </h4>
            <CustomOutputRenderer output={generatedOutput} />
          </div>
        )}
      </div>

      {/* Single "Manage Templates" Modal with built-in Create/Edit */}
      <ManageTemplatesModal
        isOpen={managerOpen}
        closeModal={closeManagerModal}
        templates={customTemplates}
        fetchTemplates={fetchTemplates}
        meeting={meeting}
        amplitude={amplitude}
      />
    </div>
  );
}

/**
 * Displays a (possibly) structured JSON-like output in a more user-friendly card style.
 * - If the JSON has known structures like { content: {...} } or { title, points },
 *   it will display them in a stylized manner.
 * - Otherwise, it shows a fallback pretty-printed JSON or raw text with markdown / code highlight.
 */
export function CustomOutputRenderer({ output }) {
  if (!output) return null;

  let parsed;
  try {
    parsed = typeof output === "string" ? JSON.parse(output) : output;
  } catch {
    // Could not parse JSON. Show raw text in a card.
    return (
      <div className="rounded-lg p-4 bg-white shadow-sm border border-gray-200 text-sm text-gray-700 whitespace-pre-wrap">
        {output}
      </div>
    );
  }

  // If it has .content with known keys:
  if (parsed?.content) {
    const { title, textBlock, bulletList, numberList, collapsibleList } =
      parsed.content;
    return (
      <div className="rounded-lg p-4 bg-white shadow-sm border border-gray-200 space-y-3 text-gray-800">
        {/* Title */}
        {title?.text && <div className="font-bold text-lg">{title.text}</div>}

        {/* Text block */}
        {textBlock?.text && <div className="text-sm">{textBlock.text}</div>}

        {/* Bullet list */}
        {bulletList?.items && Array.isArray(bulletList.items) && (
          <div>{renderNestedList(bulletList.items, false)}</div>
        )}

        {/* Number list */}
        {numberList?.items && Array.isArray(numberList.items) && (
          <div>{renderNestedList(numberList.items, true)}</div>
        )}

        {/* Collapsible list */}
        {collapsibleList?.items && Array.isArray(collapsibleList.items) && (
          <div className="text-sm">
            {collapsibleList.items.map((item, idx) => (
              <details key={idx} className="mb-2">
                <summary className="cursor-pointer text-sm font-medium">
                  {item.title || `Item ${idx + 1}`}
                </summary>
                <div className="pl-4 mt-1 text-gray-600 text-sm">
                  {item.content}
                </div>
              </details>
            ))}
          </div>
        )}
      </div>
    );
  }

  // If it has { title, points } top-level
  if (parsed?.title || parsed?.points) {
    return (
      <div className="rounded-lg p-4 bg-white shadow-sm border border-gray-200 space-y-3 text-gray-800">
        {parsed.title && (
          <div className="font-bold text-lg">{parsed.title}</div>
        )}
        {Array.isArray(parsed.points) && (
          <div>{renderNestedList(parsed.points, false)}</div>
        )}
      </div>
    );
  }

  // Otherwise, fallback to showing pretty JSON (with syntax highlighting).
  const fallbackOutput = JSON.stringify(parsed, null, 2);
  return (
    <div className="rounded-lg p-4 bg-white shadow-sm border border-gray-200">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline ? (
              <SyntaxHighlighter
                style={dracula}
                language={match ? match[1] : "plaintext"}
                PreTag="div"
                {...props}
              >
                {String(children).replace(/\n$/, "")}
              </SyntaxHighlighter>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      >
        {`\`\`\`json\n${fallbackOutput}\n\`\`\``}
      </ReactMarkdown>
    </div>
  );
}

/**
 * Modal for managing templates in a single view (list + create/edit).
 *
 * Changes:
 * - We made the entire modal a flex-col container with a *fixed max height* to avoid unwanted page scrolling.
 * - The header is now purely a brand-colored bar, no white behind it.
 */
export function ManageTemplatesModal({
  isOpen,
  closeModal,
  templates,
  fetchTemplates,
  meeting,
  amplitude,
}) {
  const [mode, setMode] = useState("none"); // "none" | "create" | "edit"
  const [selectedTemplateID, setSelectedTemplateID] = useState(null);

  // Form fields
  const [templateName, setTemplateName] = useState("");
  const [customQuery, setCustomQuery] = useState("");
  const [previewOutput, setPreviewOutput] = useState("");

  // Local loading state for manager actions (preview, save, delete)
  const [isLoadingManager, setIsLoadingManager] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      // Reset everything when the modal is closed
      resetManagerState();
    }
  }, [isOpen]);

  useEffect(() => {
    if (mode === "edit" && selectedTemplateID) {
      const t = templates.find((tpl) => tpl.ID === selectedTemplateID);
      if (t) {
        setTemplateName(t.name);
        setCustomQuery(t.query);
        setPreviewOutput(t.generatedOutput || "");
      }
    }
  }, [mode, selectedTemplateID, templates]);

  function resetManagerState() {
    setMode("none");
    setSelectedTemplateID(null);
    setTemplateName("");
    setCustomQuery("");
    setPreviewOutput("");
    setIsLoadingManager(false);
  }

  function handleSelectTemplate(templateId) {
    setSelectedTemplateID(templateId);
    setMode("edit");
  }

  function handleCreateNew() {
    setMode("create");
    setSelectedTemplateID(null);
    setTemplateName("");
    setCustomQuery("");
    setPreviewOutput("");
  }

  async function generatePreviewOutput() {
    if (!customQuery || !meeting?.ID) return;
    setIsLoadingManager(true);

    try {
      const response = await fetch(
        "https://backend.scribbl.co/custom-outputs/preview-query",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: customQuery,
            enhancedRecordingID: Number(meeting.ID),
          }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        setPreviewOutput(data.customOutputGeneration);
        amplitude
          ?.getInstance?.()
          .logEvent("custom output ai preview generation");
      } else {
        console.error("Error previewing output:", await response.text());
      }
    } catch (error) {
      console.error("Error previewing output:", error);
    }
    setIsLoadingManager(false);
  }

  async function handleSave() {
    if (!templateName || !customQuery) return;
    setIsLoadingManager(true);

    const isEditing = mode === "edit" && selectedTemplateID;
    const url = isEditing
      ? `https://backend.scribbl.co/custom-outputs/templates/${selectedTemplateID}`
      : "https://backend.scribbl.co/custom-outputs/save-template";
    const method = isEditing ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: templateName,
          query: customQuery,
          generatedOutput: previewOutput,
          enhancedRecordingID: Number(meeting.ID),
        }),
      });

      if (response.ok) {
        await fetchTemplates();
        amplitude
          ?.getInstance?.()
          .logEvent(
            isEditing ? "custom output updated" : "custom output created"
          );
      } else {
        console.error("Error saving template:", await response.text());
      }
    } catch (error) {
      console.error("Error saving template:", error);
    }
    setIsLoadingManager(false);
  }

  async function handleDelete() {
    if (!selectedTemplateID) return;
    if (!window.confirm("Are you sure you want to delete this template?")) {
      return;
    }
    setIsLoadingManager(true);

    try {
      const response = await fetch(
        `https://backend.scribbl.co/custom-outputs/templates/${selectedTemplateID}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      if (response.ok) {
        await fetchTemplates();
        resetManagerState();
      } else {
        console.error("Error deleting template:", await response.text());
      }
    } catch (error) {
      console.error("Error deleting template:", error);
    }
    setIsLoadingManager(false);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[999]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* Background dimmer */}
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          {/* CHANGED: Constrain height with h-[85vh] and overflow-hidden to keep size consistent */}
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 translate-y-4 scale-90"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 translate-y-4 scale-90"
            >
              {/* CHANGED: Fixed height, overflow-hidden, flex-col layout */}
              <Dialog.Panel className="w-full max-w-4xl h-[85vh] transform overflow-hidden rounded-xl shadow-xl flex flex-col">
                {/* Top Header (brand gradient) */}
                <div className="bg-brand-turq px-6 py-4 flex items-center justify-between">
                  <Dialog.Title className="text-sm sm:text-base font-semibold text-white">
                    Manage Custom Templates
                  </Dialog.Title>
                  <button
                    type="button"
                    className="text-white hover:text-gray-100 transition"
                    onClick={closeModal}
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>

                {/* CHANGED: Make the middle content area scrollable */}
                <div className="flex-1 overflow-y-auto bg-white p-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Left Column: Template List */}
                    <div className="border border-gray-200 rounded-lg flex flex-col">
                      <div className="flex items-center justify-between px-3 py-2 border-b border-gray-200 bg-gray-50">
                        <h4 className="text-sm font-medium text-gray-700">
                          Your Templates
                        </h4>
                        <button
                          type="button"
                          className="text-sm px-3 py-1.5 bg-brand-turq text-white rounded-md hover:bg-brand-turq/90 transition disabled:opacity-50"
                          onClick={handleCreateNew}
                          disabled={isLoadingManager}
                        >
                          + Create
                        </button>
                      </div>
                      <div className="max-h-[320px] overflow-y-auto divide-y divide-gray-200">
                        {templates?.length === 0 && (
                          <div className="p-3 text-sm text-gray-500">
                            No templates found. Create one above!
                          </div>
                        )}
                        {templates.map((t) => (
                          <div
                            key={t.ID}
                            className={`px-3 py-2 text-sm cursor-pointer hover:bg-gray-100 transition flex items-center justify-between ${
                              selectedTemplateID === t.ID && mode === "edit"
                                ? "bg-gray-100"
                                : ""
                            }`}
                            onClick={() => handleSelectTemplate(t.ID)}
                          >
                            <span
                              className="font-medium text-gray-700 truncate"
                              title={t.name}
                            >
                              {t.name}
                            </span>
                            {selectedTemplateID === t.ID && mode === "edit" && (
                              <PencilIcon className="h-4 w-4 text-gray-400 ml-2" />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Right Column: Create/Edit Form */}
                    <div className="border border-gray-200 rounded-lg flex flex-col">
                      <div className="px-3 py-2 bg-gray-50 border-b border-gray-200">
                        <h4 className="text-sm font-medium text-gray-700">
                          {mode === "none"
                            ? "Select or Create a Template"
                            : mode === "create"
                            ? "Create New Template"
                            : "Edit Template"}
                        </h4>
                      </div>

                      <div className="flex-1 p-4 overflow-auto space-y-4">
                        {mode === "none" && (
                          <div className="text-sm text-gray-500">
                            Pick a template from the list or click{" "}
                            <span className="text-gray-700 font-medium">
                              Create
                            </span>{" "}
                            to make a new one.
                          </div>
                        )}

                        {(mode === "create" || mode === "edit") && (
                          <div className="space-y-4">
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Template Name
                              </label>
                              <input
                                type="text"
                                className="w-full border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-1 focus:ring-brand-turq"
                                placeholder="e.g., 'My Meeting Summary'"
                                value={templateName}
                                onChange={(e) =>
                                  setTemplateName(e.target.value)
                                }
                              />
                            </div>

                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Prompt / Query
                              </label>
                              <textarea
                                rows={4}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-1 focus:ring-brand-turq"
                                placeholder="Describe what info you'd like to extract..."
                                value={customQuery}
                                onChange={(e) => setCustomQuery(e.target.value)}
                              />
                            </div>

                            {/* Preview Output (styled the same as final output) */}
                            {previewOutput && (
                              <div className="border border-gray-200 rounded-md bg-gray-50 p-4">
                                <h4 className="font-semibold text-sm text-gray-700 mb-2">
                                  Preview Output
                                </h4>
                                <div className="max-h-60 overflow-y-auto">
                                  <CustomOutputRenderer
                                    output={previewOutput}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      {/* CHANGED: Improved Button Styles in Footer */}
                      {mode !== "none" && (
                        <div className="flex items-center justify-end gap-2 p-3 border-t border-gray-200 bg-gray-50">
                          {/* Delete only in edit mode */}
                          {mode === "edit" && selectedTemplateID && (
                            <button
                              type="button"
                              onClick={handleDelete}
                              disabled={isLoadingManager}
                              className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md border border-red-300 bg-white text-red-600 hover:bg-red-50 transition"
                            >
                              <TrashIcon className="w-4 h-4 mr-1" />
                              Delete
                            </button>
                          )}

                          {/* Preview */}
                          <button
                            type="button"
                            onClick={generatePreviewOutput}
                            disabled={
                              isLoadingManager || !templateName || !customQuery
                            }
                            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-100 transition disabled:opacity-60"
                          >
                            {isLoadingManager ? "Processing..." : "Preview"}
                          </button>

                          {/* Save */}
                          <button
                            type="button"
                            disabled={
                              isLoadingManager || !templateName || !customQuery
                            }
                            onClick={handleSave}
                            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md bg-brand-turq text-white hover:bg-brand-turq/90 transition disabled:opacity-60"
                          >
                            {isLoadingManager ? "Saving..." : "Save"}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Bottom: Close button (consistently styled) */}
                <div className="bg-gray-50 border-t border-gray-200 p-4 flex justify-end">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-100 transition"
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

/* 
  Helper to render nested items in a bullet or numbered list.
  Items can be:
    - A plain string or number
    - An object like { text: "...", subBullets: [ ... ] }
    - Another array
*/
function renderNestedList(items, isNumbered = false) {
  if (!Array.isArray(items)) return null;

  const listClass = isNumbered ? "list-decimal" : "list-disc";

  return (
    <ul className={`${listClass} ml-6 text-sm space-y-1`}>
      {items.map((item, idx) => {
        // If item is an object with `text` and maybe `subBullets`
        if (item && typeof item === "object" && !Array.isArray(item)) {
          return (
            <li key={idx}>
              {item.text}
              {item.subBullets && item.subBullets.length > 0 && (
                <>{renderNestedList(item.subBullets, isNumbered)}</>
              )}
            </li>
          );
        } else if (Array.isArray(item)) {
          // if item itself is an array, just recurse
          return <li key={idx}>{renderNestedList(item, isNumbered)}</li>;
        } else {
          // item is probably a string or number
          return <li key={idx}>{item}</li>;
        }
      })}
    </ul>
  );
}

export default CustomOutputTab;
