import React, { useState, useEffect, useRef } from "react";
import {
  SearchIcon,
  XIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  CheckIcon,
  PlusIcon,
  ExclamationIcon,
  ArrowRightIcon,
  SparklesIcon,
  DocumentTextIcon,
  ArrowLeftIcon,
  LightningBoltIcon,
  ClipboardCheckIcon,
  ChartBarIcon,
  DocumentDuplicateIcon,
  PencilAltIcon,
  CalendarIcon,
  ClockIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { format, parseISO } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { isEqual } from "lodash";

/**
 * ConversationalMeetingTypeStep Component - Self-Sufficient
 *
 * This component now handles:
 * - Creation/Updating of the meeting type entirely within itself
 * - Searching/selecting example meetings
 * - AI-based generation of meeting descriptions (optional)
 * - Returning the final (created/updated) meeting type to the parent via `setMeetingType`
 *
 * Props:
 * - meetingTypeId (string | null): If provided, we'll attempt to update this existing meeting type.
 * - userEmail (string): Current user's email, used for searching.
 * - setMeetingType (function): Called with the fully created/updated meeting type object
 *   once the wizard completes.
 * - onProceedToNoteTemplate (function): Navigates the user to the note-template step
 *   (no changes to the UI/UX - so we keep the same final screen button that calls this).
 *
 * No UI or UX changes from the previous version. All data fetching/creation now lives here.
 */

export default function ConversationalMeetingTypeStep({
  meetingTypeId = null,
  userEmail = "",
  setMeetingType, // <-- newly required prop
  onProceedToNoteTemplate, // <-- keeps same UI for proceeding to notes
}) {
  // --------------------------
  // Create/Update logic
  // --------------------------
  const createMeetingType = async (data) => {
    try {
      const response = await fetch("https://backend.scribbl.co/meeting-types", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          name: data.name,
          description: data.description || "",
          isDraft: data.isDraft !== undefined ? data.isDraft : true,
        }),
      });

      if (!response.ok) {
        const msg = await response.text();
        throw new Error(
          `Failed to create meeting type: ${response.statusText}. ${msg}`
        );
      }

      return await response.json();
    } catch (error) {
      console.error("Failed to create meeting type:", error);
      throw error;
    }
  };

  const updateMeetingType = async (id, data) => {
    try {
      const payload = { ...data };
      // Clean up empty name/description if not explicitly provided
      if (payload.name === undefined || payload.name === "") {
        delete payload.name;
      }
      if (payload.description === undefined || payload.description === "") {
        delete payload.description;
      }

      const response = await fetch(
        `https://backend.scribbl.co/meeting-types/${id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const msg = await response.text();
        throw new Error(
          `Failed to update meeting type: ${response.statusText}. ${msg}`
        );
      }

      return await response.json();
    } catch (error) {
      console.error("Failed to update meeting type:", error);
      throw error;
    }
  };

  // --------------------------
  // State management
  // --------------------------
  const [localMeetingTypeId, setLocalMeetingTypeId] = useState(meetingTypeId);
  const [typeName, setTypeName] = useState("");
  const [typeDescription, setTypeDescription] = useState("");
  const [selectedMeetings, setSelectedMeetings] = useState([]);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  // Flow states
  const [descriptionMethod, setDescriptionMethod] = useState("examples");
  const [aiGenerated, setAiGenerated] = useState(false);
  const [aiGenerating, setAiGenerating] = useState(false);
  const [aiError, setAiError] = useState("");
  const [aiGenerationStep, setAiGenerationStep] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [meetingResults, setMeetingResults] = useState([]);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [searchType, setSearchType] = useState("quick");
  const [showMeetingModal, setShowMeetingModal] = useState(false);

  // UI references
  const inputRefs = useRef([]);
  const contentRef = useRef(null);
  const textareaRef = useRef(null);
  const searchInputRef = useRef(null);

  // Non-UI/UX
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // We no longer track "workflowComplete" or "onComplete" since we do final create/update ourselves.
  // We'll simply track the steps and once the user hits the last step, we finalize, then show success.

  // --------------------------
  // Constants
  // --------------------------
  const MAX_MEETING_SELECTIONS = 3;
  const TEXTAREA_HEIGHT = 250;

  // --------------------------
  // Steps / Questions
  // --------------------------
  const questions = [
    {
      ID: "meeting-name",
      title: "What type of meeting is this workflow for?",
      subtitle:
        "Meeting types help us automatically recognize and organize your meetings",
      validate: (value) => value.trim().length > 0,
      errorMessage: "Please enter a name for this meeting type",
      inputType: "text",
      value: typeName,
      onChange: setTypeName,
      placeholder: "e.g. Customer Discovery Call",
      content: (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-gray-600 max-w-xl mx-auto mb-6"
        >
          <div className="bg-brand-turq bg-opacity-5 rounded-lg p-4 border border-brand-turq border-opacity-15 mb-4">
            <div className="flex items-start">
              <InformationCircleIcon className="h-5 w-5 text-brand-turq mr-3 flex-shrink-0 mt-0.5" />
              <div>
                <h3 className="text-sm font-medium text-gray-800 mb-1">
                  Meeting types are the backbone of your workflow
                </h3>
                <p className="text-xs text-gray-600">
                  They help us automatically identify and organize similar
                  meetings, enabling powerful automations and insights for your
                  specific needs.
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-3 mb-2">
            <div className="bg-white rounded-lg p-3 shadow-sm border border-gray-100 text-center">
              <ClipboardCheckIcon className="h-5 w-5 text-brand-turq mx-auto mb-1.5" />
              <h4 className="font-medium text-gray-800 text-xs">
                Consistent Formats
              </h4>
            </div>

            <div className="bg-white rounded-lg p-3 shadow-sm border border-gray-100 text-center">
              <LightningBoltIcon className="h-5 w-5 text-brand-turq mx-auto mb-1.5" />
              <h4 className="font-medium text-gray-800 text-xs">
                Smart Actions
              </h4>
            </div>

            <div className="bg-white rounded-lg p-3 shadow-sm border border-gray-100 text-center">
              <ChartBarIcon className="h-5 w-5 text-brand-turq mx-auto mb-1.5" />
              <h4 className="font-medium text-gray-800 text-xs">
                Performance Insights
              </h4>
            </div>
          </div>
        </motion.div>
      ),
    },
    {
      ID: "description-method",
      title: "How would you like to build this meeting type?",
      subtitle: "Choose the approach that works best for your workflow",
      content: (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-gray-600 max-w-xl mx-auto mt-6"
        >
          <div className="mb-6 bg-brand-turq bg-opacity-5 border border-brand-turq border-opacity-20 rounded-lg p-4">
            <h3 className="text-base font-medium text-gray-800 mb-2 flex items-center">
              <InformationCircleIcon className="h-5 w-5 text-brand-turq mr-2" />
              Why this matters
            </h3>
            <p className="text-sm text-gray-600">
              The meeting type description helps us recognize and categorize
              your meetings automatically. A well-crafted description ensures we
              can properly identify and apply your workflow to future meetings.
            </p>
          </div>

          <div className="space-y-4">
            <button
              onClick={() => setDescriptionMethod("examples")}
              className={`w-full text-left p-4 border rounded-lg transition-all ${
                descriptionMethod === "examples"
                  ? "border-brand-turq bg-brand-turq bg-opacity-5"
                  : "border-gray-200 hover:border-gray-300"
              }`}
            >
              <div className="flex items-start">
                <div
                  className={`mt-0.5 mr-3 h-5 w-5 rounded-full border flex items-center justify-center ${
                    descriptionMethod === "examples"
                      ? "border-brand-turq bg-brand-turq"
                      : "border-gray-300"
                  }`}
                >
                  {descriptionMethod === "examples" && (
                    <CheckIcon className="h-3 w-3 text-white" />
                  )}
                </div>
                <div className="flex-1">
                  <h3 className="text-base font-medium text-gray-800 flex items-center">
                    <DocumentDuplicateIcon className="h-5 w-5 text-brand-turq mr-2" />
                    AI-generate from examples
                    <span className="ml-2 text-xs px-2 py-0.5 bg-brand-turq bg-opacity-10 text-brand-turq rounded-full">
                      Recommended
                    </span>
                  </h3>
                  <p className="text-sm text-gray-600 mt-1">
                    Select up to 3 example meetings and our AI will analyze them
                    to create an accurate description that captures the essence
                    of this meeting type.
                  </p>
                </div>
              </div>
            </button>

            <button
              onClick={() => setDescriptionMethod("manual")}
              className={`w-full text-left p-4 border rounded-lg transition-all ${
                descriptionMethod === "manual"
                  ? "border-brand-turq bg-brand-turq bg-opacity-5"
                  : "border-gray-200 hover:border-gray-300"
              }`}
            >
              <div className="flex items-start">
                <div
                  className={`mt-0.5 mr-3 h-5 w-5 rounded-full border flex items-center justify-center ${
                    descriptionMethod === "manual"
                      ? "border-brand-turq bg-brand-turq"
                      : "border-gray-300"
                  }`}
                >
                  {descriptionMethod === "manual" && (
                    <CheckIcon className="h-3 w-3 text-white" />
                  )}
                </div>
                <div className="flex-1">
                  <h3 className="text-base font-medium text-gray-800 flex items-center">
                    <PencilAltIcon className="h-5 w-5 text-gray-600 mr-2" />
                    Write your own description
                  </h3>
                  <p className="text-sm text-gray-600 mt-1">
                    Create a custom description for your meeting type if you
                    don't have examples yet or prefer to craft it yourself.
                  </p>
                </div>
              </div>
            </button>
          </div>
        </motion.div>
      ),
      validate: () => true,
      inputType: "info",
    },
    {
      ID: "meeting-examples",
      title: "Select example meetings",
      subtitle: "Choose up to 3 meetings that best represent this meeting type",
      validate: () => selectedMeetings.length > 0,
      errorMessage: "Please select at least one example meeting",
      inputType: "meetingSelect",
      value: selectedMeetings,
      onChange: setSelectedMeetings,
      placeholder: "Search for meetings by title, attendee, or content",
    },
    {
      ID: "ai-generation",
      title: "Generating your meeting type",
      subtitle:
        "We're analyzing your examples to create the perfect description",
      content: (props) => renderAIGenerationContent(),
      validate: () => !aiGenerating,
      inputType: "info",
      hideNavigation: aiGenerating,
    },
    {
      ID: "meeting-description",
      title: "Describe what makes this meeting type unique",
      subtitle: aiGenerated
        ? "We've generated this based on your examples - feel free to refine it"
        : "Help us understand the specific characteristics of these meetings",
      validate: (value) => value.trim().length > 0,
      errorMessage: "Please provide a description",
      inputType: "enhanced-textarea",
      value: typeDescription,
      onChange: setTypeDescription,
      placeholder:
        "Describe the purpose, typical content, and key characteristics of this meeting type...",
      showAiOption:
        selectedMeetings.length > 0 &&
        !aiGenerated &&
        descriptionMethod === "examples",
    },
    {
      ID: "meeting-type-complete",
      title: "Success! Your meeting type is ready",
      subtitle: null,
      content: (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center pt-4"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.1 }}
            className="mb-8"
          >
            <div className="inline-flex items-center justify-center w-20 h-20 bg-brand-turq bg-opacity-10 rounded-full mb-6">
              <CheckCircleIcon className="h-10 w-10 text-brand-turq" />
            </div>
            <h3 className="text-2xl font-medium text-gray-800 mb-3">
              "{typeName}" is all set!
            </h3>
            <p className="text-gray-600 max-w-md mx-auto">
              Your meeting type has been created successfully. Now let's set up
              how your meeting notes will look.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="max-w-md mx-auto"
          >
            <motion.button
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => {
                if (!isTransitioning) {
                  setIsTransitioning(true);
                  setTimeout(() => {
                    // Navigate to note template step
                    onProceedToNoteTemplate?.();
                  }, 500);
                }
              }}
              disabled={isTransitioning}
              className={`inline-flex items-center px-8 py-4 bg-gradient-to-r from-brand-turq to-brand-green text-white rounded-lg font-medium shadow-sm hover:shadow-lg transition-all duration-200 ${
                isTransitioning ? "opacity-75 cursor-not-allowed" : ""
              }`}
            >
              {isTransitioning ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 
          5.373 0 12h4zm2 5.291A7.962 7.962 
          0 014 12H0c0 3.042 1.135 5.824 3 
          7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <DocumentTextIcon className="h-5 w-5 mr-2" />
              )}
              {isTransitioning
                ? "Processing..."
                : "Create Meeting Notes Template"}
            </motion.button>
            <p className="text-sm text-gray-500 mt-4">
              Next step: Customize your note template to capture exactly what
              matters in {typeName} meetings.
            </p>
          </motion.div>
        </motion.div>
      ),
      validate: () => true,
      inputType: "info",
    },
  ];

  // --------------------------
  // Effects
  // --------------------------

  // Auto-focus transitions
  useEffect(() => {
    if (inputRefs.current[currentQuestion]) {
      setTimeout(() => {
        inputRefs.current[currentQuestion]?.focus();
      }, 400);
    }
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [currentQuestion]);

  // Track text area count
  useEffect(() => {
    setDescriptionCharCount(typeDescription.length);
  }, [typeDescription]);

  // --------------------------
  // Meeting fetching
  // --------------------------
  const fetchMeetings = async () => {
    if (!searchTerm.trim()) {
      setMeetingResults([]);
      return;
    }

    setLoadingMeetings(true);
    setSearchError("");

    try {
      const params = new URLSearchParams();
      params.set("view", "all_meetings");
      if (userEmail) {
        params.set("email", userEmail);
      }
      params.set("q", searchTerm.trim());
      params.set("searchType", searchType);

      const url = `https://backend.scribbl.co/enhanced-recording?${params.toString()}`;
      const resp = await fetch(url, { credentials: "include" });
      if (!resp.ok) {
        throw new Error("Failed to fetch meetings");
      }
      const data = await resp.json();

      setMeetingResults(data.data || []);
    } catch (error) {
      setSearchError(
        error.message || "Failed to fetch meetings. Please try again."
      );
      setMeetingResults([]);
    } finally {
      setLoadingMeetings(false);
    }
  };

  const formatAttendees = (attendees) => {
    if (!attendees || !Array.isArray(attendees)) return "";
    const names = attendees
      .map((attendee) => {
        if (typeof attendee === "string") return attendee;
        return attendee.name || attendee.email || "";
      })
      .filter(Boolean);
    return [...new Set(names)].join(", ");
  };

  // --------------------------
  // AI Generation
  // --------------------------
  const generateAIDescription = async () => {
    if (selectedMeetings.length === 0) {
      setAiError(
        "Please select at least one example meeting for better results"
      );
      return;
    }
    if (!typeName.trim()) {
      setAiError("Please provide a meeting type name first");
      return;
    }

    // Move to AI generation step
    setCurrentQuestion(3);
    setAiGenerating(true);
    setAiError("");
    setAiGenerationStep(1);

    // Fake step progression
    const interval = setInterval(() => {
      setAiGenerationStep((prev) => (prev < 3 ? prev + 1 : prev));
    }, 1500);

    try {
      const meetingIDs = selectedMeetings.map((m) => m.ID);

      const resp = await fetch(
        "https://backend.scribbl.co/meeting-types/generate-suggestion",
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            meetingIDs,
            userProvidedName: typeName,
          }),
        }
      );

      if (!resp.ok) {
        const text = await resp.text();
        throw new Error(text || "Failed to generate suggestion");
      }

      const data = await resp.json();
      const generatedDescription = data.description || "";

      setTypeDescription(generatedDescription);
      setAiGenerated(true);
      setAiGenerating(false);
      setAiGenerationStep(3);
    } catch (error) {
      setAiError("Failed to generate description. Please try again.");
      setAiGenerating(false);
      setAiGenerationStep(0);
    } finally {
      clearInterval(interval);
    }
  };

  // --------------------------
  // Navigation & Step Handling
  // --------------------------
  const handleNext = async () => {
    const question = questions[currentQuestion];
    if (!question.validate(question.value)) {
      return;
    }

    // Step 0 => Step 1: Possibly create or update with isDraft: true
    if (currentQuestion === 0) {
      // As soon as we have a valid name, we attempt creation/update in draft mode
      if (typeName.trim()) {
        try {
          if (!localMeetingTypeId) {
            const created = await createMeetingType({
              name: typeName.trim(),
              description: "", // no description yet
              isDraft: true,
            });
            setLocalMeetingTypeId(created.ID);
          } else {
            await updateMeetingType(localMeetingTypeId, {
              name: typeName.trim(),
              isDraft: true,
            });
          }
        } catch (err) {
          console.error("Error creating/updating meeting type in draft:", err);
        }
      }
    }

    // Step 1 => Step 2 or skip to Step 4 (if manual)
    if (currentQuestion === 1) {
      if (descriptionMethod === "examples") {
        setCurrentQuestion(2);
        return;
      } else {
        // skip to Step 4 (meeting-description) for manual
        setCurrentQuestion(4);
        return;
      }
    }

    // Step 2 => Step 3 (AI generation)
    if (currentQuestion === 2 && descriptionMethod === "examples") {
      if (selectedMeetings.length === 0) {
        return;
      }
      generateAIDescription();
      return;
    }

    // Step 3 => Step 4 (once AI generation completes or user clicks next)
    if (currentQuestion === 3) {
      // Only move if AI generation is done
      if (!aiGenerating) {
        setCurrentQuestion(4);
      }
      return;
    }

    // Step 4 => Step 5 (finalizing the meeting type)
    if (currentQuestion === 4) {
      if (!typeDescription.trim()) {
        return;
      }

      // Final update with isDraft: false
      try {
        let finalMeetingTypeData;
        if (localMeetingTypeId) {
          finalMeetingTypeData = await updateMeetingType(localMeetingTypeId, {
            name: typeName.trim(),
            description: typeDescription.trim(),
            isDraft: false,
          });
        } else {
          // If somehow we have no ID yet
          finalMeetingTypeData = await createMeetingType({
            name: typeName.trim(),
            description: typeDescription.trim(),
            isDraft: false,
          });
          setLocalMeetingTypeId(finalMeetingTypeData.ID);
        }

        console.log("getting this far?", finalMeetingTypeData);

        // Give the final meeting type back to parent
        setMeetingType?.(finalMeetingTypeData);
      } catch (error) {
        console.error("Error finalizing meeting type:", error);
      }

      setCurrentQuestion(5);
      return;
    }

    // Catch-all for standard increment
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    // Step 3 => Step 2 or Step 4 => Step 2 etc
    if (currentQuestion === 3) {
      // AI generation step -> go back to meeting selection
      setCurrentQuestion(2);
      setAiGenerating(false);
      setAiGenerationStep(0);
      return;
    }

    if (currentQuestion === 4 && descriptionMethod === "examples") {
      setCurrentQuestion(2);
      return;
    }

    if (currentQuestion === 4 && descriptionMethod === "manual") {
      setCurrentQuestion(1);
      return;
    }

    if (currentQuestion === 2) {
      setCurrentQuestion(1);
      return;
    }

    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
    }
  };

  // --------------------------
  // Meeting selection
  // --------------------------
  const toggleMeeting = (meeting) => {
    if (selectedMeetings.some((m) => m.ID === meeting.ID)) {
      setSelectedMeetings((prev) => prev.filter((m) => m.ID !== meeting.ID));
    } else {
      if (selectedMeetings.length >= MAX_MEETING_SELECTIONS) {
        return;
      }
      setSelectedMeetings((prev) => [...prev, meeting]);
    }
  };

  const toggleSearchType = () => {
    setSearchType((prev) => (prev === "quick" ? "transcript" : "quick"));
  };

  // --------------------------
  // Helpers
  // --------------------------
  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      return format(parseISO(dateString), "MMM d, yyyy");
    } catch (e) {
      return dateString;
    }
  };

  const formatDuration = (seconds) => {
    if (!seconds) return "";
    const minutes = Math.round(seconds / 60);
    if (minutes < 60) {
      return `${minutes} min${minutes !== 1 ? "s" : ""}`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMins = minutes % 60;
    return `${hours} hr${hours !== 1 ? "s" : ""} ${
      remainingMins > 0
        ? `${remainingMins} min${remainingMins !== 1 ? "s" : ""}`
        : ""
    }`;
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  // --------------------------
  // Meeting Modal
  // --------------------------
  const renderMeetingModal = () => {
    if (!showMeetingModal) return null;

    const filteredResults = meetingResults.filter(
      (meeting) => !selectedMeetings.some((m) => m.ID === meeting.ID)
    );

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-50 backdrop-blur-sm flex items-center justify-center"
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          transition={{ type: "spring", bounce: 0.25 }}
          className="relative w-full max-w-4xl mx-4 bg-white rounded-2xl shadow-xl"
          style={{ height: "650px" }}
        >
          <div className="flex flex-col h-full">
            {/* Header */}
            <div className="px-6 pt-6 pb-4 border-b border-gray-200">
              <div className="flex items-start">
                <div className="flex-shrink-0 bg-brand-turq bg-opacity-10 rounded-full p-2">
                  <SearchIcon className="h-6 w-6 text-brand-turq" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-medium text-gray-900">
                    Select example meetings
                  </h3>
                  <p className="text-sm text-gray-500 mt-1">
                    Find meetings that represent your{" "}
                    {typeName || "meeting type"}
                  </p>
                </div>
                <button
                  onClick={() => setShowMeetingModal(false)}
                  className="ml-auto bg-white rounded-full p-1 hover:bg-gray-100 transition-colors"
                >
                  <XIcon className="h-5 w-5 text-gray-400" />
                </button>
              </div>
            </div>

            <div className="flex-1 flex flex-col h-full overflow-hidden">
              {/* Selection summary */}
              <div className="px-6 py-3 bg-gray-50 border-b border-gray-200">
                <div className="flex items-center justify-between">
                  <div className="text-sm text-gray-600">
                    {selectedMeetings.length === 0 ? (
                      "No meetings selected yet"
                    ) : (
                      <span className="font-medium text-gray-800">
                        {selectedMeetings.length} meeting
                        {selectedMeetings.length !== 1 ? "s" : ""} selected
                      </span>
                    )}
                  </div>
                  <div
                    className={`text-xs px-2 py-0.5 rounded-full ${
                      selectedMeetings.length === MAX_MEETING_SELECTIONS
                        ? "bg-brand-turq bg-opacity-10 text-brand-turq"
                        : "bg-gray-100 text-gray-600"
                    }`}
                  >
                    {selectedMeetings.length}/{MAX_MEETING_SELECTIONS}
                  </div>
                </div>
              </div>

              {/* Search controls */}
              <div className="p-4 border-b border-gray-200">
                <div className="relative flex items-center">
                  <div className="relative flex-1">
                    <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                    <input
                      ref={searchInputRef}
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search by title, attendee, or content"
                      className="w-full pl-10 pr-10 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:ring-brand-turq focus:border-brand-turq transition-all"
                      autoFocus
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          fetchMeetings();
                        }
                      }}
                    />
                    {searchTerm && (
                      <button
                        onClick={() => setSearchTerm("")}
                        className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600"
                      >
                        <XIcon className="w-4 h-4" />
                      </button>
                    )}
                  </div>

                  <div className="flex ml-3 space-x-2">
                    <button
                      onClick={toggleSearchType}
                      className={`px-3 py-2 text-xs font-medium rounded-md transition-colors ${
                        searchType === "transcript"
                          ? "bg-brand-turq bg-opacity-10 text-brand-turq border border-brand-turq border-opacity-20"
                          : "border border-gray-200 text-gray-600 hover:bg-gray-50"
                      }`}
                    >
                      {searchType === "transcript"
                        ? "Transcript search"
                        : "Basic search"}
                    </button>

                    <button
                      onClick={fetchMeetings}
                      disabled={!searchTerm.trim()}
                      className={`px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                        searchTerm.trim()
                          ? "text-white bg-brand-turq hover:bg-brand-green"
                          : "text-gray-400 bg-gray-100 cursor-not-allowed"
                      }`}
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div className="mt-2 flex justify-between items-center">
                  <div className="text-xs text-gray-500">
                    {searchType === "transcript"
                      ? "Searching in meeting transcripts for specific content"
                      : "Searching by meeting title and attendee names"}
                  </div>

                  {loadingMeetings && (
                    <div className="text-xs text-gray-500 flex items-center">
                      <svg
                        className="animate-spin h-3 w-3 mr-1 text-brand-turq"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 
                            0 0 5.373 0 12h4zm2 5.291A7.962 
                            7.962 0 014 12H0c0 3.042 
                            1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Searching...
                    </div>
                  )}
                </div>
              </div>

              {/* Selected meetings */}
              {selectedMeetings.length > 0 && (
                <div className="px-4 py-3 bg-gray-50 border-b border-gray-200">
                  <h4 className="text-xs font-medium text-gray-700 mb-2">
                    Selected meetings
                  </h4>
                  <div className="flex flex-wrap gap-2">
                    {selectedMeetings.map((meeting) => (
                      <div
                        key={`selected-${meeting.ID}`}
                        className="flex items-center py-1 pl-3 pr-2 bg-brand-turq bg-opacity-10 border border-brand-turq border-opacity-20 rounded-lg text-sm"
                      >
                        <span className="font-medium text-brand-turq truncate max-w-xs">
                          {meeting.meetingTitle}
                        </span>
                        <button
                          onClick={() => toggleMeeting(meeting)}
                          className="ml-2 p-1 text-brand-turq hover:bg-brand-turq hover:bg-opacity-20 rounded-full"
                        >
                          <XIcon className="w-3.5 h-3.5" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Results area */}
              <div className="flex-1 overflow-y-auto">
                {searchError ? (
                  <div className="p-4 bg-red-50 m-4 rounded-lg border border-red-100 flex items-start">
                    <ExclamationIcon className="h-5 w-5 text-red-500 mr-2 flex-shrink-0" />
                    <div>
                      <p className="text-sm font-medium text-red-700">
                        Error searching meetings
                      </p>
                      <p className="text-xs text-red-600 mt-1">{searchError}</p>
                      <button
                        onClick={() => fetchMeetings()}
                        className="mt-2 px-3 py-1 text-xs font-medium bg-white border border-red-200 rounded-md text-red-600 hover:bg-red-50"
                      >
                        Try again
                      </button>
                    </div>
                  </div>
                ) : searchTerm && meetingResults.length === 0 ? (
                  loadingMeetings ? (
                    <div className="flex flex-col items-center justify-center h-48 text-gray-500">
                      <svg
                        className="animate-spin h-8 w-8 text-brand-turq mb-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 
                            0 0 5.373 0 12h4zm2 5.291A7.962 
                            7.962 0 014 12H0c0 3.042 
                            1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <p className="text-sm">Searching for meetings...</p>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center h-48 text-center px-4">
                      <SearchIcon className="h-10 w-10 text-gray-300 mb-3" />
                      <h3 className="text-base font-medium text-gray-700">
                        No meetings found
                      </h3>
                      <p className="text-sm text-gray-500 mt-1 max-w-md">
                        Try different search terms or check your spelling. You
                        can also try searching for specific content in the
                        transcript.
                      </p>
                    </div>
                  )
                ) : !searchTerm ? (
                  <div className="flex flex-col items-center justify-center h-48 text-center px-4">
                    <DocumentTextIcon className="h-10 w-10 text-gray-300 mb-3" />
                    <h3 className="text-base font-medium text-gray-700">
                      Search for meetings
                    </h3>
                    <p className="text-sm text-gray-500 mt-1 max-w-md">
                      Enter keywords above to find meetings that represent your{" "}
                      {typeName || "meeting type"}
                    </p>
                  </div>
                ) : filteredResults.length === 0 ? (
                  <div className="flex flex-col items-center justify-center h-48">
                    <CheckCircleIcon className="h-10 w-10 text-gray-300 mb-3" />
                    <h3 className="text-base font-medium text-gray-700">
                      All matching meetings selected
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                      You've already selected all meetings that match your
                      search.
                    </p>
                  </div>
                ) : (
                  <div className="divide-y divide-gray-100">
                    <div className="px-4 py-2 bg-gray-50 border-b border-gray-200">
                      <div className="flex justify-between items-center">
                        <h4 className="text-xs font-medium text-gray-700">
                          Search results
                        </h4>
                        <span className="text-xs text-gray-500">
                          {filteredResults.length} meeting
                          {filteredResults.length !== 1 ? "s" : ""} found
                        </span>
                      </div>
                    </div>

                    <ul>
                      {filteredResults.map((meeting) => {
                        const isDisabled =
                          selectedMeetings.length >= MAX_MEETING_SELECTIONS;

                        console.log("meeting", meeting);

                        return (
                          <li
                            key={meeting.ID}
                            className="border-b border-gray-100 last:border-b-0"
                          >
                            <button
                              className={`w-full px-4 py-3 text-left flex items-center hover:bg-gray-50 transition-colors ${
                                isDisabled
                                  ? "opacity-50 cursor-not-allowed"
                                  : ""
                              }`}
                              onClick={() =>
                                !isDisabled && toggleMeeting(meeting)
                              }
                              disabled={isDisabled}
                            >
                              <div className="flex-1 min-w-0 pr-3">
                                <div className="font-medium text-sm text-gray-800">
                                  {meeting.meetingTitle}
                                </div>

                                <div className="flex flex-wrap text-xs text-gray-500 mt-1">
                                  <div className="flex items-center mr-3">
                                    <CalendarIcon className="h-3 w-3 mr-1" />
                                    {formatDate(meeting.CreatedAt)}
                                  </div>

                                  {meeting.duration && (
                                    <div className="flex items-center">
                                      <ClockIcon className="h-3 w-3 mr-1" />
                                      {formatDuration(meeting.duration)}
                                    </div>
                                  )}
                                </div>

                                {meeting.attendees &&
                                  meeting.attendees.length > 0 && (
                                    <div className="text-xs text-gray-500 mt-0.5 flex items-center">
                                      <UserCircleIcon className="h-3 w-3 mr-1" />
                                      <span className="truncate max-w-xs">
                                        {formatAttendees(meeting.attendees)}
                                      </span>
                                    </div>
                                  )}
                              </div>

                              <div
                                className={`flex-shrink-0 w-8 h-8 rounded-full border flex items-center justify-center ${
                                  isDisabled
                                    ? "border-gray-200 bg-gray-100"
                                    : "border-brand-turq border-opacity-50 bg-white"
                                }`}
                              >
                                <PlusIcon
                                  className={`h-4 w-4 ${
                                    isDisabled
                                      ? "text-gray-400"
                                      : "text-brand-turq"
                                  }`}
                                />
                              </div>
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* Footer */}
            <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-between items-center">
              <div className="text-sm text-gray-600">
                {selectedMeetings.length > 0 ? (
                  <span>
                    <span className="font-medium">
                      {selectedMeetings.length}
                    </span>{" "}
                    meeting{selectedMeetings.length !== 1 ? "s" : ""} selected
                  </span>
                ) : (
                  <span className="text-gray-500">
                    Select at least one meeting to continue
                  </span>
                )}
              </div>

              <div className="flex space-x-3">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 transition-colors"
                  onClick={() => setShowMeetingModal(false)}
                >
                  Cancel
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="button"
                  className={`px-4 py-2 text-sm font-medium rounded-lg shadow-sm transition-colors ${
                    selectedMeetings.length > 0
                      ? "text-white bg-brand-turq hover:bg-brand-green"
                      : "text-gray-400 bg-gray-100 cursor-not-allowed"
                  }`}
                  onClick={() =>
                    selectedMeetings.length > 0 && setShowMeetingModal(false)
                  }
                  disabled={selectedMeetings.length === 0}
                >
                  Done
                </motion.button>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    );
  };

  // --------------------------
  // Render Meeting Selection Step
  // --------------------------
  const renderMeetingSelection = () => {
    return (
      <div className="mt-6 space-y-5">
        {/* Selected meeting summary */}
        <div className="bg-white rounded-lg border border-gray-200 p-4 shadow-sm">
          <div className="flex justify-between items-center mb-3">
            <h3 className="text-sm font-medium text-gray-800 flex items-center">
              <CheckCircleIcon className="h-4 w-4 text-brand-turq mr-1.5" />
              Selected example meetings
            </h3>
            <span
              className={`text-xs px-2 py-0.5 rounded-full ${
                selectedMeetings.length === MAX_MEETING_SELECTIONS
                  ? "bg-brand-turq bg-opacity-10 text-brand-turq"
                  : "bg-gray-100 text-gray-600"
              }`}
            >
              {selectedMeetings.length}/{MAX_MEETING_SELECTIONS}
            </span>
          </div>

          {selectedMeetings.length > 0 ? (
            <div className="space-y-2">
              {selectedMeetings.map((meeting) => (
                <div
                  key={meeting.ID}
                  className="flex items-center p-2.5 bg-gray-50 border border-gray-200 rounded-lg group"
                >
                  <div className="flex-1 min-w-0 pr-2">
                    <p className="font-medium text-sm text-gray-800 truncate">
                      {meeting.meetingTitle}
                    </p>
                    <p className="text-xs text-gray-500 mt-0.5 flex items-center">
                      <CalendarIcon className="h-3 w-3 mr-1" />
                      {formatDate(meeting.CreatedAt)}
                      {meeting.duration && (
                        <>
                          <span className="mx-1">•</span>
                          <ClockIcon className="h-3 w-3 mr-1" />
                          {formatDuration(meeting.duration)}
                        </>
                      )}
                    </p>
                  </div>
                  <button
                    onClick={() => toggleMeeting(meeting)}
                    className="p-1.5 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-md transition-colors"
                  >
                    <XIcon className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="py-6 text-center rounded-lg border border-dashed border-gray-200 bg-gray-50">
              <p className="text-sm text-gray-500">No meetings selected yet</p>
              <p className="text-xs text-gray-400 mt-1">
                Click the button below to select example meetings
              </p>
            </div>
          )}
        </div>

        {/* Select button */}
        <div className="flex justify-center py-3">
          <motion.button
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setShowMeetingModal(true)}
            className="flex items-center px-5 py-3.5 bg-white border-2 border-dashed border-brand-turq border-opacity-50 rounded-lg text-brand-turq hover:text-brand-green hover:border-brand-green hover:bg-brand-turq hover:bg-opacity-5 transition-colors font-medium shadow-sm"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            {selectedMeetings.length > 0
              ? `${
                  selectedMeetings.length === MAX_MEETING_SELECTIONS
                    ? "Manage"
                    : "Add More"
                } Example Meetings`
              : "Select Example Meetings"}
          </motion.button>
        </div>

        {/* Help text */}
        <div className="p-4 bg-brand-turq bg-opacity-5 border border-brand-turq border-opacity-20 rounded-lg text-sm">
          <div className="flex items-start">
            <InformationCircleIcon className="h-4 w-4 text-brand-turq mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-gray-700">
                Select meetings that best represent this type. Our AI will
                analyze them to learn the patterns and characteristics that
                define
                <span className="font-medium">
                  {" "}
                  {typeName || "this meeting type"}
                </span>
                .
              </p>
              <p className="text-gray-600 mt-2 text-xs">
                The more representative your examples are, the better we can
                automatically recognize and categorize similar meetings in the
                future.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // --------------------------
  // Enhanced Textarea
  // --------------------------
  const renderEnhancedTextarea = () => {
    return (
      <div className="mt-6">
        <div className="relative">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-xs text-gray-500">
                {descriptionCharCount} characters
              </span>
            </div>
          </div>

          <div className="relative">
            <textarea
              ref={textareaRef}
              value={typeDescription}
              onChange={(e) => {
                setTypeDescription(e.target.value);
                setDescriptionCharCount(e.target.value.length);
                if (aiGenerated) setAiGenerated(false);
              }}
              style={{ height: `${TEXTAREA_HEIGHT}px` }}
              className="w-full rounded-lg border-2 border-gray-200 shadow-sm px-4 py-3.5 text-base 
                focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
                hover:border-gray-300 resize-none"
              placeholder="Describe the purpose, typical content, and key characteristics of this meeting type..."
            />
          </div>
        </div>
      </div>
    );
  };

  // --------------------------
  // AI Generation content step
  // --------------------------
  const renderAIGenerationContent = () => {
    const steps = [
      "Analyzing example meetings",
      "Extracting meaningful patterns",
      "Finalizing meeting type description",
    ];

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-lg mx-auto"
      >
        <div className="p-6 bg-white rounded-lg shadow-sm border border-gray-100 mb-6">
          <div className="flex items-center mb-5">
            <div className="p-2 bg-brand-turq bg-opacity-10 rounded-full mr-3">
              <SparklesIcon className="h-6 w-6 text-brand-turq" />
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-800">
                AI analysis in progress
              </h3>
              <p className="text-sm text-gray-600 mt-0.5">
                We're analyzing your examples to create an accurate description
              </p>
            </div>
          </div>

          {/* Time expectation */}
          <div className="mb-6 p-3 bg-gray-50 border border-gray-200 rounded-lg flex items-start">
            <InformationCircleIcon className="h-4 w-4 text-gray-500 mr-2 flex-shrink-0 mt-0.5" />
            <p className="text-xs text-gray-600">
              This process may take up to 5 minutes, but usually completes much
              faster. The more complex your meetings are, the longer it may
              take.
            </p>
          </div>

          <div className="space-y-6 mt-6">
            {steps.map((step, index) => {
              const stepNumber = index + 1;
              const isActive = aiGenerationStep === stepNumber;
              const isComplete =
                aiGenerationStep > stepNumber ||
                (!aiGenerating && aiGenerationStep === stepNumber);

              return (
                <div key={`step-${index}`} className="relative">
                  {/* Connecting line */}
                  {index < steps.length - 1 && (
                    <div
                      className={`absolute left-4 top-7 bottom-0 w-0.5 ${
                        isComplete
                          ? "bg-brand-turq bg-opacity-50"
                          : "bg-gray-200"
                      }`}
                      style={{ height: "36px" }}
                    ></div>
                  )}

                  <div className="flex items-start">
                    {isComplete ? (
                      <div className="h-8 w-8 rounded-full bg-brand-turq flex items-center justify-center mr-4 z-10">
                        <CheckIcon className="h-5 w-5 text-white" />
                      </div>
                    ) : isActive ? (
                      <div className="h-8 w-8 rounded-full border-2 border-brand-turq bg-white flex items-center justify-center mr-4 z-10">
                        <svg
                          className="animate-spin h-5 w-5 text-brand-turq"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 
                              0 0 5.373 0 12h4zm2 5.291A7.962 
                              7.962 0 014 12H0c0 3.042 1.135 
                              5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                    ) : (
                      <div className="h-8 w-8 rounded-full bg-gray-100 border border-gray-200 flex items-center justify-center mr-4 z-10">
                        <span className="text-gray-500 text-sm">
                          {index + 1}
                        </span>
                      </div>
                    )}
                    <div
                      className={`${
                        isActive || isComplete
                          ? "text-gray-800"
                          : "text-gray-500"
                      }`}
                    >
                      <h4
                        className={`font-medium ${
                          isActive ? "text-brand-turq" : ""
                        }`}
                      >
                        {step}
                      </h4>
                      <p className="text-xs mt-1">
                        {isComplete
                          ? "Complete"
                          : isActive
                          ? "In progress..."
                          : "Waiting..."}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {aiError && (
          <div className="p-4 bg-red-50 border border-red-100 rounded-lg text-red-600 flex items-start">
            <ExclamationIcon className="h-5 w-5 mr-2 flex-shrink-0" />
            <div>
              <p className="font-medium">Generation failed</p>
              <p className="text-sm mt-1">{aiError}</p>
              <button
                onClick={() => {
                  setAiError("");
                  generateAIDescription();
                }}
                className="mt-3 px-4 py-2 bg-white border border-red-200 rounded-md text-red-600 text-sm font-medium hover:bg-red-50"
              >
                Try again
              </button>
            </div>
          </div>
        )}

        {!aiGenerating && !aiError && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-brand-turq bg-opacity-5 p-4 rounded-lg border border-brand-turq border-opacity-20 text-center"
          >
            <CheckCircleIcon className="h-6 w-6 text-brand-turq mx-auto mb-2" />
            <p className="font-medium text-brand-turq">
              Description generated successfully!
            </p>
            <p className="text-sm text-gray-600 mt-1">
              Continue to review and refine your description.
            </p>
          </motion.div>
        )}
      </motion.div>
    );
  };

  // --------------------------
  // Render question input
  // --------------------------
  const renderQuestionInput = (question, index) => {
    switch (question.inputType) {
      case "info":
        return typeof question.content === "function" ? (
          question.content()
        ) : (
          <div className="mt-4">{question.content}</div>
        );

      case "text":
        return (
          <div className="mt-6">
            <div className="relative">
              <input
                ref={(el) => (inputRefs.current[index] = el)}
                type="text"
                value={question.value}
                onChange={(e) => question.onChange(e.target.value)}
                className="w-full rounded-lg border-2 border-gray-200 shadow-sm px-4 py-3.5 text-base 
                  focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
                  hover:border-gray-300"
                placeholder={question.placeholder}
                onKeyDown={(e) => e.key === "Enter" && handleNext()}
                autoFocus
              />
            </div>
            <div className="mt-3 flex flex-wrap gap-2">
              <span className="text-xs text-gray-500 mr-1 mt-1">Examples:</span>
              {["Customer Discovery", "Therapy Session", "Weekly Stand-up"].map(
                (example) => (
                  <button
                    key={example}
                    onClick={() => question.onChange(example)}
                    className="px-2 py-1 text-xs bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
                  >
                    {example}
                  </button>
                )
              )}
            </div>
          </div>
        );

      case "meetingSelect":
        return renderMeetingSelection();

      case "enhanced-textarea":
        return renderEnhancedTextarea();

      default:
        return <div>Unknown input type: {question.inputType}</div>;
    }
  };

  // --------------------------
  // Progress indicator
  // --------------------------
  const renderProgressIndicator = () => {
    // Calculate total visible steps (excluding success screen) depending on the chosen method
    const totalSteps = descriptionMethod === "examples" ? 4 : 3;
    let currentStep;

    if (descriptionMethod === "examples") {
      // For example workflow
      if (currentQuestion === 0) currentStep = 1;
      else if (currentQuestion === 1) currentStep = 2;
      else if (currentQuestion === 2) currentStep = 3;
      else if (currentQuestion === 4) currentStep = 4;
      else currentStep = 0;
    } else {
      // For manual workflow
      if (currentQuestion === 0) currentStep = 1;
      else if (currentQuestion === 1) currentStep = 2;
      else if (currentQuestion === 4) currentStep = 3;
      else currentStep = 0;
    }

    if (currentQuestion === questions.length - 1 || currentStep === 0) {
      return null; // Hide in final success step or invalid step
    }

    return (
      <div className="flex items-center justify-center mb-6">
        <div className="bg-gray-100 rounded-full h-1.5 w-64 overflow-hidden">
          <motion.div
            className="h-full bg-brand-turq rounded-full"
            initial={{ width: "0%" }}
            animate={{ width: `${(currentStep / totalSteps) * 100}%` }}
            transition={{ duration: 0.3 }}
          />
        </div>
        <div className="ml-3 text-xs text-gray-500 font-medium">
          {currentStep}/{totalSteps}
        </div>
      </div>
    );
  };

  // --------------------------
  // Render current question
  // --------------------------
  const renderCurrentQuestion = () => {
    const question = questions[currentQuestion];

    return (
      <motion.div
        key={question.ID}
        className="h-full flex flex-col"
        initial={{ opacity: 0, y: 15 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -15 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex-1">
          {/* Title and subtitle */}
          <div className="mb-4 text-center">
            <motion.h3
              className="text-2xl font-medium text-gray-900 tracking-tight"
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
            >
              {question.title}
            </motion.h3>
            {question.subtitle && (
              <motion.p
                className="mt-2 text-gray-600"
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                {question.subtitle}
              </motion.p>
            )}
          </div>

          {/* Question input */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="overflow-y-auto"
          >
            {renderQuestionInput(question, currentQuestion)}
          </motion.div>

          {/* Error message */}
          {question.errorMessage &&
            !question.validate(question.value) &&
            question.value && (
              <p className="mt-2 text-sm text-red-600 flex items-center">
                <ExclamationIcon className="h-4 w-4 mr-1" />
                {question.errorMessage}
              </p>
            )}
        </div>
      </motion.div>
    );
  };

  // --------------------------
  // Main render
  // --------------------------
  return (
    <div className="h-full flex flex-col">
      <div className="h-full flex flex-col">
        <div
          ref={contentRef}
          className="flex-1 bg-white rounded-t-xl shadow-sm border border-gray-200 border-b-0 overflow-hidden"
        >
          <div className="p-6 h-full flex flex-col relative">
            {renderProgressIndicator()}

            <div className="flex-1 overflow-y-auto">
              <AnimatePresence mode="wait">
                {renderCurrentQuestion()}
              </AnimatePresence>
            </div>
          </div>
        </div>

        {currentQuestion < questions.length - 1 &&
          !questions[currentQuestion].hideNavigation && (
            <div className="bg-white rounded-b-xl border border-t-0 border-gray-200 shadow-sm">
              <div className="px-6 py-4 flex justify-between items-center bg-gray-50 bg-opacity-70">
                {currentQuestion > 0 ? (
                  <button
                    onClick={handlePrevious}
                    className="flex items-center px-4 py-2 text-brand-turq rounded-lg hover:bg-brand-turq hover:bg-opacity-5 transition-colors"
                  >
                    <ArrowLeftIcon className="h-4 w-4 mr-1.5" />
                    <span className="font-medium">Back</span>
                  </button>
                ) : (
                  <div></div>
                )}

                <button
                  onClick={handleNext}
                  disabled={
                    !questions[currentQuestion].validate(
                      questions[currentQuestion].value
                    ) || aiGenerating
                  }
                  className={classNames(
                    "inline-flex items-center px-5 py-2.5 rounded-lg font-medium transition-colors",
                    questions[currentQuestion].validate(
                      questions[currentQuestion].value
                    ) && !aiGenerating
                      ? "bg-brand-turq text-white hover:bg-brand-green shadow-sm"
                      : "bg-gray-200 text-gray-400 cursor-not-allowed"
                  )}
                >
                  {aiGenerating ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 
                          0 0 5.373 0 12h4zm2 5.291A7.962 
                          7.962 0 014 12H0c0 3.042 
                          1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Generating...
                    </>
                  ) : (
                    <>
                      Continue
                      <ArrowRightIcon className="h-5 w-5 ml-2" />
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
      </div>

      {/* Meeting selection modal */}
      <AnimatePresence>
        {showMeetingModal && renderMeetingModal()}
      </AnimatePresence>
    </div>
  );
}
