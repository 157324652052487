import React, { useState, useRef, useEffect } from "react";
import Navbar from "./Navbar";
import Meeting from "./Meeting";
import NewMeeting from "./MeetingPage";
import Recording from "./Recording";
import NotFound from "./NotFound";
import amplitude from "amplitude-js";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DeletedMeeting from "./DeletedMeeting";

const MeetingRouter = ({ isPublic }) => {
  let siderRef = useRef();
  const navigate = useNavigate();
  const { uuid } = useParams();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const openedFromMeet = query.get("openedFromMeet");
  const oldNotes = query.get("oldNotes"); // Add this line to extract the oldNotes query parameter

  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [meeting, setMeeting] = useState(null);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const [triggerTeamModal, setTriggerTeamModal] = useState(false);

  const fetchMeeting = async (uuid, userEmail, setPageNotFound, setLoading) => {
    try {
      const enhancedRecResoponse = await fetch(
        `https://backend.scribbl.co/enhanced-recording/${uuid}?email=${userEmail}&uuid=${uuid}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (enhancedRecResoponse.status === 404) {
        try {
          const data = await enhancedRecResoponse.json();
          if (data?.reason === "deleted") {
            setIsDeleted(true);
          } else {
            setPageNotFound(true);
          }
        } catch (error) {
          // If we can't parse the JSON, it means it's a regular 404 without a body
          setPageNotFound(true);
        }
        setLoading(false);
        return;
      }

      const enhancedRecording = await enhancedRecResoponse.json();
      if (enhancedRecording.isError) {
        setPageNotFound(true);
        setLoading(false);
        alert.error(enhancedRecording.reason);
        return;
      }
      return enhancedRecording;
    } catch (exception) {
      console.log(exception);
      setPageNotFound(true);
      setLoading(false);
      return null;
    }
  };

  useEffect(() => {
    async function getPublicMeeting() {
      // Get the public recording
      let getPublicURL = `https://backend.scribbl.co/enhanced-recording/${uuid}/share`;
      if (userEmail) {
        getPublicURL = `https://backend.scribbl.co/enhanced-recording/${uuid}/share?email=${userEmail}`;
      }

      const getPublicMeetingResp = await fetch(getPublicURL, {
        method: "GET",
        credentials: "include", // This line ensures cookies are sent with the request
      });

      if (getPublicMeetingResp.status === 404) {
        setPageNotFound(true);
        return;
      }

      // we should handle the error case here or it will fail
      try {
        const meeting = await getPublicMeetingResp.json();
        if (userEmail) {
          navigate(`/recording/${meeting.uuid}`);
        } else {
          setMeeting(meeting);
        }

        if (meeting.containsVideo === false) {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    async function getSetMeeting() {
      if (userEmail) {
        const rawMeeting = await fetchMeeting(
          uuid,
          userEmail,
          setPageNotFound,
          setLoading
        );
        if (rawMeeting) {
          // This amplitude event should only be triggered the first time this is hit
          //, because this func can be called elsewhere.
          if (!meeting) {
            var eventProperties = {
              recording_uuid: rawMeeting.uuid,
              role: rawMeeting.role,
              type: rawMeeting.containsVideo ? "recording" : "transcript",
            };
            amplitude
              .getInstance()
              .logEvent("er recording viewed", eventProperties);
          }
          setMeeting(rawMeeting);
        }
        // For meetings with video, there is a setLoading call in the <video>'s onLoadedMetadata handler
        // aka video takes care of setting loading to false
        if (rawMeeting.containsVideo === false) {
          setLoading(false);
        } else if (
          rawMeeting.containsVideo &&
          openedFromMeet &&
          rawMeeting.recordingURL === "" &&
          !isPublic
        ) {
          setTimeout(async () => {
            await getSetMeeting();
          }, 1000);
        } else if (
          rawMeeting.recordingURL === "" &&
          rawMeeting.isProcessing === false
        ) {
          const updatedMeeting = structuredClone(rawMeeting);
          updatedMeeting.containsVideo = false;
          setMeeting(updatedMeeting);
          setLoading(false);
        }
      }
    }

    if (isPublic) {
      getPublicMeeting();
    } else {
      getSetMeeting();
    }
  }, [userEmail]);

  // Modified condition to check for oldNotes query parameter
  if (meeting && user && user.notionSetup && !oldNotes) {
    return <NewMeeting isPublic={isPublic} />;
  }

  return (
    <Navbar
      setUserEmail={setUserEmail}
      setUser={setUser}
      isPublic={isPublic}
      siderRef={siderRef}
      setLoading={setLoading}
      loading={loading}
      triggerTeamModal={triggerTeamModal}
    >
      {meeting &&
        meeting.containsVideo &&
        ((user && user.notionSetup === false) || oldNotes) && (
          <Recording
            isPublic={isPublic}
            user={user}
            userEmail={userEmail}
            setLoading={setLoading}
            recording={meeting}
            setTriggerTeamModal={setTriggerTeamModal}
          ></Recording>
        )}

      {meeting &&
        !meeting.containsVideo &&
        ((user && user.notionSetup === false) || oldNotes) && (
          <Meeting
            isPublic={isPublic}
            user={user}
            userEmail={userEmail}
            setLoading={setLoading}
            meeting={meeting}
            setTriggerTeamModal={setTriggerTeamModal}
          ></Meeting>
        )}

      {isDeleted && <DeletedMeeting />}
      {pageNotFound && <NotFound />}
    </Navbar>
  );
};

// ---------------------------------------------------------------------------
// 1) DUPLICATE THE FEB PROMO BANNER CODE (UTILITY FUNCTIONS + COMPONENT)
// ---------------------------------------------------------------------------

// Utility function: calculates how many days left in February
export const getDaysLeftInFeb = () => {
  const now = new Date();
  // new Date(year, 2, 0) -> last day of February, adjusting for leap years
  const endOfFeb = new Date(now.getFullYear(), 2, 0, 23, 59, 59);
  const diffTime = endOfFeb - now;
  return diffTime > 0 ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : 0;
};

// Utility function: returns how many "slots" are taken, left, etc.
export const getSlotsData = () => {
  const now = new Date();
  const dayOfMonth = now.getDate();
  const totalSlots = 100;

  // A simple formula that scales with the day, ensuring it doesn't exceed 95 initially
  const baseSlotsTaken = Math.min(Math.floor(dayOfMonth * 3.2), 95);
  // Add a random variance up to 5 for realism
  const randomVariance = Math.floor(Math.random() * 5);

  const slotsTaken = baseSlotsTaken + randomVariance;
  const slotsLeft = totalSlots - slotsTaken;
  const progressPercent = Math.min((slotsTaken / totalSlots) * 100, 100);

  return {
    totalSlots,
    slotsTaken,
    slotsLeft,
    progressPercent,
  };
};

/**
 * Minimalist Promo Banner that displays a 50% off Team Plan CTA,
 * only if it's still February and there are “slots” left.
 *
 * This also includes amplitude tracking for the "Book a demo" click,
 * labeled differently than the one on `Home.js`.
 */
const PromoBannerMinimalist = ({ user }) => {
  const [isVisible, setIsVisible] = useState(true);
  const daysLeft = getDaysLeftInFeb();
  const { slotsTaken, slotsLeft, progressPercent } = getSlotsData();

  // Hide banner once February is over
  if (!isVisible || daysLeft <= 0) return null;

  // Separate amplitude click event name for the MeetingRouter usage
  const handleBookDemoClick = (e) => {
    e.preventDefault();

    amplitude
      .getInstance()
      .logEvent("febPromoBanner bookDemo clicked from MeetingRouter", {
        userId: user?.ID,
        userEmail: user?.email,
      });

    const calendlyURL = `https://calendly.com/mike-sallese/meeting-with-mike?name=${
      user?.name || ""
    }&email=${user?.email || ""}&a1=feb%20promo%20meeting`;
    window.open(calendlyURL, "_blank");
  };

  return (
    <div className="relative isolate bg-gradient-to-r from-blue-600 to-indigo-600 text-white">
      {/* Banner Container */}
      <div className="mx-auto max-w-7xl px-4 py-3 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
          {/* Left: Offer Details */}
          <div className="flex items-center gap-3">
            {/* Sparkles Icon */}
            <svg
              className="h-6 w-6 text-white animate-pulse"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.813 15.043l1.73-2.986 2.827 1.635-1.73 2.986M11 2v2M4.22 4.22l1.42 1.42M2 11h2m14-9v2m1.364 2.636l1.415-1.415M20 11h2M4.22 19.78l1.42-1.42M11 20v2m7.778-2.22l1.415 1.415"
              />
            </svg>
            <div>
              <p className="font-semibold text-sm sm:text-base">
                50% Off Team Plan – Ends in {daysLeft} Days!
              </p>
              <p className="text-xs text-white/80 mt-0.5">
                {slotsTaken}/100 Slots Claimed • Only {slotsLeft} left
              </p>
            </div>
          </div>

          {/* Right: CTA + Close Button */}
          <div className="flex items-center gap-3">
            <button
              onClick={handleBookDemoClick}
              className="inline-flex items-center px-4 py-2 rounded-full bg-white text-blue-700 text-sm font-medium hover:bg-gray-100 transition-all duration-200 shadow-md"
            >
              Learn More
            </button>
            <button
              onClick={() => setIsVisible(false)}
              className="text-white/80 hover:text-white"
            >
              <svg
                className="h-5 w-5"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Progress Bar */}
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-white/20">
        <div
          className="h-full bg-white/40 transition-all duration-1000 ease-out"
          style={{ width: `${progressPercent}%` }}
        />
      </div>
    </div>
  );
};

// ---------------------------------------------------------------------------
// END PROMO BANNER CODE
// ---------------------------------------------------------------------------

export default MeetingRouter;
