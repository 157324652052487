import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  DocumentTextIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  XIcon,
  SearchIcon,
  CheckIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  SparklesIcon,
  PencilIcon,
  ExclamationIcon,
  CalendarIcon,
  ClockIcon,
  UserCircleIcon,
  ExternalLinkIcon,
  RefreshIcon,
  FolderIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import { format, parseISO } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { Dialog, Transition } from "@headlessui/react";

/**
 * MeetingWorkflowGoogleDoc Component
 *
 * A conversational UI for configuring Google Doc automations.
 * This component handles the Google Doc–specific configuration flow after
 * a user has selected the "Google Doc" automation type.
 */

/* ----------------------------------------------------------------------------
   1) HELPER FUNCTIONS
   -------------------------------------------------------------------------- */

/** Formats a date string as "MMM d, yyyy" or returns raw input on error. */
const formatDate = (dateString) => {
  if (!dateString) return "";
  try {
    return format(parseISO(dateString), "MMM d, yyyy");
  } catch (e) {
    return dateString;
  }
};

/** Converts a duration in seconds to a human-readable string. */
const formatDuration = (seconds) => {
  if (!seconds) return "";
  const minutes = Math.round(seconds / 60);
  if (minutes < 60) {
    return `${minutes} min${minutes !== 1 ? "s" : ""}`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMins = minutes % 60;
  return `${hours} hr${hours !== 1 ? "s" : ""}${
    remainingMins > 0
      ? ` ${remainingMins} min${remainingMins !== 1 ? "s" : ""}`
      : ""
  }`;
};

/** Returns a comma-separated list of unique attendee names/emails. */
const formatAttendees = (attendees) => {
  if (!attendees || !Array.isArray(attendees)) return "";
  const names = attendees
    .map((attendee) => {
      if (typeof attendee === "string") return attendee;
      return attendee.name || attendee.email || "";
    })
    .filter(Boolean);
  return [...new Set(names)].join(", ");
};

/* ----------------------------------------------------------------------------
   2) MAIN COMPONENT
   -------------------------------------------------------------------------- */

export default function MeetingWorkflowGoogleDoc({
  initialData = {},
  onDataChange,
  onComplete,
  onCancel,
  userEmail = "",
  meetingTypeName = "Meeting",
  meetingTypeId = null, // Added meetingTypeId prop to match other components
}) {
  // --------------------------
  // State management
  // --------------------------
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [workflowComplete, setWorkflowComplete] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Google Doc configuration state
  const [docName, setDocName] = useState(
    initialData.name || `${meetingTypeName} Google Doc`
  );
  const [googleDriveConnected, setGoogleDriveConnected] = useState(false);
  const [checkingDriveStatus, setCheckingDriveStatus] = useState(false);

  // Google Picker / Access Token state
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const [selectedFolder, setSelectedFolder] = useState(
    initialData.folderId || ""
  );
  const [folderName, setFolderName] = useState(initialData.folderName || "");
  const [folderError, setFolderError] = useState("");

  const [docPrompt, setDocPrompt] = useState(initialData.prompt || "");

  // Test meeting state
  const [meetingModalOpen, setMeetingModalOpen] = useState(false);
  const [testSearchTerm, setTestSearchTerm] = useState("");
  const [testSearchType, setTestSearchType] = useState("quick");
  const [testMeetings, setTestMeetings] = useState([]);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [selectedTestMeeting, setSelectedTestMeeting] = useState(null);

  // Preview state
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewError, setPreviewError] = useState("");
  const [previewResult, setPreviewResult] = useState(null);

  // API base URL
  const API_BASE = "https://backend.scribbl.co";

  // Refs for focusing inputs
  const inputRefs = useRef([]);
  const contentRef = useRef(null);
  const searchInputRef = useRef(null);

  // --------------------------
  // Question definitions
  // --------------------------
  const questions = [
    {
      id: "gdoc-name",
      title: "What do you want to name this Google Doc automation?",
      subtitle: "Give your Google Doc automation a descriptive name",
      validate: (value) => value && value.trim().length > 0,
      errorMessage: "Please provide a name for your Google Doc automation",
      inputType: "text",
      value: docName,
      onChange: setDocName,
      placeholder: `${meetingTypeName} Google Doc`,
    },
    {
      id: "google-drive-connection",
      title: "Connect to your Google Drive",
      subtitle:
        "Select the folder where your meeting documents will be created",
      inputType: "googleDriveConnection",
      validate: () => googleDriveConnected && selectedFolder.trim().length > 0,
      errorMessage: "Please connect to Google Drive and select a folder",
    },
    {
      id: "doc-prompt",
      title: "Customize your Google Doc content",
      subtitle:
        "Define what information should be included in your meeting documents",
      inputType: "docPrompt",
      validate: () => docPrompt.trim().length > 0,
      errorMessage: "Please provide instructions for your Google Doc content",
    },
    {
      id: "test-meeting",
      title: "Select a meeting to test your Google Doc",
      subtitle: "See how your document would look based on a real meeting",
      inputType: "testMeeting",
      validate: () => true, // Optional step
    },
    {
      id: "preview-result",
      title: "Review your Google Doc preview",
      subtitle: "This is how your automated document will look",
      inputType: "previewResult",
      validate: () => true,
    },
  ];

  // --------------------------
  // Effects
  // --------------------------

  // 1) On mount, check if user is connected, and load gapi + token
  useEffect(() => {
    checkGoogleDriveStatus();
    loadGooglePicker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 2) Update parent on changes
  useEffect(() => {
    if (onDataChange) {
      onDataChange({
        name: docName,
        prompt: docPrompt,
        folderId: selectedFolder,
        folderName: folderName,
        isGoogleDriveConnected: googleDriveConnected,
      });
    }
  }, [
    docName,
    docPrompt,
    selectedFolder,
    folderName,
    googleDriveConnected,
    onDataChange,
  ]);

  // 3) Focus input when question changes
  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRefs.current[currentQuestion]) {
        inputRefs.current[currentQuestion]?.focus();
      }
    }, 500);

    // Scroll to top of content
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }

    return () => clearTimeout(timer);
  }, [currentQuestion]);

  // 4) Auto-finalize when workflow is complete
  useEffect(() => {
    if (workflowComplete) {
      finalizeAutomationCreation();
    }
  }, [workflowComplete]);

  // --------------------------
  // Server Communication
  // --------------------------

  /**
   * Creates a meeting action on the server
   */
  async function createMeetingAction(actionData) {
    try {
      const response = await fetch(`${API_BASE}/meeting-actions`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(actionData),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to create meeting action: ${response.statusText}`
        );
      }
      return await response.json();
    } catch (error) {
      console.error("Error creating meeting action:", error);
      return null;
    }
  }

  /**
   * finalizeAutomationCreation
   *
   * Gathers the final data from this wizard, creates a meeting action on the server
   * (if possible), then calls onComplete with the new action data.
   */
  async function finalizeAutomationCreation() {
    // Build local data object from user inputs
    const completeData = {
      type: "google_doc",
      name: docName,
      docPrompt: docPrompt,
      folderId: selectedFolder,
      folderName: folderName,
    };

    // If no meetingTypeId was given, we can still pass the data to parent
    // but we won't be able to attach the action to a meeting type on the server.
    if (!meetingTypeId) {
      console.warn(
        "No meetingTypeId provided; cannot attach Google Doc action to a meeting type in the database."
      );
      if (onComplete) {
        onComplete({
          ...completeData,
          error: "No meetingTypeId provided, not created on server",
        });
      }
      return;
    }

    // Attempt to create action on server
    setIsTransitioning(true);
    const payload = {
      meetingTypeID: meetingTypeId,
      name: docName,
      actionType: "google_doc",
      description: `Generate Google Docs for ${meetingTypeName} meetings`,
      customData: JSON.stringify(completeData), // Pass complete config in the expected key
      isGlobal: false, // Since this action is specific to a meeting type
    };

    try {
      const createdAction = await createMeetingAction(payload);
      setIsTransitioning(false);

      // If creation failed, we can still pass local data
      if (!createdAction) {
        console.warn("Creation on server failed; returning local config only.");
        if (onComplete) {
          onComplete({
            ...completeData,
            error: "Action creation failed on server",
          });
        }
        return;
      }

      // If creation succeeded, pass that final action data to parent
      if (onComplete) {
        onComplete({
          ...completeData,
          ...createdAction, // merges server fields (ID, timestamps, etc.)
        });
      }
    } catch (error) {
      console.error("Failed to create action:", error);
      setIsTransitioning(false);

      // Still call onComplete with error state
      if (onComplete) {
        onComplete({
          ...completeData,
          error: error.message || "Unknown error creating action",
        });
      }
    }
  }

  // --------------------------
  // Google Drive / Google Picker
  // --------------------------
  const checkGoogleDriveStatus = async () => {
    setCheckingDriveStatus(true);
    setFolderError("");

    try {
      const resp = await fetch(`${API_BASE}/google-drive/status`, {
        credentials: "include",
      });
      if (!resp.ok) {
        throw new Error("Failed to check Google Drive status");
      }
      const data = await resp.json();
      setGoogleDriveConnected(data.connected || false);
    } catch (err) {
      console.error("Error checking Google Drive status:", err);
      setFolderError("Failed to check Google Drive connection status");
    } finally {
      setCheckingDriveStatus(false);
    }
  };

  const handleConnectGoogleDrive = async () => {
    try {
      const resp = await fetch(`${API_BASE}/google-drive/connect`, {
        credentials: "include",
      });
      if (!resp.ok) {
        throw new Error("Failed to get Google Drive connect URL");
      }
      const data = await resp.json();
      if (data.authUrl) {
        window.open(data.authUrl, "_blank");
      }
    } catch (err) {
      setFolderError("Error connecting to Google Drive: " + err.message);
    }
  };

  const loadGooglePicker = () => {
    const scriptId = "google-api-script";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://apis.google.com/js/api.js";
      script.onload = () => {
        window.gapi.load("picker", {
          callback: () => setPickerApiLoaded(true),
        });
      };
      document.body.appendChild(script);
    } else {
      window.gapi.load("picker", { callback: () => setPickerApiLoaded(true) });
    }
    fetchAccessToken();
  };

  const fetchAccessToken = async () => {
    try {
      const response = await fetch(`${API_BASE}/google-drive/access-token`, {
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch Google Drive access token");
      }
      const data = await response.json();
      setAccessToken(data.accessToken || null);
    } catch (err) {
      setFolderError(
        `Error fetching Google Drive access token: ${err.message}`
      );
    }
  };

  const openGooglePicker = () => {
    if (!pickerApiLoaded) {
      setFolderError("Google Picker API is not loaded yet.");
      return;
    }
    if (!accessToken) {
      setFolderError("No access token available. Please reconnect or refresh.");
      return;
    }

    const docsView = new window.google.picker.DocsView()
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true)
      .setMimeTypes("application/vnd.google-apps.folder");

    const picker = new window.google.picker.PickerBuilder()
      .addView(docsView)
      .setOAuthToken(accessToken)
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);
  };

  const pickerCallback = (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      const doc = data.docs && data.docs[0];
      if (doc) {
        setSelectedFolder(doc.id);
        setFolderName(doc.name);
      }
    }
  };

  // --------------------------
  // Navigation and Actions
  // --------------------------
  const handleNext = () => {
    const currentQ = questions[currentQuestion];
    if (currentQ.validate && !currentQ.validate(currentQ.value)) {
      return;
    }

    // if drive step but not connected
    if (currentQ.id === "google-drive-connection" && !googleDriveConnected) {
      return;
    }
    // if test step
    if (currentQ.id === "test-meeting" && selectedTestMeeting) {
      handleGeneratePreview();
      return;
    }
    if (currentQ.id === "test-meeting" && !selectedTestMeeting) {
      setWorkflowComplete(true);
      return;
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      setWorkflowComplete(true);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
    } else if (onCancel) {
      onCancel();
    }
  };

  const toggleSearchType = () => {
    setTestSearchType((prev) => (prev === "quick" ? "transcript" : "quick"));
  };

  // --------------------------
  // Searching / Test Meeting
  // --------------------------
  const fetchTestMeetings = async (query) => {
    if (!query?.trim()) {
      setTestMeetings([]);
      return;
    }
    setLoadingMeetings(true);
    setSearchError("");

    try {
      const params = new URLSearchParams();
      params.set("view", "all_meetings");
      if (userEmail) {
        params.set("email", userEmail);
      }
      params.set("q", query.trim());
      params.set("searchType", testSearchType);

      const url = `${API_BASE}/enhanced-recording?${params.toString()}`;
      const response = await fetch(url, { credentials: "include" });
      if (!response.ok) {
        throw new Error("Failed to fetch meetings");
      }
      const data = await response.json();
      setTestMeetings(data.data || []);
    } catch (error) {
      console.error("Error fetching meetings:", error);
      setSearchError(error.message || "Failed to fetch meetings");
    } finally {
      setLoadingMeetings(false);
    }
  };

  const handleSelectTestMeeting = (meeting) => {
    setSelectedTestMeeting(meeting);
    setMeetingModalOpen(false);
  };

  // --------------------------
  // Preview
  // --------------------------
  const handleGeneratePreview = async () => {
    if (!selectedTestMeeting || !docPrompt.trim()) {
      return;
    }
    setPreviewLoading(true);
    setPreviewError("");
    setPreviewResult(null);

    try {
      const response = await fetch(`${API_BASE}/actions/test/google-doc`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          meetingID: selectedTestMeeting.ID,
          additionalPrompt: docPrompt,
          folderId: selectedFolder,
        }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to generate preview");
      }
      const data = await response.json();
      setPreviewResult(data.preview || {});
      setCurrentQuestion(4);
    } catch (error) {
      console.error("Error generating preview:", error);
      setPreviewError(error.message || "Failed to generate Google Doc preview");
      setCurrentQuestion(4);
    } finally {
      setPreviewLoading(false);
    }
  };

  const handleAcceptConfig = () => {
    setWorkflowComplete(true);
  };

  // --------------------------
  // Rendering question steps
  // --------------------------
  const renderDocNameInput = (question) => {
    return (
      <div className="mt-6">
        <div className="relative">
          <input
            ref={(el) => (inputRefs.current[currentQuestion] = el)}
            type="text"
            value={question.value}
            onChange={(e) => question.onChange(e.target.value)}
            className="w-full rounded-lg border-2 border-gray-200 shadow-sm px-4 py-3.5 text-base 
              focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
              hover:border-gray-300"
            placeholder={question.placeholder}
            onKeyDown={(e) => e.key === "Enter" && handleNext()}
          />
        </div>

        <div className="mt-4 p-4 bg-brand-turq bg-opacity-5 border border-brand-turq border-opacity-20 rounded-lg">
          <div className="flex items-start">
            <InformationCircleIcon className="h-4 w-4 text-brand-turq mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-sm text-gray-700">
                This name will help you identify this automation in your
                workflow.
              </p>
              <p className="text-xs text-gray-600 mt-2">
                Giving it a descriptive name like "{meetingTypeName} Meeting
                Notes" makes it easy to identify its purpose.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderGoogleDriveConnectionInput = () => {
    return (
      <div className="mt-6 space-y-4">
        <div
          className={
            googleDriveConnected
              ? "p-4 border rounded-lg bg-green-50 border-green-100"
              : "p-4 border rounded-lg bg-yellow-50 border-yellow-100"
          }
        >
          <div className="flex items-start">
            {googleDriveConnected ? (
              <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
            ) : (
              <ExclamationIcon className="h-5 w-5 text-yellow-500 mr-2 flex-shrink-0" />
            )}
            <div>
              <p className="text-sm font-medium text-gray-800">
                {googleDriveConnected
                  ? "Connected to Google Drive"
                  : "Google Drive connection required"}
              </p>
              <p className="text-xs text-gray-600 mt-1">
                {googleDriveConnected
                  ? "Your Google Drive is connected. Choose a folder below."
                  : "To create meeting documents in Google Drive, you need to connect your account first."}
              </p>

              {!googleDriveConnected && (
                <div className="mt-3 flex space-x-3">
                  <button
                    onClick={handleConnectGoogleDrive}
                    disabled={checkingDriveStatus}
                    className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-brand-turq hover:bg-brand-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq transition-colors"
                  >
                    <ExternalLinkIcon className="h-4 w-4 mr-1.5" />
                    Connect to Google Drive
                  </button>

                  <button
                    onClick={checkGoogleDriveStatus}
                    disabled={checkingDriveStatus}
                    className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-xs font-medium rounded shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq transition-colors"
                  >
                    {checkingDriveStatus ? (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-2 h-4 w-4 text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Checking...
                      </>
                    ) : (
                      <>
                        <RefreshIcon className="h-4 w-4 mr-1.5" />
                        Check Again
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {googleDriveConnected && (
          <>
            {folderError && (
              <div className="p-3 bg-red-50 border border-red-100 rounded-md">
                <div className="flex">
                  <ExclamationIcon className="h-5 w-5 text-red-400 mr-2" />
                  <p className="text-sm text-red-600">{folderError}</p>
                </div>
              </div>
            )}

            <div className="p-4 border border-gray-200 bg-white rounded-lg">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Google Drive Folder
              </label>
              <button
                onClick={openGooglePicker}
                className="inline-flex px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq shadow-sm transition-colors duration-150"
              >
                Choose from Drive
              </button>
            </div>

            {selectedFolder && (
              <div className="flex items-center p-3 bg-gray-50 border border-gray-200 rounded-lg mt-3">
                <FolderIcon className="h-5 w-5 text-brand-turq mr-2" />
                <span className="text-sm text-gray-700">
                  Documents will be created in{" "}
                  <span className="font-medium">{folderName}</span>
                </span>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderDocPromptInput = () => {
    return (
      <div className="mt-6 space-y-4">
        <div className="p-4 bg-brand-turq bg-opacity-5 border border-brand-turq border-opacity-20 rounded-lg">
          <div className="flex items-start">
            <InformationCircleIcon className="h-5 w-5 text-brand-turq mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-sm text-gray-700">
                Create instructions for what information should be included in
                your Google Doc after each meeting
              </p>
              <p className="text-xs text-gray-600 mt-1">
                Be specific about the structure, sections, and content you want
                included, such as key decisions, action items, or important
                takeaways.
              </p>
            </div>
          </div>
        </div>

        <div>
          <label
            htmlFor="doc-prompt"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Google Doc Instructions
          </label>
          <textarea
            id="doc-prompt"
            value={docPrompt}
            onChange={(e) => setDocPrompt(e.target.value)}
            rows={8}
            className="w-full border-2 border-gray-200 rounded-lg shadow-sm p-3 text-sm
              focus:ring-brand-turq focus:border-brand-turq"
            placeholder="Write instructions for how the AI should create your Google Docs. For example: 'Create a comprehensive document that summarizes the key points from the meeting, lists all action items with owners and deadlines, and highlights any important decisions made. Format the document with clear section headers, bullet points for readability, and include a table of contents at the beginning.'"
            ref={(el) => (inputRefs.current[currentQuestion] = el)}
          />
        </div>
      </div>
    );
  };

  const renderTestMeeting = () => {
    return (
      <div className="mt-6 space-y-4">
        <div className="p-4 bg-brand-turq bg-opacity-5 border border-brand-turq border-opacity-20 rounded-lg">
          <div className="flex items-start">
            <InformationCircleIcon className="h-5 w-5 text-brand-turq mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-sm text-gray-700">
                Select a past meeting to see how your Google Doc would look.
              </p>
              <p className="text-xs text-gray-600 mt-1">
                This helps ensure your automation will produce the kind of
                documents you want.
              </p>
            </div>
          </div>
        </div>

        {selectedTestMeeting ? (
          <div className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm">
            <div className="flex items-start">
              <div className="flex-shrink-0 h-10 w-10 bg-gray-100 rounded-md flex items-center justify-center text-gray-500">
                <DocumentTextIcon className="h-5 w-5" />
              </div>
              <div className="ml-3 flex-1">
                <div className="flex justify-between">
                  <h4 className="text-sm font-medium text-gray-900">
                    {selectedTestMeeting.meetingTitle || "Untitled Meeting"}
                  </h4>
                  <button
                    onClick={() => setSelectedTestMeeting(null)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <XIcon className="h-4 w-4" />
                  </button>
                </div>
                <p className="text-xs text-gray-500 mt-0.5">
                  {formatDate(selectedTestMeeting.CreatedAt)}
                  {selectedTestMeeting.duration &&
                    ` • ${formatDuration(selectedTestMeeting.duration)}`}
                </p>
                {selectedTestMeeting.attendees?.length > 0 && (
                  <p className="text-xs text-gray-500 mt-0.5">
                    {formatAttendees(selectedTestMeeting.attendees)}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center py-6">
            <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-3">
              <DocumentTextIcon className="h-8 w-8 text-gray-400" />
            </div>
            <p className="text-sm text-gray-600 mb-1">No meeting selected</p>
            <p className="text-xs text-gray-500">
              Select a meeting to see a preview of your Google Doc
            </p>
          </div>
        )}

        <div className="flex justify-center space-x-3 pt-4">
          <motion.button
            whileHover={{ y: -2 }}
            whileTap={{ y: 0 }}
            onClick={() => setMeetingModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg shadow-sm text-gray-700 bg-white hover:bg-gray-50 transition-all duration-200"
          >
            <SearchIcon className="h-4 w-4 mr-1.5" />
            {selectedTestMeeting ? "Change Meeting" : "Select a Meeting"}
          </motion.button>

          {selectedTestMeeting && (
            <motion.button
              whileHover={{ y: -2 }}
              whileTap={{ y: 0 }}
              onClick={handleNext}
              disabled={previewLoading}
              className={
                previewLoading
                  ? "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-gray-400 cursor-not-allowed"
                  : "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-brand-turq hover:bg-brand-green"
              }
            >
              {previewLoading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Generating...
                </>
              ) : (
                <>
                  <SparklesIcon className="h-4 w-4 mr-1.5" />
                  Generate Google Doc Preview
                </>
              )}
            </motion.button>
          )}
        </div>

        <div className="flex justify-center pt-4">
          <button
            onClick={() => {
              setWorkflowComplete(true);
            }}
            className="text-sm text-gray-500 hover:text-brand-turq transition-colors"
          >
            Skip testing and complete setup
          </button>
        </div>
      </div>
    );
  };

  const renderPreviewResult = () => {
    return (
      <div className="mt-6 space-y-4">
        {previewError ? (
          <div className="p-4 bg-red-50 border border-red-100 rounded-lg">
            <div className="flex items-start">
              <ExclamationIcon className="h-5 w-5 text-red-500 mr-2 flex-shrink-0" />
              <div>
                <p className="text-sm font-medium text-red-800">
                  There was an error generating your Google Doc preview
                </p>
                <p className="text-xs text-red-700 mt-1">{previewError}</p>
                <p className="text-xs text-red-700 mt-2">
                  You can try again with a different meeting or adjust your
                  instructions.
                </p>
              </div>
            </div>
          </div>
        ) : previewResult ? (
          <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
            <div className="px-4 py-3 bg-gray-50 border-b border-gray-200">
              <h3 className="text-sm font-medium text-gray-700 flex items-center">
                <DocumentTextIcon className="h-4 w-4 mr-1.5 text-brand-turq" />
                Google Doc Preview
                <span className="ml-2 px-2 py-0.5 text-xs rounded bg-gray-200 text-gray-700">
                  {folderName || "Google Drive"}
                </span>
              </h3>
            </div>
            <div className="p-4">{renderDocPreview(previewResult)}</div>
          </div>
        ) : (
          <div className="text-center py-6">
            <p className="text-sm text-gray-600">No preview available</p>
          </div>
        )}

        <div className="flex justify-center space-x-4 pt-4">
          <button
            onClick={() => setCurrentQuestion(2)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg shadow-sm text-gray-700 bg-white hover:bg-gray-50"
          >
            <PencilIcon className="h-4 w-4 mr-1.5" />
            Edit Instructions
          </button>

          <button
            onClick={handleAcceptConfig}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-brand-turq hover:bg-brand-green"
          >
            <CheckIcon className="h-4 w-4 mr-1.5" />
            Looks Good
          </button>
        </div>
      </div>
    );
  };

  function renderDocPreview(result) {
    if (!result) return null;
    return (
      <div className="space-y-6">
        {/* Document Title */}
        <div className="flex items-center">
          <h3 className="text-xl font-bold text-gray-800">
            {result.title || "Meeting Notes"}
          </h3>
        </div>

        {/* Simulated Google Doc UI */}
        <div className="border border-gray-200 rounded-lg shadow-sm overflow-hidden">
          {/* Doc Header */}
          <div className="bg-white border-b border-gray-200 px-6 py-3 flex items-center">
            <DocumentTextIcon className="h-5 w-5 text-brand-turq mr-2" />
            <span className="text-sm font-medium text-gray-700">
              Google Document Preview
            </span>
          </div>

          {/* Doc Content */}
          <div className="bg-white px-8 py-6 max-h-[450px] overflow-y-auto">
            {/* Paper-like styling */}
            <div className="bg-white rounded shadow-sm border border-gray-100 p-6 mx-auto max-w-[750px]">
              {/* Title */}
              <div className="mb-6 pb-2 border-b border-gray-100">
                <h1 className="text-2xl font-bold text-gray-800">
                  {result.title || "Meeting Notes"}
                </h1>
              </div>

              {/* Table of Contents */}
              {result.sections && result.sections.length > 0 && (
                <div className="mb-6 pb-3 border-b border-gray-100">
                  <h2 className="text-base font-bold text-gray-700 mb-2">
                    Table of Contents
                  </h2>
                  <ul className="list-inside space-y-1 pl-2">
                    {result.sections?.map((section, idx) => (
                      <li
                        key={idx}
                        className="text-sm text-brand-turq flex items-center"
                      >
                        <div className="w-1 h-1 bg-brand-turq rounded-full mr-2"></div>
                        <span>{section.title}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Document Sections */}
              <div className="space-y-6">
                {result.sections?.map((section, idx) => (
                  <div key={idx} className="pb-4">
                    <h2 className="text-lg font-semibold text-gray-800 mb-2">
                      {section.title}
                    </h2>
                    <div className="text-sm text-gray-700 whitespace-pre-line">
                      {section.preview ||
                        "Content will be generated based on meeting transcript..."}
                    </div>
                    {idx < 1 && (
                      <div className="text-xs text-gray-400 mt-2 italic flex items-center">
                        <InformationCircleIcon className="h-3 w-3 mr-1" />
                        Preview showing partial content
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* Action Items */}
              {result.actionItems && result.actionItems.length > 0 && (
                <div className="mt-6 pt-3 border-t border-gray-100">
                  <h2 className="text-lg font-semibold text-gray-800 mb-3">
                    Action Items
                  </h2>
                  <ul className="space-y-3">
                    {result.actionItems.slice(0, 3).map((item, idx) => (
                      <li
                        key={idx}
                        className="bg-gray-50 rounded-md p-3 border border-gray-100"
                      >
                        <div className="font-medium text-gray-800">
                          {item.title}
                        </div>
                        <div className="flex flex-wrap gap-3 mt-1">
                          {item.assignee && (
                            <div className="text-xs bg-brand-turq bg-opacity-10 text-brand-turq px-2 py-1 rounded flex items-center">
                              <UserCircleIcon className="h-3 w-3 mr-1" />
                              {item.assignee}
                            </div>
                          )}
                          {item.dueDate && (
                            <div className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded flex items-center">
                              <CalendarIcon className="h-3 w-3 mr-1" />
                              {item.dueDate}
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                    {result.actionItems.length > 3 && (
                      <li className="text-xs text-gray-500 italic mt-1 flex items-center">
                        <span className="bg-gray-200 text-gray-600 rounded-full w-5 h-5 inline-flex items-center justify-center mr-2">
                          +{result.actionItems.length - 3}
                        </span>
                        More action items will be included in the document
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Document Info */}
        <div className="flex flex-wrap items-center gap-3 text-xs text-gray-500">
          <div className="flex items-center bg-gray-100 rounded-full px-3 py-1">
            <FolderIcon className="h-3 w-3 mr-1 text-gray-500" />
            {result.folderName || "Google Drive"}
          </div>
          <div className="flex items-center">
            <span className="bg-green-100 rounded-full w-2 h-2 mr-1"></span>
            Content will be formatted as a Google Doc
          </div>
        </div>
      </div>
    );
  }

  const renderComplete = () => {
    return (
      <div className="mt-6 text-center">
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5, type: "spring" }}
          className="w-20 h-20 bg-brand-turq bg-opacity-10 rounded-full flex items-center justify-center mx-auto mb-6"
        >
          <CheckCircleIcon className="h-10 w-10 text-brand-turq" />
        </motion.div>

        <motion.h3
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-2xl font-medium text-gray-900"
        >
          Your Google Doc automation is ready!
        </motion.h3>

        <motion.p
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="text-base text-gray-600 mt-3 max-w-md mx-auto"
        >
          You've successfully set up automated Google Docs creation for your{" "}
          {meetingTypeName.toLowerCase()} meetings.
        </motion.p>

        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="mt-6 text-sm text-gray-600"
        >
          <p>
            This automation will create documents in{" "}
            <span className="font-medium">
              {folderName || "your Google Drive"}
            </span>{" "}
            after each meeting.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="mt-8"
        >
          <button
            onClick={() => {
              if (onComplete) {
                onComplete({
                  name: docName,
                  type: "google_doc",
                  docPrompt: docPrompt,
                  folderId: selectedFolder,
                  folderName: folderName,
                });
              }
            }}
            className="px-5 py-2.5 bg-brand-turq text-white rounded-lg hover:bg-brand-green transition-colors shadow-sm"
          >
            <DocumentTextIcon className="inline-block h-4 w-4 mr-2 -mt-0.5" />
            Finish Setup
          </button>
        </motion.div>
      </div>
    );
  };

  // --------------------------
  // Meeting Selection Modal
  // --------------------------
  const renderMeetingModal = () => {
    return (
      <Transition.Root show={meetingModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-50 backdrop-blur-sm"
          onClose={() => setMeetingModalOpen(false)}
        >
          <div className="flex items-center justify-center min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden inline-block align-middle h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 translate-y-4 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-4 scale-95"
            >
              <div className="inline-block w-full max-w-3xl bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all align-middle">
                <div className="flex flex-col h-[650px]">
                  {/* Header */}
                  <div className="px-6 pt-6 pb-4 border-b border-gray-200">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 bg-brand-turq bg-opacity-10 rounded-full p-2">
                        <SearchIcon className="h-6 w-6 text-brand-turq" />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium text-gray-900">
                          Select a meeting to test
                        </h3>
                        <p className="text-sm text-gray-500 mt-1">
                          Find a meeting to see how your Google Doc will look
                        </p>
                      </div>
                      <button
                        onClick={() => setMeetingModalOpen(false)}
                        className="ml-auto bg-white rounded-full p-1 hover:bg-gray-100 transition-colors"
                      >
                        <XIcon className="h-5 w-5 text-gray-400" />
                      </button>
                    </div>
                  </div>

                  {/* Search controls */}
                  <div className="p-4 border-b border-gray-200">
                    <div className="relative flex items-center">
                      <div className="relative flex-1">
                        <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                        <input
                          ref={searchInputRef}
                          type="text"
                          value={testSearchTerm}
                          onChange={(e) => setTestSearchTerm(e.target.value)}
                          placeholder="Search by title, attendee, or content"
                          className="w-full pl-10 pr-10 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:ring-brand-turq focus:border-brand-turq transition-all"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              fetchTestMeetings(testSearchTerm);
                            }
                          }}
                        />
                        {testSearchTerm && (
                          <button
                            onClick={() => setTestSearchTerm("")}
                            className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600"
                          >
                            <XIcon className="w-4 h-4" />
                          </button>
                        )}
                      </div>

                      <div className="flex ml-3 space-x-2">
                        <button
                          onClick={toggleSearchType}
                          className={`px-3 py-2 text-xs font-medium rounded-md transition-colors ${
                            testSearchType === "transcript"
                              ? "bg-brand-turq bg-opacity-10 text-brand-turq border border-brand-turq border-opacity-20"
                              : "border border-gray-200 text-gray-600 hover:bg-gray-50"
                          }`}
                        >
                          {testSearchType === "transcript"
                            ? "Transcript search"
                            : "Basic search"}
                        </button>

                        <button
                          onClick={() => fetchTestMeetings(testSearchTerm)}
                          disabled={!testSearchTerm.trim()}
                          className={`px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                            testSearchTerm.trim()
                              ? "text-white bg-brand-turq hover:bg-brand-green"
                              : "text-gray-400 bg-gray-100 cursor-not-allowed"
                          }`}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Results area */}
                  <div className="flex-1 overflow-y-auto">
                    {searchError ? (
                      <div className="p-4 bg-red-50 m-4 rounded-lg border border-red-100 flex items-start">
                        <ExclamationIcon className="h-5 w-5 text-red-500 mr-2 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-red-700">
                            Error searching meetings
                          </p>
                          <p className="text-xs text-red-600 mt-1">
                            {searchError}
                          </p>
                          <button
                            onClick={() => fetchTestMeetings(testSearchTerm)}
                            className="mt-2 px-3 py-1 text-xs font-medium bg-white border border-red-200 rounded-md text-red-600 hover:bg-red-50"
                          >
                            Try again
                          </button>
                        </div>
                      </div>
                    ) : loadingMeetings ? (
                      <div className="flex flex-col items-center justify-center h-48 text-gray-500">
                        <svg
                          className="animate-spin h-8 w-8 text-brand-turq mb-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <p className="text-sm">Searching for meetings...</p>
                      </div>
                    ) : testMeetings.length === 0 ? (
                      <div className="flex flex-col items-center justify-center h-48 text-center px-4">
                        {testSearchTerm ? (
                          <>
                            <SearchIcon className="h-10 w-10 text-gray-300 mb-3" />
                            <h3 className="text-base font-medium text-gray-700">
                              No meetings found
                            </h3>
                            <p className="text-sm text-gray-500 mt-1 max-w-md">
                              Try different search terms or check your spelling.
                              You can also try searching for specific content in
                              the transcript.
                            </p>
                          </>
                        ) : (
                          <>
                            <DocumentTextIcon className="h-10 w-10 text-gray-300 mb-3" />
                            <h3 className="text-base font-medium text-gray-700">
                              Search for meetings
                            </h3>
                            <p className="text-sm text-gray-500 mt-1 max-w-md">
                              Enter keywords above to find meetings to test your
                              Google Doc automation
                            </p>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="divide-y divide-gray-100">
                        <div className="px-4 py-2 bg-gray-50 border-b border-gray-200">
                          <div className="flex justify-between items-center">
                            <h4 className="text-xs font-medium text-gray-700">
                              Search results
                            </h4>
                            <span className="text-xs text-gray-500">
                              {testMeetings.length} meeting
                              {testMeetings.length !== 1 ? "s" : ""} found
                            </span>
                          </div>
                        </div>
                        <ul>
                          {testMeetings.map((meeting) => (
                            <li
                              key={meeting.ID}
                              className="border-b border-gray-100 last:border-b-0"
                            >
                              <button
                                className="w-full px-4 py-3 text-left flex items-center hover:bg-gray-50 transition-colors"
                                onClick={() => handleSelectTestMeeting(meeting)}
                              >
                                <div className="flex-1 min-w-0 pr-3">
                                  <div className="font-medium text-sm text-gray-800">
                                    {meeting.meetingTitle || "Untitled Meeting"}
                                  </div>
                                  <div className="flex flex-wrap text-xs text-gray-500 mt-1">
                                    <div className="flex items-center mr-3">
                                      <CalendarIcon className="h-3 w-3 mr-1" />
                                      {formatDate(meeting.CreatedAt)}
                                    </div>
                                    {meeting.duration && (
                                      <div className="flex items-center">
                                        <ClockIcon className="h-3 w-3 mr-1" />
                                        {formatDuration(meeting.duration)}
                                      </div>
                                    )}
                                  </div>
                                  {meeting.attendees &&
                                    meeting.attendees.length > 0 && (
                                      <div className="text-xs text-gray-500 mt-0.5 flex items-center">
                                        <UserCircleIcon className="h-3 w-3 mr-1" />
                                        <span className="truncate max-w-xs">
                                          {formatAttendees(meeting.attendees)}
                                        </span>
                                      </div>
                                    )}
                                </div>
                                <div className="flex-shrink-0 w-8 h-8 rounded-full border border-brand-turq border-opacity-50 bg-white flex items-center justify-center">
                                  <CheckIcon className="h-4 w-4 text-brand-turq" />
                                </div>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* Footer */}
                  <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end items-center">
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 transition-colors"
                      onClick={() => setMeetingModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  // --------------------------
  // Render current question
  // --------------------------
  const renderQuestionInput = (question) => {
    switch (question.inputType) {
      case "text":
        return renderDocNameInput(question);
      case "googleDriveConnection":
        return renderGoogleDriveConnectionInput();
      case "docPrompt":
        return renderDocPromptInput();
      case "testMeeting":
        return renderTestMeeting();
      case "previewResult":
        return renderPreviewResult();
      case "complete":
        return renderComplete();
      default:
        return null;
    }
  };

  const renderCurrentQuestion = () => {
    const question = questions[currentQuestion];
    return (
      <motion.div
        key={question.id}
        className="h-full flex flex-col"
        initial={{ opacity: 0, y: 15 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -15 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex-1">
          <div className="mb-4 text-center">
            <motion.h3
              className="text-2xl font-medium text-gray-900 tracking-tight"
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
            >
              {question.title}
            </motion.h3>
            {question.subtitle && (
              <motion.p
                className="mt-2 text-gray-600"
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                {question.subtitle}
              </motion.p>
            )}
          </div>

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="overflow-y-auto"
          >
            {workflowComplete
              ? renderComplete()
              : renderQuestionInput(question)}
          </motion.div>
        </div>
      </motion.div>
    );
  };

  // --------------------------
  // Main render
  // --------------------------
  return (
    <div className="h-full flex flex-col">
      <div
        ref={contentRef}
        className="flex-1 bg-white rounded-t-xl shadow-sm border border-gray-200 border-b-0 overflow-hidden"
      >
        <div className="p-6 h-full flex flex-col relative">
          {!workflowComplete && currentQuestion < questions.length - 1 && (
            <div className="flex items-center justify-center mb-6">
              <div className="bg-gray-100 rounded-full h-1.5 w-64 overflow-hidden">
                <motion.div
                  className="h-full bg-brand-turq rounded-full"
                  initial={{ width: "0%" }}
                  animate={{
                    width: `${
                      ((currentQuestion + 1) / (questions.length - 1)) * 100
                    }%`,
                  }}
                  transition={{ duration: 0.3 }}
                />
              </div>
              <div className="ml-3 text-xs text-gray-500 font-medium">
                {currentQuestion + 1}/{questions.length - 1}
              </div>
            </div>
          )}

          <div className="flex-1 overflow-y-auto">
            <AnimatePresence mode="wait">
              {renderCurrentQuestion()}
            </AnimatePresence>
          </div>
        </div>
      </div>

      {!workflowComplete &&
        currentQuestion < questions.length - 1 &&
        !["testMeeting", "previewResult"].includes(
          questions[currentQuestion].inputType
        ) && (
          <div className="bg-white rounded-b-xl border border-t-0 border-gray-200 shadow-sm">
            <div className="px-6 py-4 flex justify-between items-center bg-gray-50 bg-opacity-70">
              <button
                onClick={handlePrevious}
                className="flex items-center px-4 py-2 text-brand-turq rounded-lg hover:bg-brand-turq hover:bg-opacity-5 transition-colors"
              >
                <ArrowLeftIcon className="h-4 w-4 mr-1.5" />
                <span className="font-medium">Back</span>
              </button>

              <button
                onClick={handleNext}
                disabled={
                  (questions[currentQuestion].validate &&
                    !questions[currentQuestion].validate(
                      questions[currentQuestion].value
                    )) ||
                  isTransitioning ||
                  (currentQuestion === 1 && !googleDriveConnected)
                }
                className={
                  questions[currentQuestion].validate &&
                  !questions[currentQuestion].validate(
                    questions[currentQuestion].value
                  )
                    ? "inline-flex items-center px-5 py-2.5 rounded-lg font-medium bg-gray-200 text-gray-400 cursor-not-allowed"
                    : isTransitioning
                    ? "inline-flex items-center px-5 py-2.5 rounded-lg font-medium bg-gray-200 text-gray-400 cursor-wait"
                    : currentQuestion === 1 && !googleDriveConnected
                    ? "inline-flex items-center px-5 py-2.5 rounded-lg font-medium bg-gray-200 text-gray-400 cursor-not-allowed"
                    : "inline-flex items-center px-5 py-2.5 rounded-lg font-medium bg-brand-turq text-white hover:bg-brand-green shadow-sm transition-colors"
                }
              >
                {isTransitioning ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  <>
                    Continue
                    <ArrowRightIcon className="h-5 w-5 ml-2" />
                  </>
                )}
              </button>
            </div>
          </div>
        )}

      {!workflowComplete &&
        ["testMeeting", "previewResult"].includes(
          questions[currentQuestion].inputType
        ) && (
          <div className="bg-white rounded-b-xl border border-t-0 border-gray-200 shadow-sm">
            <div className="px-6 py-4 flex justify-start items-center bg-gray-50 bg-opacity-70">
              <button
                onClick={handlePrevious}
                className="flex items-center px-4 py-2 text-brand-turq rounded-lg hover:bg-brand-turq hover:bg-opacity-5 transition-colors"
              >
                <ArrowLeftIcon className="h-4 w-4 mr-1.5" />
                <span className="font-medium">Back</span>
              </button>
            </div>
          </div>
        )}

      {renderMeetingModal()}
    </div>
  );
}
