import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { PencilIcon } from "@heroicons/react/outline";
import EditActionItemDrawer from "./recording/EditActionItemDrawer";
import MondayActionItemModal from "./recording/MondayActionItemModal";
import AsanaActionItemModal from "./recording/AsanaActionItemModal";

var moment = require("moment");

const ActionItemsTab = ({
  actionItems = [],
  setActionItems,
  meetingUUID,
  user,
  isPublic,
  onTimeJump, // <-- NEW: function from parent to jump video/transcript
}) => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedActionItem, setSelectedActionItem] = useState(null);
  const [mondayModalOpen, setMondayModalOpen] = useState(false);
  const [asanaModalOpen, setAsanaModalOpen] = useState(false);
  const [itemForIntegration, setItemForIntegration] = useState(null);
  const [hasMondayIntegration, setHasMondayIntegration] = useState(false);
  const [hasAsanaIntegration, setHasAsanaIntegration] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState("Copy Items");

  useEffect(() => {
    checkIntegrations();
  }, []);

  const checkIntegrations = async () => {
    try {
      const [mondayRes, asanaRes] = await Promise.all([
        fetch("https://backend.scribbl.co/monday/status", {
          credentials: "include",
        }),
        fetch("https://backend.scribbl.co/asana/status", { credentials: "include" }),
      ]);

      const mondayData = await mondayRes.json();
      const asanaData = await asanaRes.json();

      setHasMondayIntegration(mondayData.connected);
      setHasAsanaIntegration(asanaData.connected);
    } catch (error) {
      console.error("Error checking integrations:", error);
    }
  };

  const formatTimeFromSeconds = (seconds) => {
    return moment
      .utc(moment.duration(seconds, "seconds").asMilliseconds())
      .format(
        moment.duration(seconds, "seconds").asHours() >= 1 ? "H:mm:ss" : "m:ss"
      );
  };

  const formatDueDate = (dateString) => {
    if (!dateString) return "Not specified";
    const date = moment.utc(dateString);
    const today = moment.utc();
    if (date.isSame(today, "day")) return "Today";
    if (date.isSame(today.add(1, "day"), "day")) return "Tomorrow";
    return date.format("MM/DD/YY");
  };

  const handleCopyItems = async () => {
    const formattedText = actionItems
      .filter((item) => item.title && item.title.trim().length > 0)
      .map(
        (item) =>
          `- ${item.title} (${formatTimeFromSeconds(item.start_time)})\n` +
          `  Assigned to: ${item.assignee || "No assignee"}\n` +
          `  Due: ${formatDueDate(item.due_date)}`
      )
      .join("\n\n");

    try {
      await navigator.clipboard.writeText(formattedText);
      setCopyButtonText("Copied!");
      setTimeout(() => setCopyButtonText("Copy Items"), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
      setCopyButtonText("Failed to copy");
      setTimeout(() => setCopyButtonText("Copy Items"), 2000);
    }
  };

  const hasTeamSubscription = () => {
    return (
      user?.subscription?.accountType === 3 ||
      user?.subscription?.accountType === 4
    );
  };

  const handleOpenMondayModal = (item) => {
    if (!isPublic) {
      setItemForIntegration(item);
      setMondayModalOpen(true);
    }
  };

  const handleOpenAsanaModal = (item) => {
    if (!isPublic) {
      setItemForIntegration(item);
      setAsanaModalOpen(true);
    }
  };

  const handleActionItemChange = (updatedItems) => {
    setActionItems(updatedItems);
  };

  return (
    <div className="bg-white border border-gray-200 rounded-xl p-4 shadow-md h-full flex flex-col">
      <div className="flex items-center justify-between mb-4">
        <h2 className="font-semibold text-lg text-gray-800">Action Items</h2>
        <div className="flex gap-2">
          <button
            onClick={handleCopyItems}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50 transition-colors"
          >
            {copyButtonText}
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto space-y-3">
        {actionItems &&
        actionItems.filter(
          (item) =>
            item.title &&
            item.title.trim().length > 0 &&
            item.DeletedAt === null
        ).length > 0 ? (
          actionItems
            .filter(
              (item) =>
                item.title &&
                item.title.trim().length > 0 &&
                item.DeletedAt === null
            )
            .map((item) => (
              <div
                key={item.ID}
                className="group flex items-start w-full p-3 rounded-md bg-white hover:bg-gray-50 cursor-pointer border border-gray-200"
                onClick={() => {
                  // Only jump if we have onTimeJump available and a valid start_time
                  if (
                    typeof onTimeJump === "function" &&
                    item.start_time != null
                  ) {
                    onTimeJump(item.start_time);
                  }
                }}
              >
                <div className="flex-grow min-w-0">
                  <div className="text-sm text-gray-900">
                    {item.title} ({formatTimeFromSeconds(item.start_time)})
                  </div>
                  <div className="mt-1 text-xs text-gray-500">
                    <span>Assignee: {item.assignee || "No assignee"}</span>
                    <span className="mx-2">•</span>
                    <span>Due: {formatDueDate(item.due_date)}</span>
                  </div>
                </div>

                {!isPublic && (
                  <div
                    className="flex gap-2 ml-2"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <button
                      onClick={() => {
                        setSelectedActionItem(item);
                        setIsDrawerOpen(true);
                      }}
                      className="p-1.5 text-gray-500 hover:text-brand-turq rounded-md transition-all duration-200 hover:-translate-y-0.5 hover:bg-gray-100 opacity-0 group-hover:opacity-100"
                      title="Edit Action Item"
                    >
                      <PencilIcon className="h-4 w-4" />
                    </button>

                    {/* Monday.com integration button */}
                    <button
                      onClick={() => {
                        if (hasMondayIntegration && hasTeamSubscription()) {
                          handleOpenMondayModal(item);
                        } else {
                          navigate("/integrations");
                        }
                      }}
                      className="p-1.5 text-gray-500 hover:text-brand-turq rounded-md transition-all duration-200 hover:-translate-y-0.5 hover:bg-gray-100 opacity-0 group-hover:opacity-100"
                      title="Create Monday.com Item"
                    >
                      {/* Monday.com Logo SVG */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 64 64"
                      >
                        <g transform="matrix(3.208255 0 0 3.208255 -35.559129 -63.587202)">
                          <path
                            d="M13.513 35.76a2.433 2.433 0 0 1-2.059-3.723l4.377-6.99a2.432 2.432 0 1 1 4.123 2.582l-4.378 6.99a2.43 2.43 0 0 1-2.063 1.141z"
                            fill="#ff3d57"
                          />
                          <path
                            d="M21.056 35.76a2.433 2.433 0 0 1-2.063-3.723l4.38-6.99a2.432 2.432 0 1 1 4.117 2.582l-4.372 6.99a2.43 2.43 0 0 1-2.063 1.141z"
                            fill="#ffcb00"
                          />
                          <ellipse
                            ry="2.375"
                            rx="2.436"
                            cy="33.384"
                            cx="28.597"
                            fill="#00d647"
                          />
                        </g>
                      </svg>
                    </button>

                    {/* Asana integration button */}
                    <button
                      onClick={() => {
                        if (hasAsanaIntegration && hasTeamSubscription()) {
                          handleOpenAsanaModal(item);
                        } else {
                          navigate("/integrations");
                        }
                      }}
                      className="p-1.5 text-gray-500 hover:text-brand-turq rounded-md transition-all duration-200 hover:-translate-y-0.5 hover:bg-gray-100 opacity-0 group-hover:opacity-100"
                      title="Create Asana Task"
                    >
                      {/* Asana Logo SVG */}
                      <svg
                        viewBox="781.361 0 944.893 873.377"
                        className="h-4 w-4"
                      >
                        <defs>
                          <radialGradient
                            id="asanaGradient"
                            cx="943.992"
                            cy="1221.416"
                            r=".663"
                            gradientTransform="matrix(944.8934 0 0 -873.3772 -890717.875 1067234.75)"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0" stopColor="#ffb900" />
                            <stop offset=".6" stopColor="#f95d8f" />
                            <stop offset=".999" stopColor="#f95353" />
                          </radialGradient>
                        </defs>
                        <path
                          fill="url(#asanaGradient)"
                          d="M1520.766 462.371c-113.508 0-205.508 92-205.508 205.488 0 113.499 92 205.518 205.508 205.518 113.489 0 205.488-92.019 205.488-205.518 0-113.488-91.999-205.488-205.488-205.488zm-533.907.01c-113.489.01-205.498 91.99-205.498 205.488 0 113.489 92.009 205.498 205.498 205.498 113.498 0 205.508-92.009 205.508-205.498 0-113.499-92.01-205.488-205.518-205.488h.01zm472.447-256.883c0 113.489-91.999 205.518-205.488 205.518-113.508 0-205.508-92.029-205.508-205.518S1140.31 0 1253.817 0c113.489 0 205.479 92.009 205.479 205.498h.01z"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            ))
        ) : (
          <div className="text-sm text-gray-500 text-center py-4">
            No action items found for this meeting
          </div>
        )}
      </div>

      {/* Modals and Drawers */}
      <EditActionItemDrawer
        isOpen={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedActionItem(null);
        }}
        actionItem={selectedActionItem}
        meetingUUID={meetingUUID}
        onActionItemChange={handleActionItemChange}
        currentActionItems={actionItems}
      />

      <MondayActionItemModal
        isOpen={mondayModalOpen}
        onClose={() => {
          setMondayModalOpen(false);
          setItemForIntegration(null);
        }}
        actionItem={itemForIntegration}
        onSuccess={() => {
          setMondayModalOpen(false);
          setItemForIntegration(null);
        }}
      />

      <AsanaActionItemModal
        isOpen={asanaModalOpen}
        onClose={() => {
          setAsanaModalOpen(false);
          setItemForIntegration(null);
        }}
        actionItem={itemForIntegration}
        onSuccess={() => {
          setAsanaModalOpen(false);
          setItemForIntegration(null);
        }}
      />
    </div>
  );
};

export default ActionItemsTab;
