import React, { Fragment, useEffect, useState } from "react";
import {
  CheckIcon,
  XIcon,
  ChevronLeftIcon,
  PlusIcon,
  DocumentTextIcon,
  LightningBoltIcon,
  MailIcon,
  TrashIcon,
  LockClosedIcon,
  CalendarIcon,
  CheckCircleIcon,
  PencilIcon,
  ExclamationIcon,
  ChatAlt2Icon,
  UserGroupIcon,
  PencilAltIcon,
} from "@heroicons/react/outline";

// Child components (refactored to handle their own data logic)
import ConversationalMeetingTypeStep from "./MeetingWorkflows/CreateMeetingType";
import ConversationalNoteTemplateStep from "./MeetingWorkflows/CreateNoteTemplate";
import EditMeetingTypeStep from "./EditMeetingType";
import EditNoteTemplateStep from "./EditNoteTemplate";
import { DefaultNoteTemplateScreen } from "./EditNoteTemplate";
import MeetingWorkflowAutomationStep from "./MeetingWorkflowAutomationStep";
import { motion } from "framer-motion";
import EditFollowUpEmail from "./EditFollowUpEmail";
import EditSlackAutomation from "./EditSlackStep";
import { Dialog, Transition } from "@headlessui/react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import EditGoogleDocAutomation from "./EditGoogleDoc";

// Utility function
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MeetingWorkflowEdit({
  workflowId = null,
  workflowData = null, // Add this prop to receive the complete data
  onSave,
  onCancel,
  userEmail,
}) {
  // -------------------------------------------------------------------
  // STEP STATE
  // -------------------------------------------------------------------
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  // Holds the created/updated MeetingType and Note Template
  const [meetingType, setMeetingType] = useState(null);
  const [noteTemplate, setNoteTemplate] = useState(null);

  // We store whether the meeting type is using default (regular) notes
  const [useRegularMeetingNotes, setUseRegularMeetingNotes] = useState(false);
  const [isDirectNavigation, setIsDirectNavigation] = useState(false);
  const [showWorkflowCompleteScreen, setShowWorkflowCompleteScreen] =
    useState(false);

  const [originalActionData, setOriginalActionData] = useState(null);

  // Delete confirmation modal state
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [stepToDelete, setStepToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  // Step definitions
  const [workflowSteps, setWorkflowSteps] = useState([
    {
      ID: "meeting-type",
      type: "meeting-type",
      name: "Meeting Type",
      description: "Define detection rules & parameters",
      color: "bg-brand-turq",
      icon: "meeting",
      isRequired: true,
      status: "pending",
    },
    {
      ID: "note-template",
      type: "note-template",
      name: "Meeting Notes",
      description: "Structure how you capture notes",
      color: "bg-brand-red",
      icon: "note-template",
      isRequired: true,
      status: "pending",
    },
    // Additional steps (automation, etc.) get added dynamically
  ]);

  // -------------------------------------------------------------------
  // FETCH & INIT
  // -------------------------------------------------------------------
  useEffect(() => {
    if (workflowData && workflowId) {
      // Use the provided data instead of fetching
      initializeFromProvidedData(workflowData);
    } else if (workflowId) {
      // Fallback to fetching if no data provided
      fetchWorkflowDataIfNeeded();
    }
  }, [workflowId, workflowData]);

  const initializeFromProvidedData = (data) => {
    if (!data) return;

    // Set the meeting type
    const fetchedMeetingType = data.meetingType;
    setMeetingType(fetchedMeetingType);

    // Mark the meeting-type step complete
    markStepComplete("meeting-type");

    // Handle regular vs custom notes
    if (fetchedMeetingType.regularMeetingNotes) {
      setUseRegularMeetingNotes(true);
      markStepComplete("note-template");
      setNoteTemplate(null);
    } else if (data.meetingTemplate) {
      setNoteTemplate(data.meetingTemplate);
      markStepComplete("note-template");
    } else {
      setNoteTemplate(null);
    }

    // Handle actions if present
    if (data.meetingActions && data.meetingActions.length > 0) {
      processMeetingActions(data.meetingActions);
    }
  };

  // Add a helper function to create workflow steps from actions
  const addWorkflowStepFromAction = (action) => {
    let stepType = "untyped";
    let icon = "automation";
    let color = "bg-gray-400";
    let name = "Automation";
    let description = "Automated action";

    // Determine action type from action.type or action.actionType
    const actionType = action.type || action.actionType || "untyped";

    // Determine step type, name, etc. based on action type
    if (actionType === "follow_up_email" || actionType === "email-action") {
      stepType = "follow_up_email"; // Use consistent type names
      icon = "mail";
      color = "bg-orange-500";
      name = action.name || "Follow-up Email";
      description = "Send automated emails after meetings";
    } else if (actionType === "slack_post") {
      stepType = "slack_post";
      icon = "slack";
      color = "bg-purple-500";
      name = action.name || "Slack Notification";
      description = "Post updates to Slack after meetings";
    } else if (actionType === "google_doc") {
      stepType = "google_doc";
      icon = "google-doc";
      color = "bg-blue-500";
      name = action.name || "Google Doc";
      description = "Generate Google Docs with meeting notes";
    } else if (actionType === "calendar-action") {
      stepType = "calendar-action";
      icon = "calendar";
      color = "bg-green-500";
      name = action.name || "Calendar Action";
      description = "Schedule follow-up meetings automatically";
    }

    // Create a normalized action object
    const normalizedAction = {
      ...action,
      type: stepType, // Use the consistent type
      ID: action.ID || action.id, // Ensure we have an ID field
    };

    // Create and add the step
    const newStep = {
      ID: `action-${normalizedAction.ID || Date.now()}`,
      type: stepType,
      name: name,
      description: description,
      color: color,
      icon: icon,
      isRequired: false,
      status: "complete", // Mark as complete since it's existing
      actionData: normalizedAction, // Store the normalized action data
    };

    setWorkflowSteps((prev) => [...prev, newStep]);
  };

  useEffect(() => {
    if (meetingType && meetingType.regularMeetingNotes) {
      setUseRegularMeetingNotes(true);
      // Ensure we don't have a noteTemplate when using regular notes
      setNoteTemplate(null);
    }
  }, [meetingType]);

  const fetchWorkflowDataIfNeeded = async () => {
    if (!workflowId) return;

    try {
      const mtResponse = await fetch(
        `https://backend.scribbl.co/meeting-types/${workflowId}`,
        {
          credentials: "include",
        }
      );
      if (!mtResponse.ok) {
        throw new Error(
          `Failed to fetch meeting type: ${mtResponse.status} ${mtResponse.statusText}`
        );
      }

      const meetingTypeData = await mtResponse.json();
      console.log("Meeting type data:", meetingTypeData);

      // Extract and set the meeting type
      const fetchedMeetingType = meetingTypeData.meetingType;
      setMeetingType(fetchedMeetingType);
      markStepComplete("meeting-type");

      // Handle regular meeting notes or custom template
      if (fetchedMeetingType.regularMeetingNotes) {
        setUseRegularMeetingNotes(true);
        markStepComplete("note-template");
        setNoteTemplate(null);
      } else if (meetingTypeData.meetingTemplate) {
        setNoteTemplate(meetingTypeData.meetingTemplate);
        markStepComplete("note-template");
      } else {
        setNoteTemplate(null);
      }

      // Handle meeting actions if present
      if (
        meetingTypeData.meetingActions &&
        meetingTypeData.meetingActions.length > 0
      ) {
        processMeetingActions(meetingTypeData.meetingActions);
      }
    } catch (error) {
      console.error("Error fetching workflow data:", error);
    }
  };

  // -------------------------------------------------------------------
  // STEP NAV & STATUS
  // -------------------------------------------------------------------
  const isStepAvailable = (index) => {
    if (index === 0) return true; // meeting-type is always available
    for (let i = 0; i < index; i++) {
      if (
        workflowSteps[i].isRequired &&
        workflowSteps[i].status !== "complete"
      ) {
        return false;
      }
    }
    return true;
  };

  const attemptStepNavigation = (targetIndex) => {
    if (!isStepAvailable(targetIndex)) return;
    // Set flag when user is explicitly navigating via sidebar
    setIsDirectNavigation(true);
    setActiveStepIndex(targetIndex);
  };

  const markStepComplete = (stepID) => {
    setWorkflowSteps((prev) =>
      prev.map((step) =>
        step.ID === stepID ? { ...step, status: "complete" } : step
      )
    );
  };

  // -------------------------------------------------------------------
  // HANDLE DELETE WORKFLOW STEPS (AUTOMATIONS)
  // -------------------------------------------------------------------

  // Initialize deletion modal for a step
  const initiateStepDeletion = (e, stepID) => {
    e.stopPropagation();

    // Find the step to be deleted
    const step = workflowSteps.find((s) => s.ID === stepID);
    if (!step || step.isRequired) return;

    setStepToDelete(step);
    setDeleteModalOpen(true);
  };

  // Handle actual deletion after confirmation
  const handleDeleteConfirmation = async () => {
    if (!stepToDelete) return;

    setIsDeleting(true);

    try {
      // Extract the actual action ID from the step ID or action data
      let actionId;

      if (stepToDelete.actionData) {
        actionId = stepToDelete.actionData.ID || stepToDelete.actionData.id;
      } else if (stepToDelete.ID.startsWith("action-")) {
        // Extract the ID from the step ID format "action-123"
        actionId = stepToDelete.ID.replace("action-", "");
      }

      if (actionId) {
        // Call API to delete the action
        const result = await deleteMeetingAction(actionId);

        if (!result.success) {
          throw new Error(result.error || "Failed to delete the action");
        }
      }

      // Find the index before removing the step
      const removeIndex = workflowSteps.findIndex(
        (s) => s.ID === stepToDelete.ID
      );

      // Update the steps array
      setWorkflowSteps((prev) => prev.filter((s) => s.ID !== stepToDelete.ID));

      // If we're currently on the step being deleted, navigate to the previous step
      if (activeStepIndex === removeIndex) {
        setActiveStepIndex(Math.max(0, activeStepIndex - 1));
      }
      // If we're on a step after the deleted one, adjust the index
      else if (activeStepIndex > removeIndex) {
        setActiveStepIndex(activeStepIndex - 1);
      }
    } catch (error) {
      console.error("Error deleting workflow step:", error);
      // You could show an error notification here
    } finally {
      setIsDeleting(false);
      setDeleteModalOpen(false);
      setStepToDelete(null);
    }
  };

  // The original removeWorkflowStep function is now replaced by the delete confirmation flow
  const removeWorkflowStep = (stepID) => {
    // Prevent deletion of required steps
    const stepToRemove = workflowSteps.find((s) => s.ID === stepID);
    if (!stepToRemove || stepToRemove.isRequired) return;

    // Find the index before removing the step
    const removeIndex = workflowSteps.findIndex((s) => s.ID === stepID);

    // Update the steps array
    setWorkflowSteps((prev) => prev.filter((s) => s.ID !== stepID));

    // If we're currently on the step being deleted, navigate to the previous step
    if (activeStepIndex === removeIndex) {
      setActiveStepIndex(Math.max(0, activeStepIndex - 1));
    }
    // If we're on a step after the deleted one, adjust the index
    else if (activeStepIndex > removeIndex) {
      setActiveStepIndex(activeStepIndex - 1);
    }
  };

  // -------------------------------------------------------------------
  // ADD / MANAGE WORKFLOW STEPS (AUTOMATIONS)
  // -------------------------------------------------------------------
  const addWorkflowStep = () => {
    // Ensure required steps are done
    const allRequiredDone = workflowSteps
      .filter((s) => s.isRequired)
      .every((s) => s.status === "complete");
    if (!allRequiredDone) {
      console.warn("Complete required steps before adding automations.");
      return;
    }

    // If there's an unfinished automation step, jump there
    const existingAutomationIndex = workflowSteps.findIndex(
      (step) =>
        (step.type === "untyped" || step.type.includes("-action")) &&
        step.status !== "complete"
    );
    if (existingAutomationIndex !== -1) {
      setActiveStepIndex(existingAutomationIndex);
      return;
    }

    // Otherwise, create a new automation step
    const newStep = {
      ID: `automation-${Date.now()}`,
      type: "untyped",
      name: "New Automation",
      description: "Select an automation type to configure",
      color: "bg-gray-400",
      icon: "automation",
      isRequired: false,
      status: "pending",
    };
    setWorkflowSteps((prev) => [...prev, newStep]);
    setActiveStepIndex(workflowSteps.length); // jump to it
  };

  const updateStepType = (stepID, newType) => {
    let updateData = {};
    if (newType === "email-action" || newType === "follow_up_email") {
      updateData = {
        name: "Follow-up Email",
        description: "Send automated emails after meetings",
        color: "bg-orange-500",
        icon: "mail",
      };
    } else if (newType === "calendar-action") {
      updateData = {
        name: "Calendar Action",
        description: "Schedule follow-up meetings automatically",
        color: "bg-green-500",
        icon: "calendar",
      };
    } else if (newType === "custom-action") {
      updateData = {
        name: "Custom Action",
        description: "Define a custom automation workflow",
        color: "bg-purple-500",
        icon: "automation",
      };
    }

    // Update workflowSteps state with the new step type and metadata
    setWorkflowSteps((prev) =>
      prev.map((step) =>
        step.ID === stepID
          ? {
              ...step,
              type: newType,
              ...updateData,
              status: "in-progress",
            }
          : step
      )
    );
  };

  // -------------------------------------------------------------------
  // RENDER STEP CONTENT
  // -------------------------------------------------------------------
  const getStepIcon = (step) => {
    switch (step.icon) {
      case "meeting":
        // Replace the number with a dedicated meeting icon
        return <UserGroupIcon className="h-5 w-5" />;
      case "document":
        return <DocumentTextIcon className="h-5 w-5" />;
      case "note-template": // Add new case for note template
        return <PencilAltIcon className="h-5 w-5" />;
      case "mail":
        return <MailIcon className="h-5 w-5" />;
      case "calendar":
        return <CalendarIcon className="h-5 w-5" />;
      case "automation":
        return <LightningBoltIcon className="h-5 w-5" />;
      // Add specific handlers for slack and google doc
      case "slack":
        return <ChatAlt2Icon className="h-5 w-5" />;
      case "google-doc":
        // Using a different document icon or you could add another import
        return <DocumentTextIcon className="h-5 w-5" />;
      default:
        return <LightningBoltIcon className="h-5 w-5" />;
    }
  };

  function renderStepContent() {
    const currentStep = workflowSteps[activeStepIndex];
    if (!currentStep) return null;

    // 1) Untyped automation
    if (currentStep.type === "untyped") {
      console.log("meeting type name", meetingType);

      return (
        <MeetingWorkflowAutomationStep
          step={currentStep}
          userEmail={userEmail}
          meetingTypeId={meetingType?.ID} // Add optional chaining here
          meetingTypeName={meetingType?.name}
          onTypeSelect={(id, type) => updateStepType(id, type)}
          onAutomationCreated={(automationData) => {
            // Determine icon and description based on automation type
            let icon = "mail";
            let color = "bg-orange-500";
            let description = "Send automated emails after meetings";

            if (automationData.type === "slack_post") {
              icon = "slack";
              color = "bg-purple-500";
              description = "Post updates to Slack after meetings";
            } else if (automationData.type === "google_doc") {
              icon = "google-doc";
              color = "bg-blue-500";
              description = "Generate Google Documents with meeting notes";
            }

            // Update step name and status based on the automation data
            const updateData = {
              name: automationData.name || "Automation",
              description: description,
              color: color,
              icon: icon,
              status: "complete", // Mark as complete immediately
            };

            // Store the meeting type ID with the action data
            const enhancedAutomationData = {
              ...automationData,
              meetingTypeId: meetingType?.ID || meetingType?.id, // Use optional chaining here too
            };

            // Update the step with new name and mark complete
            setWorkflowSteps((prev) =>
              prev.map((step) =>
                step.ID === currentStep.ID
                  ? {
                      ...step,
                      ...updateData,
                      type: automationData.type || "email-action",
                      actionData: enhancedAutomationData,
                    }
                  : step
              )
            );

            // ADDED: Reset direct navigation flag to ensure we show completion screen
            setIsDirectNavigation(false);
          }}
          onCancel={() => {
            console.log("current step", currentStep);
            removeWorkflowStep(currentStep.ID);
          }}
        />
      );
    }

    // 2) Meeting-type step
    if (currentStep.type === "meeting-type") {
      if (
        workflowId ||
        (isDirectNavigation && currentStep.status === "complete" && meetingType)
      ) {
        // We have an existing meeting type OR navigating directly to a completed step => show the Edit form
        return (
          <EditMeetingTypeStep
            key={currentStep.ID}
            meetingTypeId={workflowId || meetingType?.ID}
            userEmail={userEmail}
            onComplete={(meetingTypeData) => {
              setMeetingType(meetingTypeData.meetingType);
              // If user selects default notes
              if (meetingTypeData.regularMeetingNotes) {
                setUseRegularMeetingNotes(true);
                setNoteTemplate(null); // Clear noteTemplate when regular notes are selected
              } else {
                setUseRegularMeetingNotes(false);
              }
              markStepComplete(currentStep.ID);
              // Reset direct navigation flag
              setIsDirectNavigation(false);
              // proceed to next step
              // setActiveStepIndex(1);
            }}
            onSwitchToConversational={() => {
              // fallback if you want to revert to the wizard
              setMeetingType(null);
              setIsDirectNavigation(false);
            }}
            setMeetingNoteTemplate={(templateData) => {
              setNoteTemplate(templateData);
            }}
          />
        );
      } else {
        // New meeting type or continuing the natural flow => use conversational creation
        return (
          <ConversationalMeetingTypeStep
            key={currentStep.ID}
            userEmail={userEmail}
            setMeetingType={(meetingTypeData) => {
              setMeetingType(meetingTypeData);
              if (meetingTypeData.regularMeetingNotes) {
                setUseRegularMeetingNotes(true);
              }
              markStepComplete(currentStep.ID);
              // Reset direct navigation flag since we're in the flow
              setIsDirectNavigation(false);
            }}
            onCancel={onCancel}
            onProceedToNoteTemplate={() => {
              markStepComplete(currentStep.ID);
              // Reset direct navigation flag since we're continuing in the flow
              setIsDirectNavigation(false);
              setActiveStepIndex(1);
            }}
          />
        );
      }
    }

    // 3) Note-template step
    // This snippet contains just the noteTemplate handling part that needs to be fixed
    // Insert this code within the renderStepContent function in MeetingWorkflowEdit.jsx

    // 3) Note-template step
    if (currentStep.type === "note-template") {
      // First, check if we should show the final workflow completion screen
      if (showWorkflowCompleteScreen) {
        return (
          <div className="flex flex-col items-center justify-center py-8">
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, type: "spring" }}
              className="w-20 h-20 bg-brand-turq bg-opacity-10 rounded-full flex items-center justify-center mb-6"
            >
              <CheckCircleIcon className="h-10 w-10 text-brand-turq" />
            </motion.div>
            <motion.h3
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-xl font-medium text-gray-900 text-center"
            >
              Your workflow is taking shape
            </motion.h3>
            <motion.p
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="text-base text-gray-600 mt-3 text-center max-w-md"
            >
              You've set up your note template. What would you like to do next?
            </motion.p>
            <div className="flex items-center justify-center space-x-4 mt-10">
              <motion.button
                whileHover={{ y: -2 }}
                whileTap={{ y: 0 }}
                onClick={() => {
                  if (onSave) {
                    onSave({ meetingType, noteTemplate });
                  }
                }}
                className="inline-flex items-center px-5 py-3 border border-gray-300 rounded-lg shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
              >
                <CheckIcon className="h-5 w-5 mr-2 text-gray-500" />
                Complete Setup
              </motion.button>

              <motion.button
                whileHover={{ y: -2 }}
                whileTap={{ y: 0 }}
                onClick={() => addWorkflowStep()}
                className="inline-flex items-center px-5 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-brand-turq hover:bg-brand-green transition-colors"
              >
                <LightningBoltIcon className="h-5 w-5 mr-2" />
                Add Automation
              </motion.button>
            </div>
          </div>
        );
      }

      // If the user has selected default/regular notes
      if (meetingType && meetingType.regularMeetingNotes) {
        return (
          <DefaultNoteTemplateScreen
            key={currentStep.ID}
            meetingTypeName={meetingType?.name || "Meeting"}
            onSwitchToCustom={() => {
              // Switch to custom
              setUseRegularMeetingNotes(false);
              setNoteTemplate(null);
              if (meetingType?.ID) {
                fetch(`https://backend.scribbl.co/meeting-types/${meetingType.ID}`, {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  credentials: "include",
                  body: JSON.stringify({
                    regularMeetingNotes: false,
                    name: meetingType.name,
                    description: meetingType.description,
                  }),
                })
                  .then(() => {
                    setMeetingType({
                      ...meetingType,
                      regularMeetingNotes: false,
                    });
                  })
                  .catch(console.error);
              }
            }}
            onComplete={() => {
              markStepComplete(currentStep.ID);
              // Show the completion screen instead of proceeding
              setShowWorkflowCompleteScreen(true);
            }}
            onProceedToNextStep={() => {
              markStepComplete(currentStep.ID);
              // Show the completion screen instead of proceeding
              setShowWorkflowCompleteScreen(true);
            }}
            meetingTypeId={meetingType?.ID}
          />
        );
      }

      // If we have a noteTemplate and we're explicitly direct navigating
      if (
        noteTemplate &&
        isDirectNavigation &&
        currentStep.status === "complete"
      ) {
        console.log("what is note templates", noteTemplate);

        // FIX: Ensure we preserve the ID when passing to EditNoteTemplateStep
        // Check if noteTemplate has ID in uppercase or lowercase format
        const templateWithID = {
          ...noteTemplate,
          // If neither ID nor id exists, this will remain undefined
          ID: noteTemplate.ID || noteTemplate.id,
        };

        return (
          <EditNoteTemplateStep
            key={currentStep.ID}
            initialData={templateWithID} // Use the fixed template object with ID
            userEmail={userEmail}
            onComplete={(updatedTemplate) => {
              markStepComplete(currentStep.ID);
              // Show completion screen
              setShowWorkflowCompleteScreen(true);
            }}
            onDataChange={(updatedNoteTemplateData) => {
              setNoteTemplate((prev) => {
                // FIX: Ensure we preserve the ID when updating noteTemplate state
                const updatedTemplate = {
                  ...updatedNoteTemplateData,
                  // Preserve the original ID to ensure it's never lost
                  ID: prev?.ID || prev?.id || updatedNoteTemplateData.ID,
                };

                if (
                  prev?.title === updatedTemplate.title &&
                  prev?.content === updatedTemplate.content
                ) {
                  return prev;
                }
                return updatedTemplate;
              });
            }}
            onSwitchToConversational={() => {
              setNoteTemplate(null);
            }}
          />
        );
      }

      // Otherwise => conversational flow for new template
      return (
        <ConversationalNoteTemplateStep
          key={currentStep.ID}
          meetingTypeId={meetingType?.ID}
          meetingTypeName={meetingType?.name || "Meeting"}
          userEmail={userEmail}
          setMeetingNoteTemplate={(templateData) => {
            // If this is a default template and we have the updated meeting type
            if (templateData.isDefault && templateData.updatedMeetingType) {
              setMeetingType(templateData.updatedMeetingType);
              setUseRegularMeetingNotes(true);
              setNoteTemplate(null);
            } else {
              // Normal case: just update the noteTemplate
              // FIX: Ensure the ID is preserved when setting the note template
              const templateWithID = {
                ...templateData,
                ID: templateData.ID || templateData.id,
              };
              setNoteTemplate(templateWithID);
            }

            markStepComplete(currentStep.ID);
            // Show the final completion screen
            setShowWorkflowCompleteScreen(true);
          }}
          onComplete={() => {
            markStepComplete(currentStep.ID);
            // Show the final completion screen
            setShowWorkflowCompleteScreen(true);
          }}
          onCancel={onCancel}
          onProceedToNextStep={() => {
            markStepComplete(currentStep.ID);
            // Show the final completion screen
            setShowWorkflowCompleteScreen(true);
          }}
          onCompletionFinished={() => {
            if (onSave) {
              onSave({
                meetingType,
                noteTemplate,
              });
            }
          }}
        />
      );
    }

    // 5) Our new "notes-complete" step if added
    if (currentStep.type === "notes-complete") {
      return (
        <div className="p-6 bg-white border border-gray-200 rounded-md shadow-sm">
          <h2 className="text-xl font-semibold text-gray-800 mb-3">
            Meeting Notes Setup Complete
          </h2>
          <p className="text-gray-600 mb-5">
            Your meeting notes are all set for{" "}
            <strong>{meetingType?.name || "this meeting type"}</strong>.
          </p>
          <p className="text-sm text-gray-500 mb-5">
            You can now add optional automations or finish your workflow.
          </p>
          <div className="flex items-center space-x-3">
            <button
              onClick={() => addWorkflowStep()}
              className="px-4 py-2.5 text-sm font-medium text-white bg-brand-turq rounded-md hover:bg-brand-green"
            >
              Add an Automation
            </button>
            <button
              onClick={() => {
                if (onSave) {
                  onSave({ meetingType, noteTemplate });
                }
              }}
              className="px-4 py-2.5 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
            >
              Finish
            </button>
          </div>
        </div>
      );
    }
    if (
      currentStep.type === "follow_up_email" ||
      currentStep.type === "email-action" ||
      currentStep.type === "slack_post" ||
      currentStep.type === "google_doc" ||
      currentStep.type === "calendar-action" ||
      currentStep.type === "custom-action"
    ) {
      // Case 1: Show edit form ONLY when directly navigating to a completed step
      if (currentStep.status === "complete" && isDirectNavigation) {
        // Determine which edit component to show based on the type
        if (
          currentStep.type === "follow_up_email" ||
          currentStep.type === "email-action"
        ) {
          console.log("current step", currentStep);
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <EditFollowUpEmail
                action={currentStep.actionData}
                meetingType={meetingType}
                userEmail={userEmail}
                onComplete={(updatedAction) => {
                  // Update the step with the new data
                  setWorkflowSteps((prev) =>
                    prev.map((step) =>
                      step.ID === currentStep.ID
                        ? {
                            ...step,
                            name: updatedAction.name,
                            actionData: {
                              ...step.actionData,
                              ...updatedAction,
                              // Make sure to preserve the ID
                              ID:
                                step.actionData?.ID ||
                                step.actionData?.id ||
                                updatedAction.ID ||
                                updatedAction.id,
                            },
                          }
                        : step
                    )
                  );

                  // Mark step as complete
                  markStepComplete(currentStep.ID);

                  // Continue workflow
                  setIsDirectNavigation(false);
                }}
                onCancel={() => setIsDirectNavigation(false)}
                onSwitchToConversational={() => {
                  // Store the original action data before clearing
                  setOriginalActionData(currentStep.actionData);

                  // Reset direct navigation flag
                  setIsDirectNavigation(false);

                  // Reset this automation step to force the conversational flow
                  setWorkflowSteps((prev) =>
                    prev.map((step) =>
                      step.ID === currentStep.ID
                        ? {
                            ...step,
                            status: "in-progress",
                            actionData: null, // Clear existing action data
                          }
                        : step
                    )
                  );
                }}
              />
            </React.Suspense>
          );
        } else if (currentStep.type === "slack_post") {
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <EditSlackAutomation
                action={currentStep.actionData}
                meetingType={meetingType}
                userEmail={userEmail}
                onComplete={(updatedAction) => {
                  // Update the step with the new data
                  setWorkflowSteps((prev) =>
                    prev.map((step) =>
                      step.ID === currentStep.ID
                        ? {
                            ...step,
                            name: updatedAction.name,
                            actionData: {
                              ...step.actionData,
                              ...updatedAction,
                              // Make sure to preserve the ID
                              ID:
                                step.actionData?.ID ||
                                step.actionData?.id ||
                                updatedAction.ID ||
                                updatedAction.id,
                            },
                          }
                        : step
                    )
                  );

                  // Mark step as complete
                  markStepComplete(currentStep.ID);

                  // Continue workflow
                  setIsDirectNavigation(false);
                }}
                onCancel={() => setIsDirectNavigation(false)}
                onSwitchToConversational={() => {
                  // Store the original action data before clearing
                  setOriginalActionData(currentStep.actionData);

                  // Reset direct navigation flag
                  setIsDirectNavigation(false);

                  // Reset this automation step to force the conversational flow
                  setWorkflowSteps((prev) =>
                    prev.map((step) =>
                      step.ID === currentStep.ID
                        ? {
                            ...step,
                            status: "in-progress",
                            actionData: null, // Clear existing action data
                          }
                        : step
                    )
                  );
                }}
              />
            </React.Suspense>
          );
        } else if (currentStep.type === "google_doc") {
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <EditGoogleDocAutomation
                action={currentStep.actionData}
                meetingType={meetingType}
                userEmail={userEmail}
                onComplete={(updatedAction) => {
                  // Update the step with the new data
                  setWorkflowSteps((prev) =>
                    prev.map((step) =>
                      step.ID === currentStep.ID
                        ? {
                            ...step,
                            name: updatedAction.name,
                            actionData: {
                              ...step.actionData,
                              ...updatedAction,
                              // Make sure to preserve the ID
                              ID:
                                step.actionData?.ID ||
                                step.actionData?.id ||
                                updatedAction.ID ||
                                updatedAction.id,
                            },
                          }
                        : step
                    )
                  );

                  // Mark step as complete
                  markStepComplete(currentStep.ID);

                  // Continue workflow
                  setIsDirectNavigation(false);
                }}
                onCancel={() => setIsDirectNavigation(false)}
                onSwitchToConversational={() => {
                  // Store the original action data before clearing
                  setOriginalActionData(currentStep.actionData);

                  // Reset direct navigation flag
                  setIsDirectNavigation(false);

                  // Reset this automation step to force the conversational flow
                  setWorkflowSteps((prev) =>
                    prev.map((step) =>
                      step.ID === currentStep.ID
                        ? {
                            ...step,
                            status: "in-progress",
                            actionData: null, // Clear existing action data
                          }
                        : step
                    )
                  );
                }}
              />
            </React.Suspense>
          );
        } else {
          // Generic edit interface for other automation types
          return (
            <div className="p-4">
              <p className="text-gray-700">
                Edit {currentStep.name} (not implemented)
              </p>
            </div>
          );
        }
      }

      // Case 2: Completed step in creation flow - show the completion screen
      else if (currentStep.status === "complete" && !isDirectNavigation) {
        return (
          <div className="flex flex-col items-center justify-center py-8">
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, type: "spring" }}
              className="w-20 h-20 bg-brand-turq bg-opacity-10 rounded-full flex items-center justify-center mb-6"
            >
              <CheckCircleIcon className="h-10 w-10 text-brand-turq" />
            </motion.div>
            <motion.h3
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-2xl font-medium text-gray-900 text-center"
            >
              Your {currentStep.name} is ready!
            </motion.h3>
            <motion.p
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="text-base text-gray-600 mt-3 text-center max-w-md"
            >
              This automation will run automatically after your meetings.
            </motion.p>
            <div className="flex items-center space-x-4 mt-10">
              <motion.button
                whileHover={{ y: -2 }}
                whileTap={{ y: 0 }}
                onClick={() => {
                  // Set isDirectNavigation to true to show the edit form
                  setIsDirectNavigation(true);
                  // Stay on the current step
                  setActiveStepIndex(activeStepIndex);
                }}
                className="inline-flex items-center px-5 py-3 border border-gray-300 rounded-lg shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
              >
                <PencilIcon className="h-5 w-5 mr-2 text-gray-500" />
                Edit Configuration
              </motion.button>

              <motion.button
                whileHover={{ y: -2 }}
                whileTap={{ y: 0 }}
                onClick={() => addWorkflowStep()}
                className="inline-flex items-center px-5 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-brand-turq hover:bg-brand-green transition-colors"
              >
                <LightningBoltIcon className="h-5 w-5 mr-2" />
                Add Another Automation
              </motion.button>
            </div>
          </div>
        );
      }

      // Case 3: New or in-progress automation - use the MeetingWorkflowAutomationStep
      else {
        console.log("meeting type name", meetingType);
        return (
          <MeetingWorkflowAutomationStep
            key={currentStep.ID}
            step={currentStep}
            userEmail={userEmail}
            meetingTypeId={meetingType?.ID}
            meetingTypeName={meetingType?.name || "Meeting"}
            onTypeSelect={(id, type) => updateStepType(id, type)}
            onAutomationCreated={(automationData) => {
              // Determine icon and description based on automation type
              let icon = "mail";
              let color = "bg-orange-500";
              let description = "Send automated emails after meetings";

              if (automationData.type === "slack_post") {
                icon = "slack";
                color = "bg-purple-500";
                description = "Post updates to Slack after meetings";
              } else if (automationData.type === "google_doc") {
                icon = "google-doc";
                color = "bg-blue-500";
                description = "Generate Google Documents with meeting notes";
              }

              // Update step name and status based on the automation data
              const updateData = {
                name: automationData.name || "Automation",
                description: description,
                color: color,
                icon: icon,
                status: "complete", // Mark as complete immediately
              };

              // Store the meeting type ID with the action data
              const enhancedAutomationData = {
                ...automationData,
                meetingTypeId: meetingType?.ID || meetingType?.id,
              };

              // Update the step with new name and mark complete
              setWorkflowSteps((prev) =>
                prev.map((step) =>
                  step.ID === currentStep.ID
                    ? {
                        ...step,
                        ...updateData,
                        type: automationData.type || "email-action",
                        actionData: enhancedAutomationData, // Store the action data in the step
                      }
                    : step
                )
              );

              // ADDED: Reset direct navigation flag to ensure we show completion screen
              setIsDirectNavigation(false);
            }}
            onCancel={() => {
              if (originalActionData) {
                // This is an existing step that we switched to conversational mode for
                // Restore the original data instead of removing the step
                setWorkflowSteps((prev) =>
                  prev.map((step) =>
                    step.ID === currentStep.ID
                      ? {
                          ...step,
                          status: "complete", // Mark it complete again
                          actionData: originalActionData, // Restore original data
                          // Also restore the original type, name, description, color, icon
                          // These would have been set based on the automation type
                          type: originalActionData.type || step.type,
                          name: originalActionData.name || step.name,
                        }
                      : step
                  )
                );
                // Clear the stored original data
                setOriginalActionData(null);
                // Set direct navigation to true to show the edit form again
                setIsDirectNavigation(true);
              } else {
                // This is a new step, so remove it as before
                removeWorkflowStep(currentStep.ID);
              }
            }}
          />
        );
      }
    }

    return null;
  }

  const processMeetingActions = (actions) => {
    if (!actions || !actions.length) return;

    actions.forEach((action) => {
      // Parse customData if it's a string
      let customData = {};
      if (action.customData) {
        if (typeof action.customData === "string") {
          try {
            customData = JSON.parse(action.customData);
          } catch (e) {
            console.error("Error parsing customData:", e);
          }
        } else {
          customData = action.customData;
        }
      }

      // Create a normalized action object with consistent format
      const normalizedAction = {
        ...action,
        type: action.type || action.actionType || "untyped",
        // Extract properties from customData for easier access
        emailPrompt: customData.emailPrompt || action.emailPrompt || "",
        slackPrompt: customData.slackPrompt || action.slackPrompt || "",
        slackChannelID:
          customData.slackChannelID || action.slackChannelID || "",
        // Keep the original or parsed customData
        customData,
      };

      // Create and add the step with the normalized action data
      const stepType = determineStepTypeFromAction(normalizedAction);
      const newStep = {
        ID: `action-${
          normalizedAction.ID || normalizedAction.id || Date.now()
        }`,
        type: stepType.type,
        name: stepType.name,
        description: stepType.description,
        color: stepType.color,
        icon: stepType.icon,
        isRequired: false,
        status: "complete", // Mark as complete since it's existing
        actionData: normalizedAction, // Store the normalized action data
      };

      setWorkflowSteps((prev) => [...prev, newStep]);
    });
  };

  const determineStepTypeFromAction = (action) => {
    const actionType = action.type || action.actionType || "untyped";

    let result = {
      type: "untyped",
      name: action.name || "Automation",
      description: "Automated action",
      color: "bg-gray-400",
      icon: "automation",
    };

    if (actionType === "follow_up_email" || actionType === "email-action") {
      result = {
        type: "follow_up_email",
        name: action.name || "Follow-up Email",
        description: "Send automated emails after meetings",
        color: "bg-orange-500",
        icon: "mail",
      };
    } else if (actionType === "slack_post") {
      result = {
        type: "slack_post",
        name: action.name || "Slack Notification",
        description: "Post updates to Slack after meetings",
        color: "bg-purple-500",
        icon: "slack",
      };
    } else if (actionType === "google_doc") {
      result = {
        type: "google_doc",
        name: action.name || "Google Doc",
        description: "Generate Google Docs with meeting notes",
        color: "bg-blue-500",
        icon: "google-doc",
      };
    } else if (actionType === "calendar-action") {
      result = {
        type: "calendar-action",
        name: action.name || "Calendar Action",
        description: "Schedule follow-up meetings automatically",
        color: "bg-green-500",
        icon: "calendar",
      };
    }

    return result;
  };

  // -------------------------------------------------------------------
  // MAIN RENDER
  // -------------------------------------------------------------------
  return (
    <div className="flex flex-col h-screen bg-gray-50">
      {/* Header */}
      <header className="relative overflow-hidden bg-gradient-to-r from-brand-turq to-brand-green text-white shadow-md">
        <div className="absolute inset-0 mix-blend-overlay bg-gradient-to-br from-white/10 to-black/20 pointer-events-none" />
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="flex items-center justify-between">
            {/* Left: Back + Title */}
            <div className="flex items-center">
              <button
                type="button"
                onClick={onCancel}
                className="mr-4 inline-flex items-center p-2 rounded-full text-white bg-white bg-opacity-20 hover:bg-opacity-30"
              >
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <div>
                <h1 className="text-xl md:text-2xl font-bold flex items-center">
                  {workflowId ? "Edit" : "Create"}:{" "}
                  {meetingType?.name || "Meeting Workflow"}
                </h1>
                <p className="text-white text-opacity-90 text-sm">
                  {workflowId
                    ? "Customize your workflow steps and configuration"
                    : "Define your new meeting workflow"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Main layout */}
      <div className="flex-1 flex overflow-hidden">
        {/* LEFT SIDEBAR */}
        <div className="w-80 bg-white border-r border-gray-200 overflow-y-auto">
          <div className="p-5 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-medium text-gray-900">
                Workflow Steps
              </h2>
            </div>
          </div>

          <div className="px-4 py-4 space-y-2">
            {workflowSteps.map((step, index) => {
              const isActive = activeStepIndex === index;
              const isComplete = step.status === "complete";
              const isAvailable = isStepAvailable(index);
              return (
                <div key={step.ID} className="relative">
                  <button
                    className={classNames(
                      "w-full flex items-start p-3 rounded-lg relative transition-all duration-200 text-left",
                      isActive
                        ? "bg-brand-turq bg-opacity-5 border border-brand-turq shadow-sm"
                        : isAvailable
                        ? "hover:bg-gray-50 border border-transparent"
                        : "opacity-50 cursor-not-allowed border border-transparent",
                      isComplete ? "opacity-90" : ""
                    )}
                    onClick={() => attemptStepNavigation(index)}
                    disabled={!isAvailable}
                  >
                    <div
                      className={classNames(
                        "flex-shrink-0 h-10 w-10 rounded-lg flex items-center justify-center text-white shadow-sm mr-3",
                        isAvailable ? step.color : "bg-gray-400"
                      )}
                    >
                      {!isAvailable ? (
                        <LockClosedIcon className="h-5 w-5" />
                      ) : (
                        getStepIcon(step, index + 1)
                      )}
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center">
                        <div
                          className={classNames(
                            "font-medium truncate",
                            isActive
                              ? "text-brand-turq"
                              : isComplete
                              ? "text-green-700"
                              : isAvailable
                              ? "text-gray-800"
                              : "text-gray-500"
                          )}
                        >
                          {step.name}
                        </div>
                        {isComplete && (
                          <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Complete
                          </span>
                        )}
                      </div>
                      <div className="text-xs text-gray-500 mt-0.5">
                        {step.description}
                      </div>
                    </div>
                    {!step.isRequired && step.type !== "notes-complete" && (
                      <button
                        type="button"
                        onClick={(e) => initiateStepDeletion(e, step.ID)}
                        className="flex-shrink-0 ml-2 p-1.5 rounded-full text-gray-400 hover:text-red-500 hover:bg-red-50 transition-colors"
                        aria-label="Remove step"
                      >
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    )}
                  </button>
                </div>
              );
            })}

            {/* ADD AUTOMATION BUTTON */}
            <button
              type="button"
              onClick={addWorkflowStep}
              className={classNames(
                "group w-full flex items-center p-3 rounded-md border-2 border-dashed transition-colors",
                workflowSteps
                  .filter((s) => s.isRequired)
                  .every((s) => s.status === "complete")
                  ? "border-gray-300 hover:border-brand-turq text-gray-500 hover:text-brand-turq focus:outline-none focus:ring-2 focus:ring-brand-turq focus:border-brand-turq"
                  : "border-gray-200 text-gray-400 cursor-not-allowed opacity-60"
              )}
              disabled={
                !workflowSteps
                  .filter((s) => s.isRequired)
                  .every((s) => s.status === "complete")
              }
            >
              <div
                className={classNames(
                  "flex-shrink-0 h-10 w-10 rounded-lg flex items-center justify-center mr-3 transition-colors",
                  workflowSteps
                    .filter((s) => s.isRequired)
                    .every((s) => s.status === "complete")
                    ? "bg-gray-100 group-hover:bg-brand-turq group-hover:bg-opacity-10"
                    : "bg-gray-50"
                )}
              >
                <PlusIcon className="h-5 w-5" />
              </div>
              <div>
                <div className="font-medium">Add Automation</div>
                <div
                  className={classNames(
                    "text-xs transition-colors",
                    workflowSteps
                      .filter((s) => s.isRequired)
                      .every((s) => s.status === "complete")
                      ? "text-gray-500 group-hover:text-brand-turq"
                      : "text-gray-400"
                  )}
                >
                  {workflowSteps
                    .filter((s) => s.isRequired)
                    .every((s) => s.status === "complete")
                    ? "Add email, calendar, or custom action"
                    : "Complete required steps first"}
                </div>
              </div>
            </button>
          </div>
        </div>

        {/* RIGHT CONTENT */}
        <div className="flex-1 overflow-y-auto">
          <div className="bg-gray-50 h-full">
            <div className="max-w-4xl mx-auto py-6 px-4 sm:px-6 lg:px-8 h-full flex flex-col">
              <div className="flex-1 relative">
                <TransitionGroup component={null}>
                  <CSSTransition
                    key={workflowSteps[activeStepIndex]?.ID}
                    classNames="fade-slide"
                    timeout={300}
                  >
                    <div className="absolute inset-0">
                      {renderStepContent()}
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setStepToDelete(null);
        }}
        onConfirm={handleDeleteConfirmation}
        title="Delete Automation"
        message="Are you sure you want to delete this automation? This action cannot be undone and the automation will no longer run for future meetings."
        isLoading={isDeleting}
      />

      {/* Fade-slide transition CSS */}
      <style jsx global>{`
        .fade-slide-enter {
          opacity: 0;
          transform: translateY(20px);
        }
        .fade-slide-enter-active {
          opacity: 1;
          transform: translateY(0);
          transition: opacity 300ms, transform 300ms;
        }
        .fade-slide-exit {
          opacity: 1;
          transform: translateY(0);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .fade-slide-exit-active {
          opacity: 0;
          transform: translateY(-20px);
          transition: opacity 300ms, transform 300ms;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      `}</style>
    </div>
  );
}

// Add this component within the MeetingWorkflowEdit.jsx file before the main component export
function DeleteConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  isLoading,
}) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{message} </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:ring-2 focus:ring-brand-turq sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onConfirm}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Deleting...
                    </>
                  ) : (
                    "Delete"
                  )}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:ring-2 focus:ring-brand-turq sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

/**

 * Updates an existing meeting action via the API

 *

 * @param {Object} actionData - The updated action data

 * @param {number|string} actionId - The ID of the action to update

 * @returns {Promise<Object>} - The updated action from the server

 */

/**
 * Updates an existing meeting action via the API
 *
 * @param {Object} actionData - The updated action data
 * @param {number|string} actionId - The ID of the action to update
 * @returns {Promise<Object>} - The updated action from the server
 */
export const updateMeetingAction = async (
  actionData,
  actionId,
  meetingTypeId
) => {
  if (!actionId) {
    console.error("No action ID provided for update");
    return { success: false, error: "No action ID provided" };
  }

  try {
    // Transform action data to match server expectations
    const payload = {
      meetingTypeID: meetingTypeId,
      name: actionData.name,
      actionType: actionData.type || actionData.actionType,
      isGlobal: actionData.isGlobal || false,
      description: actionData.description || `Automated ${actionData.name}`,
      customData: {}, // Initialize empty object for customData
    };

    // Handle specific action types
    if (
      actionData.type === "follow_up_email" ||
      actionData.actionType === "follow_up_email"
    ) {
      // Email-specific data
      payload.customData = {
        emailPrompt: actionData.emailPrompt,
        version: 1, // Version can be used to track schema changes
      };
    } else if (
      actionData.type === "slack_post" ||
      actionData.actionType === "slack_post"
    ) {
      // Slack-specific data
      payload.customData = {
        slackPrompt: actionData.slackPrompt,
        slackChannelID: actionData.slackChannelID,
        version: 1,
      };
    } else if (
      actionData.type === "google_doc" ||
      actionData.actionType === "google_doc"
    ) {
      // Google Doc-specific data
      console.log("action data", actionData);

      // Include folder information in the customData
      payload.customData = {
        docPrompt: actionData.docPrompt,
        folderId: actionData.folderId,
        folderName: actionData.folderName,
        version: 1,
      };
    }

    // Convert customData to JSON if it's not already a string
    if (typeof payload.customData !== "string") {
      payload.customData = JSON.stringify(payload.customData);
    }

    console.log("Sending update with payload:", payload);

    const response = await fetch(
      `https://backend.scribbl.co/meeting-actions/${actionId}`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Failed to update meeting action: ${response.statusText}`
      );
    }

    const updatedAction = await response.json();
    console.log("Successfully updated action:", updatedAction);

    return {
      success: true,
      data: updatedAction,
    };
  } catch (error) {
    console.error("Error updating meeting action:", error);
    return {
      success: false,
      error: error.message || "Unknown error occurred",
    };
  }
};

/**

 * Creates a new meeting action via the API

 *

 * @param {Object} actionData - The action data to create

 * @param {number|string} meetingTypeId - The ID of the meeting type

 * @returns {Promise<Object>} - The created action from the server

 */

export const createMeetingAction = async (actionData, meetingTypeId) => {
  try {
    // Format payload for server

    const payload = {
      meetingTypeID: meetingTypeId,

      name: actionData.name,

      actionType: actionData.type || actionData.actionType,

      isGlobal: actionData.isGlobal || false,

      description: actionData.description || `Automated ${actionData.name}`,

      customData: {},
    };

    // Handle specific action types

    if (
      actionData.type === "follow_up_email" ||
      actionData.actionType === "follow_up_email"
    ) {
      payload.customData = {
        emailPrompt: actionData.emailPrompt,

        version: 1,
      };
    } else if (
      actionData.type === "slack_post" ||
      actionData.actionType === "slack_post"
    ) {
      payload.customData = {
        slackPrompt: actionData.slackPrompt,

        slackChannelID: actionData.slackChannelID,

        version: 1,
      };
    } else if (
      actionData.type === "google_doc" ||
      actionData.actionType === "google_doc"
    ) {
      payload.customData = {
        docPrompt: actionData.docPrompt,

        version: 1,
      };
    }

    // Convert customData to JSON

    if (typeof payload.customData !== "string") {
      payload.customData = JSON.stringify(payload.customData);
    }

    const response = await fetch("https://backend.scribbl.co/meeting-actions", {
      method: "POST",

      headers: { "Content-Type": "application/json" },

      credentials: "include",

      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(
        `Failed to create meeting action: ${response.statusText}`
      );
    }

    const createdAction = await response.json();

    return {
      success: true,

      data: createdAction,
    };
  } catch (error) {
    console.error("Error creating meeting action:", error);

    return {
      success: false,

      error: error.message || "Unknown error occurred",
    };
  }
};

/**

 * Deletes a meeting action via the API

 *

 * @param {number|string} actionId - The ID of the action to delete

 * @returns {Promise<Object>} - Success status

 */

export const deleteMeetingAction = async (actionId) => {
  try {
    const response = await fetch(
      `https://backend.scribbl.co/meeting-actions/${actionId}`,

      {
        method: "DELETE",

        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error(
        `Failed to delete meeting action: ${response.statusText}`
      );
    }

    return { success: true };
  } catch (error) {
    console.error("Error deleting meeting action:", error);

    return {
      success: false,

      error: error.message || "Unknown error occurred",
    };
  }
};
