import React, { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { useLocation } from "react-router-dom";
import amplitude from "amplitude-js";

const OutOfCredits = (props) => {
  const [firstOpen, setFirstOpen] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const openedFromMeet = query.get("openedFromMeet");

  useEffect(() => {
    if (openedFromMeet) {
      setFirstOpen(true);
    }
  }, [openedFromMeet]);

  useEffect(() => {
    if (props.user) {
      var eventProperties = {
        show_invite_incentive: props.user?.org?.ID !== 0 && props.userEmail,
      };
      amplitude.getInstance().logEvent("show out of credits", eventProperties);
    }
  }, [props.user, props.userEmail]);

  return (
    <div
      className="fixed inset-0 z-50 bg-gray-500/75 flex items-center justify-center overflow-y-auto"
      role="dialog"
      aria-modal="true"
    >
      {/* Outer wrapper takes up entire screen, 
          with background overlay and high z-index */}
      <div className="bg-white shadow-xl rounded-3xl p-7 max-w-3xl w-full mx-4 mt-2 sm:mx-auto">
        {/* Inner container that holds our locked message UI */}
        <div className="mx-auto max-w-2xl text-center">
          {firstOpen ? (
            <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              You're out of Scribbl credits 😔
            </p>
          ) : (
            <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              This meeting is currently locked 🔒
            </p>
          )}
        </div>
        <p className="mx-auto mt-1 max-w-3xl text-center leading-6 text-gray-600">
          Don’t worry, your meeting is safe with us. It’s locked because you
          recorded it while out of credits. Upgrade today to view this meeting
          and get unlimited meetings going forward.
        </p>
        <div className="mx-auto mt-6 grid max-w-lg grid-cols-1 items-center gap-y-3 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
          <div className="relative bg-white shadow-2xl rounded-3xl p-4 ring-1 ring-gray-900/10 sm:p-6">
            <h3
              id="tier-pro"
              className="text-base font-semibold leading-7 text-brand-green"
            >
              Upgrade Today
            </h3>
            <div className="mt-4 text-base text-gray-500">Starting at</div>
            <p className="flex items-baseline gap-x-2">
              <span className="text-4xl font-bold tracking-tight text-gray-900">
                $13
              </span>
              <span className="text-base text-gray-500">/month</span>
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Unlock this meeting, unlimited meetings, and more great features.
            </p>
            <ul
              role="list"
              className="mt-8 space-y-3 text-sm leading-6 text-gray-600 sm:mt-6"
            >
              <li className="flex gap-x-3">
                <CheckIcon
                  className="h-6 w-5 flex-none text-brand-green"
                  aria-hidden="true"
                />
                Unlock all "out of credits" meetings
              </li>
              <li className="flex gap-x-3">
                <CheckIcon
                  className="h-6 w-5 flex-none text-brand-green"
                  aria-hidden="true"
                />
                Unlimited meetings going forward
              </li>
              <li className="flex gap-x-3">
                <CheckIcon
                  className="h-6 w-5 flex-none text-brand-green"
                  aria-hidden="true"
                />
                AI-generated notes and action items
              </li>
              <li className="flex gap-x-3">
                <CheckIcon
                  className="h-6 w-5 flex-none text-brand-green"
                  aria-hidden="true"
                />
                Video recording
              </li>
              <li className="flex gap-x-3">
                <CheckIcon
                  className="h-6 w-5 flex-none text-brand-green"
                  aria-hidden="true"
                />
                Access to many more great features...
              </li>
            </ul>
            <a
              href="/pricing"
              target="_blank"
              rel="noreferrer"
              aria-describedby="tier-pro"
              className="bg-brand-green text-white shadow hover:bg-brand-green mt-8 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-green sm:mt-10"
            >
              View Plans
            </a>
          </div>
          <div className="bg-white/60 sm:mx-8 lg:mx-0 sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none rounded-3xl p-4 ring-1 ring-gray-900/10 sm:p-6">
            <h3
              id="leave-review-card"
              className="text-base font-semibold leading-7 text-brand-green"
            >
              Earn Free Credits
            </h3>
            <p className="mt-4 flex items-baseline gap-x-2">
              <span className="text-4xl font-bold tracking-tight text-gray-900">
                Earn free credits 💰
              </span>
            </p>
            <p className="mt-6 text-base leading-6 text-gray-600">
              Write us a 5-star review in the Chrome Store. Once completed, send
              mike@scribbl.co a screenshot of the review.
            </p>
            <a
              href="https://chromewebstore.google.com/detail/scribbl-ai-meeting-notes/kmjmlilenakedodldceipdnmmnfkahni/reviews"
              target="_blank"
              onClick={() => {
                amplitude
                  .getInstance()
                  .logEvent("clicked chrome store incentive");
              }}
              rel="noreferrer"
              aria-describedby="leave-review-card"
              className="text-brand-green ring-1 ring-inset ring-brand-green hover:ring-brand-green mt-6 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-green sm:mt-6"
            >
              Earn 15 Free Credits
            </a>
            <div>
              <div className="flex mt-6">
                <div className="flex-1 border-t border-gray-300 mt-3"></div>
                <div className="mx-3 text-gray-700">OR</div>
                <div className="flex-1 border-t border-gray-300 mt-3"></div>
              </div>
              <p className="mt-4 text-base leading-6 text-gray-600">
                Write us a 5-star review on G2. Once completed, send
                mike@scribbl.co a screenshot of the review.
              </p>
              <a
                href="https://www.g2.com/products/scribbl/take_survey"
                target="_blank"
                rel="noreferrer"
                aria-describedby="leave-review-card"
                onClick={() => {
                  amplitude.getInstance().logEvent("clicked g2 incentive");
                }}
                className="text-brand-green ring-1 ring-inset ring-brand-green hover:ring-brand-green mt-6 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-green sm:mt-6"
              >
                Earn 3 Free Months of Scribbl Pro
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutOfCredits;
