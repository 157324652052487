import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import * as PhosphorIcons from "phosphor-react";

// Format title text
function formatTitle(key = "") {
  if (!key) return "";
  return key
    .replace(/[_-]+/g, " ")
    .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
    );
}

// Get section icon based on name or icon property
function getSectionIcon(section) {
  // If section has an icon property, use it directly
  if (section.icon) {
    const IconComponent = PhosphorIcons[section.icon];
    // Return the icon if it exists in phosphor-react
    if (IconComponent) {
      return <IconComponent size={16} weight="fill" />;
    }
  }

  // Fallback to inferred icon based on section name
  const key = section.title.toLowerCase();

  if (key.includes("summary") || key.includes("overview")) {
    return <PhosphorIcons.FileText size={16} weight="fill" />;
  } else if (key.includes("attendees") || key.includes("participants")) {
    return <PhosphorIcons.Users size={16} weight="fill" />;
  } else if (
    key.includes("action") ||
    key.includes("task") ||
    key.includes("todo")
  ) {
    return <PhosphorIcons.CheckSquare size={16} weight="fill" />;
  } else if (key.includes("time") || key.includes("schedule")) {
    return <PhosphorIcons.Clock size={16} weight="fill" />;
  } else if (key.includes("decision") || key.includes("conclusion")) {
    return <PhosphorIcons.CheckCircle size={16} weight="fill" />;
  } else if (key.includes("question") || key.includes("q&a")) {
    return <PhosphorIcons.Question size={16} weight="fill" />;
  } else if (key.includes("pricing") || key.includes("cost")) {
    return <PhosphorIcons.CurrencyDollar size={16} weight="fill" />;
  }

  // Default icon
  return <PhosphorIcons.FileText size={16} weight="fill" />;
}

// Format timestamp for display
function formatTimestamp(seconds) {
  if (!seconds && seconds !== 0) return "";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  } else {
    return `${minutes}:${secs.toString().padStart(2, "0")}`;
  }
}

export default function EnhancedMeetingNotes({
  data,
  onTimeJump,
  isLoading,
  meetingTemplates = [],
  selectedMeetingTemplateId,
  setSelectedMeetingTemplateId,
  onRegen,
}) {
  const [activeSection, setActiveSection] = useState(null);
  const [expandedSections, setExpandedSections] = useState({});
  const [copySuccess, setCopySuccess] = useState(false);
  const [sectionProgress, setSectionProgress] = useState({});
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [showTemplateMenu, setShowTemplateMenu] = useState(false);
  const [allSectionsExpanded, setAllSectionsExpanded] = useState(true);
  const [showGenerateTemplateMenu, setShowGenerateTemplateMenu] =
    useState(false);

  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const contentRef = useRef(null);
  const containerRef = useRef(null);
  const navigatorRef = useRef(null);
  const sectionRefs = useRef({});
  const sectionOrder = useRef([]);
  const contentScrollRef = useRef(0);
  const contentHeightRef = useRef(0);
  const programmaticScrollRef = useRef(false);
  const scrollListenerAttachedRef = useRef(false);

  // Parse document sections from the new format
  const documentSections = data?.sections || [];

  // Auto-select first template if none selected
  useEffect(() => {
    if (
      (!data || !documentSections || documentSections.length === 0) &&
      meetingTemplates.length > 0 &&
      !selectedMeetingTemplateId
    ) {
      setSelectedMeetingTemplateId(meetingTemplates[0].ID);
    }
  }, [
    data,
    documentSections,
    meetingTemplates,
    selectedMeetingTemplateId,
    setSelectedMeetingTemplateId,
  ]);

  // Initialize with first section active and all sections expanded
  useEffect(() => {
    if (documentSections && documentSections.length > 0) {
      const sectionIds = documentSections.map((section) => section.id);
      sectionOrder.current = sectionIds;

      if (sectionIds.length > 0 && !activeSection) {
        setActiveSection(sectionIds[0]);
      }

      // Initialize all sections as expanded
      const initialExpandState = {};
      sectionIds.forEach((id) => {
        initialExpandState[id] = true;
      });
      setExpandedSections(initialExpandState);

      // Initialize section progress
      const initialProgress = {};
      sectionIds.forEach((id) => {
        initialProgress[id] = 0;
      });
      setSectionProgress(initialProgress);
    }
  }, [data]);

  // Toggle sections
  const toggleSection = (sectionId, e) => {
    if (e) e.stopPropagation();

    setExpandedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));

    // Update allSectionsExpanded state based on the new state
    const updatedExpandState = {
      ...expandedSections,
      [sectionId]: !expandedSections[sectionId],
    };

    const allExpanded = Object.values(updatedExpandState).every(
      (val) => val === true
    );
    setAllSectionsExpanded(allExpanded);
  };

  // Toggle all sections
  const toggleAllSections = (expanded) => {
    if (!documentSections || documentSections.length === 0) return;

    const sectionIds = documentSections.map((section) => section.id);
    const newState = {};

    sectionIds.forEach((id) => {
      newState[id] = expanded;
    });

    setExpandedSections(newState);
    setAllSectionsExpanded(expanded);
  };

  // Navigate to section
  const navigateToSection = useCallback((sectionId) => {
    setActiveSection(sectionId);
    programmaticScrollRef.current = true;

    // Scroll to section even if collapsed
    setTimeout(() => {
      if (sectionRefs.current[sectionId] && contentRef.current) {
        const sectionEl = sectionRefs.current[sectionId];
        const yOffset = -20; // Small offset

        // Get the section's position relative to the content container
        const sectionRect = sectionEl.getBoundingClientRect();
        const containerRect = contentRef.current.getBoundingClientRect();
        const relativeTop =
          sectionRect.top - containerRect.top + contentRef.current.scrollTop;

        // Scroll to the section
        contentRef.current.scrollTo({
          top: relativeTop + yOffset,
          behavior: "smooth",
        });

        // Reset programmatic scroll flag after animation completes
        setTimeout(() => {
          programmaticScrollRef.current = false;
        }, 500);
      }
    }, 100);
  }, []);

  // Scroll to top
  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  // Copy notes to clipboard
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(data, null, 2));
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error("Copy failed:", err);
    }
  };

  // Handle template selection
  const handleSelectTemplate = (templateId) => {
    setSelectedMeetingTemplateId(templateId);
    setShowTemplateMenu(false);

    if (onRegen && typeof onRegen === "function") {
      // Pass the templateId directly to onRegen
      onRegen(templateId);
    }
  };

  // Handle template selection for generation
  const handleSelectGenerateTemplate = (templateId) => {
    setSelectedMeetingTemplateId(templateId);
    setShowGenerateTemplateMenu(false);
  };

  // Set up the scroll handler that manages both tracking and progress calculation
  const setupScrollHandler = useCallback(() => {
    if (
      !contentRef.current ||
      !documentSections.length ||
      scrollListenerAttachedRef.current
    )
      return;

    const handleScroll = () => {
      if (programmaticScrollRef.current) return;

      const scrollTop = contentRef.current.scrollTop;
      const scrollHeight = contentRef.current.scrollHeight;
      const clientHeight = contentRef.current.clientHeight;

      // Update refs
      contentScrollRef.current = scrollTop;
      contentHeightRef.current = scrollHeight;

      // Show back to top button after scrolling down a bit
      setShowBackToTop(scrollTop > 300);

      // Find the active section based on scroll position
      let currentSection = null;
      let minDistance = Infinity;

      sectionOrder.current.forEach((sectionId) => {
        const sectionEl = sectionRefs.current[sectionId];
        if (!sectionEl) return;

        const sectionRect = sectionEl.getBoundingClientRect();
        const contentRect = contentRef.current.getBoundingClientRect();

        // Calculate how visible the section is in the viewport
        const visibleTop = Math.max(sectionRect.top, contentRect.top);
        const visibleBottom = Math.min(sectionRect.bottom, contentRect.bottom);
        const visibleHeight = Math.max(0, visibleBottom - visibleTop);

        // Use visibility as priority, with position as tiebreaker
        if (visibleHeight > 0) {
          const distanceFromTop = Math.abs(sectionRect.top - contentRect.top);
          if (distanceFromTop < minDistance) {
            minDistance = distanceFromTop;
            currentSection = sectionId;
          }
        }
      });

      // Update active section without affecting expanded state
      if (currentSection && currentSection !== activeSection) {
        setActiveSection(currentSection);
      }

      // Calculate reading progress for each section
      const newProgress = { ...sectionProgress };

      sectionOrder.current.forEach((sectionId) => {
        const sectionEl = sectionRefs.current[sectionId];
        if (!sectionEl) return;

        const sectionTop = sectionEl.offsetTop;
        const sectionHeight = sectionEl.offsetHeight;
        const sectionBottom = sectionTop + sectionHeight;

        // If scrolled past this section
        if (scrollTop > sectionBottom) {
          newProgress[sectionId] = 100;
        }
        // If within this section
        else if (scrollTop > sectionTop) {
          const progress = Math.min(
            100,
            Math.round(((scrollTop - sectionTop) / sectionHeight) * 100)
          );
          newProgress[sectionId] = progress;
        } else {
          // Reset progress if we're above this section
          newProgress[sectionId] = 0;
        }
      });

      setSectionProgress(newProgress);
    };

    // Add scroll event listener
    contentRef.current.addEventListener("scroll", handleScroll);
    scrollListenerAttachedRef.current = true;

    // Initial calculation
    setTimeout(handleScroll, 100);

    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", handleScroll);
        scrollListenerAttachedRef.current = false;
      }
    };
  }, [documentSections, activeSection, sectionProgress]);

  // Track scroll position and update active section
  useLayoutEffect(() => {
    // Wait until after initial render when all refs are populated
    requestAnimationFrame(() => {
      if (contentRef.current && documentSections.length > 0) {
        setupScrollHandler();
      }
    });

    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", () => {});
        scrollListenerAttachedRef.current = false;
      }
    };
  }, [data, setupScrollHandler]);

  // Find current template
  const currentTemplate = meetingTemplates.find(
    (t) => String(t.ID) === String(selectedMeetingTemplateId)
  );
  const templateName = currentTemplate ? currentTemplate.title : "Default";

  // Process text with inline links
  const processInlineLinks = (text, links = []) => {
    if (typeof text !== "string" || !links || links.length === 0) {
      return processTimestamps(text); // Use the existing timestamp processor if no links
    }

    // Sort links by start_index to process them in order
    const sortedLinks = [...links].sort(
      (a, b) => a.start_index - b.start_index
    );

    // Split the text into segments with links
    let currentIndex = 0;
    const segments = [];

    sortedLinks.forEach((link, index) => {
      // Add regular text before the link
      if (link.start_index > currentIndex) {
        const textSegment = text.substring(currentIndex, link.start_index);
        if (textSegment) {
          segments.push(processTimestamps(textSegment));
        }
      }

      // Add the linked segment
      const linkText = text.substring(link.start_index, link.end_index);
      segments.push(
        <button
          key={`link-${index}`}
          onClick={() => onTimeJump && onTimeJump(link.start_time)}
          className="inline-flex items-center text-brand-turq hover:text-brand-turq/80 rounded px-1 py-0.5 font-medium bg-brand-turq/5 hover:bg-brand-turq/10 transition-colors group"
          title={link.label || `Jump to ${formatTimestamp(link.start_time)}`}
        >
          <svg
            className="w-3 h-3 mr-0.5 opacity-0 group-hover:opacity-100 transition-opacity"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
            />
          </svg>
          {linkText}
        </button>
      );

      // Update current position
      currentIndex = link.end_index;
    });

    // Add any remaining text after the last link
    if (currentIndex < text.length) {
      segments.push(processTimestamps(text.substring(currentIndex)));
    }

    return segments;
  };

  // Process timestamps in text to make them clickable
  const processTimestamps = (text) => {
    if (typeof text !== "string") return text;

    // Pattern to match timestamps like [00:00], [00:00:00], [0:00]
    const timestampRegex = /\[(\d{1,2}:)?\d{1,2}:\d{2}\]/g;
    const parts = text.split(timestampRegex);
    const matches = text.match(timestampRegex) || [];

    // No timestamps found
    if (matches.length === 0) return text;

    // Convert timestamp to seconds
    const convertToSeconds = (timestamp) => {
      const withoutBrackets = timestamp.replace(/[\[\]]/g, "");
      const parts = withoutBrackets.split(":").map(Number);

      if (parts.length === 2) {
        // MM:SS
        return parts[0] * 60 + parts[1];
      } else if (parts.length === 3) {
        // HH:MM:SS
        return parts[0] * 3600 + parts[1] * 60 + parts[2];
      }
      return 0;
    };

    // Rebuild text with clickable timestamps
    const result = [];
    for (let i = 0; i < parts.length; i++) {
      result.push(parts[i]);
      if (i < matches.length) {
        const seconds = convertToSeconds(matches[i]);
        result.push(
          <button
            key={`ts-${i}`}
            onClick={() => onTimeJump && onTimeJump(seconds)}
            className="inline-flex items-center text-brand-turq hover:text-brand-turq/80 rounded-full px-1.5 py-0.5 text-xs font-medium bg-brand-turq/5 hover:bg-brand-turq/10 transition-colors"
          >
            <svg
              className="w-2.5 h-2.5 mr-0.5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
              />
            </svg>
            {matches[i]}
          </button>
        );
      }
    }

    return result;
  };

  // Render content blocks based on type
  const renderContentBlock = (block) => {
    if (!block || !block.type) {
      return null;
    }

    switch (block.type) {
      case "paragraph":
        return (
          <div className="mb-4">
            <p className="text-gray-800 leading-relaxed text-base tracking-normal">
              {block.links && block.links.length > 0
                ? processInlineLinks(block.text, block.links)
                : processTimestamps(block.text)}
            </p>
          </div>
        );

      case "heading":
        const HeadingTag = `h${block.level || 3}`;
        return (
          <HeadingTag
            className={`text-gray-900 font-medium mb-3 ${
              block.level <= 2 ? "text-xl mt-6" : "text-lg mt-4"
            }`}
          >
            {block.links && block.links.length > 0
              ? processInlineLinks(block.text, block.links)
              : processTimestamps(block.text)}
          </HeadingTag>
        );

      case "list":
        return (
          <ul className="list-disc ml-5 mb-4 space-y-2 text-gray-800">
            {(block.items || []).map((item, i) => (
              <li key={i} className="leading-relaxed">
                {processTimestamps(item)}
              </li>
            ))}
          </ul>
        );

      case "metadata":
        return (
          <div className="flex items-center mb-2 text-gray-700">
            <span className="text-gray-500 text-sm font-medium mr-2">
              {block.label}:
            </span>
            <span>{block.value}</span>
          </div>
        );

      case "action_item":
        return (
          <div className="mb-4 p-3 bg-brand-turq/5 rounded-lg border border-brand-turq/10">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <PhosphorIcons.CheckSquare
                  size={18}
                  className="text-brand-turq"
                  weight="fill"
                />
              </div>
              <div className="ml-2 flex-1">
                <div className="text-gray-800 font-medium">
                  {block.links && block.links.length > 0
                    ? processInlineLinks(block.text, block.links)
                    : processTimestamps(block.text)}
                </div>
                <div className="mt-1 flex flex-wrap gap-x-4 gap-y-1 items-center text-xs text-gray-500">
                  {block.assignee && (
                    <div className="flex items-center">
                      <PhosphorIcons.User size={12} className="mr-1" />
                      <span>{block.assignee}</span>
                    </div>
                  )}
                  {block.due_date && (
                    <div className="flex items-center">
                      <PhosphorIcons.Calendar size={12} className="mr-1" />
                      <span>{block.due_date}</span>
                    </div>
                  )}
                  {(block.start_time || block.start_time === 0) &&
                    onTimeJump && (
                      <button
                        onClick={() => onTimeJump(block.start_time)}
                        className="flex items-center text-brand-turq hover:text-brand-turq/80"
                      >
                        <PhosphorIcons.Clock size={12} className="mr-1" />
                        <span>{formatTimestamp(block.start_time)}</span>
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className="mb-4">
            <p className="text-gray-800 leading-relaxed text-base tracking-normal">
              {block.links && block.links.length > 0
                ? processInlineLinks(block.text, block.links)
                : processTimestamps(String(block.text) || "")}
            </p>
          </div>
        );
    }
  };

  // Loading state - use the existing loading UI
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-center">
          <svg
            className="animate-spin h-8 w-8 text-brand-turq mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
            ></path>
          </svg>
          <p className="mt-3 text-sm text-gray-600">Preparing your notes...</p>
        </div>
      </div>
    );
  }

  // Empty state with simple generation UI
  if (!data || !documentSections || documentSections.length === 0) {
    return (
      <div className="h-full flex flex-col">
        {/* Simple header */}
        <div className="px-4 py-3 border-b border-gray-200 flex items-center justify-between bg-white z-10">
          <div className="flex items-center">
            <div className="text-xs text-gray-500">Meeting Notes</div>
          </div>
        </div>

        {/* Simple centered content */}
        <div className="flex-1 flex items-center justify-center p-6 bg-gray-50">
          <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6 max-w-md w-full">
            <div className="text-center mb-6">
              <div className="inline-flex items-center justify-center w-12 h-12 bg-brand-turq/10 rounded-full mb-4">
                <PhosphorIcons.NotePencil
                  size={24}
                  className="text-brand-turq"
                />
              </div>
              <h3 className="text-lg font-medium text-gray-800 mb-2">
                No Meeting Notes
              </h3>
              <p className="text-sm text-gray-600">
                Generate AI notes from your meeting transcript
              </p>
            </div>

            {meetingTemplates.length > 0 && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Note Format
                </label>
                <div className="relative">
                  <button
                    onClick={() =>
                      setShowGenerateTemplateMenu(!showGenerateTemplateMenu)
                    }
                    className="w-full flex items-center justify-between px-3 py-2 text-sm bg-white border border-gray-300 rounded-md hover:border-gray-400"
                  >
                    <span>{currentTemplate?.title || "Select a template"}</span>
                    <PhosphorIcons.CaretDown size={16} />
                  </button>

                  {showGenerateTemplateMenu && (
                    <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-md shadow-md max-h-48 overflow-y-auto">
                      {meetingTemplates.map((template) => (
                        <button
                          key={template.ID}
                          onClick={() =>
                            handleSelectGenerateTemplate(template.ID)
                          }
                          className={`w-full text-left px-3 py-2 text-sm hover:bg-gray-50 
                          ${
                            String(selectedMeetingTemplateId) ===
                            String(template.ID)
                              ? "bg-brand-turq/5 text-brand-turq font-medium"
                              : "text-gray-700"
                          }`}
                        >
                          {template.title}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}

            <button
              onClick={() => onRegen && onRegen(selectedMeetingTemplateId)}
              disabled={
                !selectedMeetingTemplateId || meetingTemplates.length === 0
              }
              className={`w-full py-2 px-4 rounded-md font-medium 
                ${
                  selectedMeetingTemplateId
                    ? "bg-brand-turq text-white hover:bg-brand-turq/90"
                    : "bg-gray-100 text-gray-400 cursor-not-allowed"
                }`}
            >
              Generate Notes
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Main content view for when we have notes
  return (
    <div
      ref={containerRef}
      className="bg-white border border-gray-200 shadow-sm h-full flex flex-col overflow-hidden font-sans"
    >
      {/* Simplified header with better template selector */}
      <div className="px-4 py-3 border-b border-gray-200 flex items-center justify-between bg-white z-10">
        <div className="flex items-center">
          <div className="text-xs text-gray-500 mr-4">
            {documentSections.length} sections
          </div>

          {meetingTemplates?.length > 0 && (
            <div className="relative inline-block">
              <button
                onClick={() => setShowTemplateMenu(!showTemplateMenu)}
                className="flex items-center px-3 py-1.5 text-sm bg-gray-50 hover:bg-gray-100 border border-gray-200 rounded-md transition-colors"
                aria-label="Change template"
                title="Change template format"
              >
                <span className="text-gray-600 mr-1.5">Template:</span>
                <span className="font-medium text-gray-800 mr-1">
                  {templateName}
                </span>
                <PhosphorIcons.CaretDown size={14} className="text-gray-500" />
              </button>

              {showTemplateMenu && (
                <div className="absolute z-50 mt-1 w-56 bg-white rounded-md border border-gray-200 shadow-md py-1">
                  <div className="px-3 py-2 text-xs text-gray-500 border-b border-gray-100">
                    Select a template format
                  </div>
                  {meetingTemplates.map((template) => (
                    <button
                      key={template.ID}
                      onClick={() => handleSelectTemplate(template.ID)}
                      className={`w-full text-left px-3 py-2 text-sm hover:bg-gray-50 transition-colors flex items-center
                        ${
                          String(selectedMeetingTemplateId) ===
                          String(template.ID)
                            ? "bg-brand-turq/5 text-brand-turq"
                            : "text-gray-700"
                        }`}
                    >
                      {String(selectedMeetingTemplateId) ===
                        String(template.ID) && (
                        <PhosphorIcons.CheckCircle
                          size={14}
                          className="mr-2 text-brand-turq"
                          weight="fill"
                        />
                      )}
                      <span
                        className={
                          String(selectedMeetingTemplateId) ===
                          String(template.ID)
                            ? "font-medium"
                            : ""
                        }
                      >
                        {template.title}
                      </span>
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex items-center space-x-3">
          <button
            onClick={() => toggleAllSections(!allSectionsExpanded)}
            className="text-xs text-gray-600 px-3 py-1.5 hover:bg-gray-50 rounded border border-gray-200 transition-colors"
          >
            {allSectionsExpanded ? "Collapse All" : "Expand All"}
          </button>

          <button
            onClick={handleCopy}
            className={`
              px-3 py-1.5 text-xs rounded border transition-colors
              ${
                copySuccess
                  ? "bg-green-50 text-green-700 border-green-200"
                  : "text-gray-600 border-gray-200 hover:bg-gray-50"
              }
            `}
          >
            <span className="flex items-center">
              {copySuccess ? (
                <>
                  <PhosphorIcons.CheckCircle
                    className="h-3.5 w-3.5 mr-1.5"
                    weight="fill"
                  />
                  <span>Copied</span>
                </>
              ) : (
                <>
                  <PhosphorIcons.Copy className="h-3.5 w-3.5 mr-1.5" />
                  <span>Copy</span>
                </>
              )}
            </span>
          </button>
        </div>
      </div>

      {/* Main content with improved layout */}
      <div className="flex-1 overflow-hidden flex">
        {/* Refined navigator sidebar */}
        <div
          ref={navigatorRef}
          className={`
    flex-shrink-0 border-r border-gray-200 bg-gray-50 relative
    transition-width duration-300 ease-in-out overflow-hidden
    ${sidebarExpanded ? "w-56" : "w-12"}
  `}
          onMouseEnter={() => setSidebarExpanded(true)}
          onMouseLeave={() => setSidebarExpanded(false)}
        >
          {/* Fixed height container with strict overflow control */}
          <div className="absolute inset-0 overflow-y-auto overflow-x-hidden scrollbar-thin py-3">
            <div className="px-2 flex flex-col space-y-1.5">
              {documentSections.map((section) => {
                const isActive = activeSection === section.id;

                return (
                  <button
                    key={section.id}
                    className={`
              flex items-center rounded-md w-full
              ${
                isActive
                  ? "bg-brand-turq text-white"
                  : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
              }
              transition-colors duration-150 h-9 overflow-hidden
            `}
                    onClick={() => navigateToSection(section.id)}
                    aria-label={section.title}
                  >
                    {/* Icon container with precise dimensions */}
                    <div className="flex items-center justify-center w-8 h-9 flex-shrink-0">
                      <div className="flex items-center justify-center w-4 h-4">
                        {getSectionIcon(section)}
                      </div>
                    </div>

                    {/* Text with controlled visibility and strict overflow prevention */}
                    <span
                      className={`
                text-sm font-medium truncate transition-opacity duration-200
                ${sidebarExpanded ? "opacity-100 w-full" : "opacity-0 w-0"}
              `}
                      style={{
                        maxWidth: sidebarExpanded ? "calc(100% - 44px)" : "0px",
                        overflow: "hidden",
                      }}
                    >
                      {section.title}
                    </span>
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        {/* Content area with clean styling */}
        <div ref={contentRef} className="flex-1 overflow-y-auto bg-white">
          <div className="max-w-3xl mx-auto py-6 px-8">
            {documentSections.map((section, index) => {
              const isExpanded = expandedSections[section.id] || false;
              const isActive = activeSection === section.id;

              return (
                <div
                  key={section.id}
                  id={`section-${section.id}`}
                  ref={(el) => (sectionRefs.current[section.id] = el)}
                  className={`
                    mb-8 pb-8 scroll-mt-6
                    ${
                      index !== documentSections.length - 1
                        ? "border-b border-gray-100"
                        : ""
                    }
                  `}
                >
                  {/* Section header with clear interaction */}
                  <div
                    className={`
                      flex items-center cursor-pointer group mb-4
                      ${isActive ? "text-brand-turq" : "text-gray-700"}
                    `}
                    onClick={() => toggleSection(section.id)}
                  >
                    <div className="flex items-center">
                      <div
                        className={`mr-3 ${
                          isActive ? "text-brand-turq" : "text-gray-400"
                        }`}
                      >
                        {getSectionIcon(section)}
                      </div>

                      <h2 className="text-xl font-medium">{section.title}</h2>
                    </div>

                    <button
                      className={`
                        ml-auto p-1 text-gray-400 rounded-full
                        ${
                          isActive
                            ? "hover:bg-brand-turq/10"
                            : "hover:bg-gray-100"
                        }
                        group-hover:text-gray-600 transition-colors
                      `}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleSection(section.id, e);
                      }}
                    >
                      {isExpanded ? (
                        <PhosphorIcons.CaretDown size={18} weight="bold" />
                      ) : (
                        <PhosphorIcons.CaretRight size={18} weight="bold" />
                      )}
                    </button>
                  </div>

                  {/* Expanded section content with improved readability */}
                  <div
                    className={`
                      transition-all duration-300 overflow-hidden
                      ${
                        isExpanded
                          ? "max-h-[9999px] opacity-100"
                          : "max-h-0 opacity-0"
                      }
                    `}
                  >
                    <div className="prose prose-slate max-w-none prose-p:text-gray-800 prose-headings:text-gray-900">
                      {section.content &&
                        section.content.map((block, blockIndex) => (
                          <div key={`${section.id}-block-${blockIndex}`}>
                            {renderContentBlock(block)}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Back to top button */}
      {showBackToTop && (
        <button
          onClick={scrollToTop}
          className="absolute right-4 bottom-4 z-10 bg-white text-gray-600 rounded-full p-2 shadow-sm border border-gray-200 hover:bg-gray-50 transition-colors"
          aria-label="Back to top"
        >
          <PhosphorIcons.ArrowUp size={16} weight="bold" />
        </button>
      )}
    </div>
  );
}
