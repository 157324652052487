import React, { useState, useEffect } from "react";
import {
  CheckIcon,
  XIcon,
  MailIcon,
  RefreshIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { updateMeetingAction } from "./MeetingWorkflowEdit";

/**
 * EditFollowUpEmail Component
 *
 * A simplified form-based UI for editing follow-up email automations.
 */
export default function EditFollowUpEmail({
  action,
  meetingType,
  userEmail = "",
  onComplete,
  onCancel,
  onSwitchToConversational,
}) {
  // Get actionData from action prop
  const actionId = action?.id || action?.ID;
  const actionName = action?.name || "";

  // Parse customData if it exists and is a string
  let customData = {};
  if (action?.customData) {
    if (typeof action.customData === "string") {
      try {
        customData = JSON.parse(action.customData);
      } catch (e) {
        console.error("Error parsing customData:", e);
      }
    } else {
      customData = action.customData;
    }
  }

  console.log("customdata", customData, action);
  // Extract emailPrompt from customData or directly from action
  const initialEmailPrompt =
    customData?.emailPrompt || action?.emailPrompt || "";

  // State management
  const [emailName, setEmailName] = useState(
    actionName || `${meetingType?.name || "Meeting"} Follow-up Email`
  );
  const [emailPrompt, setEmailPrompt] = useState(initialEmailPrompt);
  const [validationErrors, setValidationErrors] = useState({});
  const [dirty, setDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [notification, setNotification] = useState(null);

  // Validation
  useEffect(() => {
    const errors = {};

    if (!emailName.trim()) {
      errors.name = "Automation name is required";
    }

    if (!emailPrompt.trim()) {
      errors.prompt = "Email instructions are required";
    }

    setValidationErrors(errors);
  }, [emailName, emailPrompt]);

  // Mark as dirty when changes are made
  useEffect(() => {
    if (
      (emailName !== actionName &&
        emailName !== `${meetingType?.name || "Meeting"} Follow-up Email`) ||
      emailPrompt !== initialEmailPrompt
    ) {
      setDirty(true);
    }
  }, [
    emailName,
    emailPrompt,
    actionName,
    initialEmailPrompt,
    meetingType?.name,
  ]);

  // Auto-hide notification after 5 seconds
  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  // Helper function for class names
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Show notification
  const showNotification = (message, type = "success") => {
    setNotification({ message, type });
  };

  const handleSave = async () => {
    // Validate form
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    setIsSubmitting(true);
    setError("");

    // Prepare the data that will be sent to the server
    const emailData = {
      name: emailName,
      type: "follow_up_email",
      emailPrompt,
    };

    if (actionId) {
      // Update existing action
      const result = await updateMeetingAction(
        emailData,
        actionId,
        meetingType?.ID || meetingType?.id
      );

      setIsSubmitting(false);

      if (result.success) {
        // Show success notification
        showNotification(`"${emailName}" saved successfully`);

        // Notify parent component
        if (onComplete) {
          onComplete(result.data);
        }
      } else {
        // Show error
        setError(result.error || "Failed to update automation");
        showNotification("Failed to save changes", "error");
      }
    } else {
      // For new actions or when we don't have an actionId
      setIsSubmitting(false);

      if (onComplete) {
        onComplete(emailData);
      }
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden h-full flex flex-col">
      <div className="p-6 flex flex-col flex-1 overflow-hidden">
        <div className="flex items-center justify-between mb-6 flex-shrink-0">
          <h2 className="text-xl font-semibold text-gray-900">
            Edit Follow-up Email Automation
          </h2>
          <div className="flex space-x-3">
            {onSwitchToConversational && (
              <button
                onClick={onSwitchToConversational}
                className="flex items-center px-3 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                <RefreshIcon className="h-4 w-4 mr-2" />
                Start Over
              </button>
            )}
          </div>
        </div>

        {/* Notification */}
        {notification && (
          <div
            className={`mb-4 p-3 rounded-md flex items-start ${
              notification.type === "success"
                ? "bg-green-50 text-green-700"
                : "bg-red-50 text-red-700"
            }`}
          >
            <div className="flex-shrink-0">
              {notification.type === "success" ? (
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              ) : (
                <XCircleIcon className="h-5 w-5 text-red-500" />
              )}
            </div>
            <div className="ml-3 flex-1">
              <p className="text-sm font-medium">{notification.message}</p>
            </div>
            <button
              className="flex-shrink-0 ml-auto"
              onClick={() => setNotification(null)}
            >
              <XIcon className="h-4 w-4 text-gray-400 hover:text-gray-600" />
            </button>
          </div>
        )}

        <div className="space-y-6 overflow-y-auto flex-1 pr-1">
          {/* Name field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Automation Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={emailName}
              onChange={(e) => setEmailName(e.target.value)}
              className={`w-full rounded-lg ${
                validationErrors.name ? "border-red-300" : "border-gray-200"
              } shadow-sm px-4 py-3 text-base focus:ring-brand-turq focus:border-brand-turq`}
              placeholder={`${meetingType?.name || "Meeting"} Follow-up Email`}
            />
            {validationErrors.name && (
              <p className="mt-1 text-sm text-red-600">
                {validationErrors.name}
              </p>
            )}
          </div>

          {/* Email prompt */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email Instructions <span className="text-red-500">*</span>
            </label>
            <p className="text-sm text-gray-500 mb-3">
              Define how the AI should structure and write your follow-up emails
            </p>
            <textarea
              value={emailPrompt}
              onChange={(e) => setEmailPrompt(e.target.value)}
              rows={8}
              className={`w-full rounded-lg ${
                validationErrors.prompt ? "border-red-300" : "border-gray-200"
              } shadow-sm px-4 py-3 text-base focus:ring-brand-turq focus:border-brand-turq`}
              placeholder="Write instructions for how the AI should create your follow-up emails. For example: 'Write a concise, friendly follow-up email that thanks the attendee for their time, summarizes the key points discussed, lists the action items with owners and deadlines, and suggests a timeline for the next meeting.'"
            />
            {validationErrors.prompt && (
              <p className="mt-1 text-sm text-red-600">
                {validationErrors.prompt}
              </p>
            )}
          </div>

          {/* Error message */}
          {error && (
            <div className="bg-red-50 border border-red-200 rounded-md p-3">
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}
        </div>

        {/* Action buttons */}
        <div className="mt-8 flex justify-between flex-shrink-0">
          <div>
            {onSwitchToConversational && (
              <button
                onClick={onSwitchToConversational}
                className="flex items-center px-4 py-2.5 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                disabled={isSubmitting}
              >
                <RefreshIcon className="h-4 w-4 mr-2" />
                Start Over with Guided Flow
              </button>
            )}
          </div>

          <div className="flex space-x-3">
            {onCancel && (
              <button
                onClick={onCancel}
                className="px-4 py-2.5 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                disabled={isSubmitting}
              >
                Cancel
              </button>
            )}
            <button
              onClick={handleSave}
              className={`px-5 py-2.5 rounded-lg text-white text-sm font-medium transition-colors ${
                Object.keys(validationErrors).length > 0 || isSubmitting
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-brand-turq hover:bg-brand-green shadow-sm"
              }`}
              disabled={
                Object.keys(validationErrors).length > 0 || isSubmitting
              }
            >
              {isSubmitting ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Saving...
                </>
              ) : dirty ? (
                "Save Changes"
              ) : (
                "Complete Step"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
