import React, { useState, useEffect, useRef } from "react";
import {
  CheckCircleIcon,
  ExclamationIcon,
  SparklesIcon,
  RefreshIcon,
} from "@heroicons/react/outline";

/**
 * EditMeetingTypeStep Component
 *
 * A form-based alternative to the conversational flow for editing meeting types.
 * This component shows all editable fields at once and supports immediate validation.
 * Now fetches its own data when given a meetingTypeId.
 *
 * Props:
 * - meetingTypeId: optional ID for editing an existing meeting type (component will fetch data itself)
 * - onDataChange: callback to bubble up the latest typed data
 * - onComplete: callback after successful save
 * - userEmail: string (for future use if needed)
 * - onSwitchToConversational: callback if user chooses to revert to a conversational flow
 * - setMeetingNoteTemplate: function to return any newly created note template (if desired)
 */
export default function EditMeetingTypeStep({
  meetingTypeId = null,
  onDataChange,
  onComplete,
  userEmail,
  onSwitchToConversational,
  setMeetingNoteTemplate = () => {},
}) {
  // --------------------------
  // State management
  // --------------------------
  const [typeName, setTypeName] = useState("");
  const [typeDescription, setTypeDescription] = useState("");
  const [selectedMeetings, setSelectedMeetings] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [isLoading, setIsLoading] = useState(meetingTypeId ? true : false);
  const [loadingError, setLoadingError] = useState("");

  // Description editing
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);

  // AI functionality
  const [aiGenerated, setAiGenerated] = useState(false);
  const [aiGenerating, setAiGenerating] = useState(false);
  const [aiError, setAiError] = useState("");
  const [aiGenerationStep, setAiGenerationStep] = useState(0);

  // Internal ref to store the meeting type ID
  const meetingTypeIdRef = useRef(meetingTypeId);

  // Refs
  const textareaRef = useRef(null);
  const originalValuesRef = useRef(null);

  // --------------------------
  // Constants
  // --------------------------
  const MAX_MEETING_SELECTIONS = 3;
  const FIXED_TEXTAREA_HEIGHT = 200;

  // --------------------------
  // Data Fetching
  // --------------------------
  useEffect(() => {
    if (meetingTypeId) {
      fetchMeetingType();
    } else {
      // Initialize with empty values if no ID
      originalValuesRef.current = {
        name: "",
        description: "",
        selectedMeetings: [],
      };
    }
  }, [meetingTypeId]);

  const fetchMeetingType = async () => {
    if (!meetingTypeId) return;

    setIsLoading(true);
    setLoadingError("");

    try {
      const response = await fetch(
        `https://backend.scribbl.co/meeting-types/${meetingTypeId}`,
        {
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch meeting type: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("what is data", data);

      // Update component state with fetched data
      setTypeName(data.meetingType.name || "");
      setTypeDescription(data.meetingType.description || "");
      if (data.selectedMeetings) {
        setSelectedMeetings(data.selectedMeetings);
      }

      // Store original values for dirty checking
      originalValuesRef.current = {
        name: data.name || "",
        description: data.description || "",
        selectedMeetings: [...(data.selectedMeetings || [])],
      };

      // Update the ref
      meetingTypeIdRef.current = meetingTypeId;

      // Reset dirty flag since we just loaded the data
      setDirty(false);

      // Notify parent of the loaded data if needed
      if (onDataChange) {
        onDataChange(data);
      }
    } catch (error) {
      console.error("Error fetching meeting type:", error);
      setLoadingError(error.message || "Failed to load meeting type data");
    } finally {
      setIsLoading(false);
    }
  };

  // --------------------------
  // Effects
  // --------------------------

  // Update character count when description changes
  useEffect(() => {
    setDescriptionCharCount(typeDescription.length);
  }, [typeDescription]);

  // Update parent component when data changes
  useEffect(() => {
    if (onDataChange) {
      onDataChange({
        name: typeName,
        description: typeDescription,
        selectedMeetings,
      });
    }
  }, [typeName, typeDescription, selectedMeetings, onDataChange]);

  // Validate inputs when they change
  useEffect(() => {
    const errors = {};

    // Only mark as error if the field is required AND empty
    if (typeName.trim() === "" && dirty) {
      errors.name = "Meeting type name is required";
    }

    if (typeDescription.trim() === "" && dirty) {
      errors.description = "Description is required";
    }

    setValidationErrors(errors);
  }, [typeName, typeDescription, dirty]);

  // Dirty check
  useEffect(() => {
    if (originalValuesRef.current === null) return;

    const originalName = originalValuesRef.current.name;
    const originalDescription = originalValuesRef.current.description;
    const originalSelectedMeetings = originalValuesRef.current.selectedMeetings;

    const nameChanged = typeName !== originalName;
    const descriptionChanged = typeDescription !== originalDescription;
    const meetingsChanged =
      JSON.stringify(selectedMeetings) !==
      JSON.stringify(originalSelectedMeetings);

    const isDirty = nameChanged || descriptionChanged || meetingsChanged;
    setDirty(isDirty);
  }, [typeName, typeDescription, selectedMeetings]);

  // --------------------------
  // Enhanced Textarea Component
  // --------------------------
  const renderEnhancedTextarea = () => {
    return (
      <div className="relative">
        <textarea
          ref={textareaRef}
          value={typeDescription}
          onChange={(e) => {
            setTypeDescription(e.target.value);
            setDescriptionCharCount(e.target.value.length);
            if (aiGenerated) setAiGenerated(false);
          }}
          style={{ height: `${FIXED_TEXTAREA_HEIGHT}px` }}
          className={`w-full rounded-lg border-2 ${
            validationErrors.description ? "border-red-300" : "border-gray-200"
          } shadow-sm px-4 py-3.5 text-base 
            focus:ring-brand-turq focus:border-brand-turq transition-all duration-200
            hover:border-gray-300 resize-none`}
          placeholder="Describe the purpose, typical content, and key characteristics of this meeting type..."
        />
        <div className="absolute top-2 right-3">
          <span className="text-xs text-gray-500 bg-white px-2 py-1 rounded-full border border-gray-200">
            {descriptionCharCount} chars
          </span>
        </div>
      </div>
    );
  };

  // --------------------------
  // AI Description Generation
  // --------------------------
  const generateAIDescription = async () => {
    if (selectedMeetings.length === 0) {
      setAiError(
        "Please select at least one example meeting for better results"
      );
      return;
    }

    if (!typeName.trim()) {
      setAiError("Please provide a meeting type name first");
      return;
    }

    setAiGenerating(true);
    setAiError("");
    setAiGenerationStep(1);

    // Step progression effect
    const interval = setInterval(() => {
      setAiGenerationStep((prev) => (prev < 3 ? prev + 1 : prev));
    }, 1500);

    try {
      // Real API call to generate meeting type description
      const meetingIDs = selectedMeetings.map((m) => m.ID);

      const resp = await fetch(
        "https://backend.scribbl.co/meeting-types/generate-suggestion",
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            meetingIDs,
            userProvidedName: typeName,
          }),
        }
      );

      if (!resp.ok) {
        const text = await resp.text();
        throw new Error(text || "Failed to generate suggestion");
      }

      const data = await resp.json();
      const generatedDescription = data.description || "";

      setTypeDescription(generatedDescription);
      setAiGenerated(true);
      setAiGenerating(false);
      setAiGenerationStep(3);
    } catch (error) {
      setAiError("Failed to generate description. Please try again.");
      setAiGenerating(false);
      setAiGenerationStep(0);
    } finally {
      clearInterval(interval);
    }
  };

  // --------------------------
  // Create/Update Logic (Self-Sufficient)
  // --------------------------
  const handleSave = async () => {
    // Validate form
    const errors = {};

    if (!typeName.trim()) {
      errors.name = "Meeting type name is required";
    }

    if (!typeDescription.trim()) {
      errors.description = "Description is required";
    }

    setValidationErrors(errors);

    // If there are errors, don't proceed
    if (Object.keys(errors).length > 0) {
      return;
    }

    // Proceed with create or update
    try {
      const method = meetingTypeIdRef.current ? "PUT" : "POST";
      const url = meetingTypeIdRef.current
        ? `https://backend.scribbl.co/meeting-types/${meetingTypeIdRef.current}`
        : "https://backend.scribbl.co/meeting-types";

      // First, get the current full meeting type to preserve all properties
      let currentMeetingType = { isDraft: true };

      // If we're editing an existing meeting type, fetch it first to get all properties
      if (meetingTypeIdRef.current) {
        try {
          const getResp = await fetch(
            `https://backend.scribbl.co/meeting-types/${meetingTypeIdRef.current}`,
            { credentials: "include" }
          );
          if (getResp.ok) {
            const data = await getResp.json();
            currentMeetingType = { ...data.meetingType };
          }
        } catch (err) {
          console.error("Failed to fetch current meeting type:", err);
        }
      }

      // Now update only the fields being edited while preserving the rest
      const updatedMeetingType = {
        ...currentMeetingType,
        name: typeName,
        description: typeDescription,
      };

      const resp = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(updatedMeetingType),
      });

      if (!resp.ok) {
        throw new Error("Failed to create/update the meeting type.");
      }

      const meetingTypeData = await resp.json();

      // If newly created, store the ID so subsequent saves will update
      if (!meetingTypeIdRef.current && meetingTypeData?.ID) {
        meetingTypeIdRef.current = meetingTypeData.ID;
      }

      // (Optional) Create a matching note template behind the scenes
      try {
        const templateResponse = await fetch(
          "https://backend.scribbl.co/meeting-templates",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
              title: `${typeName} Notes`,
              content: typeDescription,
            }),
          }
        );
        if (templateResponse.ok) {
          const templateData = await templateResponse.json();
          // Let parent know about the newly created note template
          setMeetingNoteTemplate(templateData);
        }
      } catch (templateErr) {
        console.error("Failed to create a note template:", templateErr);
        // We won't block meeting type save if note template fails
      }

      // Update original values to match current values
      originalValuesRef.current = {
        name: typeName,
        description: typeDescription,
        selectedMeetings: [...selectedMeetings],
      };

      // Mark not dirty anymore
      setDirty(false);

      if (onComplete) {
        onComplete(meetingTypeData);
      }
    } catch (err) {
      console.error(err);
      // In a larger app, you'd handle error UI or notifications here
    }
  };

  // --------------------------
  // Navigation & Save Logic
  // --------------------------
  const attemptStartOver = () => {
    // Directly switch to conversational flow without checking for unsaved changes
    onSwitchToConversational();
  };

  // --------------------------
  // Loading State
  // --------------------------
  if (isLoading) {
    return (
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <div className="p-6">
          <div className="flex items-center justify-center h-60">
            <div className="text-center">
              <svg
                className="animate-spin h-10 w-10 text-brand-turq mx-auto mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p className="text-gray-500">Loading meeting type data...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // --------------------------
  // Error State
  // --------------------------
  if (loadingError) {
    return (
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <div className="p-6">
          <div className="flex items-center justify-center h-60">
            <div className="text-center">
              <ExclamationIcon className="h-10 w-10 text-red-500 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                Error Loading Data
              </h3>
              <p className="text-gray-500 mb-4">{loadingError}</p>
              <button
                onClick={fetchMeetingType}
                className="px-4 py-2 bg-brand-turq text-white rounded-md hover:bg-brand-green transition-colors"
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // --------------------------
  // Main render
  // --------------------------
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
      <div className="p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">
            {meetingTypeId ? "Edit" : "Create"} Meeting Type
          </h2>
        </div>

        <div className="space-y-6">
          {/* Name field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Meeting Type Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={typeName}
              onChange={(e) => setTypeName(e.target.value)}
              className={`w-full rounded-lg ${
                validationErrors.name ? "border-red-300" : "border-gray-200"
              } shadow-sm px-4 py-3 text-base focus:ring-brand-turq focus:border-brand-turq`}
              placeholder="e.g. Customer Discovery Call"
            />
            {validationErrors.name && (
              <p className="mt-1 text-sm text-red-600">
                {validationErrors.name}
              </p>
            )}

            {/* Example suggestions */}
            <div className="mt-2 flex flex-wrap gap-2">
              <span className="text-xs text-gray-500 mr-1 mt-1">Examples:</span>
              {["Customer Discovery", "Therapy Session", "Weekly Stand-up"].map(
                (example) => (
                  <button
                    key={example}
                    onClick={() => setTypeName(example)}
                    className="px-2 py-1 text-xs bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
                  >
                    {example}
                  </button>
                )
              )}
            </div>
          </div>

          {/* Description */}
          <div>
            <div className="flex justify-between items-end mb-1">
              <label className="block text-sm font-medium text-gray-700">
                Meeting Type Description <span className="text-red-500">*</span>
              </label>

              {/* AI generation button */}
              {selectedMeetings.length > 0 && (
                <button
                  onClick={generateAIDescription}
                  disabled={aiGenerating}
                  className={`flex items-center text-sm ${
                    aiGenerating
                      ? "text-gray-400 cursor-wait"
                      : "text-brand-turq hover:text-brand-green"
                  }`}
                >
                  {aiGenerating ? (
                    <>
                      <svg
                        className="animate-spin h-4 w-4 mr-1.5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 
                          5.373 0 12h4zm2 5.291A7.962 7.962 
                          0 014 12H0c0 3.042 1.135 5.824 3 
                          7.938l3-2.647z"
                        ></path>
                      </svg>
                      Generating...
                    </>
                  ) : (
                    <>
                      <SparklesIcon className="h-4 w-4 mr-1.5" />
                      Generate with AI
                    </>
                  )}
                </button>
              )}
            </div>

            <p className="text-sm text-gray-500 mb-3">
              Describe what makes this meeting type unique and its key
              characteristics
            </p>

            {/* Enhanced text editor */}
            {renderEnhancedTextarea()}

            {validationErrors.description && (
              <p className="mt-1 text-sm text-red-600">
                {validationErrors.description}
              </p>
            )}

            {/* AI generation error */}
            {aiError && (
              <div className="mt-3 p-3 bg-red-50 border border-red-200 rounded-lg text-sm text-red-600 flex items-start">
                <ExclamationIcon className="h-4 w-4 mr-2 flex-shrink-0 mt-0.5" />
                <div>
                  <p>{aiError}</p>
                  <button
                    onClick={() => setAiError("")}
                    className="mt-1 text-red-700 hover:text-red-800 font-medium"
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            )}

            {/* AI generation success */}
            {aiGenerated && !aiError && (
              <div className="mt-3 p-3 bg-green-50 border border-green-200 rounded-lg text-sm text-green-700 flex items-start">
                <CheckCircleIcon className="h-4 w-4 mr-2 flex-shrink-0 mt-0.5" />
                <p>
                  Description successfully generated from your example meetings.
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Action buttons */}
        <div className="mt-8 flex justify-between">
          <div>
            {onSwitchToConversational && (
              <button
                onClick={attemptStartOver}
                className="flex items-center px-4 py-2.5 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                <RefreshIcon className="h-4 w-4 mr-2" />
                Start Over
              </button>
            )}
          </div>

          <div>
            <button
              onClick={handleSave}
              className={`px-5 py-2.5 rounded-lg text-white text-sm font-medium transition-colors ${
                Object.keys(validationErrors).length > 0
                  ? "bg-gray-300 cursor-not-allowed"
                  : !dirty
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-brand-turq hover:bg-brand-green shadow-sm"
              }`}
              disabled={Object.keys(validationErrors).length > 0 || !dirty}
            >
              {dirty ? "Save Changes" : "No Changes to Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
