import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import {
  X as XIcon,
  Share as ShareIcon,
  Lock as LockClosedIcon,
  Rewind,
  FastForward,
  Play,
  Pause,
  SpeakerSlash,
  SpeakerHigh,
  MagnifyingGlass as SearchIcon,
  Sidebar,
  ArrowsOutSimple,
  ArrowsInSimple,
  MonitorPlay,
  NotePencil,
  CheckSquare,
  Robot,
  PuzzlePiece,
  Copy,
  ChatsCircle,
  TextAlignLeft,
  UsersThree,
  CircleNotch,
} from "phosphor-react";
import Navbar from "./Navbar";
import moment from "moment";
import remarkGfm from "remark-gfm";
import { useParams, useLocation } from "react-router-dom";
import "tippy.js/dist/tippy.css";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { ActionsTab } from "./ActionsTab";

import ShareMeetingModal from "./ShareMeetingModal";
import ActionItemsTab from "./ActionItemsTab";
import OutOfCredits from "./recording/OutOfCredits";
import {
  CheckCircleIcon,
  ClockIcon,
  PencilIcon,
  PlusIcon,
  SparklesIcon,
} from "lucide-react";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import CustomOutputTab from "./CustomOutputs/CustomOutputTab";

import {
  SimpleTabs,
  SimpleTabList,
  SimpleTab,
  SimpleTabPanels,
  SimpleTabPanel,
} from "./SimpleTab";
import EnhancedMeetingNotes from "./RichJsonRenderer";
import { LightningBoltIcon, UserGroupIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";

function formatTranscriptForCopy(
  meeting,
  transcriptItems,
  recordingTimeOffset = 0
) {
  if (!transcriptItems?.length) return "No transcript available.";

  // Group items by speaker to combine consecutive items from same speaker
  const groupedItems = [];
  let currentGroup = null;

  transcriptItems.forEach((item) => {
    const timestamp = formatTimeHHMMSS(item.itemStart - recordingTimeOffset);

    // If this is the first item or a new speaker
    if (!currentGroup || currentGroup.speaker !== item.speaker) {
      // Save the previous group if it exists
      if (currentGroup) {
        groupedItems.push(currentGroup);
      }
      // Start a new group
      currentGroup = {
        speaker: item.speaker || "Unknown Speaker",
        timestamp,
        text: [item.transcript],
      };
    } else {
      // Add to existing group
      currentGroup.text.push(item.transcript);
    }
  });

  // Don't forget to add the last group
  if (currentGroup) {
    groupedItems.push(currentGroup);
  }

  // Format the grouped items into a readable string
  const formattedText = groupedItems
    .map((group) => {
      const speakerLine = `[${group.timestamp}] ${group.speaker}:`;
      const textContent = group.text.join(" "); // Join with space to prevent word running together
      return `${speakerLine}\n${textContent}\n`;
    })
    .join("\n");

  // Create a detailed header with meeting info
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const header = `Meeting Title: ${meeting?.meetingTitle || "Untitled Meeting"}
  Date: ${formatDate(meeting?.CreatedAt) || "Date not available"}
  ${"=".repeat(50)}

  `;

  return header + formattedText;
}

// Add this to your handleCopyTranscript function
async function handleCopyTranscript(meeting, setCopyState) {
  if (!meeting?.transcriptItems?.length) {
    setCopyState("error");
    setTimeout(() => setCopyState("default"), 2000);
    return;
  }

  try {
    const formattedTranscript = formatTranscriptForCopy(
      meeting,
      meeting.transcriptItems,
      meeting.recordingTimeOffset
    );

    await navigator.clipboard.writeText(formattedTranscript);
    setCopyState("copied");
    setTimeout(() => setCopyState("default"), 2000);
  } catch (error) {
    console.error("Failed to copy transcript:", error);
    setCopyState("error");
    setTimeout(() => setCopyState("default"), 2000);
  }
}

/** Utility: format seconds as M:SS or H:MM:SS. */
function formatTimeHHMMSS(totalSecs = 0) {
  if (totalSecs < 0) totalSecs = 0;
  const h = Math.floor(totalSecs / 3600);
  const m = Math.floor((totalSecs % 3600) / 60);
  const s = Math.floor(totalSecs % 60);
  const mm = m < 10 ? `0${m}` : m;
  const ss = s < 10 ? `0${s}` : s;
  return h > 0 ? `${h}:${mm}:${ss}` : `${mm}:${ss}`;
}

/** Build aggregated speaker chunks & speaker array. */
function buildSpeakerChunksAndSpeakers(meetingData) {
  const { transcriptItems = [], recordingTimeOffset = 0 } = meetingData;
  if (!transcriptItems.length) {
    return { speakerChunks: [], speakers: [] };
  }

  const brandColors = ["#6366F1", "#14B8A6", "#EC4899", "#F59E0B", "#4ADE80"];
  const speakerMap = {};
  let colorIndex = 0;

  for (const tx of transcriptItems) {
    const spName = tx.speaker || "Unknown";
    if (!speakerMap[spName]) {
      speakerMap[spName] = {
        name: spName,
        color: brandColors[colorIndex % brandColors.length],
        talkTime: 0,
        chunks: [],
      };
      colorIndex++;
    }
    const start = tx.itemStart - recordingTimeOffset;
    const end = tx.itemEnd - recordingTimeOffset;
    speakerMap[spName].chunks.push({
      start: start < 0 ? 0 : start,
      end: end < 0 ? 0 : end,
    });
    if (end > start) {
      speakerMap[spName].talkTime += end - start;
    }
  }

  const speakerChunks = [];
  const speakers = [];

  Object.values(speakerMap).forEach((spObj) => {
    spObj.chunks.forEach((ch) => {
      speakerChunks.push({
        speaker: spObj.name,
        color: spObj.color,
        start: ch.start,
        end: ch.end,
      });
    });
    speakers.push({
      name: spObj.name,
      color: spObj.color,
      talkTime: spObj.talkTime,
      formattedTalkTime: formatTimeHHMMSS(spObj.talkTime),
      chunks: spObj.chunks,
    });
  });

  speakerChunks.sort((a, b) => a.start - b.start);
  speakers.sort((a, b) => b.talkTime - a.talkTime);

  return { speakerChunks, speakers };
}

function convertScreenShareToChunks(screenData, totalDuration) {
  return screenData.map((sd) => {
    const start = Math.max(sd.sharingStarted, 0);
    const end = sd.sharingEnded > 0 ? sd.sharingEnded : totalDuration;
    return { start, end };
  });
}

function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

/** A single transcript line. */
function TranscriptItemView({
  item,
  offset = 0,
  highlightTerm = "",
  isActiveMatch = false,
  isFollowed = false,
  onClick,
  isTranscriptOnly = false,
}) {
  const startTime = formatTimeHHMMSS(item.itemStart - offset);
  const sanitizedTerm = highlightTerm ? escapeRegExp(highlightTerm) : "";
  const highlightRegex = sanitizedTerm
    ? new RegExp(`(${sanitizedTerm})`, "gi")
    : null;
  const text = item.transcript || "";

  const highlightText = () => {
    if (!highlightRegex) return text;
    return text.split(highlightRegex).map((part, i) =>
      part.toLowerCase() === highlightTerm.toLowerCase() ? (
        <span
          key={i}
          className={`${
            isActiveMatch ? "bg-yellow-200" : "bg-yellow-100"
          } rounded-sm px-0.5`}
        >
          {part}
        </span>
      ) : (
        <span key={i}>{part}</span>
      )
    );
  };

  // Avatar
  const speakerInitial = (item.speaker || "U").charAt(0).toUpperCase();

  const classes = [
    "group transition-colors p-3 rounded-md border border-transparent hover:border-gray-300 hover:bg-white",
  ];
  if (!isTranscriptOnly) {
    classes.push("cursor-pointer");
  }
  if (isActiveMatch) {
    classes.push("bg-yellow-50 shadow-sm");
  }
  if (isFollowed) {
    classes.push("border-brand-turq/80 bg-brand-turq/5");
  }

  return (
    <div
      className={classes.join(" ")}
      onClick={isTranscriptOnly ? undefined : onClick}
    >
      <div className="flex items-start space-x-3">
        <div
          className="w-8 h-8 rounded-full bg-brand-turq flex items-center justify-center text-white text-xs font-medium uppercase flex-none"
          title={item.speaker || "Unknown Speaker"}
        >
          {speakerInitial}
        </div>

        <div className="flex-1 text-sm leading-relaxed">
          <div className="flex items-center justify-between mb-1">
            <span className="font-semibold text-gray-800 truncate">
              {item.speaker || "Unknown"}
            </span>

            <span className="flex items-center space-x-1 text-[0.75rem] text-gray-400 group-hover:text-gray-600">
              {!isTranscriptOnly && <Play size={12} weight="fill" />}
              <span>{startTime}</span>
            </span>
          </div>
          <p className="text-gray-700">{highlightText()}</p>
        </div>
      </div>
    </div>
  );
}

/**
 * Optional: Code block with "copy" functionality for a more polished look.
 */
function CodeBlock({ language, value }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  return (
    <div className="relative group">
      {/* Copy button (shows on hover) */}
      <button
        onClick={handleCopy}
        className="absolute hidden group-hover:inline-block top-2 right-2 bg-gray-800 text-white text-xs px-2 py-1 rounded-md"
      >
        {copied ? "Copied!" : "Copy"}
      </button>
      <SyntaxHighlighter
        language={language || "plaintext"}
        style={oneDark}
        PreTag="div"
        customStyle={{ borderRadius: "0.5rem", margin: 0 }}
      >
        {value}
      </SyntaxHighlighter>
    </div>
  );
}

/** AI Chat component. */
function AiChatView({
  chatHistory,
  setChatHistory,
  aiInput,
  setAiInput,
  onSend,
  aiLoading,
  isProUser,
  numAiResponses,
}) {
  const bottomRef = useRef(null);
  const textAreaRef = useRef(null);

  useEffect(() => {
    // Scroll to bottom on chat updates or when AI is loading
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory, aiLoading]);

  const handleInputChange = (e) => {
    setAiInput(e.target.value);

    // Auto-expand textarea height
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  };

  const handleKeyDown = (e) => {
    // Send on Enter (unless Shift+Enter)
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!aiLoading) {
        const textToSend = aiInput.trim();
        if (textToSend) {
          setAiInput("");
          if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
          }
          onSend();
        }
      }
    }
  };

  const renderMessage = (message, idx) => {
    const isUser = message.role === "user";
    const nameLabel = isUser ? "You" : "Scribbl AI";

    // Slightly different brand styling for AI vs. user
    const bubbleClasses = isUser
      ? "bg-white border border-gray-200 text-gray-800 shadow-sm"
      : "bg-brand-turq/10 border border-brand-turq/30 text-gray-800 shadow-sm";

    return (
      <div key={idx} className="mb-6">
        {/* Who is speaking */}
        <div className="text-xs text-gray-500 mb-1">{nameLabel}</div>
        {/* Chat bubble with improved markdown styling */}
        <div className={`rounded-xl p-4 ${bubbleClasses}`}>
          <ReactMarkdown
            // The "prose" class ensures headings, lists, etc. are elegantly styled.
            className="prose prose-sm max-w-none text-gray-800"
            remarkPlugins={[remarkGfm]}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || "");
                const lang = match && match[1] ? match[1] : "";
                // Inline code
                if (inline) {
                  return (
                    <code className="bg-gray-200 text-sm px-1 py-0.5 rounded">
                      {children}
                    </code>
                  );
                }
                // Block code
                return (
                  <CodeBlock
                    language={lang}
                    value={String(children).replace(/\n$/, "")}
                  />
                );
              },
              blockquote({ children }) {
                return (
                  <blockquote className="border-l-4 border-gray-300 pl-4 italic text-gray-600">
                    {children}
                  </blockquote>
                );
              },
            }}
          >
            {message.content}
          </ReactMarkdown>
        </div>
      </div>
    );
  };

  return (
    /**
     * We'll use absolute positioning to ensure the input area
     * is pinned to the bottom while only the chat content scrolls.
     */
    <div className="relative w-full h-full bg-white rounded-xl border border-gray-200 shadow-md">
      {/* Scrollable messages container */}
      <div className="absolute inset-0 overflow-y-auto pb-32 px-4 pt-4">
        {/* If no conversation yet, center a prompt */}
        {chatHistory.length === 0 && !aiLoading ? (
          <div className="h-full flex items-center justify-center">
            <div className="text-sm text-gray-400 text-center max-w-xs">
              Ask anything about your meeting to get AI insights.
            </div>
          </div>
        ) : (
          <>
            {chatHistory.map(renderMessage)}

            {aiLoading && (
              <div className="mb-6">
                <div className="rounded-xl p-4 bg-white text-gray-600 border border-gray-200 flex items-center space-x-2 shadow-sm">
                  <svg
                    className="animate-spin h-4 w-4 text-brand-turq"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="3"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                    />
                  </svg>
                  <span>Scribbl AI is thinking...</span>
                </div>
              </div>
            )}
          </>
        )}

        <div ref={bottomRef} />
      </div>

      {/* Footer area pinned at bottom */}
      <div className="absolute bottom-0 w-full bg-gray-50 border-t border-gray-200 p-3">
        {/* Show free-plan limit notice if applicable */}
        {!isProUser && numAiResponses >= 1 && (
          <div className="mb-2 text-sm text-center text-red-500 font-medium">
            You’ve reached your free plan limit. Please upgrade to continue.
          </div>
        )}

        {/* Input row */}
        <div className="flex items-center rounded-lg border border-gray-300 bg-white px-2 py-1 shadow-sm">
          <textarea
            ref={textAreaRef}
            className="flex-1 text-sm px-2 py-1 border-none focus:ring-0 focus:outline-none resize-none placeholder-gray-400 overflow-hidden"
            rows={1}
            style={{ height: "auto" }}
            placeholder="Type your question... (Shift+Enter for new line)"
            value={aiInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            disabled={!isProUser && numAiResponses >= 1}
          />
          <button
            onClick={() => {
              if (!aiLoading) {
                const textToSend = aiInput.trim();
                if (textToSend) {
                  setAiInput("");
                  if (textAreaRef.current) {
                    textAreaRef.current.style.height = "auto";
                  }
                  onSend();
                }
              }
            }}
            disabled={(!isProUser && numAiResponses >= 1) || aiLoading}
            className="ml-2 flex items-center justify-center h-8 px-4 text-white bg-brand-turq rounded-md text-sm font-medium hover:bg-brand-turq-dark transition disabled:opacity-50"
          >
            {aiLoading ? "Thinking..." : "Send"}
          </button>
        </div>
      </div>
    </div>
  );
}

// In MeetingPage2.jsx, update the CustomMeetingNotesCard component
// Update the CustomMeetingNotesCard component with our simplified loading state
export function CustomMeetingNotesCard({
  customNotes,
  onRegen,
  isRegenLoading,
  meetingTemplates,
  selectedMeetingTemplateId,
  setSelectedMeetingTemplateId,
  onTimeJump,
  isPollingMeetingStatus,
}) {
  let parsedNotes;
  try {
    parsedNotes =
      typeof customNotes === "string" ? JSON.parse(customNotes) : customNotes;
  } catch {
    parsedNotes = null;
  }

  // Determine if we should show the loading state
  // Only if we're polling after opening from a meeting
  const showLoadingState = isPollingMeetingStatus;

  // If we're regenerating notes, show the regeneration loading state
  if (isRegenLoading) {
    return (
      <div className="p-6 text-center bg-white rounded-lg shadow-sm">
        <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-brand-turq mb-4"></div>
        <h3 className="text-xl font-semibold text-gray-800 mb-2">
          Regenerating Notes
        </h3>
        <p className="text-sm text-gray-600">
          Our AI is creating fresh notes based on your meeting transcript.
        </p>
      </div>
    );
  }

  // If we're showing the main loading state for initial processing
  if (showLoadingState) {
    return <MeetingNotesLoadingState />;
  }

  // If we have no notes at all
  if (!parsedNotes) {
    return (
      <div className="p-6 text-center bg-white rounded-lg shadow-sm">
        <h3 className="text-xl font-semibold text-gray-800 mb-2">
          No Meeting Notes
        </h3>
        <p className="text-sm text-gray-600 mb-4">
          Generate AI notes from your meeting transcript
        </p>

        {meetingTemplates && meetingTemplates.length > 0 && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Select template:
            </label>
            <select
              value={selectedMeetingTemplateId}
              onChange={(e) => setSelectedMeetingTemplateId(e.target.value)}
              className="w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-brand-turq focus:border-brand-turq sm:text-sm"
            >
              <option value="">Default Template</option>
              {meetingTemplates.map((template) => (
                <option key={template.ID} value={template.ID}>
                  {template.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <button
          onClick={() => onRegen && onRegen(selectedMeetingTemplateId)}
          className="px-4 py-2 bg-brand-turq text-white rounded-md hover:bg-brand-turq/90 transition-colors text-sm font-medium flex items-center justify-center mx-auto"
        >
          <Robot size={16} className="mr-2" />
          Generate Notes
        </button>
      </div>
    );
  }

  // Render the actual meeting notes
  return (
    <EnhancedMeetingNotes
      data={parsedNotes}
      onTimeJump={onTimeJump}
      meetingTemplates={meetingTemplates}
      selectedMeetingTemplateId={selectedMeetingTemplateId}
      setSelectedMeetingTemplateId={setSelectedMeetingTemplateId}
      onRegen={onRegen}
    />
  );
}

function MeetingChatBubble({ chat, isCurrentUser }) {
  return (
    <div
      className={`flex items-start ${
        isCurrentUser ? "justify-end" : "justify-start"
      }`}
    >
      {!isCurrentUser && (
        <div className="flex-none mr-2">
          <div className="w-7 h-7 rounded-full bg-gray-300 text-white text-xs font-semibold flex items-center justify-center uppercase">
            {chat.user?.charAt(0) || "?"}
          </div>
        </div>
      )}

      <div
        className={`rounded-md px-3 py-2 text-sm ${
          isCurrentUser
            ? "bg-brand-turq text-white"
            : "bg-gray-100 text-gray-800"
        } max-w-[70%] whitespace-pre-wrap`}
      >
        <div className="flex items-center justify-between mb-1">
          <span className="font-medium text-xs text-gray-700 truncate">
            {chat.user || "Unknown"}
          </span>
          <span className="text-[0.7rem] text-gray-400 ml-2">
            {chat.date || ""}
          </span>
        </div>
        <p className="text-sm leading-snug">{chat.message}</p>
      </div>
    </div>
  );
}

// ---------------------------------------------------------------------
//                     MAIN COMPONENT (MeetingPage2)
// ---------------------------------------------------------------------
export default function Meeting({ isPublic }) {
  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [logout, setLogout] = useState(false);
  const [loading, setLoading] = useState(true);
  const [meetingLoading, setMeetingLoading] = useState(true); // For actual meeting fetch

  const { uuid } = useParams();
  const location = useLocation();
  const lastProgrammaticScrollRef = useRef(0);
  const [forceClickedIndex, setForceClickedIndex] = useState(null); // NEW
  const [uiTime, setUiTime] = useState(0);
  const rafRef = useRef(null);

  const [playbackRate, setPlaybackRate] = useState(1);
  const [meeting, setMeeting] = useState(null);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  // For sharing
  const [openShareDialog, setOpenShareDialog] = useState(false);

  // GPT meeting notes
  const [customMeetingNotes, setCustomMeetingNotes] = useState(null);
  const [editingName, setEditingName] = useState(false);
  const [editingNameValue, setEditingNameValue] = useState("");

  // Video references
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [volumeOn, setVolumeOn] = useState(true);

  const [isScrubbing, setIsScrubbing] = useState(false);
  const [scrubTime, setScrubTime] = useState(0); // Where the user is hovering/draggng
  const [hoverTime, setHoverTime] = useState(null); // For a tooltip preview on hover
  const progressBarRef = useRef(null);

  // Layout toggles
  const [triggerTeamModal, setTriggerTeamModal] = useState(false);
  const [leftPaneCollapsed, setLeftPaneCollapsed] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [theaterMode, setTheaterMode] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(true);

  const [actionItems, setActionItems] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [thumbnailsMap, setThumbnailsMap] = useState({});

  // Tabbing
  const [leftTabIndex, setLeftTabIndex] = useState(0);
  const [rightTabIndex, setRightTabIndex] = useState(0);

  // For actions tab
  const [actionsTabFullWidth, setActionsTabFullWidth] = useState(false);

  // Transcript search
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchIndex, setSearchIndex] = useState(0);
  const transcriptItemRefs = useRef([]);

  // Follow along
  const [followAlongTranscript, setFollowAlongTranscript] = useState(false);
  const [currentTranscriptIndex, setCurrentTranscriptIndex] = useState(-1);

  // AI Chat
  const [chatHistory, setChatHistory] = useState([]);
  const [aiInput, setAiInput] = useState("");
  const [aiLoading, setAiLoading] = useState(false);
  const [numAiResponses, setNumAiResponses] = useState(0);

  // Meeting Templates
  const [meetingTemplates, setMeetingTemplates] = useState([]);
  const [selectedMeetingTemplateId, setSelectedMeetingTemplateId] =
    useState("");

  // Action Items & Outputs
  const [actionOutputs, setActionOutputs] = useState([]);

  // Re-gen loading
  const [isRegenLoading, setIsRegenLoading] = useState(false);

  // Screen shares
  const [processedScreenShares, setProcessedScreenShares] = useState([]);

  const [isPollingMeetingStatus, setIsPollingMeetingStatus] = useState(false);
  const [meetingStatusPollingInterval, setMeetingStatusPollingInterval] =
    useState(null);
  const [pollingStartTime, setPollingStartTime] = useState(null);

  const [isPollingForMeetingData, setIsPollingForMeetingData] = useState(false);
  const fetchMeetingData = useCallback(async () => {
    if (!meeting?.uuid || !userEmail) return;

    try {
      setIsPollingForMeetingData(true);

      // Fetch meeting notes
      const notesRes = await fetch(
        `https://backend.scribbl.co/gpt-meeting-note/${meeting.uuid}`,
        {
          credentials: "include",
        }
      );

      if (notesRes.ok) {
        const data = await notesRes.json();
        if (data.note && data.note.data) {
          try {
            // Attempt to parse as JSON
            const parsedNotes = JSON.parse(data.note.data);
            setCustomMeetingNotes(parsedNotes);
          } catch (err) {
            // If parse fails, use as-is (might be plain text)
            setCustomMeetingNotes(data.note.data);
          }

          if (data.note && data.note.meetingTemplateID) {
            setSelectedMeetingTemplateId(String(data.note.meetingTemplateID));
          }
        }
      }

      // Fetch action items and other relevant data
      if (!isPublic) {
        const actionRes = await fetch(
          `https://backend.scribbl.co/action-outputs/${meeting.uuid}`,
          {
            credentials: "include",
          }
        );

        if (actionRes.ok) {
          const data = await actionRes.json();
          setActionOutputs(data);
        }
      }
    } catch (error) {
      console.error("Error fetching meeting data:", error);
    } finally {
      setIsPollingForMeetingData(false);
    }
  }, [meeting?.uuid, userEmail, isPublic]);

  const pollMeetingProcessingStatus = useCallback(async () => {
    if (!meeting?.uuid || !userEmail) return;

    console.log("Polling meeting processing status...");

    try {
      const response = await fetch(
        `https://backend.scribbl.co/meeting-processing-status/${meeting.uuid}`,
        {
          credentials: "include",
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          setLogout(true);
          return;
        }
        console.error(
          "Failed to fetch meeting processing status:",
          response.status
        );
        return;
      }

      const status = await response.json();

      // Stop polling if processing is complete, if we've timed out, or after a maximum polling duration (10 minutes)
      const MAX_POLLING_DURATION = 10 * 60 * 1000; // 10 minutes in milliseconds
      const currentTime = Date.now();

      const hasReachedMaxPollingTime =
        pollingStartTime &&
        currentTime - pollingStartTime > MAX_POLLING_DURATION;
      const isProcessingComplete = !status.isProcessing; // Main condition: processing is done
      const hasTimedOut = status.timeout;
      const shouldStopPolling =
        isProcessingComplete || hasTimedOut || hasReachedMaxPollingTime;

      if (shouldStopPolling) {
        if (meetingStatusPollingInterval) {
          console.log(
            `Stopping polling because: ${
              isProcessingComplete ? "processing complete" : ""
            } ${hasTimedOut ? "timeout reported" : ""} ${
              hasReachedMaxPollingTime ? "max time reached" : ""
            }`
          );
          clearInterval(meetingStatusPollingInterval);
          setMeetingStatusPollingInterval(null);
        }
        setIsPollingMeetingStatus(false);

        // Clear the start time
        setPollingStartTime(null);

        // If processing is complete or we've hit max time, fetch whatever data is available
        if (isProcessingComplete || hasReachedMaxPollingTime) {
          console.log(
            "Processing complete or max time reached. Fetching available meeting data..."
          );
          await fetchMeetingData();
        }
      }
    } catch (error) {
      console.error("Error polling meeting processing status:", error);
    }
  }, [
    meeting?.uuid,
    userEmail,
    meetingStatusPollingInterval,
    pollingStartTime,
    fetchMeetingData,
  ]);

  useEffect(() => {
    // Parse the query parameters to check for openedFromMeet flag
    const query = new URLSearchParams(location.search);
    const openedFromMeet = query.get("openedFromMeet") === "true";

    console.log("Checking if should start polling:", {
      openedFromMeet,
      meetingLoaded: !!meeting,
      alreadyPolling: isPollingMeetingStatus,
      hasInterval: !!meetingStatusPollingInterval,
    });

    // Start polling if we're coming directly from a meeting and not already polling
    if (
      meeting &&
      openedFromMeet &&
      !isPollingMeetingStatus &&
      !meetingStatusPollingInterval
    ) {
      console.log("Starting polling for meeting processing status");
      setIsPollingMeetingStatus(true);

      // Record the start time for polling (for timeout purposes)
      setPollingStartTime(Date.now());

      // Poll immediately
      pollMeetingProcessingStatus();

      // Then set up interval
      const intervalId = setInterval(pollMeetingProcessingStatus, 5000); // Poll every 5 seconds
      setMeetingStatusPollingInterval(intervalId);
    }

    // Cleanup function
    return () => {
      if (meetingStatusPollingInterval) {
        console.log("Cleaning up polling interval");
        clearInterval(meetingStatusPollingInterval);
      }
    };
  }, [
    meeting,
    location.search,
    isPollingMeetingStatus,
    meetingStatusPollingInterval,
    pollMeetingProcessingStatus,
  ]);

  // For convenience
  const currentTimeRef = useRef(0);
  // Transcript only check
  const isTranscriptOnly =
    !meeting?.containsVideo ||
    !meeting?.recordingURL ||
    meeting?.recordingURL?.trim?.() === "";

  const isVideoMeeting = !!(
    meeting?.containsVideo &&
    meeting?.recordingURL &&
    meeting.recordingURL.trim() !== ""
  );

  useEffect(() => {
    if (isTranscriptOnly && meeting?.transcriptItems?.length) {
      const lastItem =
        meeting.transcriptItems[meeting.transcriptItems.length - 1];
      const transcriptDuration = lastItem
        ? lastItem.itemEnd - (meeting.recordingTimeOffset || 0)
        : 0;
      setDuration(transcriptDuration);
    }
  }, [isTranscriptOnly, meeting]);

  // ----------------------------
  //   Fetch user (if not public)
  // ----------------------------
  useEffect(() => {
    if (isPublic) {
      // skip user fetch if public
      setLoading(false);
    } else {
      (async function fetchUserInfo() {
        try {
          const userRes = await fetch("https://backend.scribbl.co/auth/userinfo", {
            method: "POST",
            credentials: "include",
          });
          if (userRes.status === 401) {
            setLogout(true);
            setLoading(false);
            return;
          }
          if (userRes.ok) {
            const userJson = await userRes.json();
            setUser(userJson);
            setUserEmail(userJson.email);
          }
          setLoading(false);
        } catch (error) {
          console.error("Failed to fetch user info", error);
          setLoading(false);
        }
      })();
    }
  }, [isPublic]);

  // 1. First, modify the useEffect for video events to better handle play/pause state
  useEffect(() => {
    const vid = videoRef.current;
    if (!vid) return;

    function handleLoadedMetadata() {
      setDuration(vid.duration || 0);
      // Also sync initial play state based on video state
      setIsPlaying(!vid.paused);
    }

    function handlePlay() {
      setIsPlaying(true);
      // Important: Update uiTime to sync with current video time
      setUiTime(vid.currentTime);
    }

    function handlePause() {
      setIsPlaying(false);
      // Once paused, we can "lock in" the last time:
      setUiTime(vid.currentTime);
    }

    function handleEnded() {
      setIsPlaying(false);
      // Optionally set the time to the end
      setUiTime(vid.duration);
    }

    // Add event listener for playing state
    function handlePlaying() {
      // Double-check state is correct during playback
      if (!isPlaying) {
        setIsPlaying(true);
      }
    }

    function handleSeeking() {
      // When seeking happens, update UI time
      setUiTime(vid.currentTime);
    }

    function handleTimeUpdate() {
      // Update the current time reference
      currentTimeRef.current = vid.currentTime;
    }

    vid.addEventListener("loadedmetadata", handleLoadedMetadata);
    vid.addEventListener("play", handlePlay);
    vid.addEventListener("playing", handlePlaying);
    vid.addEventListener("pause", handlePause);
    vid.addEventListener("ended", handleEnded);
    vid.addEventListener("seeking", handleSeeking);
    vid.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      vid.removeEventListener("loadedmetadata", handleLoadedMetadata);
      vid.removeEventListener("play", handlePlay);
      vid.removeEventListener("playing", handlePlaying);
      vid.removeEventListener("pause", handlePause);
      vid.removeEventListener("ended", handleEnded);
      vid.removeEventListener("seeking", handleSeeking);
      vid.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [isPlaying]);

  // A helper to format time
  function formatTime(sec = 0) {
    // your existing function is fine, or:
    const h = Math.floor(sec / 3600);
    const m = Math.floor((sec % 3600) / 60);
    const s = Math.floor(sec % 60);
    if (h > 0) {
      return `${h}:${m.toString().padStart(2, "0")}:${s
        .toString()
        .padStart(2, "0")}`;
    }
    return `${m}:${s.toString().padStart(2, "0")}`;
  }

  // Grab a "currentTime" value to display:
  // If we're scrubbing, show `scrubTime`, else show actual video time
  const displayTime = isScrubbing ? scrubTime : uiTime;

  const displayTimeFormatted = formatTime(displayTime);
  const durationFormatted = formatTime(duration);

  // % progress

  // ----------------------------
  //   Fetch meeting data
  // ----------------------------
  useEffect(() => {
    if (loading) return;

    (async function fetchMeeting() {
      setMeetingLoading(true);

      try {
        let meetingUrl;
        if (isPublic) {
          // If it's the public page => use the /share endpoint
          meetingUrl = `https://backend.scribbl.co/enhanced-recording/${uuid}/share`;
        } else {
          // If private => normal endpoint with user’s email
          const baseUrl = `https://backend.scribbl.co/enhanced-recording/${uuid}?uuid=${uuid}`;
          meetingUrl = userEmail
            ? baseUrl + `&email=${encodeURIComponent(userEmail)}`
            : baseUrl;
        }

        const resp = await fetch(meetingUrl, { credentials: "include" });

        // If it's truly public, the server should not 401 or redirect you to login.
        // If your server code is auto-redirecting, double-check server logic or remove stale cookies.

        if (resp.status === 404) {
          setPageNotFound(true);
          setMeetingLoading(false);
          return;
        }
        // If private route => might get 401 if not logged in
        if (resp.status === 401 && !isPublic) {
          setLogout(true);
          setMeetingLoading(false);
          return;
        }
        const meetingJson = await resp.json();
        if (meetingJson?.reason === "deleted") {
          setIsDeleted(true);
          setMeetingLoading(false);
          return;
        }

        setMeeting(meetingJson);
        setEditingNameValue(meetingJson.meetingTitle || "Untitled Meeting");

        if (meetingJson.thumbnailURLs) {
          setThumbnailsMap(meetingJson.thumbnailURLs);
        }

        if (meetingJson.transcriptItems?.length) {
          const { speakerChunks: sc, speakers: spArr } =
            buildSpeakerChunksAndSpeakers(meetingJson);
          setSpeakers(spArr);
        }

        if (meetingJson.aiChat) {
          try {
            const cParsed = JSON.parse(meetingJson.aiChat);
            const relevant = cParsed.filter(
              (c) => c.role === "assistant" || c.role === "user"
            );
            setChatHistory(relevant);
            setNumAiResponses(
              relevant.filter((c) => c.role === "assistant").length
            );
          } catch (err) {
            console.log("Error parsing aiChat", err);
          }
        }
      } catch (error) {
        console.error("Failed to fetch meeting", error);
        setPageNotFound(true);
      } finally {
        setMeetingLoading(false);
      }
    })();
  }, [uuid, userEmail, loading, isPublic]);

  // GPT meeting notes (optionally skip if public)
  useEffect(() => {
    if (!uuid || !meeting) return;
    // If you do not want GPT notes in public mode, uncomment next line:
    // if (isPublic) return;

    (async function fetchMeetingNotes() {
      try {
        // This fetch uses 'include' but will be harmless if user is not logged in
        const res = await fetch(
          `https://backend.scribbl.co/gpt-meeting-note/${uuid}`,
          {
            credentials: "include",
          }
        );
        if (res.ok) {
          const data = await res.json();
          if (data.note && data.note.data) {
            try {
              // Attempt to parse as JSON
              const parsedNotes = JSON.parse(data.note.data);
              setCustomMeetingNotes(parsedNotes);
            } catch (err) {
              // If parse fails, use as-is (might be plain text)
              setCustomMeetingNotes(data.note.data);
            }
          } else if (meeting.gptMeetingData) {
            // Fallback to legacy format
            try {
              const parsedNotes =
                typeof meeting.gptMeetingData === "string"
                  ? JSON.parse(meeting.gptMeetingData)
                  : meeting.gptMeetingData;
              setCustomMeetingNotes(parsedNotes);
            } catch (err) {
              setCustomMeetingNotes(meeting.gptMeetingData);
            }
          }

          if (data.note && data.note.meetingTemplateID) {
            setSelectedMeetingTemplateId(String(data.note.meetingTemplateID));
          }
        } else if (res.status === 401 && !isPublic) {
          setLogout(true);
        }
      } catch (err) {
        console.error("Error fetching meeting notes:", err);
      }
    })();
  }, [uuid, meeting, isPublic]);

  // meeting templates (skip if public)
  useEffect(() => {
    if (isPublic) return;
    if (!userEmail) return;

    (async function fetchMeetingTemplates() {
      try {
        const res = await fetch("https://backend.scribbl.co/meeting-templates", {
          credentials: "include",
        });
        if (res.status === 401) {
          setLogout(true);
          return;
        }
        if (res.ok) {
          const data = await res.json();
          setMeetingTemplates(data);
        }
      } catch (err) {
        console.log("Error fetching meeting templates", err);
      }
    })();
  }, [userEmail, isPublic]);

  // Action outputs (skip if public)
  useEffect(() => {
    if (!meeting) return;
    if (isPublic) return;

    (async function fetchActionOutputs() {
      try {
        const res = await fetch(
          `https://backend.scribbl.co/action-outputs/${meeting.uuid}`,
          {
            credentials: "include",
          }
        );
        if (res.ok) {
          const data = await res.json();
          setActionOutputs(data);
        } else {
          console.error("fetchActionOutputs failed:", await res.text());
        }
      } catch (err) {
        console.error("Failed to fetch action outputs", err);
      }
    })();
  }, [meeting, isPublic]);

  function animationLoop() {
    if (!videoRef.current) return;
    // If isPlaying and not scrubbing, update uiTime from video's currentTime
    if (isPlaying && !isScrubbing) {
      setUiTime(videoRef.current.currentTime);
    }
    rafRef.current = requestAnimationFrame(animationLoop);
  }

  useEffect(() => {
    if (isPlaying && !isScrubbing) {
      // Start the loop
      rafRef.current = requestAnimationFrame(animationLoop);
    } else {
      // If paused or we start scrubbing => stop the loop
      if (rafRef.current) cancelAnimationFrame(rafRef.current);
    }
    return () => {
      // Cleanup on unmount
      if (rafRef.current) cancelAnimationFrame(rafRef.current);
    };
  }, [isPlaying, isScrubbing]);

  // Video events
  useEffect(() => {
    if (!videoRef.current) return;
    const vid = videoRef.current;

    const onLoadedMetadata = () => {
      setDuration(vid.duration || 0);
    };
    const onTimeUpdate = () => {
      currentTimeRef.current = vid.currentTime;
    };
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);

    vid.addEventListener("loadedmetadata", onLoadedMetadata);
    vid.addEventListener("timeupdate", onTimeUpdate);
    vid.addEventListener("play", onPlay);
    vid.addEventListener("pause", onPause);

    return () => {
      vid.removeEventListener("loadedmetadata", onLoadedMetadata);
      vid.removeEventListener("timeupdate", onTimeUpdate);
      vid.removeEventListener("play", onPlay);
      vid.removeEventListener("pause", onPause);
    };
  }, []);

  // Once metadata is loaded, re-check transcript if needed
  useEffect(() => {
    if (!videoRef.current || !meeting) return;
    const vid = videoRef.current;
    const onLoadedMetadata = () => {
      setDuration(vid.duration || 0);
      if (meeting.transcriptItems?.length) {
        const { speakerChunks, speakers } =
          buildSpeakerChunksAndSpeakers(meeting);
        setSpeakers(speakers);
      }
    };
    vid.addEventListener("loadedmetadata", onLoadedMetadata);
    if (vid.readyState >= 2) {
      onLoadedMetadata();
    }
    return () => vid.removeEventListener("loadedmetadata", onLoadedMetadata);
  }, [meeting]);

  // Force full width if we come from another tab to the actions tab
  useEffect(() => {
    if (!meeting?.transcriptItems?.length) {
      setSearchResults([]);
      return;
    }
    if (!searchTerm) {
      setSearchResults([]);
      setSearchIndex(0);
      return;
    }

    // First, create a sorted version of transcriptItems (exactly as done in rendering)
    const sortedTranscriptItems = [...meeting.transcriptItems].sort(
      (a, b) => a.itemStart - b.itemStart
    );

    const term = searchTerm.toLowerCase();
    const results = [];

    // Search in the sorted array to match rendering
    sortedTranscriptItems.forEach((item, i) => {
      const speaker = (item.speaker || "").toLowerCase();
      const text = (item.transcript || "").toLowerCase();
      if (speaker.includes(term) || text.includes(term)) {
        results.push(i);
      }
    });

    setSearchResults(results);
    setSearchIndex(0);
  }, [searchTerm, meeting]);

  // Jump to search index
  useEffect(() => {
    if (!searchResults.length) return;
    if (searchIndex < 0 || searchIndex >= searchResults.length) return;
    const itemIdx = searchResults[searchIndex];
    const ref = transcriptItemRefs.current[itemIdx];
    if (ref?.scrollIntoView) {
      ref.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [searchIndex, searchResults]);

  // auto-hide video controls
  useEffect(() => {
    let hideTimeout;
    const handleMouseMove = () => {
      setControlsVisible(true);
      clearTimeout(hideTimeout);
      hideTimeout = setTimeout(() => {
        setControlsVisible(false);
      }, 3000);
    };
    const container = videoContainerRef.current;
    if (container) container.addEventListener("mousemove", handleMouseMove);

    return () => {
      if (container)
        container.removeEventListener("mousemove", handleMouseMove);
      clearTimeout(hideTimeout);
    };
  }, []);

  // track fullscreen changes
  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false);
      }
    };
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  // Screen sharing
  useEffect(() => {
    if (!meeting || !meeting.screenSharingData) {
      setProcessedScreenShares([]);
      return;
    }
    const processed = meeting.screenSharingData.map((sd) => ({
      ...sd,
      sharingStarted: Math.max(sd.sharingStarted, 0),
      sharingEnded: sd.sharingEnded > 0 ? sd.sharingEnded : duration || 0,
    }));
    setProcessedScreenShares(processed);
  }, [meeting, duration]);

  function findTranscriptIndexOrClosest(time) {
    if (!meeting?.transcriptItems?.length) return -1;

    // Sort the transcript items by start time to ensure we're working with ordered data
    const sortedItems = [...meeting.transcriptItems].sort(
      (a, b) => a.itemStart - b.itemStart
    );

    // Apply recordingTimeOffset to all time comparisons
    const offset = meeting.recordingTimeOffset || 0;

    // 1) Exact match: item whose [start, end) contains 'time'
    let idx = sortedItems.findIndex(
      (t) => t.itemStart - offset <= time && t.itemEnd - offset > time
    );

    if (idx !== -1) return idx;

    // 2) No exact match => find item whose midpoint is nearest
    let bestIdx = -1;
    let bestDiff = Infinity;

    sortedItems.forEach((t, i) => {
      const start = t.itemStart - offset;
      const end = t.itemEnd - offset;
      const midpoint = (start + end) / 2;
      const diff = Math.abs(midpoint - time);

      if (diff < bestDiff) {
        bestDiff = diff;
        bestIdx = i;
      }
    });

    return bestIdx;
  }

  // Replace or add the enhanced TimelineBar component
  function TimelineContainer({ children, duration, thumbnailsMap }) {
    const containerRef = useRef(null);
    const [hoverPosition, setHoverPosition] = useState(null);
    const [hovering, setHovering] = useState(false);
    const [activeThumb, setActiveThumb] = useState(null);

    const handleMouseMove = (e) => {
      if (!containerRef.current) return;

      setHovering(true);

      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const percentPosition = Math.max(0, Math.min(1, x / rect.width));

      setHoverPosition(percentPosition);

      // Handle thumbnail logic
      const timePosition = percentPosition * duration;
      if (thumbnailsMap && Object.keys(thumbnailsMap).length > 0) {
        const timePositionSec = Math.floor(timePosition);

        // Find closest thumbnail
        const times = Object.keys(thumbnailsMap).map(Number);
        if (times.length > 0) {
          const closest = times.reduce((prev, curr) => {
            return Math.abs(curr - timePositionSec) <
              Math.abs(prev - timePositionSec)
              ? curr
              : prev;
          });

          setActiveThumb({
            src: thumbnailsMap[closest],
            time: closest,
            position: {
              x: e.clientX,
              y: e.clientY,
            },
          });
        }
      }
    };

    const handleMouseLeave = () => {
      setHovering(false);
      setActiveThumb(null); // Immediately clear thumbnail
      setHoverPosition(null); // Immediately clear hover position
    };

    // Pass necessary props to children
    const childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          globalHoverPosition: hoverPosition,
          isHovering: hovering,
        });
      }
      return child;
    });

    return (
      <div
        ref={containerRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        className="relative pb-6" // Add padding to expand the hover area
      >
        {childrenWithProps}

        {/* Global hover indicator line that spans across all timelines */}
        {hoverPosition !== null && (
          <div
            className="absolute top-0 bottom-0 w-0.5 bg-brand-turq shadow-md z-30"
            style={{
              left: `${hoverPosition * 100}%`,
              transform: "translateX(-50%)",
              pointerEvents: "none",
            }}
          />
        )}

        {/* Thumbnail preview */}
        {activeThumb && (
          <div
            className="fixed z-50 pointer-events-none"
            style={{
              left: `${activeThumb.position.x}px`,
              top: `${activeThumb.position.y - 120}px`,
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="bg-black rounded-md shadow-lg p-1">
              <img
                src={activeThumb.src}
                alt={`Thumbnail at ${activeThumb.time}s`}
                className="w-40 h-auto rounded object-cover"
              />
              <div className="text-white text-xs text-center mt-1">
                {formatTimeHHMMSS(activeThumb.time)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  // 2. Update the EnhancedTimelineBar component to accept global hover state
  function EnhancedTimelineBar({
    chunks,
    duration,
    color,
    onTimeJump,
    index = 0,
    globalHoverPosition = null, // New prop received from parent
    isHovering = false, // New prop to know if any timeline is being hovered
  }) {
    const timelineRef = useRef(null);
    const containerRef = useRef(null);

    // We no longer need the timeline-specific hover state or thumbnail logic
    // since it's now handled by the parent container

    const handleClick = (e) => {
      if (!containerRef.current || !duration) return;

      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const percentPosition = Math.max(0, Math.min(1, x / rect.width));
      const timePosition = percentPosition * duration;

      // Call onTimeJump with false to prevent tab switching for timeline clicks
      onTimeJump(timePosition, false);
    };

    // Calculate positions for each chunk as percentage of total duration
    const chunkPositions = chunks.map((chunk) => ({
      start: (chunk.start / duration) * 100,
      end: (chunk.end / duration) * 100,
      color,
    }));

    return (
      <div
        ref={containerRef}
        className="relative mb-2" // Added margin for better spacing
        onClick={handleClick}
      >
        <div
          ref={timelineRef}
          className="relative h-6 bg-gray-100 rounded-md cursor-pointer overflow-hidden z-10"
        >
          {/* Render the chunks */}
          {chunkPositions.map((chunk, idx) => (
            <div
              key={idx}
              className="absolute top-0 bottom-0 h-full"
              style={{
                left: `${chunk.start}%`,
                width: `${chunk.end - chunk.start}%`,
                backgroundColor: chunk.color,
                opacity: 0.7,
              }}
            />
          ))}
        </div>

        {/* We no longer need the individual hover indicator line here */}
      </div>
    );
  }

  function runOneImmediateCheckTime() {
    if (!videoRef.current || !meeting?.transcriptItems?.length) return;

    const curTime = videoRef.current.currentTime || 0;
    const offset = meeting.recordingTimeOffset || 0;

    // Get the sorted transcript items
    const sortedTranscriptItems = [...meeting.transcriptItems].sort(
      (a, b) => a.itemStart - b.itemStart
    );

    // Find the closest transcript item to the current video time
    let bestIdx = -1;
    let bestDiff = Infinity;

    sortedTranscriptItems.forEach((item, i) => {
      const start = item.itemStart - offset;
      const end = item.itemEnd - offset;

      // If current time is within this item's range, it's an exact match
      if (curTime >= start && curTime < end) {
        if (bestIdx === -1 || bestDiff > 0) {
          bestIdx = i;
          bestDiff = 0;
        }
      } else {
        // For items that don't contain the current time,
        // find the one with the closest start time to current time
        const diff = Math.abs(start - curTime);
        if (diff < bestDiff) {
          bestDiff = diff;
          bestIdx = i;
        }
      }
    });

    if (bestIdx === -1) return;

    // Set the current transcript index and force it to be highlighted
    setCurrentTranscriptIndex(bestIdx);
    setForceClickedIndex(bestIdx);

    // Scroll to the transcript item
    lastProgrammaticScrollRef.current = Date.now();
    const refEl = transcriptItemRefs.current[bestIdx];
    if (refEl?.scrollIntoView) {
      refEl.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  useEffect(() => {
    if (!followAlongTranscript) return;
    if (!meeting?.transcriptItems?.length) return;
    if (!videoRef.current) return;

    let frameId;
    const offset = meeting.recordingTimeOffset || 0;

    // For consistent ordering when tracking the current index
    const sortedTranscriptItems = [...meeting.transcriptItems].sort(
      (a, b) => a.itemStart - b.itemStart
    );

    const checkTime = () => {
      const curTime = videoRef.current?.currentTime || 0;

      // If we have a forced item, check if time is still within that item
      if (forceClickedIndex != null) {
        const forcedItem = sortedTranscriptItems[forceClickedIndex];

        if (forcedItem) {
          const start = forcedItem.itemStart - offset;
          const end = forcedItem.itemEnd - offset;

          // If the current time is still within that forced item's range
          // (plus a small threshold), skip re-locating
          if (curTime >= start && curTime < end) {
            frameId = requestAnimationFrame(checkTime);
            return; // DO NOT override the highlight
          } else {
            // Time has moved outside that item => remove the forced index
            setForceClickedIndex(null);
          }
        }
      }

      // Find the item that contains the current time
      let idx = -1;
      for (let i = 0; i < sortedTranscriptItems.length; i++) {
        const item = sortedTranscriptItems[i];
        const start = item.itemStart - offset;
        const end = item.itemEnd - offset;

        if (curTime >= start && curTime < end) {
          idx = i;
          break;
        }
      }

      // If no exact match, find closest item by start time
      if (idx === -1) {
        let closestIdx = -1;
        let closestDiff = Infinity;

        sortedTranscriptItems.forEach((item, i) => {
          const start = item.itemStart - offset;
          const diff = Math.abs(start - curTime);
          if (diff < closestDiff) {
            closestDiff = diff;
            closestIdx = i;
          }
        });

        idx = closestIdx;
      }

      // Only update if we found an index and it's different from the current one
      if (idx !== -1 && idx !== currentTranscriptIndex) {
        setCurrentTranscriptIndex(idx);

        lastProgrammaticScrollRef.current = Date.now();
        const refEl = transcriptItemRefs.current[idx];
        if (refEl?.scrollIntoView) {
          refEl.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }

      frameId = requestAnimationFrame(checkTime);
    };

    frameId = requestAnimationFrame(checkTime);
    return () => cancelAnimationFrame(frameId);
  }, [
    followAlongTranscript,
    meeting,
    currentTranscriptIndex,
    forceClickedIndex,
  ]);

  // ------------------ Handlers ------------------
  const handleRenameMeeting = async () => {
    if (!meeting || isPublic) return; // skip if public
    try {
      const renameRes = await fetch(
        `https://backend.scribbl.co/enhanced-recording/${meeting.uuid}/rename`,
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: userEmail, name: editingNameValue }),
        }
      );
      if (renameRes.status === 401) {
        setLogout(true);
        return;
      }
      const renameJson = await renameRes.json();
      setMeeting((prev) => ({ ...prev, meetingTitle: renameJson.name }));
      setEditingName(false);
    } catch (err) {
      console.error("Rename error", err);
    }
  };

  const togglePlay = () => {
    const vid = videoRef.current;
    if (!vid) return;

    if (vid.paused || vid.ended) {
      // Play and handle any errors
      const playPromise = vid.play();

      // Modern browsers return a promise from play()
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Playback started successfully
            setIsPlaying(true);
          })
          .catch((error) => {
            // Auto-play was prevented or other error
            console.error("Play error:", error);
            setIsPlaying(false);
          });
      }
    } else {
      vid.pause();
      setIsPlaying(false);
    }
  };

  const skipSeconds = (delta) => {
    const vid = videoRef.current;
    if (!vid) return;
    vid.currentTime = Math.max(0, Math.min(vid.currentTime + delta, duration));
  };

  const handlePointerDown = (e) => {
    setIsScrubbing(true);
    updateScrubTime(e);
  };

  // Helper: calculates a time in seconds based on pointer location
  const calcTimeFromPointer = (e) => {
    if (!progressBarRef.current) return 0;
    const rect = progressBarRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    let ratio = x / rect.width;
    ratio = Math.min(Math.max(ratio, 0), 1); // clamp 0..1
    return duration * ratio;
  };

  // Update the `scrubTime` preview while dragging
  const updateScrubTime = (e) => {
    const newTime = calcTimeFromPointer(e);
    setScrubTime(newTime);
  };

  const handlePointerMove = (e) => {
    if (!isScrubbing) {
      // If not actively dragging, we can optionally show a hoverTime for a "hover preview" tooltip
      setHoverTime(calcTimeFromPointer(e));
      return;
    }
    updateScrubTime(e);
  };

  const handlePointerUp = (e) => {
    if (!isScrubbing) return;
    setIsScrubbing(false);
    const newTime = calcTimeFromPointer(e);
    const vid = videoRef.current;
    if (vid) {
      vid.currentTime = newTime;
    }
  };

  const toggleVolume = () => {
    if (!videoRef.current) return;
    videoRef.current.muted = !videoRef.current.muted;
    setVolumeOn(!videoRef.current.muted);
  };

  const progressPercent = duration ? (displayTime / duration) * 100 : 0;

  // In MeetingPage2.jsx, replace the handleTimeJump function with this improved version
  // Replace the existing handleTimeJump function
  function handleTimeJump(seconds, switchToTranscriptTab = true) {
    const safeTime = Math.max(0, parseFloat(seconds) || 0);

    // Jump the video if it exists (for video meetings)
    if (isVideoMeeting && videoRef.current) {
      videoRef.current.currentTime = safeTime;
    } else {
      currentTimeRef.current = safeTime;
    }

    // If there's actual video, we can set "followAlongTranscript" so it keeps highlighting
    if (!isTranscriptOnly) {
      setFollowAlongTranscript(true);
    }

    // Locate the closest transcript item to that time
    if (meeting?.transcriptItems?.length) {
      const idx = findTranscriptIndexOrClosest(safeTime);

      if (idx !== -1) {
        setCurrentTranscriptIndex(idx);
        setForceClickedIndex(idx);

        // Only switch to transcript tab if specifically requested
        const wasOnTranscriptTab = rightTabIndex === 1;
        if (switchToTranscriptTab && !wasOnTranscriptTab) {
          // Switch to transcript tab first
          setRightTabIndex(1);

          // Wait for tab switch to complete before scrolling
          setTimeout(() => {
            const refEl = transcriptItemRefs.current[idx];
            if (refEl?.scrollIntoView) {
              refEl.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          }, 100);
        } else if (rightTabIndex === 1) {
          // We're already on transcript tab, just scroll
          const refEl = transcriptItemRefs.current[idx];
          if (refEl?.scrollIntoView) {
            refEl.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
    }
  }

  const toggleLeftPane = () => {
    setLeftPaneCollapsed((prev) => !prev);
  };

  const toggleFullscreen = () => {
    if (!videoContainerRef.current) return;
    if (!isFullscreen && !document.fullscreenElement) {
      videoContainerRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const handleSetSpeed = (newSpeed) => {
    setPlaybackRate(newSpeed);
    if (videoRef.current) {
      videoRef.current.playbackRate = newSpeed;
    }
  };

  // AI chat
  const handleSendAiMessage = async () => {
    if (!aiInput.trim() || !meeting) return;
    if (isPublic) {
      return;
    }

    if (!isProUser() && numAiResponses >= 1) {
      return;
    }
    setAiLoading(true);
    const newUserMessage = { role: "user", content: aiInput };
    const updatedChat = [...chatHistory, newUserMessage];
    setChatHistory(updatedChat);

    try {
      const res = await fetch(
        `https://backend.scribbl.co/enhanced-recording/${meeting.uuid}/aichat`,
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userMessage: aiInput }),
        }
      );
      if (res.status === 401) {
        setLogout(true);
        return;
      }
      if (res.ok) {
        const data = await res.json();
        const parsed = JSON.parse(data.aiChat);
        const relevant = parsed.filter(
          (c) => c.role === "assistant" || c.role === "user"
        );
        setChatHistory(relevant);
        setNumAiResponses(
          relevant.filter((c) => c.role === "assistant").length
        );
      }
    } catch (err) {
      console.error("AI chat error", err);
    }
    setAiLoading(false);
    setAiInput("");
  };

  const isProUser = () => {
    return user && user.subscription && user.subscription.accountType !== 0;
  };

  // Re-generate summary
  const handleRegenSummary = async (templateId) => {
    // Use provided templateId or fall back to the state value
    const idToUse = templateId || selectedMeetingTemplateId;

    if (!idToUse || !meeting) return;
    if (isPublic) return;

    setIsRegenLoading(true);
    try {
      const bodyData = {
        meetingTemplateID: parseInt(idToUse, 10),
      };
      const res = await fetch(
        `https://backend.scribbl.co/gpt-meeting-note/${meeting.uuid}`,
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(bodyData),
        }
      );
      if (res.status === 401) {
        setLogout(true);
        setIsRegenLoading(false);
        return;
      }
      if (!res.ok) {
        alert("Failed to re-generate meeting notes.");
        setIsRegenLoading(false);
        return;
      }
      const data = await res.json();
      if (data && data.data) {
        try {
          const parsed = JSON.parse(data.data);
          setCustomMeetingNotes(parsed);
        } catch (err) {
          setCustomMeetingNotes(data.data);
        }
        // alert("Meeting notes regenerated successfully!");
      }
    } catch (err) {
      console.error("Error regenerating meeting notes:", err);
      alert("Error regenerating meeting notes. See console.");
    }
    setIsRegenLoading(false);
  };

  // Transcript search next/prev
  const handleNextMatch = () => {
    if (!searchResults.length) return;
    setSearchIndex((prev) => (prev + 1) % searchResults.length);
  };
  const handlePrevMatch = () => {
    if (!searchResults.length) return;
    setSearchIndex((prev) =>
      prev === 0 ? searchResults.length - 1 : prev - 1
    );
  };

  function handleTranscriptItemClick(itemIndex) {
    if (!meeting?.transcriptItems) return;

    // Get the sorted transcript items (matching what's used in rendering)
    const sortedTranscriptItems = [...meeting.transcriptItems].sort(
      (a, b) => a.itemStart - b.itemStart
    );

    const item = sortedTranscriptItems[itemIndex];
    if (!item) return;

    // Calculate the adjusted time
    const offset = meeting.recordingTimeOffset || 0;
    const targetTime = Math.max(item.itemStart - offset, 0);

    // Jump to the specific time
    if (isVideoMeeting && videoRef.current) {
      // Set the time
      videoRef.current.currentTime = targetTime;

      // Start playback if the video is paused
      if (videoRef.current.paused) {
        videoRef.current.play();
      }

      // Update uiTime immediately for visual consistency
      setUiTime(targetTime);
    } else {
      currentTimeRef.current = targetTime;
    }

    // Force that item so we won't override it in the effect
    setForceClickedIndex(itemIndex);
    setFollowAlongTranscript(true);
    setCurrentTranscriptIndex(itemIndex);

    lastProgrammaticScrollRef.current = Date.now();
    const refEl = transcriptItemRefs.current[itemIndex];
    if (refEl?.scrollIntoView) {
      refEl.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  if (!isPublic && logout) {
    return (
      <Navbar
        setUserEmail={setUserEmail}
        setUser={setUser}
        isPublic={isPublic}
        loading={loading}
        setLoading={setLoading}
        setLogout={setLogout}
        logout={logout}
      >
        <div className="flex items-center justify-center h-screen">
          <p className="text-sm text-gray-500">
            You are not authorized. Please log in again.
          </p>
        </div>
      </Navbar>
    );
  }

  if ((pageNotFound || isDeleted) && loading === false) {
    return (
      <Navbar
        setUserEmail={setUserEmail}
        setUser={setUser}
        isPublic={isPublic}
        loading={loading}
        setLoading={setLoading}
        setLogout={setLogout}
        logout={logout}
      >
        <div className="flex items-center justify-center h-screen">
          <p className="text-sm text-gray-500">
            {isDeleted
              ? "This meeting was deleted."
              : "Meeting not found or you do not have access."}
          </p>
        </div>
      </Navbar>
    );
  }

  return (
    <Navbar
      setUserEmail={setUserEmail}
      setUser={setUser}
      isPublic={isPublic}
      loading={loading}
      setLoading={setLoading}
      setLogout={setLogout}
      logout={logout}
    >
      {/* 4C.1) If the meeting is still loading => show a spinner placeholder */}
      {meetingLoading && (
        <div className="flex items-center justify-center h-[calc(100vh-64px)]">
          <div className="text-center">
            <svg
              className="animate-spin h-8 w-8 text-gray-400 mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
              />
            </svg>
            <p className="text-sm text-gray-500 mt-2">Loading meeting...</p>
          </div>
        </div>
      )}

      {/* 4C.2) If we have a known error: page not found or deleted */}
      {!meetingLoading && (pageNotFound || isDeleted) && (
        <div className="flex items-center justify-center h-[calc(100vh-64px)]">
          <p className="text-sm text-gray-500">
            {isDeleted
              ? "This meeting was deleted."
              : "Meeting not found or you do not have access."}
          </p>
        </div>
      )}
      {meeting && (
        <Transition show={isRegenLoading} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[9999]"
            onClose={() => {
              /* optional: disable user close */
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/60" />
            </Transition.Child>

            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-90"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-150"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-90"
              >
                <Dialog.Panel className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full flex flex-col items-center space-y-4">
                  <svg
                    className="animate-spin h-8 w-8 text-brand-turq"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                    />
                  </svg>

                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-gray-700 text-center"
                  >
                    Generating your new meeting notes...
                  </Dialog.Title>
                  <p className="text-sm text-gray-500 text-center">
                    Scribbl AI is analyzing your transcript. This might take a
                    few seconds.
                  </p>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      )}
      {meeting && (
        <div className="flex flex-col h-screen w-full overflow-hidden bg-gray-50">
          <div
            className={`flex-1 flex flex-row overflow-hidden ${
              leftPaneCollapsed ? "gap-0" : "gap-4"
            }`}
          >
            {meeting?.isLocked && (
              <OutOfCredits user={user} userEmail={userEmail} />
            )}

            {/* LEFT PANE */}
            <div
              className={`
                transition-all duration-300
                ${
                  actionsTabFullWidth
                    ? "w-full"
                    : leftPaneCollapsed
                    ? "w-0 overflow-hidden"
                    : "w-full sm:w-1/2"
                }
                flex flex-col h-full min-h-0
              `}
            >
              {(!leftPaneCollapsed ||
                (leftTabIndex === 4 && actionsTabFullWidth)) && (
                <div className="h-full flex flex-col min-h-0 overflow-hidden border-r border-gray-200 bg-white rounded-t-xl shadow-sm">
                  {/* Header */}
                  <Header32WiredUp
                    meetingTitle={meeting.meetingTitle}
                    isLocked={meeting.isLocked}
                    isEditingName={editingName}
                    editingNameValue={editingNameValue}
                    meetingDateFormatted={moment(meeting.CreatedAt).format(
                      "MMM D, YYYY"
                    )}
                    isPublic={isPublic}
                    actionsTabFullWidth={actionsTabFullWidth}
                    onRenameMeeting={handleRenameMeeting}
                    onCancelRename={() => setEditingName(false)}
                    onEditingNameChange={(value) => setEditingNameValue(value)}
                    setIsEditingName={setEditingName}
                    onCopyTranscript={(setCopyState) =>
                      handleCopyTranscript(meeting, setCopyState)
                    } // Updated this line
                    onShare={() => {
                      if (!isPublic) {
                        setOpenShareDialog(true);
                      }
                    }}
                    onToggleFullWidth={() => {
                      setActionsTabFullWidth((prev) => !prev);
                    }}
                  />
                  {/* Left side tabs */}
                  <SimpleTabs
                    selectedIndex={leftTabIndex}
                    onChange={setLeftTabIndex}
                  >
                    <SimpleTabList>
                      <SimpleTab
                        selected={leftTabIndex === 0}
                        onClick={() => setLeftTabIndex(0)}
                      >
                        <NotePencil size={16} />
                        <span>Notes</span>
                        {isPollingMeetingStatus && (
                          <motion.div
                            animate={{ opacity: [0.5, 1, 0.5] }}
                            transition={{
                              duration: 1.5,
                              repeat: Infinity,
                              repeatType: "loop",
                            }}
                            className="ml-1.5 w-2 h-2 rounded-full bg-brand-turq"
                          />
                        )}
                      </SimpleTab>
                      <SimpleTab
                        selected={leftTabIndex === 1}
                        onClick={() => setLeftTabIndex(1)}
                      >
                        <CheckSquare size={16} />
                        <span>Action Items</span>
                      </SimpleTab>
                      {!isPublic && (
                        <SimpleTab
                          selected={leftTabIndex === 2}
                          onClick={() => setLeftTabIndex(2)}
                        >
                          <Robot size={16} />
                          <span>AI Chat</span>
                        </SimpleTab>
                      )}
                      {!isPublic && (
                        <SimpleTab
                          selected={leftTabIndex === 3}
                          onClick={() => setLeftTabIndex(3)}
                        >
                          <PuzzlePiece size={16} />
                          <span>Custom</span>
                        </SimpleTab>
                      )}
                      {!isPublic && (
                        <SimpleTab
                          selected={leftTabIndex === 4}
                          onClick={() => setLeftTabIndex(4)}
                          className={`ai-feature-tab ${
                            leftTabIndex === 4 ? "active" : ""
                          }`}
                        >
                          <div className="ai-sparkle-3d">
                            <style jsx>{`
                              @keyframes float {
                                0%,
                                100% {
                                  transform: translateY(0) rotate(45deg);
                                }
                                50% {
                                  transform: translateY(-2px) rotate(45deg);
                                }
                              }

                              @keyframes floatAlt {
                                0%,
                                100% {
                                  transform: translateY(0) rotate(45deg);
                                }
                                50% {
                                  transform: translateY(2px) rotate(45deg);
                                }
                              }

                              @keyframes shimmer {
                                0% {
                                  background-position: -100% 0;
                                }
                                100% {
                                  background-position: 200% 0;
                                }
                              }

                              @keyframes sparkleEffect {
                                0%,
                                100% {
                                  transform: scale(0);
                                  opacity: 0;
                                }
                                50% {
                                  transform: scale(1);
                                  opacity: 1;
                                }
                              }

                              @keyframes glowPulse {
                                0%,
                                100% {
                                  opacity: 0.4;
                                  transform: scale(0.8);
                                }
                                50% {
                                  opacity: 0.8;
                                  transform: scale(1.2);
                                }
                              }

                              .ai-sparkle-3d {
                                position: relative;
                                width: 18px;
                                height: 18px;
                                margin-right: 2px;
                              }

                              .diamond-3d {
                                position: absolute;
                                width: 10px;
                                height: 10px;
                                transform: rotate(45deg);
                                transform-style: preserve-3d;
                                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                              }

                              .diamond-primary {
                                background: linear-gradient(
                                  135deg,
                                  #00c4cc,
                                  #008c92
                                );
                                top: 2px;
                                left: 2px;
                                z-index: 2;
                                animation: float 3s infinite ease-in-out;
                                border: 1px solid rgba(255, 255, 255, 0.5);
                              }

                              .diamond-secondary {
                                background: linear-gradient(
                                  135deg,
                                  #5dadff,
                                  #00c4cc
                                );
                                top: 4px;
                                left: 4px;
                                z-index: 1;
                                animation: floatAlt 3s infinite ease-in-out;
                                animation-delay: 0.5s;
                                border: 1px solid rgba(255, 255, 255, 0.3);
                              }

                              .shimmer {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: linear-gradient(
                                  90deg,
                                  rgba(255, 255, 255, 0) 0%,
                                  rgba(255, 255, 255, 0.4) 50%,
                                  rgba(255, 255, 255, 0) 100%
                                );
                                background-size: 200% 100%;
                                animation: shimmer 2s infinite linear;
                                z-index: 4;
                              }

                              .sparkle {
                                position: absolute;
                                width: 3px;
                                height: 3px;
                                background-color: white;
                                border-radius: 50%;
                                z-index: 5;
                                animation: sparkleEffect 2s infinite ease-out;
                              }

                              .sparkle-1 {
                                top: -3px;
                                left: 7px;
                                animation-delay: 0s;
                              }

                              .sparkle-2 {
                                top: 7px;
                                right: -3px;
                                animation-delay: 0.5s;
                              }

                              .sparkle-3 {
                                bottom: -3px;
                                left: 7px;
                                animation-delay: 1s;
                              }

                              .sparkle-4 {
                                top: 7px;
                                left: -3px;
                                animation-delay: 1.5s;
                              }

                              .glow-effect {
                                position: absolute;
                                top: -5px;
                                left: -5px;
                                right: -5px;
                                bottom: -5px;
                                border-radius: 50%;
                                background: radial-gradient(
                                  circle,
                                  rgba(0, 196, 204, 0.6) 0%,
                                  rgba(0, 196, 204, 0) 70%
                                );
                                z-index: 0;
                                animation: glowPulse 3s infinite ease-in-out;
                                pointer-events: none;
                              }

                              /* Tab styling */
                              .ai-feature-tab {
                                position: relative;
                                overflow: hidden;
                              }

                              .ai-feature-tab.active::after {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 15%;
                                width: 70%;
                                height: 2px;
                                background: linear-gradient(
                                  90deg,
                                  transparent,
                                  #00c4cc,
                                  transparent
                                );
                                animation: shimmer 2s infinite linear;
                                border-radius: 2px;
                              }

                              .ai-feature-tab:hover .glow-effect {
                                opacity: 1;
                              }
                            `}</style>
                            <div className="diamond-3d diamond-primary">
                              <div className="shimmer"></div>
                            </div>
                            <div className="diamond-3d diamond-secondary"></div>
                            <div className="glow-effect"></div>
                            <div className="sparkle sparkle-1"></div>
                            <div className="sparkle sparkle-2"></div>
                            <div className="sparkle sparkle-3"></div>
                            <div className="sparkle sparkle-4"></div>
                          </div>
                          <span>AI Actions</span>
                          {isPollingMeetingStatus && (
                            <motion.div
                              animate={{ opacity: [0.5, 1, 0.5] }}
                              transition={{
                                duration: 1.5,
                                repeat: Infinity,
                                repeatType: "loop",
                              }}
                              className="ml-1.5 w-2 h-2 rounded-full bg-brand-turq"
                            />
                          )}
                        </SimpleTab>
                      )}
                    </SimpleTabList>

                    <SimpleTabPanels selectedIndex={leftTabIndex}>
                      <SimpleTabPanel>
                        <CustomMeetingNotesCard
                          customNotes={customMeetingNotes}
                          onRegen={handleRegenSummary}
                          isRegenLoading={isRegenLoading}
                          meetingTemplates={meetingTemplates}
                          selectedMeetingTemplateId={selectedMeetingTemplateId}
                          setSelectedMeetingTemplateId={
                            setSelectedMeetingTemplateId
                          }
                          onTimeJump={handleTimeJump}
                          isPollingForMeetingData={isPollingForMeetingData}
                          isPollingMeetingStatus={isPollingMeetingStatus} // Add this line
                        />
                      </SimpleTabPanel>

                      <SimpleTabPanel>
                        {/* Action Items content */}
                        <div className="h-full">
                          <ActionItemsTab
                            actionItems={meeting.actionItems}
                            setActionItems={setActionItems}
                            meetingUUID={meeting.uuid}
                            user={user}
                            isPublic={isPublic}
                            onTimeJump={handleTimeJump}
                          />
                        </div>
                      </SimpleTabPanel>

                      {/* Only include these panels if not public */}
                      {!isPublic && (
                        <SimpleTabPanel>
                          {/* AI Chat content */}
                          <AiChatView
                            chatHistory={chatHistory}
                            setChatHistory={setChatHistory}
                            aiInput={aiInput}
                            setAiInput={setAiInput}
                            onSend={handleSendAiMessage}
                            aiLoading={aiLoading}
                            isProUser={isProUser()}
                            numAiResponses={numAiResponses}
                          />
                        </SimpleTabPanel>
                      )}

                      {!isPublic && (
                        <SimpleTabPanel>
                          {/* Custom tab content */}
                          <CustomOutputTab meeting={meeting} />
                        </SimpleTabPanel>
                      )}

                      {!isPublic && (
                        <SimpleTabPanel>
                          {/* Actions tab content */}
                          <div className="h-full flex flex-col">
                            {actionsTabFullWidth ? (
                              <>
                                <div className="flex items-center justify-between bg-white border-b border-gray-200 p-3">
                                  <div className="flex items-center gap-2">
                                    <CheckSquare className="text-brand-turq h-5 w-5" />
                                    <h3 className="font-semibold text-gray-800">
                                      Actions
                                    </h3>
                                  </div>
                                  <button
                                    onClick={() => {
                                      setActionsTabFullWidth(false);
                                    }}
                                    className="inline-flex items-center gap-1 text-xs text-gray-600 hover:text-gray-800 transition-colors px-3 py-1.5 border border-gray-200 rounded-md"
                                  >
                                    <ArrowsInSimple className="h-4 w-4" />
                                    <span>Split View</span>
                                  </button>
                                </div>
                                <ActionsTab
                                  meetingId={meeting.uuid}
                                  actionOutputs={actionOutputs}
                                />
                              </>
                            ) : (
                              <>
                                {actionOutputs.length === 0 ? (
                                  <AIActionsEmptyState
                                    onCreateWorkflow={() => {
                                      // Open the workflow creation page in a new tab
                                      window.open(
                                        "/meeting-workflows",
                                        "_blank"
                                      );
                                      // Alternatively, if using React Router, ensure it handles external navigation properly
                                    }}
                                  />
                                ) : (
                                  <>
                                    <div className="flex items-center justify-between bg-white border-b border-gray-200 p-3">
                                      <div className="flex items-center gap-2">
                                        <CheckSquare className="text-brand-turq h-5 w-5" />
                                        <h3 className="font-semibold text-gray-800">
                                          Actions
                                        </h3>
                                      </div>
                                      <button
                                        onClick={() => {
                                          setActionsTabFullWidth(true);
                                        }}
                                        className="inline-flex items-center gap-1 text-xs text-gray-600 hover:text-gray-800 transition-colors px-3 py-1.5 border border-gray-200 rounded-md"
                                      >
                                        <ArrowsOutSimple className="h-4 w-4" />
                                        <span>Full View</span>
                                      </button>
                                    </div>
                                    <div className="flex-1 overflow-y-auto p-3 bg-gray-50">
                                      <div className="space-y-2">
                                        {actionOutputs.map((output) => (
                                          <div
                                            key={output.ID}
                                            className="bg-white border border-gray-200 rounded-md shadow-sm"
                                          >
                                            <div className="p-3">
                                              <div className="flex items-start justify-between gap-2">
                                                <div className="min-w-0">
                                                  <p className="text-sm font-medium text-gray-700 truncate">
                                                    {output.actionType}
                                                  </p>
                                                  <p className="text-xs text-gray-500">
                                                    Status:{" "}
                                                    {output.status || "draft"}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </SimpleTabPanel>
                      )}
                    </SimpleTabPanels>
                  </SimpleTabs>
                </div>
              )}
            </div>

            {/* RIGHT COLUMN */}
            {!actionsTabFullWidth && (
              <div className="flex-1 flex flex-col gap-4 h-full min-h-0 overflow-hidden p-4">
                {isVideoMeeting && (
                  <div
                    ref={videoContainerRef}
                    className="relative w-full bg-black rounded-xl overflow-hidden shadow-lg"
                    style={{
                      aspectRatio: "16/9",
                      maxHeight: isFullscreen
                        ? "100vh"
                        : theaterMode
                        ? "55vh"
                        : "40vh",
                      transition: "max-height 0.3s ease",
                    }}
                  >
                    {/* Add a transparent overlay to catch clicks */}
                    <div
                      className="absolute inset-0 z-10 cursor-pointer"
                      onClick={togglePlay}
                    />

                    <video
                      ref={videoRef}
                      className="absolute inset-0 w-full h-full object-contain bg-black"
                      playsInline
                      src={meeting?.recordingURL || ""}
                    />

                    {/* Overlay controls */}
                    <div
                      className={`absolute inset-0 z-20 flex flex-col justify-between transition-opacity duration-300 ${
                        controlsVisible
                          ? "opacity-100"
                          : "opacity-0 pointer-events-none"
                      }`}
                    >
                      {/* Top bar */}
                      <div className="bg-gradient-to-b from-black/70 to-transparent p-3 text-white flex items-center justify-between pointer-events-auto">
                        {/* Title, left side */}
                        <div className="text-sm font-medium line-clamp-1 mr-2">
                          {meeting?.meetingTitle}
                        </div>

                        {/* Right side buttons */}
                        <div className="flex items-center space-x-4">
                          {/* Toggle left pane, etc. */}
                          <button
                            onClick={toggleLeftPane}
                            className="text-white hover:text-brand-turq"
                          >
                            <Sidebar size={20} weight="fill" />
                          </button>
                          <button
                            onClick={() => setTheaterMode((prev) => !prev)}
                            className="text-white hover:text-brand-turq"
                          >
                            <MonitorPlay
                              size={20}
                              weight={theaterMode ? "regular" : "fill"}
                            />
                          </button>
                          <button
                            onClick={toggleFullscreen}
                            className="text-white hover:text-brand-turq"
                          >
                            {isFullscreen ? (
                              <ArrowsInSimple size={20} weight="fill" />
                            ) : (
                              <ArrowsOutSimple size={20} weight="fill" />
                            )}
                          </button>
                        </div>
                      </div>

                      {/* Bottom bar */}
                      <div className="bg-gradient-to-t from-black/80 to-transparent p-3 pointer-events-auto">
                        {/* Progress / timeline */}
                        <div
                          className="relative w-full h-4 cursor-pointer group"
                          ref={progressBarRef}
                          onPointerDown={handlePointerDown}
                          onPointerMove={handlePointerMove}
                          onPointerUp={handlePointerUp}
                          onPointerLeave={() => setHoverTime(null)}
                        >
                          {/* Track */}
                          <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full h-1 bg-gray-400 rounded-full" />
                          {/* Progress fill */}
                          <div
                            className="absolute top-1/2 -translate-y-1/2 left-0 h-1 bg-brand-turq rounded-full"
                            style={{ width: `${progressPercent}%` }}
                          />
                          {/* Scrubber thumb (when scrubbing or hovered) */}
                          {(isScrubbing || hoverTime !== null) && (
                            <div
                              className="absolute top-1/2 -translate-y-1/2 w-3 h-3 rounded-full bg-white shadow"
                              style={{
                                left: isScrubbing
                                  ? `${progressPercent}%`
                                  : `${(hoverTime / duration) * 100}%`,
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          )}

                          {/* Tooltip (only when hovering or scrubbing) */}
                          {(isScrubbing || hoverTime !== null) && (
                            <div
                              className="absolute bottom-full mb-1 px-2 py-1 bg-black text-white text-xs rounded whitespace-nowrap transform -translate-x-1/2 pointer-events-none"
                              style={{
                                left: isScrubbing
                                  ? `${progressPercent}%`
                                  : `${(hoverTime / duration) * 100}%`,
                              }}
                            >
                              {isScrubbing
                                ? formatTime(scrubTime)
                                : formatTime(hoverTime || 0)}
                            </div>
                          )}
                        </div>

                        {/* Time indicators */}
                        {/* For the time display: */}
                        <div className="flex items-center justify-between text-xs text-white mt-1">
                          <span>{formatTime(displayTime)}</span>
                          <span>{formatTime(duration)}</span>
                        </div>

                        {/* Controls row */}
                        <div className="flex items-center space-x-3 mt-3">
                          {/* Play / Pause */}
                          <button
                            onClick={togglePlay}
                            className="text-white hover:text-brand-turq"
                          >
                            {isPlaying ? (
                              <Pause size={22} weight="bold" />
                            ) : (
                              <Play size={22} weight="bold" />
                            )}
                          </button>

                          {/* Rewind / Forward (if you still want them) */}
                          <button
                            onClick={() => skipSeconds(-10)}
                            className="text-white hover:text-brand-turq"
                          >
                            <Rewind size={20} weight="bold" />
                          </button>
                          <button
                            onClick={() => skipSeconds(10)}
                            className="text-white hover:text-brand-turq"
                          >
                            <FastForward size={20} weight="bold" />
                          </button>

                          {/* Volume toggle */}
                          <button
                            onClick={toggleVolume}
                            className="text-white hover:text-brand-turq"
                          >
                            {volumeOn ? (
                              <SpeakerHigh size={20} weight="bold" />
                            ) : (
                              <SpeakerSlash size={20} weight="bold" />
                            )}
                          </button>

                          {/* Playback rate selector */}
                          <select
                            className="bg-black bg-opacity-50 text-white text-sm p-1 rounded"
                            value={playbackRate}
                            onChange={(e) =>
                              handleSetSpeed(Number(e.target.value))
                            }
                          >
                            <option value={0.5}>0.5x</option>
                            <option value={1}>1x</option>
                            <option value={1.25}>1.25x</option>
                            <option value={1.5}>1.5x</option>
                            <option value={2}>2x</option>
                            <option value={3}>3x</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex-1 flex flex-col min-h-0 overflow-hidden">
                  <Tab.Group
                    selectedIndex={rightTabIndex}
                    onChange={setRightTabIndex}
                  >
                    <Tab.List className="flex items-center gap-2 px-4 py-3 bg-white border-b border-gray-200">
                      <Tab
                        className={({ selected }) =>
                          selected
                            ? "flex items-center gap-2 px-3 py-2 text-sm font-medium bg-brand-turq text-white rounded-full shadow-md transition-transform transform hover:scale-105"
                            : "flex items-center gap-2 px-3 py-2 text-sm font-medium bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 transition-transform transform hover:scale-105"
                        }
                      >
                        <UsersThree size={16} weight="fill" />
                        <span>Speakers</span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          selected
                            ? "flex items-center gap-2 px-3 py-2 text-sm font-medium bg-brand-turq text-white rounded-full shadow-md transition-transform transform hover:scale-105"
                            : "flex items-center gap-2 px-3 py-2 text-sm font-medium bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 transition-transform transform hover:scale-105"
                        }
                      >
                        <TextAlignLeft size={16} weight="fill" />
                        <span>Transcript</span>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          selected
                            ? "flex items-center gap-2 px-3 py-2 text-sm font-medium bg-brand-turq text-white rounded-full shadow-md transition-transform transform hover:scale-105"
                            : "flex items-center gap-2 px-3 py-2 text-sm font-medium bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 transition-transform transform hover:scale-105"
                        }
                      >
                        <ChatsCircle size={16} weight="fill" />
                        <span>Meeting Chat</span>
                      </Tab>
                    </Tab.List>

                    <Tab.Panels className="flex-1 min-h-0 overflow-y-auto bg-gray-50">
                      <Tab.Panel className="pt-4 space-y-4">
                        {processedScreenShares.length > 0 && (
                          <div className="bg-white p-3 border border-gray-200 rounded-md shadow-sm">
                            <h3 className="text-sm font-semibold text-gray-800 mb-2">
                              Screen Sharing
                              <span className="text-xs text-gray-400 ml-1">
                                (Hover to preview, click to jump)
                              </span>
                            </h3>
                            <TimelineContainer
                              duration={duration}
                              thumbnailsMap={thumbnailsMap}
                            >
                              <EnhancedTimelineBar
                                chunks={convertScreenShareToChunks(
                                  processedScreenShares,
                                  duration
                                )}
                                duration={duration}
                                color="#14B8A6"
                                onTimeJump={handleTimeJump}
                                index={0}
                              />
                            </TimelineContainer>
                          </div>
                        )}
                        <div className="bg-white p-3 border border-gray-200 rounded-md shadow-sm space-y-4">
                          <h3 className="text-sm font-semibold text-gray-800 flex items-center gap-3">
                            Speakers
                            {isVideoMeeting && (
                              <span className="ml-2 text-xs text-gray-400">
                                (Hover to preview, click to jump)
                              </span>
                            )}
                          </h3>
                          {speakers?.length ? (
                            <TimelineContainer
                              duration={duration}
                              thumbnailsMap={thumbnailsMap}
                            >
                              {speakers.map((sp, i) => (
                                <div
                                  key={i}
                                  className="border-b border-gray-200 last:border-none pb-3 mb-3"
                                >
                                  <div className="flex items-center justify-between mb-2">
                                    <span className="text-sm font-medium text-gray-700">
                                      {sp.name}
                                    </span>
                                    <span className="text-xs text-gray-400">
                                      Talk Time: {sp.formattedTalkTime}
                                    </span>
                                  </div>
                                  <EnhancedTimelineBar
                                    chunks={sp.chunks}
                                    duration={duration}
                                    color={sp.color}
                                    onTimeJump={handleTimeJump}
                                    index={i + 1} // Account for screen sharing being index 0
                                  />
                                </div>
                              ))}
                            </TimelineContainer>
                          ) : (
                            <div className="text-sm text-gray-500">
                              No speaker data found.
                            </div>
                          )}
                        </div>
                      </Tab.Panel>
                      {/* Transcript tab panel */}
                      <Tab.Panel className="flex flex-col min-h-0 h-full relative">
                        {/* Sticky header with search bar */}
                        <div className="sticky top-0 z-10 bg-gray-50 border-b shadow-sm">
                          <div className="py-3 flex flex-col gap-3">
                            {/* Search row */}
                            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
                              <div className="relative flex-1">
                                <SearchIcon
                                  className="absolute top-2 left-2 text-gray-400"
                                  size={16}
                                  weight="bold"
                                />
                                <input
                                  type="text"
                                  placeholder="Search transcript..."
                                  value={searchTerm}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                      handleNextMatch();
                                    }
                                  }}
                                  onChange={(e) =>
                                    setSearchTerm(e.target.value)
                                  }
                                  className="w-full pl-8 pr-2 py-1 text-sm border border-gray-300 rounded-md
                focus:outline-none focus:ring-1 focus:ring-brand-turq"
                                />
                              </div>

                              {searchResults.length > 0 && (
                                <div className="flex items-center space-x-2 text-sm text-gray-600">
                                  <span>
                                    {searchIndex + 1} / {searchResults.length}
                                  </span>
                                  <button
                                    onClick={handlePrevMatch}
                                    className="px-2 py-1 border border-gray-300 rounded-md 
                  hover:bg-gray-100 transition"
                                  >
                                    Prev
                                  </button>
                                  <button
                                    onClick={handleNextMatch}
                                    className="px-2 py-1 border border-gray-300 rounded-md 
                  hover:bg-gray-100 transition"
                                  >
                                    Next
                                  </button>
                                </div>
                              )}
                            </div>
                            {/*
          Removed "Follow along" checkbox. We rely on the pinned "Sync to video" button.
        */}
                          </div>
                        </div>

                        {/*
      Scrollable container. If user manually scrolls after ~700ms from our last
      auto-scroll, we do setFollowAlongTranscript(false).
    */}
                        <div
                          className="flex-1 min-h-0 overflow-y-auto p-3 bg-white space-y-3 relative"
                          onScroll={() => {
                            if (
                              Date.now() - lastProgrammaticScrollRef.current >
                              700
                            ) {
                              setFollowAlongTranscript(false);
                            }
                          }}
                        >
                          {(() => {
                            // Sort the items by itemStart before rendering
                            const sortedTranscriptItems = [
                              ...(meeting?.transcriptItems || []),
                            ].sort((a, b) => a.itemStart - b.itemStart);

                            return sortedTranscriptItems.length ? (
                              sortedTranscriptItems.map((item, i) => {
                                // Because we re-sorted, store the ref by index:
                                const isActiveMatch =
                                  sortedTranscriptItems[
                                    searchResults[searchIndex]
                                  ] === item;
                                // We must find the "global" index for item if we rely on that.
                                // But to highlight or jump, we can just use local i in references:
                                const isFollowed = i === currentTranscriptIndex;

                                return (
                                  <div
                                    key={i}
                                    ref={(el) =>
                                      (transcriptItemRefs.current[i] = el)
                                    }
                                  >
                                    <TranscriptItemView
                                      item={item}
                                      offset={meeting.recordingTimeOffset}
                                      highlightTerm={searchTerm}
                                      isActiveMatch={isActiveMatch}
                                      isFollowed={isFollowed}
                                      // This onClick calls our new function
                                      onClick={() =>
                                        handleTranscriptItemClick(i)
                                      }
                                      isTranscriptOnly={isTranscriptOnly}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-sm text-gray-500">
                                No transcript.
                              </p>
                            );
                          })()}
                        </div>

                        {/*
      "Sync to video" button pinned bottom-right.
      Only visible when followAlongTranscript === false.
      Clicking => runOneImmediateCheckTime() to pick the best item for currentTime
    */}
                        {!isTranscriptOnly && !followAlongTranscript && (
                          <button
                            onClick={() => {
                              setFollowAlongTranscript(true);
                              runOneImmediateCheckTime();
                            }}
                            className="fixed bottom-8 right-8 z-20 bg-brand-turq text-white
          rounded-full px-4 py-2 shadow-lg text-sm
          hover:bg-brand-turq/90 transition"
                          >
                            Sync to video
                          </button>
                        )}
                      </Tab.Panel>

                      <Tab.Panel className="pt-4 flex flex-col min-h-0">
                        <div className="bg-white border border-gray-200 rounded-lg shadow-sm flex flex-col h-full overflow-hidden">
                          <div className="px-3 py-2 border-b border-gray-200 bg-gray-50">
                            <h4 className="text-sm font-semibold text-gray-900">
                              Meeting Chat
                            </h4>
                            <p className="text-xs text-gray-500 mt-1">
                              Conversation captured during the meeting
                            </p>
                          </div>
                          <div className="flex-1 overflow-y-auto p-3 space-y-2 text-sm bg-white">
                            {meeting?.chatMessages?.length ? (
                              meeting.chatMessages.map((chat, i) => (
                                <MeetingChatBubble
                                  key={i}
                                  chat={chat}
                                  isCurrentUser={
                                    userEmail &&
                                    chat.user &&
                                    chat.user.toLowerCase() ===
                                      userEmail.toLowerCase()
                                  }
                                />
                              ))
                            ) : (
                              <p className="text-sm text-gray-500">No chat.</p>
                            )}
                          </div>
                        </div>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </div>
            )}
          </div>

          {!isPublic && (
            <ShareMeetingModal
              isOpen={openShareDialog}
              onClose={() => {
                setOpenShareDialog(false);
              }}
              user={user}
              userEmail={user?.email}
              uuid={meeting.uuid}
              key={meeting.uuid}
              setTriggerTeamModal={setTriggerTeamModal}
              currentVideoTime={videoRef?.current?.currentTime}
            />
          )}
        </div>
      )}
    </Navbar>
  );
}

export function Header32WiredUp({
  meetingTitle,
  isLocked,
  editingNameValue,
  meetingDateFormatted,
  isPublic,
  actionsTabFullWidth,
  onRenameMeeting,
  onEditingNameChange,
  onCopyTranscript,
  onShare,
  onToggleFullWidth,
}) {
  const [copyState, setCopyState] = React.useState("default");
  const [showEditTitleModal, setShowEditTitleModal] = React.useState(false);

  const copyButtonContent = {
    default: (
      <>
        <Copy size={16} weight="bold" />
        <span>Copy Transcript</span>
      </>
    ),
    copied: (
      <>
        <CheckSquare size={16} weight="bold" />
        <span>Copied!</span>
      </>
    ),
    error: (
      <>
        <XIcon size={16} weight="bold" />
        <span>Copy Failed</span>
      </>
    ),
  };

  return (
    <div className="relative bg-[#f8f9fa]">
      {/* Decorative top design */}
      <div className="absolute top-0 inset-x-0">
        <div className="h-1 bg-gradient-to-r from-[#21A0A0] via-[#4DB3B3] to-[#21A0A0]/50" />
        <div className="h-6 bg-gradient-to-b from-[#21A0A0]/5 to-transparent" />
      </div>

      <div className="px-5 py-3 relative">
        <div className="flex flex-col gap-2">
          {/* Title row - full width */}
          <div className="group flex items-start gap-3">
            <h1
              className="text-xl font-semibold text-[#212121] line-clamp-2 break-words flex-1"
              title={meetingTitle}
            >
              {meetingTitle}
            </h1>
            {isLocked && (
              <LockClosedIcon
                className="text-[#21A0A0] flex-none mt-1"
                size={18}
                weight="fill"
              />
            )}
            {/* Edit button - show on hover like original */}
            {!isPublic && !isLocked && (
              <button
                onClick={() => setShowEditTitleModal(true)}
                className="opacity-0 group-hover:opacity-100 transition-opacity text-[#878787] hover:text-[#212121] flex-none mt-1"
              >
                <PencilIcon size={16} />
              </button>
            )}
          </div>

          {/* Date and buttons row */}
          <div className="flex flex-wrap items-center justify-between gap-y-2">
            {/* Date */}
            <div className="text-[#535353] text-sm">{meetingDateFormatted}</div>

            {/* Buttons */}
            <div className="flex items-center gap-3">
              {!isPublic && (
                <div className="flex items-center gap-2 px-1.5 py-1 bg-white rounded-lg border border-[#eeeeee] shadow-sm">
                  <button
                    onClick={onShare}
                    className="px-3 h-8 text-[#212121] text-sm rounded hover:bg-[#f8f9fa] transition-all inline-flex items-center gap-2"
                  >
                    <ShareIcon size={16} weight="fill" />
                    <span>Share</span>
                  </button>
                  <button
                    onClick={onToggleFullWidth}
                    className="px-3 h-8 text-[#212121] text-sm rounded hover:bg-[#f8f9fa] transition-all inline-flex items-center gap-2"
                  >
                    {actionsTabFullWidth ? (
                      <>
                        <ArrowsInSimple size={16} weight="fill" />
                        <span>Split</span>
                      </>
                    ) : (
                      <>
                        <ArrowsOutSimple size={16} weight="fill" />
                        <span>Full</span>
                      </>
                    )}
                  </button>
                </div>
              )}

              <button
                onClick={() => onCopyTranscript(setCopyState)}
                className="h-[38px] min-w-[150px] px-4 bg-[#21A0A0] hover:bg-[#1C8787] text-white text-sm font-medium rounded-lg transition-all inline-flex items-center justify-center gap-2 shadow-sm disabled:opacity-50"
                disabled={copyState !== "default"}
              >
                {copyButtonContent[copyState]}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for editing the title */}
      <EditMeetingTitleModal
        isOpen={showEditTitleModal}
        onClose={() => setShowEditTitleModal(false)}
        editingNameValue={editingNameValue}
        onEditingNameChange={onEditingNameChange}
        onSave={() => {
          onRenameMeeting();
          setShowEditTitleModal(false);
        }}
      />
    </div>
  );
}

function EditMeetingTitleModal({
  isOpen,
  onClose,
  editingNameValue,
  onEditingNameChange,
  onSave,
}) {
  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/40 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-md rounded-lg bg-white p-6 shadow-xl">
              {/* Close button */}
              <button
                onClick={onClose}
                className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
              >
                <XIcon size={16} weight="bold" />
              </button>

              <Dialog.Title className="text-lg font-medium text-gray-800 mb-3">
                Edit Meeting Title
              </Dialog.Title>

              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="meetingTitle"
                    className="block text-sm font-semibold text-gray-700 mb-1"
                  >
                    Meeting Title
                  </label>
                  <input
                    id="meetingTitle"
                    type="text"
                    className="w-full border border-gray-300 rounded-md px-3 py-2 text-base focus:ring-2 focus:ring-[#21A0A0] focus:outline-none"
                    value={editingNameValue}
                    onChange={(e) => onEditingNameChange(e.target.value)}
                  />
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-2">
                <button
                  onClick={onClose}
                  className="px-4 py-2 bg-gray-100 rounded-md text-sm text-gray-600 hover:bg-gray-200 transition"
                >
                  Cancel
                </button>
                <button
                  onClick={onSave}
                  className="px-4 py-2 bg-[#21A0A0] text-white rounded-md text-sm font-medium hover:bg-[#1C8787] transition"
                >
                  Save
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

/**
 * AIActionsEmptyState Component
 *
 * A high-quality empty state for the AI Actions tab that encourages users
 * to set up meeting workflows with compelling visuals and clear value propositions.
 */
const AIActionsEmptyState = ({ onCreateWorkflow }) => {
  return (
    <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
      {/* Simple top border accent */}
      <div className="h-1 w-full bg-gradient-to-r from-brand-turq to-brand-green"></div>

      <div className="p-5">
        <div className="flex flex-col sm:flex-row items-start gap-6">
          {/* Left content column */}
          <div className="flex-1">
            {/* Clean premium header */}
            <div className="flex items-center mb-3">
              <div className="w-8 h-8 flex items-center justify-center rounded-md bg-brand-turq/10 mr-3">
                <SparklesIcon className="h-5 w-5 text-brand-turq" />
              </div>
              <h2 className="text-lg font-bold text-gray-900">
                Automate your post-meeting tasks
              </h2>
            </div>

            {/* Concise value proposition */}
            <p className="text-sm text-gray-600 mb-4 max-w-md">
              Let AI handle the manual work that follows your meetings, saving
              time and ensuring nothing falls through the cracks.
            </p>

            {/* Simple benefits focused on post-meeting tasks */}
            <div className="space-y-2 mb-5">
              {[
                {
                  icon: ClockIcon,
                  text: "Save hours on repetitive post-meeting work",
                },
                {
                  icon: CheckCircleIcon,
                  text: "Ensure consistent follow-through every time",
                },
                {
                  icon: UserGroupIcon,
                  text: "Keep everyone in sync automatically",
                },
              ].map((benefit, i) => (
                <div key={i} className="flex items-center">
                  <div className="w-6 h-6 flex items-center justify-center text-brand-turq">
                    <benefit.icon className="h-4 w-4" />
                  </div>
                  <span className="ml-2 text-sm text-gray-700">
                    {benefit.text}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Right CTA column */}
          <div className="w-full sm:w-auto sm:min-w-[200px] bg-gray-50 rounded-lg p-4 border border-gray-100">
            {/* Clean header for CTA area */}
            <div className="flex items-center justify-center mb-3">
              <div className="w-10 h-10 rounded-full bg-brand-turq/10 flex items-center justify-center">
                <LightningBoltIcon className="h-5 w-5 text-brand-turq" />
              </div>
            </div>

            <div className="text-center mb-4">
              <h3 className="font-medium text-gray-900 text-sm">
                Workflow Builder
              </h3>
              <p className="text-xs text-gray-500 mt-1">
                Configure once, run automatically
              </p>
            </div>

            {/* Premium CTA button */}
            <button
              onClick={onCreateWorkflow}
              className="w-full flex items-center justify-center px-4 py-2.5 rounded-md bg-brand-turq text-white font-medium text-sm shadow-sm hover:bg-brand-turq/90 transition-colors"
            >
              <PlusIcon className="h-4 w-4 mr-2" />
              <span>Set Up Workflow</span>
            </button>

            {/* Simple but effective message - no badge */}
            <div className="mt-3 pt-3 border-t border-gray-200 text-center">
              <p className="text-xs text-gray-600">
                Takes less than 2 minutes to configure
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Simple footer with extra info */}
      <div className="px-5 py-2 bg-gray-50 border-t border-gray-200 flex justify-between items-center">
        <span className="text-xs text-gray-500">
          Configure once, use forever
        </span>
        <span className="text-xs text-gray-500 flex items-center">
          <LightningBoltIcon className="h-3 w-3 mr-1" />
          Runs in the background
        </span>
      </div>
    </div>
  );
};

export function MeetingNotesLoadingState() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center bg-white p-8 rounded-xl">
      <div className="max-w-md w-full text-center">
        {/* Animated icon */}
        <div className="inline-flex items-center justify-center mb-6">
          <motion.div
            animate={{
              rotate: [0, 360],
              scale: [1, 1.05, 1],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              repeatType: "loop",
              ease: "easeInOut",
            }}
            className="bg-brand-turq/10 rounded-full p-3"
          >
            <Robot weight="duotone" size={40} className="text-brand-turq" />
          </motion.div>
        </div>

        {/* Simple heading */}
        <h2 className="text-xl font-bold text-gray-800 tracking-tight mb-2">
          Generating Your Meeting Notes
        </h2>

        {/* Simple description */}
        <p className="text-sm text-gray-500 mb-6">
          This should only take a minute or two
        </p>

        {/* Animated progress bar */}
        <div className="w-full h-2 bg-gray-100 rounded-full mb-6 overflow-hidden">
          <motion.div
            className="h-full bg-gradient-to-r from-brand-turq to-brand-green rounded-full"
            initial={{ width: "10%" }}
            animate={{ width: "70%" }}
            transition={{
              duration: 15,
              ease: "easeInOut",
            }}
          />
        </div>

        {/* Simple processing indicator */}
        <div className="flex items-center justify-center text-sm text-gray-600">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
            className="mr-2"
          >
            <CircleNotch weight="bold" size={16} className="text-brand-turq" />
          </motion.div>
          <span>Processing your meeting...</span>
        </div>
      </div>
    </div>
  );
}
