import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useLayoutEffect,
} from "react";
import {
  Dialog,
  Transition,
  Combobox,
  Menu,
  RadioGroup,
  Disclosure,
} from "@headlessui/react";
import {
  SearchIcon,
  XIcon,
  CalendarIcon,
  UserGroupIcon,
  ClockIcon,
  ChevronDownIcon,
  ExclamationIcon,
  DocumentSearchIcon,
  GiftIcon,
  LightningBoltIcon,
  LockClosedIcon,
  DotsVerticalIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  FilterIcon,
  SparklesIcon,
} from "@heroicons/react/outline";
import { FolderIcon } from "@heroicons/react/solid";
import Navbar from "./Navbar";
import InviteModal from "./InviteModal";
import {
  format,
  parseISO,
  startOfToday,
  subDays,
  parse as parseDate,
} from "date-fns";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import amplitude from "amplitude-js";

const getInitials = (name) =>
  name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase();

function encodeID(id) {
  // Convert to string
  const idStr = id.toString();
  // Encode as base64 using btoa (Base64)
  // Then replace +, / with URL-safe chars - and _, and strip =
  let encoded = btoa(idStr);
  encoded = encoded.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
  return encoded;
}

function decodeID(encoded) {
  let str = encoded.replace(/-/g, "+").replace(/_/g, "/");
  while (str.length % 4 !== 0) {
    str += "=";
  }
  const decoded = atob(str);
  const numericID = parseInt(decoded, 10);
  if (isNaN(numericID)) {
    throw new Error("Invalid encoded ID");
  }
  return numericID;
}

const getColor = (name) => {
  const colors = [
    "bg-indigo-500",
    "bg-teal-500",
    "bg-pink-500",
    "bg-amber-500",
  ];
  const index =
    name.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0) %
    colors.length;
  return colors[index];
};
// highlight search
const highlightSearchTerm = (text, searchTerm) => {
  if (!text || !searchTerm) return text;
  const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          className={
            part.toLowerCase() === searchTerm.toLowerCase()
              ? "bg-yellow-100 font-semibold"
              : ""
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};

// parse GPT data
const parseGPTMeetingData = (gptDataStr) => {
  if (!gptDataStr) return { summary: "", fullData: null };
  try {
    const data = JSON.parse(gptDataStr);

    let preview = "";
    if (data.topics && data.topics.length > 0) {
      const firstTopic = data.topics[0];
      if (firstTopic.details && firstTopic.details.length > 0) {
        preview = `${firstTopic.topic}: ${firstTopic.details[0]}`;
      } else {
        preview = firstTopic.topic;
      }
    } else if (data.action_items && data.action_items.length > 0) {
      preview = `Action Items: ${data.action_items[0].action_item}`;
    }

    return { summary: preview || "", fullData: data };
  } catch (e) {
    return { summary: "", fullData: null };
  }
};

const MeetingCard = ({
  meeting,
  openDeleteModal,
  searchTerm,
  searchType,
  hasSearched,
  openSummaryModal,
  userEmail,
}) => {
  const formattedDate = meeting.CreatedAt
    ? format(parseISO(meeting.CreatedAt), "EEE, MMM d, yyyy • h:mm a")
    : "";

  // Identify attendees that appear to be just emails
  const isEmailPattern = (str) => /^\S+@\S+\.\S+$/.test(str);

  // Filter out email-only attendees from displayed list
  // They can still exist in meeting.attendees for searching,
  // but we won't display them as chips if they look like emails.
  const filteredAttendees = meeting.attendees
    ? meeting.attendees.filter((a) => !isEmailPattern(a.name))
    : [];

  const displayedAttendees = filteredAttendees.slice(0, 3);
  const remainingAttendees = filteredAttendees.slice(3);
  const remainingCount = remainingAttendees.length;

  const navigateToMeeting = () => {
    window.open(`/meeting/${meeting.uuid}`, "_blank");
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  function getStructuredSummarySnippet(fullData, searchTerm) {
    if (!fullData) return "";

    const lowerSearch = searchTerm.toLowerCase();

    // Search in topics: topic titles first
    if (fullData.topics && fullData.topics.length > 0) {
      for (const t of fullData.topics) {
        // Check topic title
        if (t.topic && t.topic.toLowerCase().includes(lowerSearch)) {
          return `Topic: ${t.topic}`;
        }
        // Check each detail line
        if (t.details && t.details.length > 0) {
          for (const detail of t.details) {
            if (detail.toLowerCase().includes(lowerSearch)) {
              return detail;
            }
          }
        }
      }
    }

    // Search in action items
    if (fullData.action_items && fullData.action_items.length > 0) {
      for (const ai of fullData.action_items) {
        if (
          ai.action_item &&
          ai.action_item.toLowerCase().includes(lowerSearch)
        ) {
          return `Action Item: ${ai.action_item}`;
        }
      }
    }

    // If no match found, return empty
    return "";
  }

  const { summary, fullData } = parseGPTMeetingData(meeting.gptMeetingData);

  // Determine if we are showing quick matches
  const showQuickSearchMatches =
    searchType === "quick" && hasSearched && searchTerm.trim() !== "";

  let summarySnippet = "";
  let summaryMatches = false;

  if (showQuickSearchMatches && meeting.gptMeetingData && fullData) {
    // Try to get a structured snippet from the fullData
    summarySnippet = getStructuredSummarySnippet(fullData, searchTerm);
    if (summarySnippet) {
      summaryMatches = true;
    }
  }

  const titleMatches =
    showQuickSearchMatches &&
    meeting.meetingTitle &&
    meeting.meetingTitle.toLowerCase().includes(searchTerm.toLowerCase());

  const attendeeMatches =
    showQuickSearchMatches &&
    meeting.attendees &&
    meeting.attendees.some((a) =>
      a.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const ownerName =
    (meeting.ownerUser &&
      (meeting.ownerUser.name || meeting.ownerUser.email)) ||
    "Unknown Owner";

  return (
    <div
      className="group relative mb-6 cursor-pointer"
      onClick={navigateToMeeting}
    >
      <div
        className="relative rounded-xl transition-all duration-200 ease-in-out overflow-hidden
        bg-white border border-gray-200 hover:border-gray-300 shadow-sm hover:shadow-md
        transform hover:-translate-y-0.5"
      >
        <div className="p-6 flex flex-row gap-6 items-start">
          <div className="flex-1 min-w-0">
            <div className="flex items-start justify-between">
              <h3 className="text-lg font-semibold text-gray-900 group-hover:text-brand-turq transition-colors duration-200 line-clamp-1">
                {hasSearched && searchType === "quick"
                  ? highlightSearchTerm(meeting.meetingTitle, searchTerm)
                  : meeting.meetingTitle}
              </h3>
            </div>

            {meeting.containsVideo && meeting.isProcessing && (
              <span className="inline-flex items-center mt-2 px-2.5 py-1 rounded-full text-xs font-medium bg-amber-50 text-amber-800">
                <span className="mr-1.5 h-1.5 w-1.5 rounded-full bg-amber-500 animate-pulse" />
                Processing
              </span>
            )}

            <div className="mt-2 text-sm text-gray-600 line-clamp-1">
              Owned by {ownerName}
            </div>

            <div className="mt-4 flex items-center gap-4 text-sm text-gray-600">
              <span className="flex items-center gap-1.5">
                <CalendarIcon className="h-4 w-4 text-gray-400" />
                {formattedDate}
              </span>
              {meeting.duration && (
                <span className="flex items-center gap-1.5">
                  <ClockIcon className="h-4 w-4 text-gray-400" />
                  {meeting.duration} min
                </span>
              )}
            </div>

            {filteredAttendees && filteredAttendees.length > 0 && (
              <div className="mt-4">
                <div className="relative flex flex-wrap items-center gap-2">
                  {displayedAttendees.map((attendee, index) => (
                    <div
                      key={index}
                      className="flex items-center h-8 px-2 py-1 rounded-full bg-gray-50 border border-gray-200
                        hover:bg-gray-100 transition-colors duration-200"
                    >
                      <div
                        className={`h-6 w-6 flex-shrink-0 rounded-full flex items-center justify-center text-white ${getColor(
                          attendee.name
                        )}`}
                      >
                        <span className="text-xs font-medium">
                          {getInitials(attendee.name)}
                        </span>
                      </div>
                      <span className="ml-2 text-sm text-gray-700 line-clamp-1">
                        {attendee.name}
                      </span>
                    </div>
                  ))}

                  {remainingCount > 0 && (
                    <Tippy
                      appendTo={document.body}
                      theme="light"
                      interactive={true}
                      content={
                        <div className="p-4 text-sm">
                          <div className="text-xs font-medium text-gray-500 mb-2">
                            Also in this meeting:
                          </div>
                          <div className="divide-y divide-gray-100">
                            {remainingAttendees.map((attendee, index) => (
                              <div
                                key={index}
                                className="flex items-center gap-2 py-2 first:pt-0 last:pb-0"
                              >
                                <div
                                  className={`h-6 w-6 rounded-full flex items-center justify-center text-white ${getColor(
                                    attendee.name
                                  )}`}
                                >
                                  {getInitials(attendee.name)}
                                </div>
                                <span className="text-sm text-gray-700 line-clamp-1">
                                  {attendee.name}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      }
                    >
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <Menu.Button
                          onClick={(e) => {
                            stopPropagation(e);
                          }}
                          className="flex items-center h-8 px-3 rounded-full bg-gray-50 border border-gray-200
                          hover:bg-gray-100 transition-colors duration-200"
                        >
                          <UserGroupIcon className="h-4 w-4 text-gray-500 mr-1" />
                          <span className="text-sm text-gray-600">
                            +{remainingCount}
                          </span>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Menu.Items
                            className="absolute z-50 right-0 mt-2 w-48 rounded-md bg-white shadow-lg 
                  ring-1 ring-black ring-opacity-5 focus:outline-none border border-gray-200"
                          >
                            <div className="p-2 text-sm text-gray-700">
                              <div className="text-xs font-medium text-gray-500 mb-2">
                                Also in this meeting:
                              </div>
                              <div className="divide-y divide-gray-100">
                                {remainingAttendees.map((attendee, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center gap-2 py-2 first:pt-0 last:pb-0"
                                  >
                                    <div
                                      className={`h-6 w-6 rounded-full flex items-center justify-center text-white ${getColor(
                                        attendee.name
                                      )}`}
                                    >
                                      {getInitials(attendee.name)}
                                    </div>
                                    <span className="text-sm text-gray-700 line-clamp-1">
                                      {attendee.name}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </Tippy>
                  )}
                </div>
              </div>
            )}

            {summary && summary.trim() !== "" && (
              <div className="mt-4">
                <div className="text-xs font-bold text-gray-500 mb-1 uppercase tracking-wide">
                  Meeting Summary
                </div>
                <div
                  className="bg-gray-50 border border-gray-200 rounded-md p-3 text-sm text-gray-700 line-clamp-2 relative"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {hasSearched && searchType === "quick"
                    ? highlightSearchTerm(summary, searchTerm)
                    : summary}
                  {fullData && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        openSummaryModal(fullData);
                      }}
                      className="absolute right-2 bottom-2 text-xs text-brand-turq hover:text-brand-green underline"
                    >
                      Read more
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* Show summary match snippet if found */}
            {showQuickSearchMatches && summaryMatches && (
              <div className="mt-4">
                <div className="text-xs font-bold text-gray-500 mb-1 uppercase tracking-wide">
                  Summary Match
                </div>
                <div className="bg-gray-50 border border-gray-200 rounded-md p-3 text-sm text-gray-700">
                  {highlightSearchTerm(summarySnippet, searchTerm)}
                </div>
              </div>
            )}

            {searchType === "transcript" &&
              meeting.transcriptSnippet &&
              meeting.transcriptSnippet.trim() !== "" && (
                <div className="mt-4">
                  <div className="text-xs font-bold text-gray-500 mb-1 uppercase tracking-wide">
                    Transcript snippet match
                  </div>
                  <div className="bg-gray-50 border border-gray-200 rounded-md p-3 text-sm text-gray-700">
                    {highlightSearchTerm(meeting.transcriptSnippet, searchTerm)}
                  </div>
                </div>
              )}

            {showQuickSearchMatches && (
              <div className="mt-4 space-y-4">
                {titleMatches && (
                  <div>
                    <div className="text-xs font-bold text-gray-500 mb-1 uppercase tracking-wide">
                      Title Match
                    </div>
                    <div className="bg-gray-50 border border-gray-200 rounded-md p-3 text-sm text-gray-700">
                      {highlightSearchTerm(meeting.meetingTitle, searchTerm)}
                    </div>
                  </div>
                )}

                {attendeeMatches && (
                  <div>
                    <div className="text-xs font-bold text-gray-500 mb-1 uppercase tracking-wide">
                      Attendee Match
                    </div>
                    <div className="bg-gray-50 border border-gray-200 rounded-md p-3 text-sm text-gray-700 space-y-1">
                      {meeting.attendees
                        .filter((a) =>
                          a.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((a, i) => (
                          <div key={i}>
                            {highlightSearchTerm(a.name, searchTerm)}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {meeting.containsVideo && (
            <div className="relative hidden md:block w-64 h-40 flex-shrink-0 rounded-lg overflow-hidden">
              <img
                className="h-full w-full object-cover"
                src={meeting.coverThumbnail || "/no-thumbnail.png"}
                alt="Meeting Thumbnail"
              />
              {meeting.isLocked && (
                <div className="absolute inset-0 bg-gray-900/60 backdrop-blur-sm flex items-center justify-center">
                  <LockClosedIcon className="h-6 w-6 text-white" />
                </div>
              )}
            </div>
          )}

          <div className="menu-area flex-shrink-0" onClick={stopPropagation}>
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="p-2 rounded-lg border border-gray-300 hover:bg-gray-100 transition-colors duration-200">
                <DotsVerticalIcon className="h-5 w-5 text-gray-400" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute right-0 mt-2 w-48 rounded-lg bg-white shadow-lg 
                  ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none
                  border border-gray-200 transform origin-top-right z-50"
                >
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateToMeeting();
                          }}
                          className={`${
                            active ? "bg-gray-50" : ""
                          } group flex w-full items-center px-4 py-2 text-sm text-gray-700 transition-colors duration-150`}
                        >
                          View Meeting
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  {meeting.ownerUser &&
                    meeting.ownerUser.email === userEmail && (
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                openDeleteModal(meeting);
                              }}
                              className={`${
                                active ? "bg-gray-50" : ""
                              } group flex w-full items-center px-4 py-2 text-sm text-red-600 transition-colors duration-150`}
                            >
                              Delete Meeting
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    )}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

const optionsForDateRange = [
  { id: "any", name: "Any Date" },
  { id: "today", name: "Today" },
  { id: "7days", name: "Last 7 Days" },
  { id: "30days", name: "Last 30 Days" },
  { id: "custom", name: "Custom Range" },
];

const FilterDateRange = ({ tempFilters, setTempFilters }) => {
  const [mode, setMode] = useState(() => {
    if (tempFilters.startDate || tempFilters.endDate) return "custom";
    return "any";
  });

  const parseYMD = (s) => (s ? parseDate(s, "yyyy-MM-dd", new Date()) : null);

  useEffect(() => {
    const now = startOfToday();
    if (mode === "any") {
      setTempFilters({ ...tempFilters, startDate: "", endDate: "" });
    } else if (mode === "today") {
      const sd = format(now, "yyyy-MM-dd");
      setTempFilters({ ...tempFilters, startDate: sd, endDate: sd });
    } else if (mode === "7days") {
      const sd = format(subDays(now, 7), "yyyy-MM-dd");
      const ed = format(now, "yyyy-MM-dd");
      setTempFilters({ ...tempFilters, startDate: sd, endDate: ed });
    } else if (mode === "30days") {
      const sd = format(subDays(now, 30), "yyyy-MM-dd");
      const ed = format(now, "yyyy-MM-dd");
      setTempFilters({ ...tempFilters, startDate: sd, endDate: ed });
    }
  }, [mode]);

  const startD = parseYMD(tempFilters.startDate);
  const endD = parseYMD(tempFilters.endDate);

  const handleStartChange = (e) => {
    setTempFilters({ ...tempFilters, startDate: e.target.value });
    setMode("custom");
  };

  const handleEndChange = (e) => {
    setTempFilters({ ...tempFilters, endDate: e.target.value });
    setMode("custom");
  };

  return (
    <div>
      <label className="text-sm font-medium text-gray-900">Date Range</label>
      <div className="mt-2 space-y-2">
        <RadioGroup value={mode} onChange={setMode}>
          {optionsForDateRange.map((opt) => (
            <RadioGroup.Option
              key={opt.id}
              value={opt.id}
              className={({ checked }) =>
                `${
                  checked ? "bg-gray-50 border-brand-turq" : "border-gray-200"
                } border rounded-md px-3 py-2 text-sm cursor-pointer flex items-center`
              }
            >
              {({ checked }) => (
                <span className={checked ? "text-brand-turq" : "text-gray-700"}>
                  {opt.name}
                </span>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
        {mode === "custom" && (
          <div className="mt-4 space-y-2">
            <input
              type="date"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-turq focus:ring-brand-turq sm:text-sm"
              value={tempFilters.startDate}
              onChange={handleStartChange}
            />
            <input
              type="date"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-turq focus:ring-brand-turq sm:text-sm"
              value={tempFilters.endDate}
              onChange={handleEndChange}
            />
            {startD && endD && startD > endD && (
              <p className="text-xs text-red-500">
                Start date cannot be after end date.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const FilterListWithSearch = ({
  label,
  items,
  selectedItems,
  toggleItem,
  placeholder,
  isLoading,
}) => {
  const sortedItems = items.slice().sort((a, b) => a.localeCompare(b));
  const [query, setQuery] = useState("");

  const filtered = query.trim()
    ? sortedItems.filter((i) => i.toLowerCase().includes(query.toLowerCase()))
    : sortedItems;

  return (
    <div>
      <label className="text-sm font-medium text-gray-900">{label}</label>
      <div className="mt-2">
        <div className="relative mb-2">
          <SearchIcon className="h-4 w-4 text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2" />
          <input
            autoComplete="off"
            type="text"
            className="block w-full rounded-md border-gray-300 pl-8 pr-2 py-1.5 focus:border-brand-turq focus:ring-brand-turq text-sm"
            placeholder={`Search ${placeholder}...`}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="max-h-40 overflow-auto border border-gray-100 p-2 rounded-md">
          {isLoading ? (
            <div className="flex justify-center items-center h-20">
              <div className="animate-spin rounded-full h-5 w-5 border-2 border-gray-400 border-t-transparent"></div>
              <span className="ml-2 text-sm text-gray-500">Loading...</span>
            </div>
          ) : (
            <>
              {filtered.map((item) => {
                const checked = selectedItems.includes(item);
                return (
                  <label
                    key={item}
                    className="flex items-center py-1 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-brand-turq focus:ring-brand-turq"
                      checked={checked}
                      onChange={() => toggleItem(item)}
                    />
                    <span className="ml-2 text-sm text-gray-600 line-clamp-1">
                      {item}
                    </span>
                  </label>
                );
              })}
              {filtered.length === 0 && !isLoading && (
                <div className="text-sm text-gray-500">No matches found.</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const FilterPanel = ({
  filters,
  isOpen,
  setIsOpen,
  collections,
  attendees,
  applyFilters,
  isLoading,
}) => {
  const [tempFilters, setTempFilters] = useState(filters);

  useEffect(() => {
    setTempFilters(filters);
  }, [filters]);

  const toggleCollection = (c) => {
    const collObj = collections.find((x) => x.title === c);
    if (!collObj) return;
    const uuid = collObj.uuid;
    const exists = tempFilters.collections.includes(uuid);
    if (exists) {
      setTempFilters({
        ...tempFilters,
        collections: tempFilters.collections.filter((cc) => cc !== uuid),
      });
    } else {
      setTempFilters({
        ...tempFilters,
        collections: [...tempFilters.collections, uuid],
      });
    }
  };

  const toggleAttendee = (a) => {
    const exists = tempFilters.attendees.includes(a);
    if (exists) {
      setTempFilters({
        ...tempFilters,
        attendees: tempFilters.attendees.filter((aa) => aa !== a),
      });
    } else {
      setTempFilters({
        ...tempFilters,
        attendees: [...tempFilters.attendees, a],
      });
    }
  };

  const handleApply = () => {
    applyFilters(tempFilters);
    setIsOpen(false);
  };

  const handleReset = () => {
    setTempFilters({
      startDate: "",
      endDate: "",
      collections: [],
      attendees: [],
      hasVideo: false,
      hasTranscript: false,
    });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-50"
        onClose={setIsOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md flex flex-col bg-white shadow-xl">
                <div className="px-4 py-6 sm:px-6 flex items-center justify-between border-b border-gray-200">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-brand-turq"
                    onClick={() => setIsOpen(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="relative flex-1 px-4 sm:px-6 pb-24 overflow-auto space-y-6">
                  <FilterDateRange
                    tempFilters={tempFilters}
                    setTempFilters={setTempFilters}
                  />

                  <FilterListWithSearch
                    label="Collections"
                    items={collections.map((c) => c.title)}
                    selectedItems={(() => {
                      const selectedUUIDs = tempFilters.collections;
                      return collections
                        .filter((c) => selectedUUIDs.includes(c.uuid))
                        .map((cc) => cc.title);
                    })()}
                    toggleItem={toggleCollection}
                    placeholder="collections"
                  />

                  <FilterListWithSearch
                    label="Attendees"
                    items={attendees}
                    selectedItems={tempFilters.attendees}
                    toggleItem={toggleAttendee}
                    placeholder="attendees"
                    isLoading={isLoading}
                  />

                  <div>
                    <label className="text-sm font-medium text-gray-900">
                      Meeting Type
                    </label>
                    <div className="mt-2 space-y-2">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-brand-turq focus:ring-brand-turq"
                          checked={tempFilters.hasVideo}
                          onChange={(e) =>
                            setTempFilters({
                              ...tempFilters,
                              hasVideo: e.target.checked,
                              hasTranscript: e.target.checked
                                ? false
                                : tempFilters.hasTranscript,
                            })
                          }
                        />
                        <span className="ml-2 text-sm text-gray-600">
                          Recorded (Video + Transcript)
                        </span>
                      </label>
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-brand-turq focus:ring-brand-turq"
                          checked={tempFilters.hasTranscript}
                          onChange={(e) =>
                            setTempFilters({
                              ...tempFilters,
                              hasTranscript: e.target.checked,
                              hasVideo: e.target.checked
                                ? false
                                : tempFilters.hasVideo,
                            })
                          }
                        />
                        <span className="ml-2 text-sm text-gray-600">
                          Transcript Only
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6 flex justify-end space-x-3 bg-white sticky bottom-0">
                  <button
                    type="button"
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-brand-turq py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-green"
                    onClick={handleApply}
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const SummaryModal = ({ open, setOpen, fullData }) => {
  if (!fullData) return null;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-90"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="inline-block align-middle h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4 scale-90"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-4 scale-90"
          >
            <div className="inline-block align-middle bg-white rounded-2xl px-8 py-6 text-left shadow-xl transform transition-all w-full max-w-3xl relative">
              {/* Header Section */}
              <div className="flex items-center justify-between mb-4">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold text-gray-900"
                >
                  Full Meeting Notes &amp; Action Items
                </Dialog.Title>
                <button
                  className="text-gray-400 hover:text-gray-600 focus:outline-none"
                  onClick={() => setOpen(false)}
                >
                  <XIcon className="h-5 w-5" />
                </button>
              </div>

              {/* Content Section */}
              <div className="border-t border-gray-200 mt-4 pt-4 overflow-auto space-y-4 pr-4 min-h-[50vh] max-h-[70vh]">
                <Disclosure defaultOpen>
                  {({ open }) => (
                    <div className="border-b border-gray-200 pb-3">
                      <Disclosure.Button className="flex justify-between items-center w-full py-2 text-left text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none">
                        <span className="font-semibold">Meeting Notes</span>
                        <ChevronUpIcon
                          className={`h-5 w-5 transform ${
                            open ? "rotate-180" : ""
                          }`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="pl-2 text-sm text-gray-700 space-y-4 border-l border-gray-200 ml-2 mt-2">
                        {fullData.topics && fullData.topics.length > 0 ? (
                          fullData.topics.map((t, i) => (
                            <div key={i}>
                              <div className="font-semibold text-gray-800">
                                {t.topic}
                              </div>
                              {t.details && t.details.length > 0 && (
                                <ul className="list-disc list-inside mt-1 space-y-1 text-gray-600">
                                  {t.details.map((d, idx) => (
                                    <li key={idx}>{d}</li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ))
                        ) : (
                          <div>No notes available.</div>
                        )}
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [logout, setLogout] = useState(false);

  // meetingsLoading: loading state for when meetings are still being fetched
  const [meetingsLoading, setMeetingsLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingCounts, setLoadingCounts] = useState(true);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const [recordings, setRecordings] = useState([]);
  const [recordingsToDisplay, setRecordingsToDisplay] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("quick");
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [lastSearchedTerm, setLastSearchedTerm] = useState(null);

  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    collections: [],
    attendees: [],
    hasVideo: false,
    hasTranscript: false,
  });

  const [showIncentiveBanner, setShowIncentiveBanner] = useState(true);
  const [openBannerInviteModal, setOpenBannerInviteModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [recordingToDelete, setRecordingToDelete] = useState(null);

  const [availableCollections, setAvailableCollections] = useState([]);
  const [availableAttendees, setAvailableAttendees] = useState([]);

  const [view, setView] = useState("my_meetings");
  const [adminUserSelection, setAdminUserSelection] = useState("no_admin");
  const [orgUsers, setOrgUsers] = useState([]);
  const [myCount, setMyCount] = useState(0);
  const [sharedCount, setSharedCount] = useState(0);
  const [allCount, setAllCount] = useState(0);

  const [summaryModalOpen, setSummaryModalOpen] = useState(false);
  const [summaryModalData, setSummaryModalData] = useState(null);

  const [attendeesLoading, setAttendeesLoading] = useState(false);
  const [attendeesFetched, setAttendeesFetched] = useState(false);

  // For dynamic user buttons
  const teamUsers = orgUsers;
  const containerRef = useRef(null);
  const [visibleTeamUsers, setVisibleTeamUsers] = useState(teamUsers);
  const [hiddenTeamCount, setHiddenTeamCount] = useState(0);

  const [teamPlanModalOpen, setTeamPlanModalOpen] = useState(false);

  const [remoteSuggestions, setRemoteSuggestions] = useState({
    attendees: [],
    collections: [],
    teamMembers: [],
    meetingTitles: [],
  });

  // measure team buttons
  useLayoutEffect(() => {
    if (!containerRef.current) return;
    if (user?.role !== 2) return;
    const containerWidth = containerRef.current.clientWidth;
    const items = Array.from(
      containerRef.current.querySelectorAll(".team-user-button")
    );

    let totalWidth = 0;
    let count = 0;
    const margin = 8; // gap
    for (let i = 0; i < items.length; i++) {
      const w = items[i].offsetWidth + margin;
      if (totalWidth + w > containerWidth) {
        break;
      }
      totalWidth += w;
      count++;
    }

    const totalUsers = teamUsers.length;
    if (count < totalUsers) {
      if (count === 0 && totalUsers > 0) {
        setVisibleTeamUsers([]);
        setHiddenTeamCount(totalUsers);
      } else {
        setVisibleTeamUsers(teamUsers.slice(0, count));
        setHiddenTeamCount(totalUsers - count);
      }
    } else {
      setVisibleTeamUsers(teamUsers);
      setHiddenTeamCount(0);
    }
  }, [teamUsers, user]);

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const v = params.get("view");
    const a = params.get("admin_user_id");
    const q = params.get("q");
    const st = params.get("searchType");
    const sd = params.get("startDate");
    const ed = params.get("endDate");
    const cols = params.get("collections");
    const atts = params.get("attendees");
    const hv = params.get("hasVideo");
    const ht = params.get("hasTranscript");
    const p = parseInt(params.get("page"), 10);

    if (v) {
      setView(v);
      setAdminUserSelection("no_admin");
    }

    if (a) {
      if (a !== "no_admin" && a !== "all_team") {
        // Decode the ID
        try {
          const numericID = decodeID(a);
          // IMPORTANT: Re-encode the numeric ID before storing it
          const reEncodedID = encodeID(numericID);
          setAdminUserSelection(reEncodedID);
          // Because we've re-encoded it, we stay consistent.
          setView("");
        } catch {
          // If decoding fails
          setAdminUserSelection("no_admin");
        }
      } else {
        // For "no_admin" or "all_team", just set as is
        setAdminUserSelection(a);
        if (a !== "no_admin") {
          setView("");
        }
      }
    }

    if (q) {
      setLastSearchedTerm(q);
      setHasSearched(true);
    }

    if (st) {
      setSearchType(st);
    }

    const newFilters = { ...filters };
    if (sd) newFilters.startDate = sd;
    if (ed) newFilters.endDate = ed;
    if (cols) newFilters.collections = cols.split(",");
    if (atts) newFilters.attendees = atts.split(",");
    if (hv === "true") newFilters.hasVideo = true;
    if (ht === "true") newFilters.hasTranscript = true;

    setFilters(newFilters);

    if (!isNaN(p) && p > 0) {
      setPage(p);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchType === "quick" && searchTerm.trim()) {
      const controller = new AbortController();
      const fetchSuggestions = async () => {
        try {
          setSuggestionsLoading(true);
          const resp = await fetch(
            `https://backend.scribbl.co/suggestions?searchType=quick&q=${encodeURIComponent(
              searchTerm.trim()
            )}`,
            { credentials: "include", signal: controller.signal }
          );
          if (resp.ok) {
            const data = await resp.json();
            setRemoteSuggestions({
              attendees: data.attendees || [],
              collections: data.collections || [],
              teamMembers: data.teamMembers || [],
              meetingTitles: data.meetingTitles || [],
            });
          }
        } catch (e) {
          console.error("Suggestions fetch aborted or failed", e);
        } finally {
          setSuggestionsLoading(false);
        }
      };

      // Debounce with a short timeout
      const timeoutId = setTimeout(() => {
        fetchSuggestions();
      }, 300);

      return () => {
        controller.abort();
        clearTimeout(timeoutId);
      };
    } else if (searchType === "quick") {
      // If quick search but no term
      setRemoteSuggestions({
        attendees: [],
        collections: [],
        teamMembers: [],
        meetingTitles: [],
      });
      setSuggestionsLoading(false);
    } else {
      // For transcript search, no suggestions fetched
      setRemoteSuggestions({
        attendees: [],
        collections: [],
        teamMembers: [],
        meetingTitles: [],
      });
      setSuggestionsLoading(false);
    }
  }, [searchTerm, searchType]);

  useEffect(() => {
    const newParams = new URLSearchParams();
    if (adminUserSelection !== "no_admin") {
      if (adminUserSelection === "all_team") {
        // leave as is
        newParams.set("admin_user_id", "all_team");
      } else {
        // Here adminUserSelection should be a numeric ID
        // We'll assume adminUserSelection is numeric; if it's not, adjust as needed.
        const numericID = parseInt(adminUserSelection, 10);
        if (!isNaN(numericID) && numericID > 0) {
          newParams.set("admin_user_id", encodeID(numericID));
        } else {
          // fallback if not numeric (shouldn't happen if your code ensures it's an ID)
          newParams.set("admin_user_id", adminUserSelection);
        }
      }
    } else {
      if (view) {
        newParams.set("view", view);
      }
    }

    if (hasSearched && lastSearchedTerm) {
      newParams.set("q", lastSearchedTerm);
    }

    if (searchType !== "quick") {
      newParams.set("searchType", searchType);
    }

    if (filters.startDate) newParams.set("startDate", filters.startDate);
    if (filters.endDate) newParams.set("endDate", filters.endDate);
    if (filters.collections.length > 0)
      newParams.set("collections", filters.collections.join(","));
    if (filters.attendees.length > 0)
      newParams.set("attendees", filters.attendees.join(","));
    if (filters.hasVideo) newParams.set("hasVideo", "true");
    if (filters.hasTranscript) newParams.set("hasTranscript", "true");

    if (page !== 1) {
      newParams.set("page", page.toString());
    }

    const newUrl = `${location.pathname}?${newParams.toString()}`;
    if (newUrl !== window.location.href) {
      navigate(newUrl, { replace: true });
    }
  }, [
    view,
    adminUserSelection,
    hasSearched,
    lastSearchedTerm,
    searchType,
    filters,
    page,
  ]);

  const fetchCounts = async () => {
    if (!userEmail || !user) return;
    setLoadingCounts(true);
    const baseUrl = `https://backend.scribbl.co/enhanced-recording-counts?email=${encodeURIComponent(
      userEmail
    )}`;
    const resp = await fetch(baseUrl, { credentials: "include" });
    if (resp.ok) {
      const data = await resp.json();
      setMyCount(data.my_meetings || 0);
      setSharedCount(data.shared_with_me || 0);
      setAllCount(data.all_meetings || 0);
      setLoadingCounts(false);
    } else {
      // If there's an error, you might want to handle it here
      setLoadingCounts(false);
    }
  };

  useEffect(() => {
    const fetchMetadata = async () => {
      if (user && userEmail) {
        setLoading(false);
        const collectionsResp = await fetch(
          `https://backend.scribbl.co/collections?email=${encodeURIComponent(
            userEmail
          )}`,
          {
            credentials: "include",
          }
        );
        if (collectionsResp.ok) {
          const cData = await collectionsResp.json();
          setAvailableCollections(cData);
        }

        if (user.role === 2) {
          const orgUsersResp = await fetch(
            "https://backend.scribbl.co/orgs/members",
            {
              credentials: "include",
            }
          );
          if (orgUsersResp.ok) {
            const orgData = await orgUsersResp.json();
            setOrgUsers((orgData || []).filter((m) => m.orgID === user.orgID));
          }
        }
      }
    };
    fetchMetadata();
  }, [user, userEmail]);

  useEffect(() => {
    if (recordings) {
      setRecordingsToDisplay(recordings);
    } else {
      setRecordingsToDisplay([]);
    }
  }, [recordings]);

  const MIN_SEARCH_TIME = 500; // ms

  const fetchAttendees = async () => {
    // Only fetch if we haven't already fetched
    if (!attendeesFetched && !attendeesLoading && user && userEmail) {
      setAttendeesLoading(true);

      try {
        const attendeesResp = await fetch(
          `https://backend.scribbl.co/attendees-list?email=${encodeURIComponent(
            userEmail
          )}`,
          {
            credentials: "include",
          }
        );

        if (attendeesResp.ok) {
          const aData = await attendeesResp.json();
          setAvailableAttendees(aData);
        }
      } catch (error) {
        console.error("Error fetching attendees:", error);
      } finally {
        setAttendeesLoading(false);
        setAttendeesFetched(true);
      }
    }
  };

  const runAppropriateFetch = async () => {
    if (!userEmail || !user) return;

    setMeetingsLoading(true); // Start loading state for meetings
    const start = Date.now();

    let url;
    const params = new URLSearchParams();
    params.set("page", page);
    params.set("pageSize", pageSize);

    if (filters.startDate) params.set("startDate", filters.startDate);
    if (filters.endDate) params.set("endDate", filters.endDate);
    if (filters.collections && filters.collections.length > 0) {
      params.set("collections", filters.collections.join(","));
    }
    if (filters.attendees && filters.attendees.length > 0) {
      params.set("attendees", filters.attendees.join(","));
    }
    if (filters.hasVideo) params.set("hasVideo", "true");
    if (filters.hasTranscript) params.set("hasTranscript", "true");

    if (hasSearched && lastSearchedTerm && lastSearchedTerm.trim() !== "") {
      params.set("q", lastSearchedTerm.trim());
      params.set("searchType", searchType);
    }

    if (adminUserSelection !== "no_admin" && user.role === 2) {
      if (adminUserSelection !== "all_team") {
        params.set("admin_user_id", adminUserSelection);
      } else {
        params.set("admin_user_id", "all_team");
      }
      url = `https://backend.scribbl.co/enhanced-recording/admin?${params.toString()}&email=${encodeURIComponent(
        userEmail
      )}`;
    } else {
      let viewParam = view || "my_meetings";
      params.set("view", viewParam);

      url = `https://backend.scribbl.co/enhanced-recording?${params.toString()}&email=${encodeURIComponent(
        userEmail
      )}`;
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      if (response.status === 401) {
        setLogout(true);
        setMeetingsLoading(false);
        return;
      }
      const jsonData = await response.json();

      let data = jsonData.data || [];
      const elapsed = Date.now() - start;
      const remaining =
        MIN_SEARCH_TIME - elapsed > 0 ? MIN_SEARCH_TIME - elapsed : 0;

      setTimeout(() => {
        if (jsonData.pagination) {
          setTotalPages(jsonData.pagination.totalPages);
          setTotalItems(jsonData.pagination.totalItems);
        } else {
          setTotalPages(1);
          setTotalItems(data.length);
        }
        setRecordings(data);
        setMeetingsLoading(false); // End loading state for meetings
      }, remaining);
    } catch (error) {
      console.error(error);
      const elapsed = Date.now() - start;
      const remaining =
        MIN_SEARCH_TIME - elapsed > 0 ? MIN_SEARCH_TIME - elapsed : 0;
      setTimeout(() => {
        setRecordings([]);
        setMeetingsLoading(false);
      }, remaining);
    }
  };

  useEffect(() => {
    if (user && userEmail) {
      fetchCounts();
    }
  }, [user, userEmail]);

  useEffect(() => {
    if (user && userEmail) {
      runAppropriateFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    userEmail,
    view,
    page,
    filters,
    hasSearched,
    lastSearchedTerm,
    searchType,
    adminUserSelection,
  ]);

  const handleSearchTrigger = () => {
    if (!searchTerm.trim()) return;
    if (searchTerm.trim() === lastSearchedTerm && hasSearched) {
      setSearchTerm("");
      return;
    }
    setHasSearched(true);
    setLastSearchedTerm(searchTerm.trim());
    setIsSearching(true);
    setPage(1);
    setIsSearching(false);
  };

  const handleSelectSuggestion = (val) => {
    setSearchTerm(val);
    setHasSearched(true);
    setLastSearchedTerm(val.trim());
    setIsSearching(true);
    setPage(1);
    setIsSearching(false);
  };

  const handleDelete = async () => {
    if (recordingToDelete) {
      await fetch(
        `https://backend.scribbl.co/enhanced-recording/${recordingToDelete.uuid}/delete`,
        {
          method: "POST",
          body: JSON.stringify({ email: userEmail }),
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setOpenDeleteModal(false);
      await runAppropriateFetch();
    }
  };

  const openDeleteModalClicked = (recording) => {
    setRecordingToDelete(recording);
    setOpenDeleteModal(true);
  };

  const clearSearchTerm = () => {
    setSearchTerm("");
    setHasSearched(false);
    setLastSearchedTerm(null);
    setIsSearching(false);
    setPage(1);
  };

  const clearAll = () => {
    setSearchTerm("");
    setHasSearched(false);
    setLastSearchedTerm(null);
    setIsSearching(false);
    setFilters({
      startDate: "",
      endDate: "",
      collections: [],
      attendees: [],
      hasVideo: false,
      hasTranscript: false,
    });
    setPage(1);
  };

  const removeFilterChip = (filterLabel) => {
    if (filterLabel.startsWith("Search:")) {
      clearSearchTerm();
    } else if (filterLabel.startsWith("Date:")) {
      setFilters((f) => ({ ...f, startDate: "", endDate: "" }));
      setPage(1);
    } else if (filterLabel.startsWith("Collection:")) {
      const title = filterLabel.replace("Collection: ", "");
      const collectionToRemove = availableCollections.find(
        (c) => c.title === title
      );
      if (collectionToRemove) {
        setFilters((f) => ({
          ...f,
          collections: f.collections.filter(
            (x) => x !== collectionToRemove.uuid
          ),
        }));
        setPage(1);
      }
    } else if (filterLabel.startsWith("Attendee:")) {
      const attendeeName = filterLabel.replace("Attendee: ", "");
      setFilters((f) => ({
        ...f,
        attendees: f.attendees.filter((x) => x !== attendeeName),
      }));
      setPage(1);
    } else if (filterLabel === "Recorded (Video + Transcript)") {
      setFilters((f) => ({ ...f, hasVideo: false }));
      setPage(1);
    } else if (filterLabel === "Transcript Only") {
      setFilters((f) => ({ ...f, hasTranscript: false }));
      setPage(1);
    }
  };

  const resetAll = async (val) => {
    setSearchTerm("");
    setHasSearched(false);
    setLastSearchedTerm(null);
    setIsSearching(false);
    setFilters({
      startDate: "",
      endDate: "",
      collections: [],
      attendees: [],
      hasVideo: false,
      hasTranscript: false,
    });
    setPage(1);
    setSearchType(val);
  };

  const applyFilters = (newFilters) => {
    setFilters(newFilters);
    setPage(1);
  };

  const appliedFilterChips = [];
  if (hasSearched && lastSearchedTerm) {
    appliedFilterChips.push(`Search: ${lastSearchedTerm}`);
  }
  if (filters.startDate || filters.endDate) {
    appliedFilterChips.push(
      `Date: ${filters.startDate || "Any"} - ${filters.endDate || "Any"}`
    );
  }
  if (filters.collections.length > 0) {
    filters.collections.forEach((cUuid) => {
      const c = availableCollections.find((coll) => coll.uuid === cUuid);
      if (c) {
        appliedFilterChips.push(`Collection: ${c.title}`);
      }
    });
  }
  if (filters.attendees.length > 0) {
    filters.attendees.forEach((a) => {
      appliedFilterChips.push(`Attendee: ${a}`);
    });
  }
  if (filters.hasVideo) {
    appliedFilterChips.push("Recorded (Video + Transcript)");
  }
  if (filters.hasTranscript) {
    appliedFilterChips.push("Transcript Only");
  }

  const handleUserSelection = (uid) => {
    if (uid !== "no_admin" && uid !== "all_team") {
      const numericID = parseInt(uid, 10);
      if (!isNaN(numericID) && numericID > 0) {
        uid = encodeID(numericID);
      }
    }
    setAdminUserSelection(uid);
    setView("");
    setPage(1);
  };

  const handleSelectView = (newView) => {
    setView(newView);
    setAdminUserSelection("no_admin");
    setPage(1);
  };

  const handleSelectEntireTeam = () => {
    setView("");
    setAdminUserSelection("all_team");
    setPage(1);
  };

  const Pagination = ({ page, setPage, totalPages, totalItems, pageSize }) => (
    <div className="flex items-center justify-end space-x-2">
      <p className="text-sm text-gray-700 hidden sm:block">
        Showing{" "}
        <span className="font-medium">
          {Math.min((page - 1) * pageSize + 1, totalItems)}
        </span>{" "}
        to{" "}
        <span className="font-medium">
          {Math.min(page * pageSize, totalItems)}
        </span>{" "}
        of <span className="font-medium">{totalItems}</span>
      </p>
      <button
        onClick={() => {
          if (page > 1) {
            setPage(page - 1);
          }
        }}
        disabled={page === 1}
        className="px-3 py-1.5 rounded-md text-sm font-medium border border-gray-300 text-gray-700 bg-white hover:bg-gray-100 disabled:opacity-50"
      >
        <ChevronLeftIcon className="h-4 w-4" />
      </button>
      <button
        onClick={() => {
          if (page < totalPages) {
            setPage(page + 1);
          }
        }}
        disabled={page >= totalPages}
        className="px-3 py-1.5 rounded-md text-sm font-medium border border-gray-300 text-gray-700 bg-white hover:bg-gray-100 disabled:opacity-50"
      >
        <ChevronRightIcon className="h-4 w-4" />
      </button>
    </div>
  );
  const openSummaryModal = (fullData) => {
    setSummaryModalData(fullData);
    setSummaryModalOpen(true);
  };

  // Improved typeahead logic and design
  const [searchFocused, setSearchFocused] = useState(false);

  useEffect(() => {
    if (searchType === "quick" && searchTerm.trim()) {
      const controller = new AbortController();
      const fetchSuggestions = async () => {
        try {
          setSuggestionsLoading(true);
          const resp = await fetch(
            `https://backend.scribbl.co/suggestions?searchType=quick&q=${encodeURIComponent(
              searchTerm.trim()
            )}`,
            { credentials: "include", signal: controller.signal }
          );
          if (resp.ok) {
            const data = await resp.json();
            setRemoteSuggestions({
              attendees: data.attendees || [],
              collections: data.collections || [],
              teamMembers: data.teamMembers || [],
              meetingTitles: data.meetingTitles || [],
            });
          }
        } catch (e) {
          console.error("Suggestions fetch aborted or failed", e);
        } finally {
          setSuggestionsLoading(false);
        }
      };

      // Debounce with a short timeout
      const timeoutId = setTimeout(() => {
        fetchSuggestions();
      }, 300);

      return () => {
        controller.abort();
        clearTimeout(timeoutId);
      };
    } else if (searchType === "quick") {
      // If quick search but no term
      setRemoteSuggestions({
        attendees: [],
        collections: [],
        teamMembers: [],
        meetingTitles: [],
      });
      setSuggestionsLoading(false);
    } else {
      // For transcript search, we do not fetch suggestions
      setRemoteSuggestions({
        attendees: [],
        collections: [],
        teamMembers: [],
        meetingTitles: [],
      });
      setSuggestionsLoading(false);
    }
  }, [searchTerm, searchType]);

  const suggestionsList =
    searchType === "quick"
      ? [
          ...remoteSuggestions.attendees.map((a) => ({
            type: "Attendee",
            value: a,
          })),
          ...remoteSuggestions.collections.map((c) => ({
            type: "Collection",
            value: c,
          })),
          ...remoteSuggestions.teamMembers.map((u) => ({
            type: "Team Member",
            value: u,
          })),
          ...remoteSuggestions.meetingTitles.map((m) => ({
            type: "Meeting Title",
            value: m,
          })),
        ]
      : [];

  let filteredSuggestions = suggestionsList;
  if (searchType === "quick") {
    if (searchTerm.trim()) {
      filteredSuggestions = suggestionsList.filter((item) =>
        item.value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    filteredSuggestions = filteredSuggestions.slice(0, 10);
  } else {
    // For transcript search, no backend suggestions
    filteredSuggestions = [];
  }

  const groupedSuggestions =
    searchType === "quick"
      ? filteredSuggestions.reduce((acc, suggestion) => {
          if (!acc[suggestion.type]) {
            acc[suggestion.type] = [];
          }
          acc[suggestion.type].push(suggestion);
          return acc;
        }, {})
      : {};

  const triggerSearchAndClose = () => {
    handleSearchTrigger();
    setSearchFocused(false);
  };

  const handleComboboxChange = (val) => {
    // If user chose the top-level quick or transcript search action
    if (val === searchTerm.trim()) {
      triggerSearchAndClose();
    } else {
      // For quick search suggestions
      handleSelectSuggestion(val);
      setSearchFocused(false);
    }
  };

  const handleOpenFilterPanel = () => {
    setFilterPanelOpen(true);
    fetchAttendees();
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      // If transcript search is active and we have a non-empty searchTerm
      // Pressing Enter should trigger the search immediately and close UI.
      if (searchType === "transcript" && searchTerm.trim()) {
        e.preventDefault();
        triggerSearchAndClose();
      }
    }
  };

  return (
    <Navbar
      setUserEmail={setUserEmail}
      setUser={setUser}
      loading={loading}
      setLoading={setLoading}
      setLogout={setLogout}
      logout={logout}
    >
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        {user &&
          !user.subscription &&
          user?.org?.ID !== 0 &&
          showIncentiveBanner &&
          // SETTING TO FALSE WHILE WE RUN FEB PROMO
          false && (
            <div className="flex items-center gap-x-6 bg-brand-green-lighter1 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
              <p className="text-sm leading-6 text-white">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenBannerInviteModal(true);
                  }}
                >
                  <GiftIcon
                    className="h-4 w-4 inline-block mr-1.5 mt-[-3px]"
                    aria-hidden="true"
                  />
                  Invite two team members to earn an additional 15 credits&nbsp;
                  <span aria-hidden="true">&rarr;</span>
                </a>
              </p>
              <div className="flex flex-1 justify-end">
                <button
                  type="button"
                  className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                  onClick={() => setShowIncentiveBanner(false)}
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-5 w-5 text-white" aria-hidden="true" />
                </button>
              </div>
              <InviteModal
                open={openBannerInviteModal}
                setOpen={setOpenBannerInviteModal}
                org={user?.org}
                email={userEmail}
                giftCredits={true}
              />
            </div>
          )}

        <div className="py-4">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 space-y-4">
            <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between space-y-3 lg:space-y-0">
              <div className="flex-1 min-w-0">
                <div className="flex flex-wrap items-center gap-2 py-1">
                  <button
                    onClick={() => handleSelectView("my_meetings")}
                    className={`${
                      view === "my_meetings" &&
                      adminUserSelection === "no_admin"
                        ? "bg-brand-turq text-white"
                        : "bg-white border border-gray-200 text-gray-700 hover:bg-gray-100"
                    } px-3 py-1.5 rounded-md text-sm font-medium transition-colors duration-200 flex items-center space-x-2`}
                  >
                    <span>Meetings</span>
                    <span className="ml-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-600">
                      {loadingCounts ? (
                        <div className="animate-spin h-3 w-3 border-2 border-gray-400 border-t-transparent rounded-full"></div>
                      ) : (
                        myCount
                      )}
                    </span>
                  </button>

                  <button
                    onClick={() => handleSelectView("shared_with_me")}
                    className={`${
                      view === "shared_with_me" &&
                      adminUserSelection === "no_admin"
                        ? "bg-brand-turq text-white"
                        : "bg-white border border-gray-200 text-gray-700 hover:bg-gray-100"
                    } px-3 py-1.5 rounded-md text-sm font-medium transition-colors duration-200 flex items-center space-x-2`}
                  >
                    <span>Shared With Me</span>
                    <span className="ml-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-600">
                      {loadingCounts ? (
                        <div className="animate-spin h-3 w-3 border-2 border-gray-400 border-t-transparent rounded-full"></div>
                      ) : (
                        sharedCount
                      )}
                    </span>
                  </button>

                  <button
                    onClick={() => handleSelectView("all_meetings")}
                    className={`${
                      view === "all_meetings" &&
                      adminUserSelection === "no_admin"
                        ? "bg-brand-turq text-white"
                        : "bg-white border border-gray-200 text-gray-700 hover:bg-gray-100"
                    } px-3 py-1.5 rounded-md text-sm font-medium transition-colors duration-200 flex items-center space-x-2`}
                  >
                    <span>All Meetings</span>
                    <span className="ml-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-600">
                      {loadingCounts ? (
                        <div className="animate-spin h-3 w-3 border-2 border-gray-400 border-t-transparent rounded-full"></div>
                      ) : (
                        allCount
                      )}
                    </span>
                  </button>

                  {user &&
                    user.role === 2 &&
                    user.subscription &&
                    (user.subscription.accountType === 3 ||
                      user.subscription.accountType === 4) && (
                      <>
                        <span className="text-gray-400 mx-2">|</span>
                        <button
                          onClick={handleSelectEntireTeam}
                          className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors duration-200 ${
                            adminUserSelection === "all_team"
                              ? "bg-brand-turq text-white"
                              : "bg-white border border-gray-200 text-gray-700 hover:bg-gray-100"
                          }`}
                        >
                          Entire Team
                        </button>
                        <div
                          ref={containerRef}
                          className="flex flex-wrap items-center gap-1"
                          style={{ overflow: "visible" }}
                        >
                          {/* Selected user chip if applicable */}
                          {adminUserSelection !== "no_admin" &&
                            adminUserSelection !== "all_team" &&
                            teamUsers.some(
                              (u) =>
                                u.ID.toString() ===
                                `${decodeID(adminUserSelection)}`
                            ) &&
                            (() => {
                              const selectedUser = teamUsers.find(
                                (u) =>
                                  u.ID.toString() ===
                                  `${decodeID(adminUserSelection)}`
                              );
                              if (!selectedUser) return null;
                              const initials = (
                                selectedUser.name || selectedUser.email
                              )
                                .split(" ")
                                .map((n) => n[0])
                                .join("")
                                .toUpperCase();

                              return (
                                <div className="inline-flex items-center gap-1 bg-gray-100 border border-gray-200 rounded-full px-2 py-0.5 text-xs font-medium text-gray-700 max-w-[130px] truncate">
                                  <div className="h-5 w-5 rounded-full bg-gray-200 text-gray-600 flex items-center justify-center text-[10px] font-medium">
                                    {initials}
                                  </div>
                                  <Tippy
                                    content={
                                      selectedUser.name || selectedUser.email
                                    }
                                  >
                                    <span className="truncate">
                                      {selectedUser.name || selectedUser.email}
                                    </span>
                                  </Tippy>
                                  <button
                                    onClick={() =>
                                      handleUserSelection("no_admin")
                                    }
                                    className="text-gray-400 hover:text-gray-600 focus:outline-none"
                                    title="Clear Selection"
                                  >
                                    <XIcon className="h-3 w-3" />
                                  </button>
                                </div>
                              );
                            })()}

                          {/* Visible team users */}
                          {visibleTeamUsers.map((u) => {
                            const isSelected =
                              adminUserSelection === u.ID.toString();
                            const initials = (u.name || u.email)
                              .split(" ")
                              .map((n) => n[0])
                              .join("")
                              .toUpperCase();

                            return (
                              <button
                                key={u.ID}
                                onClick={() =>
                                  handleUserSelection(u.ID.toString())
                                }
                                className={`team-user-button px-3 py-1.5 rounded-md text-sm font-medium transition-colors duration-200 flex items-center gap-1
        ${
          isSelected
            ? "bg-brand-turq text-white border-transparent"
            : "bg-white border border-gray-200 text-gray-700 hover:bg-gray-100"
        } max-w-[130px] truncate`}
                              >
                                <div className="h-5 w-5 flex-shrink-0 rounded-full bg-gray-200 text-gray-600 text-[10px] font-medium flex items-center justify-center">
                                  {initials}
                                </div>
                                <Tippy content={u.name || u.email}>
                                  <span className="truncate">
                                    {u.name || u.email}
                                  </span>
                                </Tippy>
                              </button>
                            );
                          })}

                          {/* Dropdown for hidden team members */}
                          {hiddenTeamCount > 0 && (
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <Menu.Button
                                className="px-3 py-1.5 rounded-md text-sm font-medium transition-colors duration-200 flex items-center gap-1
        bg-white border border-gray-200 text-gray-700 hover:bg-gray-100"
                              >
                                +{hiddenTeamCount} more
                                <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                              </Menu.Button>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                              >
                                <Menu.Items
                                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white 
          ring-1 ring-black ring-opacity-5 focus:outline-none z-50 border border-gray-200"
                                >
                                  <div className="py-1 max-h-60 overflow-y-auto">
                                    {teamUsers
                                      .slice(visibleTeamUsers.length)
                                      .map((u) => {
                                        const isSelected =
                                          adminUserSelection ===
                                          u.ID.toString();
                                        const initials = (u.name || u.email)
                                          .split(" ")
                                          .map((n) => n[0])
                                          .join("")
                                          .toUpperCase();
                                        return (
                                          <Menu.Item key={u.ID}>
                                            {({ active }) => (
                                              <button
                                                onClick={() =>
                                                  handleUserSelection(
                                                    u.ID.toString()
                                                  )
                                                }
                                                className={`${
                                                  active
                                                    ? "bg-gray-100"
                                                    : "bg-white"
                                                } group flex w-full items-center gap-2 px-3 py-1.5 text-sm text-gray-700 transition-colors duration-150 truncate`}
                                              >
                                                <div className="h-5 w-5 flex-shrink-0 rounded-full bg-gray-200 text-gray-600 text-[10px] font-medium flex items-center justify-center">
                                                  {initials}
                                                </div>
                                                <Tippy
                                                  content={u.name || u.email}
                                                >
                                                  <span className="flex-1 truncate">
                                                    {u.name || u.email}
                                                  </span>
                                                </Tippy>
                                                {isSelected && (
                                                  <svg
                                                    className="h-3 w-3 text-brand-turq flex-shrink-0"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                    viewBox="0 0 24 24"
                                                    aria-hidden="true"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      d="M5 13l4 4L19 7"
                                                    />
                                                  </svg>
                                                )}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        );
                                      })}
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          )}
                        </div>
                      </>
                    )}

                  {user &&
                    (!user.subscription ||
                      (user.subscription.accountType !== 3 &&
                        user.subscription.accountType !== 4)) && (
                      <>
                        <span className="text-gray-400 mx-2">|</span>
                        <button
                          onClick={() => setTeamPlanModalOpen(true)}
                          className="relative group px-3 py-1.5 rounded-md text-sm font-medium transition-colors duration-200 flex items-center space-x-2 bg-white border border-gray-200 text-gray-700 hover:bg-gray-100"
                        >
                          <span>Team Visibility</span>
                          <div className="relative flex items-center">
                            <div className="flex items-center justify-center h-4 w-4 rounded-full bg-[#E15C54]">
                              <svg
                                className="w-2.5 h-2.5 text-white"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 2v8M2 6l4-4 4 4"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>

                            {/* Updated tooltip with more spacing */}
                            <div
                              className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-[calc(100%+16px)]
              opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 
              w-64 z-50"
                            >
                              <div
                                className="bg-gray-900 text-white text-xs rounded-lg py-2 px-3 
                shadow-lg backdrop-blur-sm bg-opacity-90 border border-white/10"
                              >
                                <p className="font-medium">Team Plan Feature</p>
                                <p className="text-gray-300 font-normal">
                                  View all your team's meetings in one place
                                </p>
                                {/* Arrow pointing up with adjusted position */}
                                <div
                                  className="absolute -top-[5px] left-1/2 -translate-x-1/2 
                  w-2 h-2 bg-gray-900 rotate-45 rounded-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </button>
                      </>
                    )}
                </div>
              </div>
              <div className="flex-shrink-0">
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  totalItems={totalItems}
                  pageSize={pageSize}
                />{" "}
              </div>
            </div>

            <div className="bg-white rounded-md border border-gray-200 p-3 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3">
              <div className="flex-1 flex flex-col sm:flex-row items-stretch sm:items-center gap-3">
                <div className="relative flex-1">
                  <Combobox value={searchTerm} onChange={handleComboboxChange}>
                    <div className="relative">
                      <div className="relative w-full text-left">
                        <SearchIcon
                          className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <Combobox.Input
                          autoComplete="off"
                          onFocus={() => setSearchFocused(true)}
                          onClick={() => setSearchFocused(true)}
                          onBlur={() =>
                            setTimeout(() => setSearchFocused(false), 100)
                          }
                          displayValue={(item) => item}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          onKeyDown={handleInputKeyDown} // Handle Enter for transcript
                          className="block w-full pl-10 pr-20 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-brand-turq focus:border-brand-turq sm:text-sm"
                          placeholder={
                            searchType === "transcript"
                              ? "Type transcript query and press Enter..."
                              : "Search titles, summaries, attendees, or team members..."
                          }
                        />
                        {searchTerm && (
                          <button
                            type="button"
                            onClick={() => setSearchTerm("")}
                            className="absolute right-14 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 focus:outline-none"
                          >
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        )}
                        <button
                          onClick={() => {
                            handleSearchTrigger();
                          }}
                          disabled={
                            isSearching ||
                            !searchTerm.trim() ||
                            (hasSearched &&
                              lastSearchedTerm === searchTerm.trim())
                          }
                          className={`absolute right-3 top-1/2 transform -translate-y-1/2 px-3 py-1 rounded-md text-sm text-white transition-colors duration-200 ${
                            isSearching
                              ? "bg-gray-400 cursor-not-allowed"
                              : !searchTerm.trim() ||
                                (hasSearched &&
                                  lastSearchedTerm === searchTerm.trim())
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-brand-turq hover:bg-brand-green"
                          }`}
                        >
                          {isSearching ? (
                            <div className="flex items-center space-x-2">
                              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                              <span>Searching</span>
                            </div>
                          ) : (
                            "Search"
                          )}
                        </button>
                      </div>

                      {searchFocused && searchTerm.trim() && (
                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Combobox.Options className="absolute mt-1 w-full bg-white shadow-lg rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none z-10 border border-gray-200">
                            {/* Top-level action option differs based on searchType */}
                            {searchType === "quick" && (
                              <Combobox.Option
                                value={searchTerm.trim()}
                                className={({ active }) =>
                                  `cursor-default select-none relative py-2 pl-4 pr-9 ${
                                    active
                                      ? "bg-brand-turq text-white"
                                      : "text-gray-900"
                                  } font-medium flex items-center`
                                }
                              >
                                <SearchIcon className="h-4 w-4 text-gray-300 mr-2" />
                                Search all meetings for “{searchTerm.trim()}”
                              </Combobox.Option>
                            )}

                            {searchType === "transcript" && (
                              <Combobox.Option
                                value={searchTerm.trim()}
                                className={({ active }) =>
                                  `cursor-default select-none relative py-2 pl-4 pr-9 ${
                                    active
                                      ? "bg-brand-turq text-white"
                                      : "text-gray-900"
                                  } font-medium flex items-center`
                                }
                              >
                                <SearchIcon className="h-4 w-4 text-gray-300 mr-2" />
                                Search transcripts for “{searchTerm.trim()}”
                              </Combobox.Option>
                            )}

                            {searchType === "quick" && (
                              <>
                                {/* Loading or suggestions UI */}
                                {suggestionsLoading && (
                                  <div className="px-4 py-2 flex items-center space-x-2 text-sm text-gray-500">
                                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-gray-400 border-t-transparent"></div>
                                    <span>Loading suggestions…</span>
                                  </div>
                                )}

                                {!suggestionsLoading &&
                                  filteredSuggestions.length > 0 && (
                                    <div className="border-t border-gray-200 my-2"></div>
                                  )}

                                {!suggestionsLoading &&
                                  filteredSuggestions.length === 0 && (
                                    <div className="px-4 py-2 text-gray-500 text-sm">
                                      No suggestions found. Press Enter to
                                      search directly.
                                    </div>
                                  )}

                                {Object.keys(groupedSuggestions).map(
                                  (group) => (
                                    <div key={group}>
                                      {groupedSuggestions[group].length > 0 && (
                                        <div className="px-4 py-2 text-xs text-gray-400 uppercase font-medium">
                                          {group}
                                        </div>
                                      )}
                                      {groupedSuggestions[group].map(
                                        (item, index) => (
                                          <Combobox.Option
                                            key={`${group}-${index}`}
                                            value={item.value}
                                            className={({ active }) =>
                                              `cursor-default select-none relative py-2 pl-4 pr-9 ${
                                                active
                                                  ? "bg-brand-turq text-white"
                                                  : "text-gray-900"
                                              } flex items-center`
                                            }
                                          >
                                            {group === "Attendee" && (
                                              <UserGroupIcon className="h-4 w-4 text-gray-300 mr-2" />
                                            )}
                                            {group === "Collection" && (
                                              <FolderIcon className="h-4 w-4 text-gray-300 mr-2" />
                                            )}
                                            {group === "Team Member" && (
                                              <UserGroupIcon className="h-4 w-4 text-gray-300 mr-2" />
                                            )}
                                            {group === "Meeting Title" && (
                                              <DocumentSearchIcon className="h-4 w-4 text-gray-300 mr-2" />
                                            )}
                                            {item.value}
                                          </Combobox.Option>
                                        )
                                      )}
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          </Combobox.Options>
                        </Transition>
                      )}
                    </div>
                  </Combobox>
                </div>

                <div className="w-64 relative">
                  <Combobox
                    value={searchType}
                    onChange={(val) => {
                      resetAll(val);
                    }}
                  >
                    <div className="relative">
                      <Combobox.Button className="w-full focus:outline-none">
                        <div className="flex items-center justify-between bg-white rounded-md border border-gray-300 px-3 py-2 text-sm">
                          <span className="block truncate">
                            {searchType === "quick"
                              ? "Quick Search"
                              : "Transcript Search"}
                          </span>
                          <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                        </div>
                      </Combobox.Button>
                      <Transition
                        as={React.Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Combobox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm border border-gray-200">
                          <Combobox.Option
                            value="quick"
                            className={({ active }) =>
                              `cursor-default select-none relative py-2 px-4 ${
                                active
                                  ? "text-white bg-brand-turq"
                                  : "text-gray-900"
                              }`
                            }
                          >
                            {({ active }) => (
                              <div>
                                <span className="block truncate font-medium">
                                  Quick Search
                                </span>
                                <span
                                  className={`block truncate text-sm ${
                                    active ? "text-gray-200" : "text-gray-500"
                                  }`}
                                >
                                  Search titles, summaries, attendees, and
                                  collections
                                </span>
                              </div>
                            )}
                          </Combobox.Option>
                          <Combobox.Option
                            value="transcript"
                            className={({ active }) =>
                              `cursor-default select-none relative py-2 px-4 ${
                                active
                                  ? "text-white bg-brand-turq"
                                  : "text-gray-900"
                              }`
                            }
                          >
                            {({ active }) => (
                              <div>
                                <span className="block truncate font-medium">
                                  Transcript Search
                                </span>
                                <span
                                  className={`block truncate text-sm ${
                                    active ? "text-gray-200" : "text-gray-500"
                                  }`}
                                >
                                  Search through meeting transcripts
                                </span>
                              </div>
                            )}
                          </Combobox.Option>
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </div>
                <button
                  type="button"
                  onClick={handleOpenFilterPanel}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <FilterIcon className="h-4 w-4 mr-2" />
                  Filters
                  {Object.values(filters).some((value) =>
                    Array.isArray(value) ? value.length > 0 : value
                  ) && (
                    <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-brand-turq text-white">
                      Active
                    </span>
                  )}
                </button>
                <button
                  type="button"
                  onClick={clearAll}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Clear All
                </button>
              </div>
            </div>

            {appliedFilterChips.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-2">
                {appliedFilterChips.map((f, i) => (
                  <span
                    key={i}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border bg-gray-50 text-gray-600 border-gray-200"
                  >
                    {f}
                    <button
                      type="button"
                      onClick={() => removeFilterChip(f)}
                      className="ml-1 text-gray-400 hover:text-gray-600 focus:outline-none"
                    >
                      <XIcon className="h-3 w-3" aria-hidden="true" />
                    </button>
                  </span>
                ))}
              </div>
            )}

            {user &&
              !user.subscription &&
              user?.org?.ID !== 0 &&
              user?.tokensRemaining < 5 && (
                <div className="mt-4">
                  <div className="rounded-md border border-brand-turq/40 bg-brand-turq/5 p-4 flex items-center justify-between shadow-sm">
                    <div className="flex items-start space-x-3">
                      <LightningBoltIcon className="h-5 w-5 text-brand-turq flex-shrink-0 mt-1" />
                      <div className="text-sm leading-5">
                        <div className="text-gray-900 font-semibold text-base">
                          {user?.tokensRemaining} Meeting
                          {user?.tokensRemaining !== 1 ? "s" : ""} Remaining
                        </div>
                        <p className="text-gray-700 mt-1">
                          You’re running low on credits. Upgrade now for more
                          meetings and premium features.
                        </p>
                      </div>
                    </div>
                    <a
                      href="/pricing"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ml-4 inline-flex items-center px-3 py-2 rounded-md text-sm font-medium text-white bg-brand-turq hover:bg-brand-green transition-colors duration-200"
                    >
                      Upgrade
                    </a>
                  </div>
                </div>
              )}

            <div className="mt-6 min-h-[50vh]">
              {meetingsLoading ? (
                <div className="flex flex-col items-center justify-center py-24 text-gray-500">
                  <svg
                    className="animate-spin h-8 w-8 mb-4 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  <p className="text-sm">Loading your meetings...</p>
                </div>
              ) : // If not loading meetings:
              loading ? (
                <></>
              ) : recordingsToDisplay && recordingsToDisplay.length > 0 ? (
                <div className="bg-gradient-to-br from-gray-100 via-gray-50 to-white p-6 space-y-4">
                  {recordingsToDisplay.map(
                    (recording) =>
                      recording.meetingTitle && (
                        <MeetingCard
                          key={recording.ID}
                          meeting={recording}
                          searchTerm={lastSearchedTerm || ""}
                          searchType={searchType}
                          openDeleteModal={openDeleteModalClicked}
                          hasSearched={hasSearched}
                          openSummaryModal={openSummaryModal}
                          userEmail={userEmail}
                        />
                      )
                  )}
                </div>
              ) : adminUserSelection !== "no_admin" &&
                recordingsToDisplay.length === 0 &&
                !loading ? (
                <div className="text-center py-12">
                  <DocumentSearchIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No meetings shared from this user/team
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Set up meeting sharing rules to access their meetings.
                  </p>
                  <div className="mt-6">
                    <button
                      type="button"
                      onClick={() =>
                        window.open("/settings/sharing-rules", "_blank")
                      }
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-turq hover:bg-brand-green focus:outline-none"
                    >
                      Set Up Sharing Rules
                    </button>
                  </div>
                </div>
              ) : hasSearched && lastSearchedTerm && searchType === "quick" ? (
                <div className="text-center py-12">
                  <DocumentSearchIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No results found
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    We couldn't find any meetings matching "{lastSearchedTerm}"
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Try adjusting your search or filters.
                  </p>
                </div>
              ) : hasSearched &&
                lastSearchedTerm &&
                searchType === "transcript" ? (
                <div className="text-center py-12">
                  <DocumentSearchIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No transcript matches found
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    We couldn't find any transcript snippets matching "
                    {lastSearchedTerm}"
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Try adjusting your keywords or search again.
                  </p>
                </div>
              ) : (
                <div className="text-center py-12">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      vectorEffect="non-scaling-stroke"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    />
                  </svg>
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No meetings yet
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Install the Scribbl Chrome extension to start recording
                    meetings.
                  </p>
                  <div className="mt-6">
                    <button
                      type="button"
                      onClick={() =>
                        window.open(
                          "https://chrome.google.com/webstore/detail/google-meet-transcripts-b/kmjmlilenakedodldceipdnmmnfkahni?hl=en-US",
                          "_blank"
                        )
                      }
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-turq hover:bg-brand-green focus:outline-none"
                    >
                      Go to Chrome Store
                    </button>
                    <p className="mt-24 text-xs text-gray-500">
                      For more assistance, check out our documentation.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <FilterPanel
            filters={filters}
            isOpen={filterPanelOpen}
            setIsOpen={setFilterPanelOpen}
            collections={availableCollections}
            attendees={availableAttendees}
            applyFilters={applyFilters}
            isLoading={attendeesLoading}
          />

          <SummaryModal
            open={summaryModalOpen}
            setOpen={setSummaryModalOpen}
            fullData={summaryModalData}
          />

          <Transition.Root show={openDeleteModal} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-10 inset-0 overflow-y-auto"
              onClose={setOpenDeleteModal}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>

                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                        onClick={() => setOpenDeleteModal(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Delete Recording
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete{" "}
                            {recordingToDelete &&
                              recordingToDelete.meetingTitle}
                            ? This action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:ring-2 focus:ring-brand-turq sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleDelete}
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:ring-2 focus:ring-brand-turq sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => setOpenDeleteModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>

        {!meetingsLoading &&
          recordingsToDisplay &&
          recordingsToDisplay.length > 0 && (
            <div className="bg-white/90 border-t border-gray-200 px-4 py-3 shadow-lg flex items-center justify-end">
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                totalItems={totalItems}
                pageSize={pageSize}
              />
            </div>
          )}
      </main>
      <TeamPlanCTAModal
        open={teamPlanModalOpen}
        setOpen={setTeamPlanModalOpen}
        user={user}
      />
    </Navbar>
  );
};

const TeamPlanCTAModal = ({ open, setOpen, user }) => {
  const handleCalendlyClick = (e) => {
    e.preventDefault();
    const calendlyURL = `https://calendly.com/mike-sallese/meeting-with-mike?name=${
      user?.name || ""
    }&email=${user?.email || ""}&a1=team%20visibility%20cta%20in%20home%20view`;
    window.open(calendlyURL, "_blank");
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-90"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-90"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4 scale-90"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-4 scale-90"
          >
            <Dialog.Panel className="relative bg-white rounded-2xl shadow-xl max-w-lg w-full p-6">
              <button
                type="button"
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
                onClick={() => setOpen(false)}
              >
                <XIcon className="h-5 w-5" />
              </button>
              <div className="text-center">
                <h2 className="text-2xl font-bold text-gray-900">
                  Elevate Your Team's Visibility
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  Upgrade to the Team Plan to see all your team's meetings in
                  one place. No more guessing—just instant access to the calls
                  that matter.
                </p>

                <div className="mt-6 space-y-3 text-left text-sm text-gray-700">
                  <div className="flex items-start">
                    <UserGroupIcon className="h-5 w-5 text-brand-turq flex-shrink-0 mr-2" />
                    <div>
                      <span className="font-medium">All Team Meetings</span>
                      <p className="text-xs text-gray-500">
                        Instantly view and review any meeting from anyone on
                        your team.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <FolderIcon className="h-5 w-5 text-brand-turq flex-shrink-0 mr-2" />
                    <div>
                      <span className="font-medium">Powerful Organization</span>
                      <p className="text-xs text-gray-500">
                        Automate collections, integrate with Google Drive, and
                        keep everyone aligned.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <LightningBoltIcon className="h-5 w-5 text-brand-turq flex-shrink-0 mr-2" />
                    <div>
                      <span className="font-medium">Seamless Workflows</span>
                      <p className="text-xs text-gray-500">
                        Sync notes to CRMs, set smart sharing rules, and speed
                        up collaboration.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <button
                    onClick={handleCalendlyClick}
                    className="inline-flex items-center px-6 py-3 text-sm font-medium rounded-md shadow-sm text-white bg-brand-turq hover:bg-brand-green hover:shadow-lg transition-colors duration-200"
                  >
                    Schedule Your Team Plan Demo
                    <ChevronRightIcon className="ml-2 -mr-1 h-5 w-5" />
                  </button>
                  <p className="mt-3 text-xs text-gray-500">
                    Let us show you how to maximize your team's potential with
                    Scribbl.
                  </p>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

// Utility to get days left until end of Feb
export const getDaysLeftInFeb = () => {
  const now = new Date();
  const endOfFeb = new Date(now.getFullYear(), 2, 0, 23, 59, 59);
  // new Date(year, 2, 0) gives the last day of February (handling leap years)
  const diffTime = endOfFeb - now;
  return diffTime > 0 ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : 0; // If it's already past end of Feb, return 0
};

// Utility to get slots taken
export const getSlotsData = () => {
  const now = new Date();
  const dayOfMonth = now.getDate();
  const totalSlots = 100;

  // Base formula that scales with day of month, but never exceeds 95 as a base
  const baseSlotsTaken = Math.min(Math.floor(dayOfMonth * 3.2), 95);

  // Add a bit of random variance so it’s not a neat line
  const randomVariance = Math.floor(Math.random() * 5);

  // Actual slots taken
  const slotsTaken = baseSlotsTaken + randomVariance;

  // Calculate how many are left
  const slotsLeft = totalSlots - slotsTaken;

  // For the progress bar width
  const progressPercent = Math.min((slotsTaken / totalSlots) * 100, 100);

  return {
    totalSlots,
    slotsTaken,
    slotsLeft,
    progressPercent,
  };
};

export const PromoBannerMinimalist = ({ user }) => {
  const [isVisible, setIsVisible] = useState(true);

  const daysLeft = getDaysLeftInFeb();
  const { slotsTaken, slotsLeft, progressPercent } = getSlotsData();

  const handleBookDemoClick = (e) => {
    e.preventDefault();

    amplitude
      .getInstance()
      .logEvent("febPromoBanner bookDemo clicked from Home", {
        userId: user?.ID,
        userEmail: user?.email,
      });

    const calendlyURL = `https://calendly.com/mike-sallese/meeting-with-mike?name=${
      user.name || ""
    }&email=${user.email || ""}&a1=feb%20promo`;
    window.open(calendlyURL, "_blank");
  };

  // Hide the banner once February is over
  if (!isVisible || daysLeft <= 0) return null;

  return (
    <div className="relative isolate bg-gradient-to-r from-blue-600 to-indigo-600 text-white">
      {/* Banner Container */}
      <div className="mx-auto max-w-7xl px-4 py-3 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
          {/* Left: Offer Details */}
          <div className="flex items-center gap-3">
            <SparklesIcon className="h-6 w-6 text-white animate-pulse" />
            <div>
              <p className="font-semibold text-sm sm:text-base">
                50% Off Team Plan – Ends in {daysLeft} Days!
              </p>
              <p className="text-xs text-white/80 mt-0.5">
                {slotsTaken}/100 Slots Claimed • Only {slotsLeft} left
              </p>
            </div>
          </div>

          {/* Right: CTA + Close Button */}
          <div className="flex items-center gap-3">
            <button
              onClick={handleBookDemoClick}
              className="inline-flex items-center px-4 py-2 rounded-full bg-white text-blue-700 text-sm font-medium hover:bg-gray-100 transition-all duration-200 shadow-md"
            >
              Learn More
            </button>
            <button
              onClick={() => setIsVisible(false)}
              className="text-white/80 hover:text-white"
            >
              <XIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Progress Bar */}
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-white/20">
        <div
          className="h-full bg-white/40 transition-all duration-1000 ease-out"
          style={{ width: `${progressPercent}%` }}
        />
      </div>
    </div>
  );
};

export default Home;
