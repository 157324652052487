import React, { useState, useEffect, useRef } from "react";
import {
  MailIcon,
  LightningBoltIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XIcon,
  ChatIcon,
  PencilAltIcon,
  DocumentTextIcon,
  CheckIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
} from "@heroicons/react/outline";
import { AnimatePresence, motion } from "framer-motion";
import { isEqual } from "lodash";

// The sub-steps for each automation type
import MeetingWorkflowFollowUpEmail from "./MeetingWorkflowFollowupEmail";
import MeetingWorkflowSlack from "./MeetingWorkflowSlack";
import MeetingWorkflowGoogleDoc from "./MeetingWorkflowGoogleDoc";
import EditFollowUpEmail from "./EditFollowUpEmail";
import EditSlackAutomation from "./EditSlackStep";
// import EditGoogleDocAutomation from "./EditGoogleDocStep";

/**
 * MeetingWorkflowAutomationStep Component
 *
 * A conversational UI for selecting and configuring automation types
 * in the meeting workflow. Once the user completes the wizard,
 * this component creates the meeting action itself (via fetch/POST)
 * and finally calls `onAutomationCreated(finalActionData)`.
 *
 * Props:
 * - step: { ... } -- meta or ID for reference (optional usage)
 * - meetingTypeId: string|number -- the ID of the parent Meeting Type
 * - onAutomationCreated: function -- called with the newly created automation data
 * - onCancel: function -- user canceled creating automation
 * - userEmail: string -- optional, used in sub-steps
 * - meetingTypeName: string -- used for display or placeholders
 */
export default function MeetingWorkflowAutomationStep({
  step,
  meetingTypeId, // The parent "Meeting Type" ID
  onAutomationCreated,
  onTypeSelect, // Make sure this prop is declared
  onCancel,
  userEmail = "",
  meetingTypeName = "Meeting",
}) {
  // --------------------------
  // State management
  // --------------------------
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedType, setSelectedType] = useState("");
  const [automationName, setAutomationName] = useState("");
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Track whether initial selection is complete
  const [initialSetupComplete, setInitialSetupComplete] = useState(false);

  // Our "combined" automation data (type, name, plus sub-step config)
  const [automationData, setAutomationData] = useState({});

  // Refs
  const contentRef = useRef(null);
  const inputRefs = useRef([]);

  // --------------------------
  // Automation Type Options
  // --------------------------
  const automationTypes = [
    {
      id: "follow_up_email",
      name: "Follow-up Email Draft",
      description:
        "Automatically draft personalized follow-up emails based on meeting content and action items",
      icon: MailIcon,
      color: "bg-orange-500",
    },
    {
      id: "slack_post",
      name: "Post in Slack Channel",
      description:
        "Share meeting summaries and action items in your team's Slack channels",
      icon: ChatIcon,
      color: "bg-purple-500",
    },
    {
      id: "google_doc",
      name: "Create Google Doc",
      description:
        "Generate structured Google Documents with meeting notes, summaries, and action items",
      icon: DocumentTextIcon,
      color: "bg-blue-500",
    },
  ];

  // --------------------------
  // Questions / Steps
  // --------------------------
  const questions = [
    {
      id: "automation-type",
      title: "What type of automation would you like to add?",
      subtitle:
        "Choose an automation to enhance your workflow and save time after meetings",
      content: () => renderAutomationTypeSelection(),
      validate: () => selectedType !== "",
      errorMessage: "Please select an automation type to continue",
      inputType: "select",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // --------------------------
  // Effects
  // --------------------------
  useEffect(() => {
    // Scroll to top of content when question changes
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }

    // Focus on input if available
    const timer = setTimeout(() => {
      if (inputRefs.current[currentQuestion]) {
        inputRefs.current[currentQuestion]?.focus();
      }
    }, 400);

    return () => clearTimeout(timer);
  }, [currentQuestion]);

  // Automatically set default name when selecting a type
  useEffect(() => {
    if (selectedType && !automationName) {
      // Set a default name based on the selected automation type
      switch (selectedType) {
        case "follow_up_email":
          setAutomationName(`${meetingTypeName} Follow-up Email`);
          break;
        case "slack_post":
          setAutomationName(`${meetingTypeName} Slack Update`);
          break;
        case "google_doc":
          setAutomationName(`${meetingTypeName} Meeting Document`);
          break;
        default:
          setAutomationName("");
      }
    }
  }, [selectedType, automationName, meetingTypeName]);

  // If the user picked some type that doesn't have a specialized subcomponent,
  // we can immediately finalize. (Currently all 3 have subcomponents, but let's keep this logic.)
  useEffect(() => {
    if (
      initialSetupComplete &&
      selectedType &&
      !["follow_up_email", "slack_post", "google_doc"].includes(selectedType)
    ) {
      // Just finalize creation with basic data
      finalizeAutomationCreation(automationData);
    }
  }, [initialSetupComplete, selectedType, automationData]);

  // --------------------------
  // Create Meeting Action
  // --------------------------
  async function createMeetingAction(actionData) {
    try {
      const response = await fetch("https://backend.scribbl.co/meeting-actions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(actionData),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to create meeting action: ${response.statusText}`
        );
      }

      return await response.json();
    } catch (error) {
      console.error("Error creating meeting action:", error);
      // Optionally, you could add toast notifications or error state
      return null;
    }
  }

  /**
   * finalizeAutomationCreation
   *
   * Called after we've gone through the sub-step wizard for the selected type.
   * This function will:
   * 1. Combine the user-provided data into a final action payload
   * 2. POST to the server to create the new action
   * 3. Call the parent's `onAutomationCreated` with the result
   */
  async function finalizeAutomationCreation(finalData) {
    if (!meetingTypeId) {
      console.warn(
        "No meetingTypeId provided. Cannot attach automation to a meeting type."
      );
      // We can still call onAutomationCreated with local data to keep the flow consistent
      if (onAutomationCreated) {
        onAutomationCreated({
          ...finalData,
          error: "No meetingTypeId provided, action not created in server",
        });
      }
      return;
    }

    // Build the final server payload
    const payload = {
      meetingTypeID: meetingTypeId,
      name: finalData.name,
      actionType: finalData.type,
      description: `Automated action for ${finalData.name}`,
      customData: finalData,
      isGlobal: false,
    };

    // Attempt to create on server
    setIsTransitioning(true);
    const createdAction = await createMeetingAction(payload);
    setIsTransitioning(false);

    // Regardless of success/fail, call parent's callback with the local final data
    // If creation succeeded, we'll include the newly created action's server fields
    if (onAutomationCreated) {
      onAutomationCreated({
        ...finalData,
        ...createdAction, // merges server data, e.g. ID, timestamps, etc.
      });
    }
  }

  // --------------------------
  // Wizard Navigation
  // --------------------------
  const handleNext = () => {
    const currentQ = questions[currentQuestion];

    // Validate
    if (!currentQ.validate(currentQ.value)) {
      return;
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      // If we're at the last step, proceed to complete
      handleComplete();
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
    }
  };

  const handleSelectType = (typeId) => {
    // Simply update local state for the selection.
    setSelectedType(typeId);
    let newName = "";
    switch (typeId) {
      case "follow_up_email":
        newName = `${meetingTypeName} Follow-up Email`;
        break;
      case "slack_post":
        newName = `${meetingTypeName} Slack Update`;
        break;
      case "google_doc":
        newName = `${meetingTypeName} Meeting Document`;
        break;
      default:
        newName = `${meetingTypeName} Automation`;
    }
    setAutomationName(newName);
  };
  const handleComplete = () => {
    setIsTransitioning(true);

    // Consolidate basic wizard data
    const newAutomationData = {
      type: selectedType,
      name: automationName,
    };
    setAutomationData(newAutomationData);

    // Move to the specialized sub-step for final configuration
    setInitialSetupComplete(true);
    setIsTransitioning(false);
  };

  // Called by the specialized sub-component
  const handleSpecificAutomationComplete = (specificData) => {
    // Combine the wizard data with the sub-step data
    const completeData = {
      ...automationData,
      ...specificData,
    };

    // Do NOT create the action here - just pass the data to parent
    if (onAutomationCreated) {
      onAutomationCreated(completeData);
    }
  };

  // --------------------------
  // Render: initial wizard
  // --------------------------
  function renderAutomationTypeSelection() {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="space-y-6 mt-6"
      >
        <div className="bg-brand-turq bg-opacity-5 rounded-lg p-4 border border-brand-turq border-opacity-15 mb-4">
          <div className="flex items-start">
            <InformationCircleIcon className="h-5 w-5 text-brand-turq mr-3 flex-shrink-0 mt-0.5" />
            <div>
              <h3 className="text-sm font-medium text-gray-800 mb-1">
                Automations help you save time after meetings
              </h3>
              <p className="text-xs text-gray-600">
                Add automated actions that happen after your meetings. These can
                help with follow-up communication, documentation, and team
                coordination.
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 p-1">
          {automationTypes.map((type) => (
            <motion.button
              key={type.id}
              whileHover={{
                scale: 1.02,
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
              }}
              whileTap={{ scale: 0.98 }}
              onClick={() => handleSelectType(type.id)}
              className={classNames(
                "relative flex items-center p-4 rounded-lg border-2 text-left transition-all duration-150",
                selectedType === type.id
                  ? "border-brand-turq bg-brand-turq bg-opacity-5 shadow-sm"
                  : "border-gray-200 hover:border-gray-300 bg-white"
              )}
            >
              <div
                className={classNames(
                  "flex items-center justify-center h-12 w-12 rounded-full text-white mr-4",
                  type.color
                )}
              >
                <type.icon className="h-6 w-6" />
              </div>

              <div className="flex-1">
                <h4 className="text-base font-medium text-gray-900 flex items-center">
                  {type.name}
                  {type.id === "follow_up_email" && (
                    <span className="ml-2 text-xs px-2 py-0.5 bg-brand-turq bg-opacity-10 text-brand-turq rounded-full">
                      Popular
                    </span>
                  )}
                </h4>
                <p className="mt-1 text-sm text-gray-500">{type.description}</p>
              </div>

              <div className="ml-4 flex-shrink-0">
                {selectedType === type.id ? (
                  <div className="h-6 w-6 rounded-full bg-brand-turq flex items-center justify-center">
                    <CheckIcon className="h-4 w-4 text-white" />
                  </div>
                ) : (
                  <div className="h-6 w-6 rounded-full border-2 border-gray-300"></div>
                )}
              </div>
            </motion.button>
          ))}
        </div>
      </motion.div>
    );
  }

  // --------------------------
  // Render specialized sub-component
  // --------------------------
  function renderSpecificAutomation() {
    switch (selectedType) {
      case "follow_up_email":
        // If the "step" was previously complete, show the "Edit" version
        if (step?.status === "complete") {
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <EditFollowUpEmail
                initialData={automationData}
                onDataChange={(data) => {
                  if (
                    !isEqual(automationData, { ...automationData, ...data })
                  ) {
                    setAutomationData((prevData) => ({ ...prevData, ...data }));
                  }
                }}
                onComplete={handleSpecificAutomationComplete}
                onCancel={() => setInitialSetupComplete(false)}
                userEmail={userEmail}
                meetingTypeName={meetingTypeName}
                onSwitchToConversational={() => setInitialSetupComplete(false)}
              />
            </React.Suspense>
          );
        } else {
          // Otherwise, show the conversational sub-step
          return (
            <MeetingWorkflowFollowUpEmail
              initialData={automationData}
              onComplete={handleSpecificAutomationComplete}
              onCancel={() => setInitialSetupComplete(false)}
              userEmail={userEmail}
              meetingTypeName={meetingTypeName}
              meetingTypeId={meetingTypeId}
            />
          );
        }

      case "slack_post":
        if (step?.status === "complete") {
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <EditSlackAutomation
                initialData={automationData}
                onDataChange={(data) => {
                  if (
                    !isEqual(automationData, { ...automationData, ...data })
                  ) {
                    setAutomationData((prevData) => ({ ...prevData, ...data }));
                  }
                }}
                onComplete={handleSpecificAutomationComplete}
                onCancel={() => setInitialSetupComplete(false)}
                userEmail={userEmail}
                meetingTypeName={meetingTypeName}
                onSwitchToConversational={() => setInitialSetupComplete(false)}
              />
            </React.Suspense>
          );
        } else {
          return (
            <MeetingWorkflowSlack
              initialData={automationData}
              onDataChange={(data) => {
                if (!isEqual(automationData, { ...automationData, ...data })) {
                  setAutomationData((prevData) => ({ ...prevData, ...data }));
                }
              }}
              onComplete={handleSpecificAutomationComplete}
              onCancel={() => setInitialSetupComplete(false)}
              userEmail={userEmail}
              meetingTypeName={meetingTypeName}
              meetingTypeId={meetingTypeId}
            />
          );
        }

      case "google_doc":
        if (step?.status === "complete") {
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              {/* 
                <EditGoogleDocAutomation
                  initialData={automationData}
                  onDataChange={(data) => {
                    if (!isEqual(automationData, {...automationData, ...data})) {
                      setAutomationData((prevData) => ({ ...prevData, ...data }));
                    }
                  }}
                  onComplete={handleSpecificAutomationComplete}
                  onCancel={() => setInitialSetupComplete(false)}
                  userEmail={userEmail}
                  meetingTypeName={meetingTypeName}
                  onSwitchToConversational={() => setInitialSetupComplete(false)}
                /> 
              */}
              <div className="p-4">
                <p className="text-gray-700 text-sm">
                  <em>EditGoogleDocAutomation</em> component is commented out.
                </p>
              </div>
            </React.Suspense>
          );
        } else {
          return (
            <MeetingWorkflowGoogleDoc
              initialData={automationData}
              onDataChange={(data) => {
                if (!isEqual(automationData, { ...automationData, ...data })) {
                  setAutomationData((prevData) => ({ ...prevData, ...data }));
                }
              }}
              onComplete={handleSpecificAutomationComplete}
              onCancel={() => setInitialSetupComplete(false)}
              userEmail={userEmail}
              meetingTypeName={meetingTypeName}
              meetingTypeId={meetingTypeId}
            />
          );
        }

      default:
        // No specialized UI. The effect above will finalize automatically if needed.
        return (
          <div className="flex items-center justify-center h-full">
            <div className="text-center">
              <svg
                className="animate-spin h-10 w-10 text-brand-turq mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p className="mt-3 text-gray-700 font-medium">Processing...</p>
            </div>
          </div>
        );
    }
  }

  // --------------------------
  // Main Render
  // --------------------------
  return (
    <div className="h-full flex flex-col">
      {initialSetupComplete ? (
        // Render the specialized sub-step wizard
        renderSpecificAutomation()
      ) : (
        // Render the initial 2-question wizard (type + name)
        <div className="h-full flex flex-col">
          <div
            ref={contentRef}
            className="flex-1 bg-white rounded-t-xl shadow-sm border border-gray-200 border-b-0 overflow-hidden"
          >
            <div className="p-6 h-full flex flex-col relative">
              {/* Progress steps */}
              {currentQuestion > 0 && (
                <div className="flex items-center justify-center mb-6">
                  <div className="bg-gray-100 rounded-full h-1.5 w-64 overflow-hidden">
                    <motion.div
                      className="h-full bg-brand-turq rounded-full"
                      initial={{ width: "0%" }}
                      animate={{
                        width: `${
                          ((currentQuestion + 1) / questions.length) * 100
                        }%`,
                      }}
                      transition={{ duration: 0.3 }}
                    />
                  </div>
                  <div className="ml-3 text-xs text-gray-500 font-medium">
                    {currentQuestion + 1}/{questions.length}
                  </div>
                </div>
              )}

              {/* Fixed-height content area with internal scrolling */}
              <div className="flex-1 overflow-y-auto">
                <AnimatePresence mode="wait">
                  {questions[currentQuestion]?.content?.()}
                </AnimatePresence>
              </div>
            </div>
          </div>

          {/* Footer (outside scroll container) */}
          <div className="bg-white rounded-b-xl border border-t-0 border-gray-200 shadow-sm">
            <div className="px-6 py-4 flex justify-between items-center bg-gray-50 bg-opacity-70">
              {currentQuestion > 0 ? (
                <button
                  onClick={handlePrevious}
                  className="flex items-center px-4 py-2 text-brand-turq rounded-lg hover:bg-brand-turq hover:bg-opacity-5 transition-colors"
                >
                  <ArrowLeftIcon className="h-4 w-4 mr-1.5" />
                  <span className="font-medium">Back</span>
                </button>
              ) : (
                <button
                  onClick={onCancel}
                  className="flex items-center px-4 py-2 text-gray-500 rounded-lg hover:bg-gray-100 transition-colors"
                >
                  <XIcon className="h-4 w-4 mr-1.5" />
                  <span className="font-medium">Cancel</span>
                </button>
              )}

              <button
                onClick={handleNext}
                disabled={
                  !questions[currentQuestion].validate(
                    questions[currentQuestion].value
                  ) || isTransitioning
                }
                className={classNames(
                  "inline-flex items-center px-5 py-2.5 rounded-lg font-medium transition-colors",
                  questions[currentQuestion].validate(
                    questions[currentQuestion].value
                  ) && !isTransitioning
                    ? "bg-brand-turq text-white hover:bg-brand-green shadow-sm"
                    : "bg-gray-200 text-gray-400 cursor-not-allowed"
                )}
              >
                {isTransitioning ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Processing...
                  </>
                ) : currentQuestion === questions.length - 1 ? (
                  <>
                    Continue to Setup
                    <ArrowRightIcon className="h-5 w-5 ml-2" />
                  </>
                ) : (
                  <>
                    Continue
                    <ArrowRightIcon className="h-5 w-5 ml-2" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
